import React, { VFC } from "react";
import { useTranslation } from "react-i18next";

import {
  pxToRem,
  formatNumber,
  formatDate,
  ratioToPerformance
} from "../../../util/helpers";
import { AnalyticsAd as AnalyticsAdProps } from "../../../types";
import AnalyticsAd from "../AnalyticsAd";
import {
  AnalyticsTile,
  Table,
  TableHead,
  TableBody,
  TH,
  TR,
  TD,
  SortingButton,
  SortingIcon
} from "./styled";

interface AnalyticsTableProps {
  isCommunity: boolean;
  ads: AnalyticsAdProps[];
  toggleViewsSorting: () => void;
  toggleClicksSorting: () => void;
  toggleRatioSorting: () => void;
  toggleDateSorting: () => void;
  viewsOrder: string;
  clicksOrder: string;
  ratioOrder: string;
  dateOrder: string;
  locale: string;
}

const AnalyticsTable: VFC<AnalyticsTableProps> = ({
  isCommunity,
  ads,
  toggleViewsSorting,
  toggleClicksSorting,
  toggleRatioSorting,
  toggleDateSorting,
  viewsOrder,
  clicksOrder,
  ratioOrder,
  dateOrder,
  locale
}) => {
  const { t } = useTranslation();

  return (
    <>
      <AnalyticsTile>
        <Table>
          <TableHead>
            <TR>
              <TH>
                {isCommunity
                  ? t("general.analytics.ads_lo_com")
                  : t("general.analytics.ads")}
              </TH>
              <TH>
                <SortingButton type="button" onClick={toggleViewsSorting}>
                  {t("general.analytics.views")}
                  <SortingIcon
                    name="arrow-down"
                    size={pxToRem(24)}
                    order={viewsOrder}
                  />
                </SortingButton>
              </TH>
              <TH>
                <SortingButton type="button" onClick={toggleClicksSorting}>
                  {t("general.analytics.clicks")}
                  <SortingIcon
                    name="arrow-down"
                    size={pxToRem(24)}
                    order={clicksOrder}
                  />
                </SortingButton>
              </TH>
              <TH>
                <SortingButton type="button" onClick={toggleRatioSorting}>
                  {t("general.analytics.ratio")}
                  <SortingIcon
                    name="arrow-down"
                    size={pxToRem(24)}
                    order={ratioOrder}
                  />
                </SortingButton>
              </TH>
              <TH>
                <SortingButton type="button" onClick={toggleDateSorting}>
                  {t("general.analytics.publication_date")}
                  <SortingIcon
                    name="arrow-down"
                    size={pxToRem(24)}
                    order={dateOrder}
                  />
                </SortingButton>
              </TH>
            </TR>
          </TableHead>
          <TableBody>
            {ads?.map((ad, index) => (
              <TR key={ad.id}>
                <TD index={index} itemsLength={ads.length}>
                  <AnalyticsAd {...ad} titleAs="h2" />
                </TD>
                <TD index={index} itemsLength={ads.length}>
                  {formatNumber(ad.views)}
                </TD>
                <TD index={index} itemsLength={ads.length}>
                  {formatNumber(ad.clicks)}
                </TD>
                <TD index={index} itemsLength={ads.length}>
                  {ratioToPerformance(ad.ratio)}
                </TD>
                <TD index={index} itemsLength={ads.length}>
                  {formatDate(ad.publishedAt, locale)}
                </TD>
              </TR>
            ))}
          </TableBody>
        </Table>
      </AnalyticsTile>
    </>
  );
};

export default AnalyticsTable;
