import React, { VFC } from "react";
import { useTranslation } from "react-i18next";

import { pxToRem, formatNumber } from "../../../util/helpers";
import Box from "../../particles/Box";
import Tile from "../../atoms/Tile";
import Text from "../../atoms/Text";

interface WidgetsAnalyticsProps {
  isCommunity: boolean;
  title: string;
  titleAs?: string;
  adsCount: number;
  views: number;
  clicks: number;
}

// TODO: improve mobile version; discuss with YK;

const WidgetsAnalytics: VFC<WidgetsAnalyticsProps> = ({
  isCommunity,
  title,
  titleAs,
  adsCount,
  views,
  clicks
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Tile
        paddingV={3}
        paddingH={6}
        width="100%"
        className="ddb-desktop ddb-desktop-s ddb-mobile-l"
      >
        <Text as={titleAs} styledAs="h4" marginB={1}>
          {title}
        </Text>
        <Box>
          <Box inlineBlock marginR={6}>
            <Text details>{isCommunity ? t("general.dashboard.ads_views_lo_com") : t("general.dashboard.ads_views")}</Text>
            <Text styledAs="h2" lineHeight={pxToRem(32)}>
              {formatNumber(views)}
            </Text>
          </Box>
          <Box inlineBlock marginR={6}>
            <Text details>{isCommunity ? t("general.dashboard.ads_clicks_lo_com") : t("general.dashboard.ads_clicks")}</Text>
            <Text styledAs="h2" lineHeight={pxToRem(32)}>
              {formatNumber(clicks)}
            </Text>
          </Box>
          <Box inlineBlock>
            <Text details>{isCommunity ? t("general.dashboard.ads_published_lo_com") : t("general.dashboard.ads_published")}</Text>
            <Text styledAs="h2" lineHeight={pxToRem(32)}>
              {formatNumber(adsCount)}
            </Text>
          </Box>
        </Box>
      </Tile>
      <Tile paddingV={1.75} paddingH={1} width="100%" className="ddb-mobile">
        <Text as={titleAs} styledAs="p" marginB={0.5}>
          {title}
        </Text>
        <Box>
          <Box inlineBlock marginR={3}>
            <Text details>{isCommunity ? t("general.dashboard.ads_views_lo_com") : t("general.dashboard.ads_views")}</Text>
            <Text styledAs="h3">{formatNumber(views)}</Text>
          </Box>
          <Box inlineBlock marginR={3}>
            <Text details>{isCommunity ?  t("general.dashboard.ads_clicks_lo_com") : t("general.dashboard.ads_clicks")}</Text>
            <Text styledAs="h3">{formatNumber(clicks)}</Text>
          </Box>
          <Box inlineBlock>
            <Text details>{isCommunity ? t("general.dashboard.ads_published_lo_com") : t("general.dashboard.ads_published")}</Text>
            <Text styledAs="h3">{formatNumber(adsCount)}</Text>
          </Box>
        </Box>
      </Tile>
    </>
  );
};

WidgetsAnalytics.defaultProps = {
  titleAs: "h3"
};

export default WidgetsAnalytics;
