import React, { VFC, useEffect, useState, useContext } from "react";
import { useSearchParams, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AppContext } from "../../components/_functional/AppProvider";
import { checkNewPasswordToken } from "../../util/apiRequests";
import { getCookieByName } from "../../util/cookieProvider";
import CleanPageLayout from "../../components/templates/CleanPageLayout";
import Button from "../../components/atoms/Button";
import Loader from "../../components/atoms/Loader";
import Component from "./Component";

const NewPassword: VFC = () => {
  const isUserAuthenticated = Boolean(getCookieByName("ddb_session_token"));

  if (isUserAuthenticated) return <Navigate to="/" replace />;

  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const resetPasswordToken = searchParams.get("reset_password_token");

  const { storeMessage } = useContext(AppContext);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!resetPasswordToken) {
      storeMessage({
        status: "warning",
        icon: "warning",
        title: t("errors.generic_title"),
        titleAs: "h1",
        text: t("errors.unauthorized")
      });

      setIsLoaded(true);
    } else {
      checkNewPasswordToken(resetPasswordToken).then(({ status }) => {
        if (status === "invalid") {
          storeMessage({
            status: "warning",
            icon: "warning",
            title: t("errors.generic_title"),
            titleAs: "h1",
            text: t("errors.invalid_new_password_token"),
            controlElement: (
              <Button primary to="/request_new_password">
                {t("session.expired_new_password_token_submit")}
              </Button>
            )
          });
        }

        setIsLoaded(true);
      });
    }
  }, [resetPasswordToken]);

  return (
    <Loader active={!isLoaded}>
      <CleanPageLayout>
        <Component resetPasswordToken={resetPasswordToken} />
      </CleanPageLayout>
    </Loader>
  );
};

export default NewPassword;
