import React, { VFC, useMemo } from "react";
import * as DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";

import { Contacts } from "../../types";
import { pxToRem, pxToEm } from "../../util/helpers";
import Box from "../../components/particles/Box";
import Transition from "../../components/particles/Transition";
import Text from "../../components/atoms/Text";
import PageMessage from "../../components/templates/PageMessage";

interface ContactsComponentProps {
  contacts: Contacts;
}

const ContactsComponent: VFC<ContactsComponentProps> = ({
  contacts
}) => {
  const { t } = useTranslation();

  const contactsText = useMemo(() => {
    if (!contacts.text) return "";
    const emailReg = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/g;

    let text = contacts.text.replace(emailReg, '<a href="mailto:$1">$1</a>');
    text = DOMPurify.sanitize(text, { USE_PROFILES: { html: true } });

    return text;
  }, [contacts]);

  return (
    <Transition>
      <Box
        as="main"
        id="ddb_main_content"
        className="ddb-max-content-width"
        marginT={3}
        marginB={3}
        paddingH={2}
      >
        <PageMessage
          title={t("general.contacts.title", { newspaper: contacts.newspaperName })}
          textElement={
            <Text
              formatted
              textLeft
              fontSize={pxToRem(18)}
              lineHeight={pxToEm(28, 18)}
              dangerouslySetInnerHTML={{ __html: contactsText }}
            />
          }
          icon="email"
        />
      </Box>
    </Transition>
  );
};

export default ContactsComponent;
