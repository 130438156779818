import React, { VFC, useEffect, useState, useContext } from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AppContext } from "../../components/_functional/AppProvider";
import { checkInvitationToken } from "../../util/apiRequests";
import Box from "../../components/particles/Box";
import Loader from "../../components/atoms/Loader";
import PageMessage from "../../components/templates/PageMessage";
import PageLayout from "../../components/templates/PageLayout";
import Component from "./Component";

const SignUp: VFC = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const invitationToken = searchParams.get("invitation_token");
  const redirectUrl = searchParams.get("redirect");

  const { newspaperSlug } = useParams();

  const { message, storeMessage } = useContext(AppContext);

  const [tokenStatus, setTokenStatus] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!invitationToken) {
      storeMessage({
        status: "warning",
        icon: "warning",
        title: t("errors.generic_title"),
        titleAs: "h1",
        text: t("errors.unauthorized")
      });

      setIsLoaded(true);
    } else {
      checkInvitationToken(invitationToken).then(({ status }) => {
        setTokenStatus(status);
        setIsLoaded(true);
      });
    }
  }, [invitationToken]);

  return (
    <>
      <div aria-live="polite">
        {!!message && (
          <Box paddingT={7} paddingH={1} paddingB={1}>
            <PageMessage {...message} />
          </Box>
        )}
      </div>
      {!message && (
        <Loader active={!isLoaded}>
          {tokenStatus === "invalid" ? (
            <Navigate to={`/${newspaperSlug}/widgets`} replace />
          ) : (
            <PageLayout>
              <Component invitationToken={invitationToken} redirectUrl={redirectUrl} />
            </PageLayout>
          )}
        </Loader>
      )}
    </>
  );
};

export default SignUp;
