import { FC } from "react";
import styled from "styled-components";

import LinkBase, { LinkBaseProps } from "../../atoms/LinkBase";

export const PageLink: FC<LinkBaseProps> = styled(LinkBase)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  border-radius: 8px;
  
  .ddb-keyboard-navigation-on &:focus {
    outline-width: 3px;
    outline-offset: 5px;
  }
`;

PageLink.displayName = "PageLink";

export const ControlWrap: FC = styled.div`
  z-index: 1;
`;

ControlWrap.displayName = "ControlWrap";
