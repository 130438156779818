import { FC } from "react";
import styled, { css } from "styled-components";

import { pxToRem, pxToEm } from "../../../util/helpers";
import constants from "../../_globalStyles/constants";
import Box, { BoxProps } from "../../particles/Box";

export interface TextProps extends BoxProps {
  small?: boolean;
  smallest?: boolean;
  big?: boolean;
  details?: boolean;
  thin?: boolean;
  medium?: boolean;
  bold?: boolean;
  uppercase?: boolean;
  capitalize?: boolean;
  formatted?: boolean;
  ellipsis?: boolean;
  break?: boolean;
  scroll?: boolean;
  spacing?: string;
  lineHeight?: string;
  weight?: "100" | "300" | "400" | "500" | "700";
  color?: string;
  fontSize?: string;
  styledAs?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
  as?: string;
  responsive?: boolean;
  clip?: number | string;
}

const getColor = (props: TextProps): string => {
  if (props.details) return constants.TEXT_LIGHTER_COLOR;
  if (props.color) return props.color;
  return null;
};

const getWeight = (props: TextProps): string => {
  if (props.thin) return "100";
  if (props.medium) return "500";
  if (props.bold) return "700";
  if (props.weight) return props.weight;
  return null;
};

const getTransform = (props: TextProps): string => {
  if (props.uppercase) return "uppercase";
  if (props.capitalize) return "capitalize";
  return null;
};

const getSpacing = (props: TextProps): string => {
  if (props.uppercase) return "0.0714em";
  if (props.spacing) return props.spacing;
  return null;
};

const Text: FC<TextProps> = styled(Box).attrs(({ as = "p" }) => ({ as }))`
  ${(props) =>
    props.smallest &&
    css`
      font-size: ${pxToRem(12)};
      line-height: ${pxToEm(16, 12)};
    `}

  ${(props) =>
    (props.small || props.details) &&
    css`
      font-size: ${pxToRem(14)};
      line-height: ${pxToEm(20, 14)};
    `}

  ${(props) =>
    props.big &&
    css`
      font-size: ${pxToRem(20)};
      line-height: ${pxToEm(24, 20)};
    `}

  ${(props) =>
    (props.styledAs === "h1" ||
      props.styledAs === "h2" ||
      props.styledAs === "h3" ||
      props.styledAs === "h4" ||
      props.styledAs === "h5" ||
      props.styledAs === "h6") &&
    css`
      font-weight: 700;
    `}

  ${(props) =>
    props.styledAs === "h1" &&
    css`
      font-size: ${pxToRem(40)};
      line-height: ${pxToEm(44, 40)};
    `}

  // TODO: support other headings & p responsiveness when it's needed.
  ${(props) =>
    props.styledAs === "h1" &&
    props.responsive &&
    css`
      @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
        font-size: ${40 / constants.BREAKPOINT_DESKTOP * 100}vw;
      }
    `}

  ${(props) =>
    props.styledAs === "h2" &&
    css`
      font-size: ${pxToRem(32)};
      line-height: ${pxToEm(36, 32)};
    `}

  ${(props) =>
    props.styledAs === "h3" &&
    css`
      font-size: ${pxToRem(24)};
      line-height: ${pxToEm(28, 24)};
    `}

  ${(props) =>
    props.styledAs === "h4" &&
    css`
      font-size: ${pxToRem(20)};
      line-height: ${pxToEm(24, 20)};
    `}

  ${(props) =>
    props.styledAs === "h5" &&
    css`
      font-size: ${pxToRem(18)};
      line-height: ${pxToEm(22, 18)};
    `}

  ${(props) =>
    props.styledAs === "h6" &&
    css`
      font-size: ${pxToRem(16)};
      line-height: ${pxToEm(24, 16)};
    `}

  ${(props) =>
    props.styledAs === "p" &&
    css`
      font-size: ${pxToRem(16)};
      line-height: ${pxToEm(24, 16)};

      a:hover,
      a:active,
      a:focus {
        text-decoration: underline;
      }
    `}

  ${(props) =>
    props.as === "a" &&
    css`
      &,
      &:link,
      &:visited {
        color: ${constants.LINK_COLOR} !important;
        text-decoration: none;
      }

      &:hover {
        color: ${constants.LINK_HOVER_COLOR} !important;
      }

      &:active,
      &:focus {
        color: ${constants.LINK_ACTIVE_COLOR} !important;
      }

      &:hover,
      &:active,
      &:focus {
        text-decoration: underline !important;
      }
    `}

  ${(props) =>
    props.as === "button" &&
    css`
      border: none;
      box-shadow: none;
      background: transparent;
      cursor: pointer;
    `}
  
  ${(props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}

  ${(props) =>
    props.lineHeight &&
    css`
      line-height: ${props.lineHeight};
    `}
  
  ${(props) =>
    props.formatted &&
    css`
      white-space: pre-line;
    `}

  ${(props) =>
    props.ellipsis &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
  
  ${(props) =>
    props.clip &&
    css`
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${props.clip};
      overflow: hidden;
      word-break: normal;
      overflow-wrap: anywhere;
    `}

  ${(props) =>
    props.scroll &&
    css`
      overflow: scroll;
      white-space: nowrap;
    `}

  ${(props) =>
    props.break &&
    css`
      word-break: break-all;
    `}
   
  color: ${getColor};
  font-weight: ${getWeight};
  text-transform: ${getTransform};
  letter-spacing: ${getSpacing};
  margin: 0;
  padding: 0;
`;

Text.displayName = "Text";

export default Text;
