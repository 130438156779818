import { FC } from "react";
import styled from "styled-components";

import constants from "../../_globalStyles/constants";
import Box, { BoxProps } from "../../particles/Box";

export const MessageBox: FC<BoxProps> = styled(Box)`
  a,
  a:link,
  a:visited,
  a:hover,
  a:active,
  a:focus {
    color: ${constants.LINK_HOVER_COLOR} !important;
  }
`;

MessageBox.displayName = "MessageBox";
