import { FC, HTMLProps } from "react";
import styled from "styled-components";

import constants from "../../_globalStyles/constants";
import Text, { TextProps } from "../../atoms/Text";
import Icon, { IconProps } from "../../atoms/Icon";
import Tile, { TileProps } from "../../atoms/Tile";
import LinkBase, { LinkBaseProps } from "../../atoms/LinkBase";

interface AdTileProps extends TileProps {
  active: boolean;
}

export const AdTile: FC<AdTileProps> = styled(Tile)`
  position: relative;
  overflow: visible;
  width: 100%;
  max-width: ${constants.GRID_UNIT * 121}px;
  box-shadow: 0 0 0 2px
    ${({ active }) =>
      active
        ? "rgba(0, 163, 0, 0.5)"
        : constants.BORDER_COLOR}; // = active shadow color = COLOR_GREEN_300;
`;

AdTile.displayName = "AdTile";

export const AdMedia: FC<HTMLProps<HTMLImageElement>> = styled.img`
  width: ${constants.GRID_UNIT * 16}px;
  height: ${constants.GRID_UNIT * 16}px;
  object-fit: cover;
  border-radius: ${constants.GRID_UNIT}px;
  overflow: hidden;

  @media (max-width: ${constants.BREAKPOINT_MOBILE_L}px) {
    width: ${constants.GRID_UNIT * 9}px;
    height: ${constants.GRID_UNIT * 9}px;
  }
`;

AdMedia.displayName = "AdMedia";

export const AdMediaPlaceholder: FC = styled.div`
  position: relative;
  width: ${constants.GRID_UNIT * 16}px;
  height: ${constants.GRID_UNIT * 16}px;
  background: ${constants.COLOR_GRAY_100};
  border-radius: ${constants.GRID_UNIT}px;
  overflow: hidden;

  @media (max-width: ${constants.BREAKPOINT_MOBILE_L}px) {
    width: ${constants.GRID_UNIT * 9}px;
    height: ${constants.GRID_UNIT * 9}px;
  }
`;

AdMediaPlaceholder.displayName = "AdMediaPlaceholder";

export const AdMediaIcon: FC<IconProps> = styled(Icon)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: ${constants.NO_MEDIA_ICON_COLOR};
  font-size: ${constants.GRID_UNIT * 8}px;

  @media (max-width: ${constants.BREAKPOINT_MOBILE_L}px) {
    font-size: ${constants.GRID_UNIT * 5}px;
  }
`;

AdMediaIcon.displayName = "AdMediaIcon";

export const AdLink: FC<LinkBaseProps> = styled(LinkBase)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: ${constants.GRID_UNIT * 1.5}px;

  .ddb-keyboard-navigation-on &:focus {
    outline-width: 3px;
    outline-offset: ${constants.GRID_UNIT / 1.6}px;
  }
`;

AdLink.displayName = "AdLink";

export const AdText: FC<TextProps> = styled(Text)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: normal;
  overflow-wrap: anywhere;

  @media (max-width: ${constants.BREAKPOINT_DESKTOP_S}px) {
    -webkit-line-clamp: 3;
  }
`;

AdText.displayName = "AdText";

export const AdControl: FC = styled.div`
  position: relative;
  z-index: 5;
`;

AdControl.displayName = "AdControl";
