import React, { VFC } from "react";
import { useTranslation } from "react-i18next";

import { formatNumber, pxToRem } from "../../../util/helpers";
import Box from "../../particles/Box";
import Tile from "../../atoms/Tile";
import Text from "../../atoms/Text";

interface AnalyticsDetailsProps {
  isCommunity: boolean;
  views: number;
  clicks: number;
  adsCount: number;
}

const AnalyticsDetails: VFC<AnalyticsDetailsProps> = ({
  isCommunity, 
  views,
  clicks,
  adsCount
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Tile
        paddingV={2}
        paddingH={6}
        inlineBlock
        radius={3}
        width="100%"
        className="ddb-desktop ddb-desktop-s ddb-mobile-l"
      >
        <Box>
          <Box inlineBlock marginR={6} marginT={1}>
            <Text details marginB={1}>
              {t("general.analytics.total_views")}
            </Text>
            <Text styledAs="h2" lineHeight={pxToRem(32)}>
              {formatNumber(views)}
            </Text>
          </Box>
          <Box inlineBlock marginR={6} marginT={1}>
            <Text details marginB={1}>
              {t("general.analytics.total_clicks")}
            </Text>
            <Text styledAs="h2" lineHeight={pxToRem(32)}>
              {formatNumber(clicks)}
            </Text>
          </Box>
          <Box inlineBlock>
            <Text details marginB={1} marginT={1}>
              {isCommunity ? t("general.analytics.total_ads_lo_com") : t("general.analytics.total_ads")}
            </Text>
            <Text styledAs="h2" lineHeight={pxToRem(32)}>
              {formatNumber(adsCount)}
            </Text>
          </Box>
        </Box>
      </Tile>
      <Tile paddingV={1.75} paddingH={1} width="100%" className="ddb-mobile">
        <Box>
          <Box inlineBlock marginR={3}>
            <Text details marginB={0.5}>
              {t("general.analytics.total_views")}
            </Text>
            <Text styledAs="h3">{formatNumber(views)}</Text>
          </Box>
          <Box inlineBlock marginR={3}>
            <Text details marginB={0.5}>
              {t("general.analytics.total_clicks")}
            </Text>
            <Text styledAs="h3">{formatNumber(clicks)}</Text>
          </Box>
          <Box inlineBlock>
            <Text details marginB={0.5}>
              {isCommunity ? t("general.analytics.total_ads_lo_com") : t("general.analytics.total_ads")}
            </Text>
            <Text styledAs="h3">{formatNumber(adsCount)}</Text>
          </Box>
        </Box>
      </Tile>
    </>
  );
};

export default AnalyticsDetails;
