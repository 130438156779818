import React, { VFC, ReactNode, useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Box from "../../particles/Box";
import Text from "../../atoms/Text";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import { PopupContainer, PopupTile, CloseButton } from "./styled";

export interface PopupProps {
  isVisible?: boolean;
  onClose?: () => void;
  title?: string;
  titleAs?: string;
  text?: string;
  ctaText?: string;
  ctaAction?: () => void;
  ctaIcon?: string;
  cancelText?: string;
  cancelAction?: () => void;
  autoClose?: boolean;
  visibilityTimeout?: number;
  customContent?: ReactNode;
}

const Popup: VFC<PopupProps> = ({
  isVisible: _isVisible,
  title,
  titleAs,
  text,
  ctaText,
  ctaAction,
  ctaIcon,
  cancelText,
  cancelAction,
  onClose,
  autoClose,
  visibilityTimeout,
  customContent
}) => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(_isVisible);

  const close = useCallback(() => {
    setIsVisible(false);
    onClose && onClose();
  }, [onClose]);

  useEffect(() => {
    setIsVisible(_isVisible);
  }, [_isVisible]);

  let setIntervalId;

  useEffect(() => {
    if (autoClose) setIntervalId = setInterval(close, visibilityTimeout);

    return () => clearInterval(setIntervalId);
  }, []);

  return (
    <>
      {isVisible && (
        <>
          <PopupContainer className="ddb-desktop ddb-desktop-s ddb-mobile-l">
            <PopupTile
              relative
              paddingL={5}
              paddingR={7}
              paddingV={3}
              maxWidth={80}
              marginH={5}
              marginV={5}
            >
              <CloseButton
                clean
                onClick={close}
                label={t("general.popup.close")}
              >
                <Icon name="close" />
              </CloseButton>
              {customContent ? (
                customContent
              ) : (
                <>
                  {title && (
                    <Box marginB={1}>
                      <Text as={titleAs} styledAs="h4">
                        {title}
                      </Text>
                    </Box>
                  )}
                  {text && (
                    <Text
                      as="p"
                      formatted
                      dangerouslySetInnerHTML={{
                        __html: text
                      }}
                    />
                  )}
                  <Box marginT={1} flex alignCenter>
                    {ctaText && ctaAction && (
                      <Box marginR={1} marginT={1} inlineBlock>
                        <Button primary onClick={ctaAction}>
                          {ctaIcon && <Icon name={ctaIcon} />}
                          {ctaText}
                        </Button>
                      </Box>
                    )}
                    {cancelText && cancelAction && (
                      <Box marginT={1} inlineBlock>
                        <Button onClick={cancelAction}>{cancelText}</Button>
                      </Box>
                    )}
                  </Box>
                </>
              )}
            </PopupTile>
          </PopupContainer>
          <PopupContainer className="ddb-mobile">
            <PopupTile
              relative
              paddingH={3}
              paddingV={3}
              marginH={3}
              marginV={5}
            >
              <CloseButton
                clean
                onClick={onClose}
                label={t("general.popup.close")}
              >
                <Icon name="close" />
              </CloseButton>
              {customContent ? (
                customContent
              ) : (
                <>
                  <Box marginB={1} marginR={5}>
                    <Text as={titleAs} styledAs="h4">
                      {title}
                    </Text>
                  </Box>
                  {text && (
                    <Text
                      as="p"
                      formatted
                      dangerouslySetInnerHTML={{
                        __html: text
                      }}
                    />
                  )}
                  <Box marginT={1} flex alignCenter>
                    {ctaText && ctaAction && (
                      <Box marginR={1} marginT={1} inlineBlock>
                        <Button primary onClick={ctaAction}>
                          {ctaIcon && <Icon name={ctaIcon} />}
                          {ctaText}
                        </Button>
                      </Box>
                    )}
                    {cancelText && cancelAction && (
                      <Box marginT={1} inlineBlock>
                        <Button onClick={cancelAction}>{cancelText}</Button>
                      </Box>
                    )}
                  </Box>
                </>
              )}
            </PopupTile>
          </PopupContainer>
        </>
      )}
    </>
  );
};

Popup.defaultProps = {
  isVisible: true,
  autoClose: true,
  visibilityTimeout: 15000,
  titleAs: "h4"
};

export default Popup;
