import React, { VFC, useCallback, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import stylesConstants from "../../components/_globalStyles/constants";
import { requestSources } from "../../util/apiRequests";
import { getPages, getWidgets } from "../../util/hooks";
import { connectToFB, verifyPages } from "../../util/facebookAPIHelpers";
import { getRequestErrorMessage } from "../../util/request";
import { AppContext } from "../../components/_functional/AppProvider";
import Box from "../../components/particles/Box";
import Icon from "../../components/atoms/Icon";
import Text from "../../components/atoms/Text";
import Button from "../../components/atoms/Button";
import SwitchAnimation from "../../components/atoms/SwitchAnimation";
import Loader from "../../components/atoms/Loader";
import ConnectionsComponent from "./Component";
import { Page } from "../../types";

type NewPage = {
  id: string;
  name?: string;
  link: string;
  access_token: string;
  facebook_id: string;
  type: "facebook" | "business_instagram";
};

type PageToVerify = {
  id: string;
  name?: string;
  link: string;
  access_token: string;
  type: "facebook" | "business_instagram" | "instagram";
  fetching_enabled: boolean;
};

const CustomPopupContent = ({ title, text, onClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box inlineBlock>
        <Box flex vertical alignCenter marginT={1}>
          <Box marginB={1}>
            <SwitchAnimation />
          </Box>
          <Box marginB={1}>
            <Icon
              name="vector"
              color="rgba(0, 0, 0, 0.3)"
              width={`${stylesConstants.GRID_UNIT * 6.75}px`}
            />
          </Box>
        </Box>
      </Box>
      <Box marginB={1}>
        <Text as="h4" styledAs="h4">
          {title}
        </Text>
      </Box>
      <Text
        as="p"
        formatted
        dangerouslySetInnerHTML={{
          __html: text
        }}
      />

      <Box marginT={1}>
        <Box marginT={1} inlineBlock>
          <Button onClick={onClose}>{t("messages.close_btn")}</Button>
        </Box>
      </Box>
    </>
  );
};

const ConnectionsContainer: VFC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { newspaperSlug } = useParams();
  const [searchParams] = useSearchParams();
  const customerId = searchParams.get("customer_id");
  const customerToken = searchParams.get("admin_customer_token");

  const dashboardUrl = `/${newspaperSlug}/widgets?${searchParams.toString()}`;
  const onSuccess = useCallback(() => navigate(dashboardUrl), [dashboardUrl]);

  const {
    newspaper,
    customer,
    pages,
    storePages,
    storeModal,
    resetModal,
    storePopup,
    resetPopup
  } = useContext(AppContext);

  const { isCommunity } = newspaper;
  const filteringEnabled = customer.filteringEnabled;
  const filteringHashtag = newspaper.filteringHashtag;
  const instanceName = newspaper.instanceName || newspaper.name;

  const requestPages = useCallback(() => {
    resetModal();
    resetPopup();

    if (customerToken || (newspaperSlug && customerId)) {
      return requestSources(newspaperSlug, customerId)
        .then(storePages)
        .catch((response) =>
          storeModal({
            title: t("errors.generic_title"),
            text: getRequestErrorMessage(response, t),
            onClose: resetModal
          })
        );
    }
  }, [customerToken, newspaperSlug, customerId]);

  const isEnabledDuplicate = useCallback(
    (pageName: string): boolean => {
      if (!pageName?.length) return false;
      return pages.some((p) => p.name === pageName && p.enabled)
    }, [pages]
  );

  const getConnectedCounterpart = useCallback(
    (_page: NewPage): Page | undefined =>
      pages?.find((page) => page.remote_id === _page.id),
    [pages]
  );

  const filterNotConnected = useCallback(
    (_pages: NewPage[]): NewPage[] =>
      _pages.filter((_page) => !getConnectedCounterpart(_page)),
    [getConnectedCounterpart]
  );

  const filterErred = useCallback(
    (_pages: NewPage[]): NewPage[] =>
      _pages.filter((_page) => {
        const cp = getConnectedCounterpart(_page);
        return cp?.authError || cp?.syncError;
      }),
    [getConnectedCounterpart]
  );

  const getErred = useCallback(
    (_pages: NewPage[], id?: string): NewPage | undefined =>
      _pages.find((_page) => _page.id === id),
    []
  );

  const doEnableFetching = useCallback(
    (_pages: NewPage[]): boolean => {
      if (_pages.length > 1) return false;
      if (_pages.length === 1 && isEnabledDuplicate(_pages[0].name))
        return false;
      return true;
    },
    [pages]
  );

  const prepareToConnect = useCallback(
    (_pages: NewPage[], _defaultEnabled: boolean): PageToVerify[] =>
      _pages.map((_page) => ({
        id: _page.id,
        access_token: _page.access_token,
        link: _page.link,
        name: _page.name,
        type: _page.type,
        fetching_enabled: _defaultEnabled
      })),
    []
  );

  const prepareToReconnect = useCallback(
    (_pages: NewPage[]): PageToVerify[] =>
      _pages.map((_page) => ({
        id: _page.id,
        access_token: _page.access_token,
        link: _page.link,
        name: _page.name,
        type: _page.type,
        fetching_enabled: getConnectedCounterpart(_page)?.enabled || false
      })),
    [getConnectedCounterpart]
  );

  /**
   * Open FB login dialog to let user grant app access to their profile and pages.
   * In case of successful connection -> show popup at the bottom of the screen.
   * In case of not all the permissions granted -> show error message about the permissions.
   * In case of no pages selected -> show error message with the explanation why there might be no needed pages in the FB modal.
   * */
  const connectPages = useCallback(() => {
    resetModal();
    resetPopup();

    return connectToFB(t)
      .then((_pages: NewPage[]) => {
        if (!_pages?.length) {
          storeModal({
            title: t("errors.no_pages_to_connect_title"),
            text: t("errors.no_pages_to_connect_body"),
            ctaAction: resetModal,
            ctaText: t("errors.close_error"),
            onClose: resetModal
          });
        } else {
          const defaultEnabled = doEnableFetching(_pages);
          const _pagesToVerify = prepareToConnect(_pages, defaultEnabled);

          verifyPages(_pagesToVerify, t, newspaperSlug, customerId)
            .then(requestPages)
            .then((_pages: Page[]) => {
              const _success = defaultEnabled
                ? {
                    title: t("messages.pages_connected_title.enabled"),
                    text:
                      filteringEnabled && filteringHashtag
                        ? t(
                            "messages.pages_connected_body.enabled_and_filtering_on",
                            {
                              hashtag: filteringHashtag
                            }
                          )
                        : t(
                            "messages.pages_connected_body.enabled_and_filtering_off"
                          ),
                    ctaText: t("messages.pages_connected_body.cta_button"),
                    ctaAction: onSuccess,
                    ctaIcon: "internal-link"
                  }
                : {
                    customContent: (
                      <CustomPopupContent
                        title={t("messages.pages_connected_title.disabled")}
                        text={
                          filteringEnabled && filteringHashtag
                            ? t(
                                "messages.pages_connected_body.disabled_and_filtering_on",
                                {
                                  hashtag: filteringHashtag
                                }
                              )
                            : t(
                                "messages.pages_connected_body.disabled_and_filtering_off"
                              )
                        }
                        onClose={() => resetPopup()}
                      />
                    )
                  };

              storePopup(_success);
            })
            .catch((response) => {
              storeModal({
                title: t("errors.generic_title"),
                text: getRequestErrorMessage(response, t),
                ctaAction: resetModal,
                ctaText: t("errors.close_error"),
                onClose: resetModal
              });
            });
        }
      })
      .catch((response) => {
        storeModal({
          title: t("errors.generic_title"),
          text: getRequestErrorMessage(response, t),
          ctaAction: () => connectPages(),
          ctaText: t("errors.retry_btn"),
          secondaryAction: resetModal,
          secondaryActionText: t("errors.close_error"),
          onClose: resetModal
        });
      });
  }, [
    t,
    doEnableFetching,
    prepareToConnect,
    requestPages,
    filteringEnabled,
    filteringHashtag,
    newspaperSlug,
    customerId,
    onSuccess
  ]);

  /**
   * Open FB login dialog to let user grant access to their other not connected pages.
   * In case of successful connection -> show popup at the bottom of the screen.
   * In case of not all the permissions granted -> show error message about the permissions.
   * In case of no NEW pages selected -> show error message with the explanation why there might be no needed pages in the FB modal.
   */
  const connectMorePages = useCallback(() => {
    resetModal();
    resetPopup();

    return connectToFB(t)
      .then((_pages: NewPage[]) => {
        _pages = filterNotConnected(_pages);

        if (!_pages?.length) {
          storeModal({
            title: t("errors.no_more_pages_to_connect_title"),
            text: t("errors.no_more_pages_to_connect_body"),
            ctaAction: resetModal,
            ctaText: t("errors.close_error"),
            onClose: resetModal
          });
        } else {
          const defaultEnabled = doEnableFetching(_pages);
          const _pagesToVerify = prepareToConnect(_pages, defaultEnabled);

          verifyPages(_pagesToVerify, t, newspaperSlug, customerId)
            .then(requestPages)
            .then((_pages: Page[]) => {
              const _success = defaultEnabled
                ? {
                    title: t("messages.pages_connected_title.enabled"),
                    text:
                      filteringEnabled && filteringHashtag
                        ? t(
                            "messages.pages_connected_body.enabled_and_filtering_on",
                            {
                              hashtag: filteringHashtag
                            }
                          )
                        : t(
                            "messages.pages_connected_body.enabled_and_filtering_off"
                          ),
                    ctaText: t("messages.pages_connected_body.cta_button"),
                    ctaAction: onSuccess,
                    ctaIcon: "internal-link"
                  }
                : {
                    customContent: (
                      <CustomPopupContent
                        title={t("messages.pages_connected_title.disabled")}
                        text={
                          filteringEnabled && filteringHashtag
                            ? t(
                                "messages.pages_connected_body.disabled_and_filtering_on",
                                {
                                  hashtag: filteringHashtag
                                }
                              )
                            : t(
                                "messages.pages_connected_body.disabled_and_filtering_off"
                              )
                        }
                        onClose={() => resetPopup()}
                      />
                    )
                  };

              storePopup(_success);
            })
            .catch((response) =>
              storeModal({
                title: t("errors.generic_title"),
                text: getRequestErrorMessage(response, t),
                ctaAction: resetModal,
                ctaText: t("errors.close_error"),
                onClose: resetModal
              })
            );
        }
      })
      .catch((response) =>
        storeModal({
          title: t("errors.generic_title"),
          text: getRequestErrorMessage(response, t),
          ctaAction: () => connectMorePages(),
          ctaText: t("errors.retry_btn"),
          secondaryAction: resetModal,
          secondaryActionText: t("errors.close_error"),
          onClose: resetModal
        })
      );
  }, [
    t,
    filterNotConnected,
    doEnableFetching,
    prepareToConnect,
    requestPages,
    filteringEnabled,
    filteringHashtag,
    newspaperSlug,
    customerId,
    onSuccess
  ]);

  /**
   * Open FB login dialog to let user change permission settings -> update erred pages with the new permissions.
   * In case of successful update -> show popup at the bottom of the screen.
   * In case of not all the permissions granted -> show error message about the permissions.
   * In case if the page on which user clicked 'Reconnect' is not selected -> show error message with the explanation why there might be no this page in the FB modal.
   * It's possible that both popup about the successful update and error about missing reconnected page appear.
   */
  const reconnectPages = useCallback(
    (id?: string) => {
      resetModal();
      resetPopup();

      return connectToFB(t)
        .then((_pages: NewPage[]) => {
          _pages = filterErred(_pages);
          const _erredPage = getErred(_pages, id);

          if (!_pages.length) {
            storeModal({
              title: t("errors.no_page_to_reconnect_title"),
              text: t("errors.no_page_to_reconnect_body"),
              ctaAction: resetModal,
              ctaText: t("errors.close_error"),
              onClose: resetModal
            });
          } else {
            const _pagesToVerify = prepareToReconnect(_pages);

            verifyPages(_pagesToVerify, t, newspaperSlug, customerId)
              .then(requestPages)
              .then((_pages: Page[]) => {
                if (!_erredPage) {
                  storeModal({
                    title: t("errors.no_page_to_reconnect_title"),
                    text: t("errors.no_page_to_reconnect_body"),
                    ctaAction: resetModal,
                    ctaText: t("errors.close_error"),
                    onClose: resetModal
                  });
                }

                storePopup({
                  title: t("messages.permissions_changed_title"),
                  text: isCommunity
                    ? t("messages.permissions_changed_body_lo_com")
                    : t("messages.permissions_changed_body")
                });
              })
              .catch((response) => {
                if (!_erredPage) {
                  storeModal({
                    title: t("errors.no_page_to_reconnect_title"),
                    text: t("errors.no_page_to_reconnect_body"),
                    ctaAction: resetModal,
                    ctaText: t("errors.close_error"),
                    onClose: resetModal
                  });
                } else {
                  storeModal({
                    title: t("errors.generic_title"),
                    text: getRequestErrorMessage(response, t),
                    ctaAction: resetModal,
                    ctaText: t("errors.close_error"),
                    onClose: resetModal
                  });
                }
              });
          }
        })
        .catch((response) =>
          storeModal({
            title: t("errors.generic_title"),
            text: getRequestErrorMessage(response, t),
            ctaAction: () => reconnectPages(id),
            ctaText: t("errors.retry_btn"),
            secondaryAction: resetModal,
            secondaryActionText: t("errors.close_error"),
            onClose: resetModal
          })
        );
    },
    [
      t,
      getErred,
      filterErred,
      prepareToReconnect,
      requestPages,
      newspaperSlug,
      customerId
    ]
  );

  getPages();
  getWidgets();

  useEffect(
    () => () => {
      resetModal();
      resetPopup();
    },
    []
  );

  const isLoaded = !!pages;

  return (
    <>
      <Helmet>
        <title>{`${t(
          "general.connections.page_title"
        )} | ${instanceName}`}</title>
      </Helmet>
      <Loader active={!isLoaded}>
        <ConnectionsComponent
          isCommunity={isCommunity}
          pages={pages}
          setPages={storePages}
          storeModal={storeModal}
          resetModal={resetModal}
          filteringEnabled={filteringEnabled}
          filteringHashtag={filteringHashtag}
          connectPages={connectPages}
          connectMorePages={connectMorePages}
          reconnectPages={reconnectPages}
          isEnabledDuplicate={isEnabledDuplicate}
        />
      </Loader>
    </>
  );
};

export default ConnectionsContainer;
