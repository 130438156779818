import request from "./request";

type Params = string | number | string[] | number[];
type Config = {
  data?: any;
  params?: any;
  headers?: any;
}; // Other config properties supported by axios: https://github.com/axios/axios#request-config

const api =
  (method: string, url: string | ((params: Params) => string)) =>
  (args?: { params?: Params; config?: Config }) => {
    const config = args?.config || {};

    return request({
      method,
      url: typeof url === "function" ? url(args.params) : url,
      ...config
    });
  };

const Api = {
  newspaperSettings: api("get", "/unified_header"),
  dashboard: {
    index: api("get", "/")
  },
  sources: {
    index: api("get", "/sources"),
    update: api("put", (params) => `/sources/${params}`)
  },
  widgets: {
    index: api("get", "/widgets"),
    show: api("get", (params) => `/widgets/${params}`)
  },
  widgetPromotions: {
    index: api("get", (params) => `/widgets/${params}/promotions`),
    hide: api(
      "put",
      (params) => `widgets/${params[0]}/promotions/${params[1]}/hide`
    ),
    display: api(
      "put",
      (params) => `widgets/${params[0]}/promotions/${params[1]}/display`
    )
  },
  promotions: {
    parse: api("get", "promotions/parse"),
    create: api("post", "promotions"),
    edit: api("get", (params) => `promotions/${params}/edit`),
    update: api("put", (params) => `promotions/${params}`)
  },
  analytics: {
    index: api("get", "/analytics")
  },
  customer: {
    show: api("get", (params) => `newspaper/${params[0]}/customer/${params[1]}`)
  },
  user: {
    show: api("get", "ad_users/show"),
    acceptInvite: api("put", "ad_users/invitations/accept"),
    signIn: api("post", "ad_users/sign_in"),
    requestNewPassword: api("post", "ad_users/password"),
    newPassword: api("put", "ad_users/password"),
    checkNewPasswordToken: api("get", "ad_users/password/check_token"),
    checkInvitationToken: api("get", "ad_users/invitations/check_token")
  },
  contacts: {
    show: api("get", "contact_us")
  }
};

export default Api;
