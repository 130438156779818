import React from "react";
import { useLocation, Navigate, useSearchParams } from "react-router-dom";
import { setCookie, getCookieByName } from "../../util/cookieProvider";

const AuthRedirect = (props) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const adminCustomerToken = searchParams.get("admin_customer_token");
  const isUserAuthenticated = Boolean(getCookieByName("ddb_session_token"));

  if (adminCustomerToken || isUserAuthenticated) {
    return <>{props.children}</>;
  } else {
    setCookie("ddb_sign_in_redirect_url", location.pathname, { path: "/" }); 
    return <Navigate to="/sign_in" replace />
  }
}

export default AuthRedirect;
