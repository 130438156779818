import React, { VFC } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Error from "./pages/Error";

const App2Ph: VFC = () => {
  const { t } = useTranslation();

  const oldPageMessage = {
    title: t("old_page.title"),
    titleAs: "h1",
    text: t("old_page.message"),
    icon: "warning"
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Error {...oldPageMessage} status="error" />}
        />
        <Route
          path="/dashboard"
          element={<Error {...oldPageMessage} status="error" />}
        />
        <Route
          path="/promotion/new"
          element={<Error {...oldPageMessage} status="error" />}
        />
        <Route
          path="/promotion/:id"
          element={<Error {...oldPageMessage} status="error" />}
        />
        <Route path="*" element={<>not found</>} />
      </Routes>
    </Router>
  );
};

export default App2Ph;
