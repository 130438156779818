import { FC, HTMLProps } from "react";
import styled from "styled-components";

import constants from "../../_globalStyles/constants";

export const SectionTileButton: FC<
  HTMLProps<HTMLButtonElement>
> = styled.button`
  margin: 0;
  padding: ${constants.GRID_UNIT * 2}px;
  border: 0;
  box-shadow: none;

  width: 100%;
  &,
  &:hover,
  &:active,
  &:focus {
    background: ${constants.MAIN_BG_COLOR};
  }

  text-align: left;
  cursor: pointer;

  .ddb-keyboard-navigation-on &:focus {
    outline-offset: -${constants.GRID_UNIT}px;
  }
`;

SectionTileButton.displayName = "SectionTileButton";
