import { FC } from "react";
import styled from "styled-components";

import constants from "../../_globalStyles/constants";
import { pxToRem } from "../../../util/helpers";

export const InputContainer: FC = styled.div`
  position: relative;
  display: flex;
  margin: 0;
  padding-top: 22px;

  input:focus:not(:disabled) + label,
  input:active:not(:disabled) + label {
    bottom: calc(100% - 33px);
    font-size: ${pxToRem(12)};
    color: ${constants.INPUT_LABEL_COLOR};
  }
`;

InputContainer.displayName = "InputContainer";
