import { FC, HTMLProps } from "react";
import styled, { css } from "styled-components";

import constants from "../../_globalStyles/constants";

interface PageButtonProps extends HTMLProps<HTMLButtonElement> {
  active: boolean;
}

export const PaginationNav: FC<HTMLProps<HTMLElement>> = styled.nav`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: ${constants.GRID_UNIT}px ${constants.GRID_UNIT * 0.5}px;
  border-radius: ${constants.GRID_UNIT * 12}px;
  background: ${constants.COLOR_WHITE};
`;

PaginationNav.displayName = "PaginationNav";

export const Page: FC<PageButtonProps> = styled.button`
  display: inline-block;
  font-size: 1rem;
  line-height: 1rem;
  margin: 0 ${constants.GRID_UNIT * 0.5}px;
  padding: ${constants.GRID_UNIT * 1.5}px ${constants.GRID_UNIT * 2}px;
  border: 0;
  box-shadow: none;
  background: transparent;
  border-radius: ${constants.GRID_UNIT * 6}px;
  cursor: pointer;

  &,
  &:link,
  &:visited {
    color: ${constants.COLOR_GRAY_500};
  }

  &:hover,
  &:active,
  &:focus {
    background: ${constants.COLOR_GRAY_100};
  }

  ${({ active }) =>
    active &&
    css`
      &,
      &:link,
      &:visited,
      &:hover,
      &:active,
      &:focus {
        color: ${constants.COLOR_WHITE} !important;
        background: ${constants.COLOR_GRAY_500};
      }
    `};

  @media (max-width: ${constants.BREAKPOINT_MOBILE}px) {
    margin: 0;
  }
`;

Page.displayName = "Page";

export const Ellipsis: FC = styled.div`
  display: inline-block;
  font-size: 1rem;
  line-height: 1rem;
  width: ${constants.GRID_UNIT * 5}px;
  height: ${constants.GRID_UNIT * 5}px;
  margin: 0 ${constants.GRID_UNIT * 0.5}px;
  padding: ${constants.GRID_UNIT * 1.5}px ${constants.GRID_UNIT * 2}px;
  color: ${constants.COLOR_GRAY_500};
`;

Ellipsis.displayName = "Ellipsis";

const NavButton: FC<HTMLProps<HTMLButtonElement>> = styled.button`
  position: relative;
  display: inline-block;
  width: ${constants.GRID_UNIT * 5}px;
  height: ${constants.GRID_UNIT * 5}px;
  margin: 0 ${constants.GRID_UNIT * 0.5}px;
  padding: ${constants.GRID_UNIT * 1.5}px ${constants.GRID_UNIT * 2}px;
  border-radius: 50%;
  border: 0;
  box-shadow: none;
  background: transparent;
  cursor: pointer;

  &,
  &:link,
  &:visited {
    color: ${constants.COLOR_GRAY_500};
  }

  &:hover,
  &:active,
  &:focus {
    background: ${constants.COLOR_GRAY_100};
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: rotate(-90deg) translate(50%, -50%);
    font-size: 1.3rem;
  }
`;

export const PrevButton: FC<HTMLProps<HTMLButtonElement>> = styled(NavButton)`
  svg {
    transform: rotate(-90deg) translate(50%, -50%);
  }
`;

PrevButton.displayName = "PrevButton";

export const NextButton: FC<HTMLProps<HTMLButtonElement>> = styled(NavButton)`
  svg {
    transform: rotate(90deg) translate(-50%, 50%);
  }
`;

NextButton.displayName = "NextButton";
