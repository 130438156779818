import React, { FC, VFC, HTMLProps } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";

import constants from "../../_globalStyles/constants";
import Box from "../../particles/Box";

interface UnorderedPaginationProps {
  activePage: number;
  pagesNumber: number;
  onPageClick: (page: number) => void;
}

interface PageProps extends HTMLProps<HTMLButtonElement> {
  active: boolean;
}

const Page: FC<PageProps> = styled.button`
  display: inline-block;
  width: ${constants.GRID_UNIT}px;
  height: ${constants.GRID_UNIT}px;
  border-radius: 50%;
  border: 0;
  margin: ${constants.GRID_UNIT}px;
  padding: 0;
  cursor: pointer;
  background: ${constants.COLOR_GRAY_300};

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  ${({ active }) =>
    active &&
    css`
      background: ${constants.COLOR_BLUE_400};
    `}
`;

Page.displayName = "Page";

const UnorderedPagination: VFC<UnorderedPaginationProps> = ({
  activePage,
  pagesNumber,
  onPageClick
}) => {
  const { t } = useTranslation();

  const pages = Array.from(Array(pagesNumber + 1).keys());
  pages.shift();

  return (
    <>
      <Box
        as="nav"
        inlineFlex
        alignCenter
        justifyCenter
        flexWrap
        marginHAuto
        maxWidth="100%"
      >
        {pages.map((page) => {
          const active = page === activePage;
          return (
            <Page
              key={page}
              type="button"
              active={active}
              aria-current={active ? "step" : "false"}
              onClick={() => onPageClick(page)}
            >
              <span className="ddb-visually-hidden">
                {active
                  ? t("general.pagination.current_slide", { number: page })
                  : t("general.pagination.n_slide", { number: page })}
              </span>
            </Page>
          )
        }
        )}
      </Box>
    </>
  );
};

export default UnorderedPagination;
