import React, { VFC } from "react";
import styled from "styled-components";

import { ReactComponent as Close } from "../../../assets/icons/close.svg";
import { ReactComponent as ExternalLink } from "../../../assets/icons/external-link.svg";
import { ReactComponent as InternalLink } from "../../../assets/icons/internal-link.svg";
import { ReactComponent as User } from "../../../assets/icons/user.svg";
import { ReactComponent as Check } from "../../../assets/icons/check.svg";
import { ReactComponent as CheckWide } from "../../../assets/icons/check-wide.svg";
import { ReactComponent as Warning } from "../../../assets/icons/warning.svg";
import { ReactComponent as Error } from "../../../assets/icons/error.svg";
import { ReactComponent as Back } from "../../../assets/icons/back.svg";
import { ReactComponent as Link } from "../../../assets/icons/link.svg";
import { ReactComponent as Pause } from "../../../assets/icons/pause.svg";
import { ReactComponent as Status } from "../../../assets/icons/status.svg";
import { ReactComponent as Play } from "../../../assets/icons/play.svg";
import { ReactComponent as Expired } from "../../../assets/icons/expired.svg";
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import { ReactComponent as NoImage } from "../../../assets/icons/no-image.svg";
import { ReactComponent as AddImage } from "../../../assets/icons/add-image.svg";
import { ReactComponent as Arrow } from "../../../assets/icons/arrow.svg";
import { ReactComponent as ArrowToggle } from "../../../assets/icons/arrow-toggle.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down.svg";
import { ReactComponent as Select } from "../../../assets/icons/select.svg";
import { ReactComponent as Plus } from "../../../assets/icons/plus.svg";
import { ReactComponent as Login } from "../../../assets/icons/login.svg";
import { ReactComponent as Logout } from "../../../assets/icons/logout.svg";
import { ReactComponent as Vector } from "../../../assets/icons/vector.svg";
import { ReactComponent as Newspaper } from "../../../assets/icons/newspaper.svg";
import { ReactComponent as Calendar } from "../../../assets/icons/calendar.svg";
import { ReactComponent as Search } from "../../../assets/icons/search.svg";
import { ReactComponent as Widget } from "../../../assets/icons/widget.svg";

export interface IconProps {
  name: string;
  color?: string;
  size?: string;
  width?: string;
  height?: string;
  className?: string;
}

const Icon: VFC<IconProps> = styled(({ name, ...props }) => {
  switch (name) {
    case "close":
      return <Close {...props} aria-hidden />;
    case "external-link":
      return <ExternalLink {...props} aria-hidden />;
    case "internal-link":
      return <InternalLink {...props} aria-hidden />;
    case "user":
      return <User {...props} aria-hidden />;
    case "check":
      return <Check {...props} aria-hidden />;
    case "check-wide":
      return <CheckWide {...props} aria-hidden />;
    case "warning":
      return <Warning {...props} aria-hidden />;
    case "error":
      return <Error {...props} aria-hidden />;
    case "back":
      return <Back {...props} aria-hidden />;
    case "link":
      return <Link {...props} aria-hidden />;
    case "pause":
      return <Pause {...props} aria-hidden />;
    case "status":
      return <Status {...props} aria-hidden />;
    case "play":
      return <Play {...props} aria-hidden />;
    case "expired":
      return <Expired {...props} aria-hidden />;
    case "edit":
      return <Edit {...props} aria-hidden />;
    case "no-image":
      return <NoImage {...props} aria-hidden />;
    case "add-image":
      return <AddImage {...props} aria-hidden />;
    case "arrow":
      return <Arrow {...props} aria-hidden />;
    case "arrow-toggle":
      return <ArrowToggle {...props} aria-hidden />;
    case "arrow-down":
      return <ArrowDown {...props} aria-hidden />;
    case "select":
      return <Select {...props} aria-hidden />;
    case "plus":
      return <Plus {...props} aria-hidden />;
    case "login":
      return <Login {...props} aria-hidden />;
    case "logout":
      return <Logout {...props} aria-hidden />;
    case "vector":
      return <Vector {...props} aria-hidden />;
    case "newspaper":
      return <Newspaper {...props} aria-hidden />;
    case "calendar":
      return <Calendar {...props} aria-hidden />;
    case "search":
      return <Search {...props} aria-hidden />;
    case "widget":
      return <Widget {...props} aria-hidden />;
    default:
      return null;
  }
})`
  font-size: ${({ size }) => size || null};
  width: ${({ width, height }) => (width || height ? "unset" : "1em")};
  height: ${({ width, height }) => (height || width ? "unset" : "1em")};
  fill: currentColor;
  color: ${({ color }) => (color ? color : null)};
`;

export default Icon;
