import React, { FC, ReactNode, useContext } from "react";

import { getContacts } from "../../util/hooks";
import { AppContext } from "./AppProvider";
import Loader from "../atoms/Loader";

interface GetContactsProps {
  children: ReactNode;
}

const GetContacts: FC<GetContactsProps> = ({ children }) => {
  getContacts();

  const { contacts } = useContext(AppContext);

  return <Loader active={!contacts}>{children}</Loader>;
};

export default GetContacts;
