import { FC } from "react";
import styled from "styled-components";

import constants from "../../_globalStyles/constants";
import CheckboxInput, { CheckboxInputProps } from "../CheckboxInput";

interface PrimaryCheckboxInputProps extends CheckboxInputProps {
  checked: boolean;
}

export const TileCheckboxInput: FC<PrimaryCheckboxInputProps> = styled(
  CheckboxInput
).attrs({
  small: true
})`
  padding: ${constants.GRID_UNIT * 1.5}px ${constants.GRID_UNIT * 4}px;
  border-radius: ${constants.GRID_UNIT}px;
  background: ${constants.COLOR_GRAY_100};

  &:hover,
  &:active {
    background: ${constants.COLOR_GRAY_150};
  }
`;

TileCheckboxInput.displayName = "TileCheckboxInput";
