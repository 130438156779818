import React, { VFC } from "react";

import Transition from "../../components/particles/Transition";
import Box from "../../components/particles/Box";
import ConnectedPages from "../../components/templates/ConnectedPages";
import ConnectionsOnboardingSlider from "../../components/templates/ConnectionsOnboardingSlider";

interface ConnectionsComponentProps {
  isCommunity: boolean;
  pages: {
    id: number;
    remote_id?: string;
    name?: string;
    url: string;
    titleAs?: string;
    author?: string;
    type: "facebook" | "instagram";
    enabled: boolean;
    syncError: boolean;
    authError: boolean;
  }[];
  filteringEnabled: boolean;
  filteringHashtag?: string;
  setPages: any;
  storeModal: any;
  resetModal: () => void;
  connectPages: () => void;
  connectMorePages: () => void;
  reconnectPages: () => void;
  isEnabledDuplicate: (pageName: string) => boolean;
}

const ConnectionsComponent: VFC<ConnectionsComponentProps> = ({
  isCommunity,
  pages,
  setPages,
  storeModal,
  resetModal,
  connectPages,
  connectMorePages,
  reconnectPages,
  filteringEnabled,
  filteringHashtag,
  isEnabledDuplicate
}) => {
  return (
    <Transition>
      {pages?.length ? (
        <Box marginH={2}>
          <ConnectedPages
            isCommunity={isCommunity}
            pages={pages}
            setPages={setPages}
            storeModal={storeModal}
            resetModal={resetModal}
            connectAction={connectMorePages}
            reconnectAction={reconnectPages}
            isEnabledDuplicate={isEnabledDuplicate}
          />
        </Box>
      ) : (
        <Box marginT={3} marginB={3} marginH={1}>
          <ConnectionsOnboardingSlider
            isCommunity={isCommunity}
            filteringEnabled={filteringEnabled}
            filteringHashtag={filteringHashtag}
            connectAction={connectPages}
          />
        </Box>
      )}
    </Transition>
  );
};

export default ConnectionsComponent;
