import { FC } from "react";
import styled from "styled-components";

import constants from "../../_globalStyles/constants";
import Tile, { TileProps } from "../../atoms/Tile";
import Button, { ButtonProps } from "../../atoms/Button";

export const ModalTile: FC<TileProps> = styled(Tile)`
  position: fixed;
  top: 3vh;
  left: 50%;
  transform: translate(-50%, 0);
  max-height: calc(100vh - 6vh);
  width: ${constants.GRID_UNIT * 80}px;
  max-width: calc(100vw - ${constants.GRID_UNIT * 4}px);
  overflow: scroll;
  z-index: 10000;

  @media (max-width: ${constants.BREAKPOINT_MOBILE_L}px) {
  }
`;

ModalTile.displayName = "ModalTile";

export const ModalOverlay: FC = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.8;
  z-index: 9999;
`;

ModalOverlay.displayName = "ModalOverlay";

export const CloseButton: FC<ButtonProps> = styled(Button)`
  position: absolute;
  top: ${constants.GRID_UNIT * 3}px;
  right: ${constants.GRID_UNIT * 3}px;
`;

CloseButton.displayName = "CloseButton";
