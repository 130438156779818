import React, { FC, HTMLProps, MouseEvent, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { Tab } from "./styled";

interface TabLinkProps extends HTMLProps<HTMLElement> {
  to?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  title: string;
  warning?: boolean;
  accent?: boolean;
  active?: boolean;
  tabId?: string;
  tabPanelId?: string;
  doScrollToActive?: boolean;
}

const TabLink: FC<TabLinkProps> = ({
  to,
  onClick,
  title,
  warning,
  accent,
  active,
  tabId,
  tabPanelId,
  doScrollToActive
}) => {
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (!doScrollToActive) return;
    if (!ref?.current) return;
    if (active) ref?.current.scrollIntoView({ block: "center" });
  }, [active, doScrollToActive]);

  return onClick ? (
    <Tab ref={ref} active={active} warning={warning} accent={accent} role="none">
      <button type="button" onClick={onClick} role="tab" aria-selected={active} aria-controls={tabPanelId} id={tabId}>{title}</button>
    </Tab>
  ) : (
    <Tab ref={ref} active={active} warning={warning} accent={accent}>
      <NavLink to={to} state={{ fromDashboard: true }}>
        {title}
      </NavLink>
    </Tab>
  );
};

TabLink.defaultProps = {
  doScrollToActive: true
}

export default TabLink;
