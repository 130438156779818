import React, { FC, ReactNode, useContext } from "react";

import { getCustomer } from "../../util/hooks";
import { AppContext } from "./AppProvider";
import Loader from "../atoms/Loader";

interface GetCustomerProps {
  children: ReactNode;
}

const GetCustomer: FC<GetCustomerProps> = ({ children }) => {
  getCustomer();

  const { customer } = useContext(AppContext);

  return <Loader active={!customer}>{children}</Loader>;
};

export default GetCustomer;
