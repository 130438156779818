import React, { VFC, ReactNode, FormEvent, useCallback, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import InputAsterisk from "../../atoms/InputAsterisk";
import Icon from "../../atoms/Icon";
import RHFInputError from "../../_decorators/withRHFError";
import {
  CheckboxLabel,
  CheckboxInputBase,
  Checkbox,
  CheckboxLabelText,
  CheckboxRound
} from "./styled";

export interface CheckboxInputProps {
  id?: string;
  name: string;
  value: string | number;
  validate?: (value: any) => boolean | string;
  label: string | ReactNode;
  labelText?: string; // labelText is supported for the cases when label is a node, not string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (value: FormEvent<HTMLInputElement>) => void;
  accent?: boolean;
  small?: boolean;
  round?: boolean;
  className?: string;
  errorId?: string;
  requiredErrorMessage?: string;
}

const CheckboxInput: VFC<CheckboxInputProps> = ({
  id: _id,
  name,
  value,
  onChange: _onChange,
  required,
  validate,
  disabled,
  label,
  labelText: _labelText,
  accent,
  small,
  round,
  className,
  errorId: _errorId,
  requiredErrorMessage
}) => {
  const { t } = useTranslation();

  const id = _id || `${name}_${value.toString()}`;
  const errorId = _errorId || `${id}_error`;
  const labelText = typeof label === 'string' ? label : _labelText;

  const { register, control, getFieldState, formState } = useFormContext();

  const { error } = getFieldState(name, formState);
  
  const validation = useMemo(() => {
    const _validation = {};
    if (validate) _validation["validate"] = validate;
    if (required) _validation["required"] = requiredErrorMessage || t("errors.required");
    return _validation;
  }, [validate, required, requiredErrorMessage]);

  const RHFInputProps = register(name, validation);

  const onChange = useCallback(
    (_value) => {
      RHFInputProps.onChange(_value);
      _onChange && _onChange(_value);
    },
    [RHFInputProps, _onChange]
  );

  const checked = !!useWatch({ control, name });

  return round ? (
    <>
      <label htmlFor={id}>
        <CheckboxInputBase
          type="checkbox"
          id={id}
          value={value}
          disabled={disabled}
          {...RHFInputProps}
          onChange={onChange}
          aria-required={required}
          aria-invalid={!!error}
          aria-errormessage={error && errorId ? errorId : ""}
        />
        <CheckboxRound>
          <Icon name="check-wide" />
        </CheckboxRound>
        <div className="ddb-visually-hidden">{label}</div>
      </label>
    </>
  ) : (
    <>
      <CheckboxLabel className={className}>
        <CheckboxInputBase
          type="checkbox"
          id={id}
          value={value}
          disabled={disabled}
          {...RHFInputProps}
          onChange={onChange}
          aria-required={required}
          aria-invalid={!!error}
          aria-errormessage={error && errorId ? errorId : ""}
        />

        <Checkbox small={small} checked={checked}>
          <Icon name="check" />
        </Checkbox>
    
        <CheckboxLabelText
          checked={checked}
          accent={accent}
          small={small}
        >
          {label}
          {required && <InputAsterisk />}
        </CheckboxLabelText>
      </CheckboxLabel>
      {!_errorId && (
        <RHFInputError name={name} elementId={errorId} label={labelText} />
      )}
    </>
  );
};

CheckboxInput.defaultProps = {
  disabled: false,
  accent: false,
  small: false,
  round: false,
  className: ""
};

export default CheckboxInput;
