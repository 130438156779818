import axios from "axios";

function getStatusMessage(status, t) {
  switch (status) {
    case 400:
    case 404: 
      return t("errors.not_found");
    case 401:
    case 403:
      return t("errors.unauthorized");
    default:
      return t("errors.generic");
  }
}

export function getRequestErrorMessage(_response, t) {
  if (_response.name === "AxiosError") {
    const {
      response: { data }
    } = _response;

    if (data?.error) return data.error;

    const {
      response: { status }
    } = _response;

    return getStatusMessage(status, t);
  }

  return _response.message || t("errors.generic");
}

const requestConfig = {
  baseURL: `${process.env.REACT_APP_API_URI}/api/advertiser_dashboard/v1`
};

const client = axios.create(requestConfig);

export default client;
