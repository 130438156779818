import { FC, HTMLProps } from "react";
import styled from "styled-components";

import constants from "../../_globalStyles/constants";
import Box, { BoxProps } from "../../particles/Box";

interface WidgetHeaderProps {
  color: string;
}

export const WidgetBox: FC<BoxProps> = styled(Box)`
  width: 100%;
  max-width: ${constants.GRID_UNIT * 160}px;
  min-height: ${constants.GRID_UNIT * 63.5}px;
  background: ${constants.COLOR_GRAY_150};
  border-radius: ${constants.GRID_UNIT * 2}px;
  overflow: hidden;
`;

WidgetBox.displayName = "WidgetBox";

export const WidgetHeader: FC<WidgetHeaderProps> = styled.header`
  height: ${constants.GRID_UNIT * 7}px;
  background: ${({ color }) => color};
  padding: ${constants.GRID_UNIT}px ${constants.GRID_UNIT * 2}px; ;
`;

WidgetHeader.displayName = "WidgetHeader";

export const WidgetLogo: FC<HTMLProps<HTMLImageElement>> = styled.img`
  display: inline-block;
  object-fit: contain;
  object-position: 0% 50%;
  vertical-align: middle;
  height: ${constants.GRID_UNIT * 4}px;
  width: 100%;
  max-width: ${constants.GRID_UNIT * 30}px;
  padding-right: ${constants.GRID_UNIT * 2}px;
`;

WidgetLogo.displayName = "WidgetLogo";

export const WidgetAdsList: FC = styled.ul`
  list-style: none;
  margin: 0 -${constants.GRID_UNIT}px;
  padding: 0;

  > li {
    display: inline-block;
    float: left;
  }

  @media (max-width: ${constants.BREAKPOINT_MOBILE_L}px) {
    display: flex;
    overflow: scroll;

    > li {
      float: none;
    }
  }
`;

WidgetAdsList.displayName = "WidgetAdsList";
