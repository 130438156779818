import React, { useState, useEffect, useRef, forwardRef, FC } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  endOfWeek,
  startOfWeek,
  endOfMonth,
  startOfMonth,
  subMonths,
  startOfYear,
  endOfYear
} from "date-fns";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import { pxToRem } from "../../../util/helpers";
import Text from "../../atoms/Text";
import {
  ButtonWrapper,
  ResetButton,
  ResetIcon,
  CalendarIcon,
  SelectIcon,
  DateRangeInputWrapper,
  TriggerButton,
  PrefilledRangesWrapper,
  RangeButton
} from "./styled";

interface DateRangeInputProps {
  name: string;
  id?: string;
  label: string;
  onChange: (values: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  }) => void;
  showPrefilledRanges?: boolean;
  locale?: string;
  initialValue?: {
    startDate: Date;
    endDate: Date;
  };
  testId?: string;
  minDate?: Date;
  maxDate?: Date;
}

type PrefilledButtonType =
  | "TODAY"
  | "THIS_WEEK"
  | "THIS_MONTH"
  | "LAST_MONTH"
  | "THIS_YEAR";

const DateRangeInput: React.FC<DateRangeInputProps> = ({
  name,
  id: _id,
  initialValue,
  label,
  onChange,
  locale = "en",
  showPrefilledRanges = true,
  minDate,
  maxDate
}) => {
  const { t } = useTranslation();

  const id = _id || name;

  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [activeRange, setActiveRange] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    i18next.changeLanguage(locale);
  }, [i18next, locale]);

  useEffect(() => {
    if (!initialValue) return;

    setStartDate(initialValue?.startDate);
    setEndDate(initialValue?.endDate);
  }, [initialValue]);

  const datePickerRef = useRef<DatePicker>(null);

  const onChangeDate = (dates: Date | [Date, Date] | null) => {
    if (dates && Array.isArray(dates)) {
      const [start, end]: Date[] = dates;
      setActiveRange("");
      setStartDate(start);
      setEndDate(end);
      if ((start && end) || (!start && !end)) {
        setIsOpen(false);
        if (onChange) {
          onChange({
            startDate: start,
            endDate: end
          });
        }
      }
    }
  };

  const ranges: { name: string; value: PrefilledButtonType }[] = [
    {
      name: t("datepicker.today"),
      value: "TODAY"
    },
    {
      name: t("datepicker.week"),
      value: "THIS_WEEK"
    },
    {
      name: t("datepicker.month"),
      value: "THIS_MONTH"
    },
    {
      name: t("datepicker.last_month"),
      value: "LAST_MONTH"
    },
    {
      name: t("datepicker.year"),
      value: "THIS_YEAR"
    }
  ];

  const handleDateSelect = (
    type: PrefilledButtonType,
    start: Date,
    end: Date
  ) => {
    setStartDate(start);
    setEndDate(end);
    setActiveRange(type);
    if (onChange) {
      onChange({
        startDate: start,
        endDate: end
      });
    }
    setIsOpen(false);
  };

  const handleRangeButtonClick = (type: PrefilledButtonType) => {
    switch (type) {
      case "TODAY":
        handleDateSelect(type, new Date(), new Date());
        break;
      case "THIS_WEEK":
        handleDateSelect(
          type,
          startOfWeek(new Date(), { weekStartsOn: 1 }),
          endOfWeek(new Date(), { weekStartsOn: 1 })
        );
        break;
      case "THIS_MONTH":
        handleDateSelect(
          type,
          startOfMonth(new Date()),
          endOfMonth(new Date())
        );
        break;
      case "LAST_MONTH":
        handleDateSelect(
          type,
          startOfMonth(subMonths(new Date(), 1)),
          endOfMonth(subMonths(new Date(), 1))
        );
        break;
      case "THIS_YEAR":
        handleDateSelect(type, startOfYear(new Date()), endOfYear(new Date()));
        break;
      default:
        return null;
    }
    datePickerRef?.current?.setOpen(false);
  };

  const resetDate = () => {
    onChangeDate([null, null]);
  };

  const PrefilledRanges: FC = () => {
    return (
      <PrefilledRangesWrapper>
        {ranges.map((range) => {
          const buttonActive = range.value === activeRange;
          return (
            <RangeButton
              key={range.value}
              onClick={() => handleRangeButtonClick(range.value)}
              active={buttonActive}
            >
              {buttonActive ? (
                <Text fontSize={pxToRem(12)} lineHeight={pxToRem(16)} medium>
                  {range.name}
                </Text>
              ) : (
                <Text fontSize={pxToRem(12)} lineHeight={pxToRem(16)}>
                  {range.name}
                </Text>
              )}
            </RangeButton>
          );
        })}
      </PrefilledRangesWrapper>
    );
  };

  const CalendarContainer = (containerValues: {
    children: React.ReactNode[];
    className: string;
  }) => {
    return (
      <div id={id} className={containerValues.className}>
        {containerValues.children}
        {showPrefilledRanges && <PrefilledRanges />}
      </div>
    );
  };

  const CustomInput = forwardRef(function Input(data: any, ref) {
    return (
      <ButtonWrapper hasValue={!!data.value}>
        <TriggerButton
          type="button"
          big
          hasValue={!!data.value}
          isOpen={isOpen}
          onClick={(e) => {
            setIsOpen(true);
            data.onClick(e);
          }}
        >
          <CalendarIcon name="calendar" />
          {data.value ? (
            <Text
              as="span"
              medium
              fontSize={pxToRem(16)}
              lineHeight={pxToRem(24)}
              ellipsis
            >
              {data.value}
            </Text>
          ) : (
            <Text as="span" medium fontSize={pxToRem(18)} lineHeight={pxToRem(24)}>
              {label}
            </Text>
          )}
          <SelectIcon name="arrow-toggle" />
        </TriggerButton>
        {!!data.value && (
          <ResetButton
            type="button"
            onClick={resetDate}
            label={t("accessibility.reset")}
          >
            <ResetIcon name="close" />
          </ResetButton>
        )}
      </ButtonWrapper>
    );
  });

  return (
    <DateRangeInputWrapper>
      <DatePicker
        name={name}
        ref={datePickerRef}
        calendarStartDay={1}
        wrapperClassName="date-picker"
        dateFormat="d MMM. yyyy"
        selectsRange
        onChange={onChangeDate}
        startDate={startDate}
        endDate={endDate}
        minDate={minDate}
        maxDate={maxDate}
        monthsShown={2}
        showPopperArrow={false}
        renderCustomHeader={({
          monthDate,
          customHeaderCount,
          decreaseMonth,
          increaseMonth
        }) => (
          <div>
            {customHeaderCount !== 1 && (
              <button
                type="button"
                aria-label="Previous Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--previous"
                }
                onClick={decreaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                  }
                >
                  {"<"}
                </span>
              </button>
            )}
            <span className="react-datepicker__current-month">
              {monthDate.toLocaleString("en-US", {
                month: "long",
                year: "numeric"
              })}
            </span>
            {customHeaderCount !== 0 && (
              <button
                type="button"
                aria-label="Next Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--next"
                }
                onClick={increaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                  }
                >
                  {">"}
                </span>
              </button>
            )}
          </div>
        )}
        customInput={<CustomInput />}
        calendarContainer={CalendarContainer}
      />
    </DateRangeInputWrapper>
  );
};

export default DateRangeInput;
