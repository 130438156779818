import { useRef, useEffect, useContext, MutableRefObject } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { setDocumentLang } from "./helpers";
import { getRequestErrorMessage } from "./request";
import {
  requestDashboard,
  requestUser,
  requestCustomer,
  requestSources,
  requestWidgets,
  requestContacts
} from "./apiRequests";
import { signOut } from "./authentication";
import { AppContext } from "../components/_functional/AppProvider";

export const useFocus = (): [MutableRefObject<HTMLElement>, () => void] => {
  const elRef = useRef<HTMLElement>();

  const setFocus = () => elRef.current?.focus();

  return [elRef, setFocus];
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export const syncNewspaperWithUrl = () => {
  const { i18n } = useTranslation();

  const { newspaperSlug } = useParams();

  const { user, storeNewspaper } = useContext(AppContext);

  useEffect(() => {
    const currentNewspaper = user.newspapers.find(
      (newspaper) => newspaper.slug === newspaperSlug
    );

    const currentLocale = currentNewspaper.locale;
    if (currentLocale) {
      i18n.changeLanguage(currentLocale);
      setDocumentLang(currentLocale);
    }

    storeNewspaper(currentNewspaper);
  }, [newspaperSlug]);
};

export const getUser = () => {
  const { t } = useTranslation();

  const { newspaperSlug } = useParams();

  const { user, storeUser, storeNewspaper, storeMessage } =
    useContext(AppContext);

  useEffect(() => {
    if (!user) {
      requestUser()
        .then((data) => {
          storeUser(data);

          const _newspaper = newspaperSlug
            ? data.newspapers.find((n) => n.slug === newspaperSlug)
            : data.newspapers[0];
          storeNewspaper(_newspaper);
        })
        .catch((error) => {
          const { response } = error;

          console.error(
            "DDB message: ",
            error.message,
            response?.data?.message,
            response?.data?.details,
            response?.data?.errors?.[0] || response?.data?.error
          );

          storeMessage({
            icon: "warning",
            status: "warning",
            title: t("errors.generic_title"),
            text: getRequestErrorMessage(error, t),
            ctaText: t("errors.unauthorized_cta_action"),
            ctaAction: signOut
          });
        });
    }
  }, [user]);
};

export const getCustomer = () => {
  const { t } = useTranslation();

  const { newspaperSlug } = useParams();
  const [searchParams] = useSearchParams();
  const customerId = searchParams.get("customer_id");
  const prevCustomerId = usePrevious(customerId);

  const { customer, storeCustomer, storeFBAppId, storeModal, resetModal } =
    useContext(AppContext);

  const doGetData =
    newspaperSlug && customerId && (!customer || prevCustomerId !== customerId);

  useEffect(() => {
    if (doGetData) {
      requestCustomer(newspaperSlug, customerId)
        .then((_customer) => {
          storeFBAppId(_customer.FBAppId);
          storeCustomer(_customer);
        })
        .catch((error) => {
          const { response } = error;

          console.error(
            "DDB message: ",
            error.message,
            response?.data?.message,
            response?.data?.details,
            response?.data?.errors?.[0] || response?.data?.error
          );

          storeModal({
            icon: "warning",
            status: "warning",
            title: t("errors.generic_title"),
            text: getRequestErrorMessage(error, t),
            onClose: resetModal
          });
        });
    }
  }, [doGetData, newspaperSlug, customerId]);
};

export const getPages = () => {
  const { t } = useTranslation();

  const { newspaperSlug } = useParams();
  const [searchParams] = useSearchParams();
  const customerToken = searchParams.get("admin_customer_token");
  const customerId = searchParams.get("customer_id");
  const prevCustomerId = usePrevious(customerId);

  const { storePages, resetModal, storeModal } = useContext(AppContext);

  const doGetData =
    customerToken ||
    (newspaperSlug && customerId && customerId !== prevCustomerId);

  useEffect(() => {
    if (doGetData) {
      requestSources(newspaperSlug, customerId)
        .then(storePages)
        .catch((error) => {
          const { response } = error;

          console.error(
            "DDB message: ",
            error.message,
            response?.data?.message,
            response?.data?.details,
            response?.data?.errors?.[0] || response?.data?.error
          );

          storeModal({
            icon: "warning",
            status: "warning",
            title: t("errors.generic_title"),
            text: getRequestErrorMessage(error, t),
            onClose: resetModal
          });
        });
    }
  }, [doGetData, newspaperSlug, customerId]);
};

export const getWidgets = () => {
  const { t } = useTranslation();

  const { newspaperSlug } = useParams();
  const [searchParams] = useSearchParams();
  const customerToken = searchParams.get("admin_customer_token");
  const customerId = searchParams.get("customer_id");
  const prevCustomerId = usePrevious(customerId);

  const { widgets, storeWidgets, storeModal, resetModal } =
    useContext(AppContext);

  const doGetData =
    customerToken ||
    (newspaperSlug &&
      customerId &&
      (!widgets || customerId !== prevCustomerId));

  useEffect(() => {
    if (doGetData) {
      requestWidgets(newspaperSlug, customerId)
        .then(storeWidgets)
        .catch((error) => {
          const { response } = error;

          console.error(
            "DDB message: ",
            error.message,
            response?.data?.message,
            response?.data?.details,
            response?.data?.errors?.[0] || response?.data?.error
          );

          storeModal({
            icon: "warning",
            status: "warning",
            title: t("errors.generic_title"),
            text: getRequestErrorMessage(error, t),
            onClose: resetModal
          });
        });
    }
  }, [doGetData, newspaperSlug, customerId]);
};

export const getDashboardDataByToken = () => {
  const { t, i18n } = useTranslation();

  const [searchParams] = useSearchParams();

  const customerToken =
    searchParams.get("customer_token") ||
    searchParams.get("admin_customer_token");

  const { storeNewspaper, storeCustomer, storeFBAppId, storeMessage } =
    useContext(AppContext);

  useEffect(() => {
    if (!customerToken) {
      storeMessage({
        title: t("errors.generic_title"),
        text: t("errors.unauthorized")
      });
    }

    requestDashboard()
      .then(([_newspaper, _customer, _FBAppId]) => {
        const currentLocale = _newspaper.locale;
        if (currentLocale) {
          i18n.changeLanguage(currentLocale);
          setDocumentLang(currentLocale);
        }

        storeNewspaper(_newspaper);
        storeCustomer(_customer);
        storeFBAppId(_FBAppId);
      })
      .catch((error) => {
        const { response } = error;

        console.error(
          "DDB message: ",
          error.message,
          response?.data?.message,
          response?.data?.details,
          response?.data?.errors?.[0] || response?.data?.error
        );

        storeMessage({
          icon: "warning",
          status: "warning",
          title: t("errors.generic_title"),
          text: getRequestErrorMessage(error, t)
        });
      });
  }, []);
};

export const getContacts = () => {
  const { t } = useTranslation();

  const { newspaperSlug } = useParams();
  const [searchParams] = useSearchParams();
  const customerId = searchParams.get("customer_id");
  const customerToken = searchParams.get("admin_customer_token");
  const prevNewspaperSlug = usePrevious(newspaperSlug);
  const prevCustomerId = usePrevious(customerId);

  const { contacts, storeContacts, storeModal, resetModal } =
    useContext(AppContext);

  const doGetData =
    ((newspaperSlug && customerId) || customerToken) &&
    (!contacts || prevNewspaperSlug !== newspaperSlug || customerId !== prevCustomerId);

  useEffect(() => {
    if (doGetData) {
      requestContacts(newspaperSlug, customerId)
        .then(storeContacts)
        .catch((error) => {
          const { response } = error;

          console.error(
            "DDB message: ",
            error.message,
            response?.data?.message,
            response?.data?.details,
            response?.data?.errors?.[0] || response?.data?.error
          );

          storeModal({
            icon: "warning",
            status: "warning",
            title: t("errors.generic_title"),
            text: getRequestErrorMessage(error, t),
            onClose: resetModal
          });
        });
    }
  }, [doGetData, customerId, newspaperSlug]);
};
