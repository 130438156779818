import React, { FC, ReactNode, useMemo, useCallback, useEffect, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { usePrevious } from "../../util/hooks";
import { AppContext } from "./AppProvider";

const SetDefaultCustomer: FC<{ children: ReactNode; }> = ({ children }) => {  
  const { newspaperSlug } = useParams();
  
  const [searchParams, setSearchParams] = useSearchParams();
  const customerId = searchParams.get("customer_id");

  const { user } = useContext(AppContext);

  const newspaperCustomers = useMemo(
    () => user.customers.filter((_c) => _c.newspaper.slug === newspaperSlug),
    [user, newspaperSlug]
  );

  const prevNewspaperCustomers = usePrevious(newspaperCustomers);

  const updateUrlWithCustomer = useCallback(
    (id, replace = false) => setSearchParams(`customer_id=${id}`, { replace }),
    [setSearchParams]
  );

  useEffect(() => {
    if (
      !customerId &&
      newspaperCustomers?.length &&
      newspaperCustomers !== prevNewspaperCustomers
    ) {
      updateUrlWithCustomer(newspaperCustomers[0].id, true);
    }
  }, [customerId, newspaperCustomers, updateUrlWithCustomer]);

  return <>{children}</>;
};

export default SetDefaultCustomer;
