import { FC } from "react";
import styled, { css } from "styled-components";

import { pxToRem } from "../../../util/helpers";
import constants from "../../_globalStyles/constants";
import Box, { BoxProps } from "../../particles/Box";

export interface IconBadgeProps extends BoxProps {
  name?: string;
  status?: "success" | "error" | "warning";
  accent?: boolean;
  inverted?: boolean;
  hint?: boolean;
  custom?: boolean;
  small?: boolean;
}

export const IconBadgeWrap: FC<IconBadgeProps> = styled(Box)`
  position: relative;
  height: ${constants.GRID_UNIT * 11}px;
  width: ${constants.GRID_UNIT * 11}px;
  border-radius: 50%;
  background: ${constants.ICON_BADGE_COLOR};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ name }) =>
    name === "email" ? constants.GRID_UNIT * 5 : constants.GRID_UNIT * 6}px;
  color: ${constants.ICON_BADGE_TEXT_COLOR};

  svg {
    z-index: 1;
  }

  ${({ accent }) =>
    accent &&
    css`
      &:after {
        content: "";
        position: absolute;
        top: calc(50% - ${constants.GRID_UNIT * 4}px);
        bottom: calc(50% - ${constants.GRID_UNIT * 4}px);
        left: calc(50% - ${constants.GRID_UNIT * 4}px);
        right: calc(50% - ${constants.GRID_UNIT * 4}px);
        background: ${constants.MAIN_BG_COLOR};
        border-radius: 50%;
      }
    `}

  ${({ status, inverted }) =>
    status &&
    inverted &&
    css`
      height: ${constants.GRID_UNIT * 3.25}px;
      width: ${constants.GRID_UNIT * 3.25}px;
      color: ${constants.TEXT_LIGHT_COLOR};
      font-size: ${pxToRem(14)};
      border: 1px solid ${constants.BORDER_COLOR};

      svg {
        margin-bottom: ${constants.GRID_UNIT * 0.25}px;
      }
    `}
  
  ${({ status, inverted }) =>
    status &&
    inverted &&
    status === "error" &&
    css`
      background: ${constants.ICON_ERROR_COLOR};
    `}

  ${({ status, inverted }) =>
    status &&
    inverted &&
    status === "warning" &&
    css`
      background: ${constants.ICON_WARNING_COLOR};
    `}

  ${({ hint }) =>
    hint &&
    css`
      height: ${constants.GRID_UNIT * 6}px;
      width: ${constants.GRID_UNIT * 6}px;
      background: transparent;
      color: ${constants.ICON_HINT_TEXT_COLOR};
      border: 1px solid ${constants.ICON_HINT_BORDER_COLOR};

      svg {
        width: unset;
        height: unset;
      }
    `}

  ${({ custom }) =>
    custom &&
    css`
      font-size: ${constants.GRID_UNIT * 11}px;
      background: transparent;
    `}

  ${({ small, status }) =>
    small &&
    !status &&
    css`
      height: ${constants.GRID_UNIT * 7}px;
      width: ${constants.GRID_UNIT * 7}px;
      font-size: ${constants.GRID_UNIT * 4}px;
    `}

  ${({ small, accent }) =>
    small &&
    accent &&
    css`
      height: ${constants.GRID_UNIT * 8}px;
      width: ${constants.GRID_UNIT * 8}px;
      font-size: ${constants.GRID_UNIT * 4}px;

      &:after {
        top: ${constants.GRID_UNIT}px;
        bottom: ${constants.GRID_UNIT}px;
        left: ${constants.GRID_UNIT}px;
        right: ${constants.GRID_UNIT}px;
      }
    `}
`;

IconBadgeWrap.displayName = "IconBadgeWrap";
