import axios from "axios";

import { getSearchParams } from "./helpers";
import { getCookieByName } from "./cookieProvider";

function FBLogin(scope = "pages_show_list", t) {
  return (_response) =>
    new Promise((resolve, reject) => {
      window.FB.login(
        (response) => {
          if (response.status !== "connected") return; // Status "unknown" is received when user closes login popup. No error is thrown here for better UX.

          const accessToken = response.authResponse?.accessToken || null;

          if (!accessToken)
            reject(new Error(t("errors.app_connection_error_body")));

          let permissionError;

          const grantedPermissions = response.authResponse?.grantedScopes;

          if (!grantedPermissions) return; // Sometimes when user closes login popup status 'connected' is received but with no granted persmissions. No error is thrown in such cases for better UX.

          [
            "pages_show_list",
            "pages_read_user_content",
            "pages_read_engagement"
          ].forEach((per) => {
            if (!grantedPermissions.includes(per)) {
              permissionError = new Error(
                t("errors.app_permission_error_body")
              );
            }
          });

          if (permissionError) reject(permissionError);

          resolve({
            ..._response,
            accessToken
          });
        },
        {
          scope,
          return_scopes: true
        }
      );
    });
}

function exchangeToken(_response, t) {
  return new Promise((resolve, reject) => {
    const { accessToken } = _response;

    if (!accessToken) reject(new Error(t("errors.app_token_error_body")));

    const url = `${process.env.REACT_APP_API_URI}/oauth/exchange`;

    const config = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    };

    axios
      .post(url, { access_token: accessToken }, config)
      .then(({ data }) => {
        resolve({
          longLiveAccessToken: data.data.access_token
        });
      })
      .catch((response) => reject(response));
  });
}

function getAccounts(fields = "id, name, link, access_token") {
  return (_response) =>
    new Promise((resolve, reject) => {
      const { longLiveAccessToken } = _response;

      let pages = [];

      function getFromFB(nextCursor) {
        window.FB.api(
          "/me/accounts",
          {
            fields,
            access_token: longLiveAccessToken,
            after: nextCursor
          },
          (response) => {
            if (!response || response.error) {
              reject(new Error(response.error));
            }

            pages = pages.concat(response.data);

            if (
              !(response.paging && response.paging.next) ||
              response.data.length === 0
            ) {
              resolve({
                ..._response,
                pages
              });
            } else {
              getFromFB(response.paging.cursors.after);
            }
          }
        );
      }

      getFromFB();
    });
}

export function verifyPages(pages, t, newspaperSlug, customerId) {
  let config = {};
  let params = {};
  let headers = {};

  const customerToken =
    getSearchParams(window.location.search, "customer_token") ||
    getSearchParams(window.location.search, "admin_customer_token");

  if (customerToken) {
    params["customer_token"] = customerToken;
  } else {
    const sessionToken = getCookieByName("ddb_session_token");

    if (!sessionToken || !newspaperSlug || !customerId)
      return Promise.reject(new Error(t("errors.unauthorized")));

    params["newspaper_id"] = newspaperSlug;
    params["customer_id"] = customerId;
    headers["Authorization"] = `Bearer ${sessionToken}`;
  }

  headers["Content-Type"] = "application/json;charset=UTF-8";

  config["headers"] = headers;
  config["params"] = params;

  const url = `${process.env.REACT_APP_API_URI}/oauth`;

  const promises = pages.map((page) =>
    axios
      .post(url, { source: { ...page } }, config)
      .then((response) => Promise.resolve(response))
      .catch((response) => Promise.reject(response))
  );

  return Promise.all(promises);
}

function prepareIBPages(pages, longLiveAccessToken) {
  const IBPages = pages
    .filter((page) => page.instagram_business_account)
    .map((page) => ({
      id: page.instagram_business_account.id,
      name: page.name,
      link: page.link,
      access_token: longLiveAccessToken,
      facebook_id: page.id,
      type: "business_instagram"
    }));

  return IBPages;
}

function prepareFBPages(pages) {
  const FBPages = pages.map((page) => ({
    id: page.id,
    name: page.name,
    link: page.link,
    access_token: page.access_token,
    facebook_id: page.id,
    type: "facebook"
  }));

  return FBPages;
}

function revokePageListPermission() {
  window.FB.api("/me/permissions/pages_show_list", "delete");
}

export function connectToFB(t) {
  return (
    Promise.resolve({})
      .then(
        FBLogin(
          "pages_show_list, pages_read_user_content, pages_read_engagement, instagram_basic, business_management",
          t
        )
      )
      // Request pages permission
      .then((_response) => exchangeToken(_response, t)) // Exchange short live token to Long live token in the backend
      .then(
        getAccounts("id, name, link, instagram_business_account, access_token")
      )
      // Let user select the page he or she would like to connect to Deals
      .then((_response) => {
        const { pages, longLiveAccessToken } = _response;

        const grantedPages = pages.filter(_page => _page.access_token);
        const FBPages = prepareFBPages(grantedPages);
        const IBPages = prepareIBPages(grantedPages, longLiveAccessToken);

        revokePageListPermission();

        return [...FBPages, ...IBPages];
      })
  );
}

export function initFBSDK(FBAppId) {
  return new Promise((resolve) => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: FBAppId,
        cookie: true,
        xfbml: true,
        version: "v19.0"
      });

      window.FB.AppEvents.logPageView();
      resolve();
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  });
}
