import { FC } from "react";
import styled from "styled-components";

import constants from "../../_globalStyles/constants";
import unifiedHeaderConstants from "../../templates/UnifiedHeader/constants";
import Box, { BoxProps } from "../../particles/Box";

export const PageBox: FC<BoxProps> = styled(Box)`
  min-height: 100vh;
  background: ${constants.COLOR_GRAY_100};
  padding: ${constants.GRID_UNIT * 7}px ${constants.GRID_UNIT}px 0;

  @media (max-width: ${unifiedHeaderConstants.UNI_BREAKPOINT_HEADER_SMALL}px) {
    padding: ${constants.GRID_UNIT * 6}px ${constants.GRID_UNIT}px 0;
  }
`;

PageBox.displayName = "PageBox";
