import { FC } from "react";
import styled from "styled-components";

import constants from '../_globalStyles/constants';
import { pxToRem } from '../../util/helpers';
import Box, { BoxProps} from "../particles/Box";

const InputError: FC<BoxProps> = styled(Box)`
  display: inline-block;
  vertical-align: middle;
  font-size: ${pxToRem(12)};
  font-weight: 400;
  line-height: 1.1;
  margin: 0.5em 0;
  color: ${constants.ERROR_COLOR};
`;

InputError.displayName = 'InputError';

export default InputError;
