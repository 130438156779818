import { FormAd, DefaultAdFormValues } from "../../types";

type Widgets = {
  id: number;
  envId: number;
  name: string;
  categories: {
    id: number;
    name: string;
  }[];
}[];

type Data = {
  description: string;
  link: string;
  image: File;
  customer_promotion_environments: object;
  promotion_environment_categories?: object;
};

type SubmitData = {
  description: string;
  link: string;
  image?: File;
  customer_promotion_environments: {
    id: number;
    delete?: 1;
  }[];
  promotion_environment_categories?: {
    id: number;
    delete?: 1;
  }[];
};

export const composeSubmitData = (
  data: Data,
  widgets: Widgets,
  ad: FormAd | null,
  isAdNew: boolean
): SubmitData => {
  const customer_promotion_environments = [];
  const promotion_environment_categories = [];

  widgets?.forEach((widget) => {
    if (data?.customer_promotion_environments?.[widget.id]) {
      customer_promotion_environments.push({ id: widget.id });
    } else if (
      !isAdNew &&
      ad?.customer_promotion_environments?.some((cpe) => cpe.id === widget.id)
    ) {
      customer_promotion_environments.push({ id: widget.id, delete: 1 });
    }

    widget.categories?.forEach((category) => {
      if (data?.promotion_environment_categories?.[category.id]) {
        promotion_environment_categories.push({ id: category.id });
      } else if (
        !isAdNew &&
        ad?.promotion_environment_categories?.some(
          (pec) => pec.id === category.id
        )
      ) {
        promotion_environment_categories.push({
          id: category.id,
          delete: 1
        });
      }
    });
  });

  const sd = {
    link: data.link,
    description: data.description,
    customer_promotion_environments,
    promotion_environment_categories
  };

  if (data.image instanceof File) sd["image"] = data.image;

  return sd;
};

export const composeDefaultValues = (
  widgets: Widgets,
  widgetId: number | string,
  ad: FormAd | null,
  isAdNew: boolean
): DefaultAdFormValues | any => {
  const dv = {};
  const customer_promotion_environments = {};
  const promotion_environment_categories = {};
  let customer_promotion_environments_all = false;

  if (isAdNew || !ad) {
    widgets?.forEach((widget) => {
      customer_promotion_environments[widget.id] =
        widget.envId === Number(widgetId);

      widget.categories?.forEach((category) => {
        promotion_environment_categories[category.id] = false;
      });
    });

    dv["customer_promotion_environments"] = customer_promotion_environments;
    dv["promotion_environment_categories"] = promotion_environment_categories;
    dv["customer_promotion_environments_all"] =
      customer_promotion_environments_all;

    return dv;
  }

  dv["image"] = ad.image;
  dv["description"] = ad.description || ad.message;
  dv["link"] = ad.link;
  dv["customer_promotion_environments_all"] =
    customer_promotion_environments_all;

  widgets?.forEach((widget) => {
    customer_promotion_environments[widget.id] =
      ad.customer_promotion_environments?.some((cpe) => cpe.id === widget.id);

    widget.categories?.forEach((category) => {
      promotion_environment_categories[category.id] =
        ad.promotion_environment_categories?.some(
          (pec) => pec.id === category.id
        );
    });
  });

  if (
    !Object.values(customer_promotion_environments).includes(false) &&
    !Object.values(promotion_environment_categories).includes(false)
  ) {
    customer_promotion_environments_all = true;
  }

  dv["customer_promotion_environments"] = customer_promotion_environments;
  dv["promotion_environment_categories"] = promotion_environment_categories;
  dv["customer_promotion_environments_all"] =
    customer_promotion_environments_all;

  return dv;
};
