import { FC } from "react";
import styled, { css } from "styled-components";

import { pxToRem } from "../../../util/helpers";
import constants from "../../_globalStyles/constants";
import Tile, { TileProps } from "../../atoms/Tile";
import Button, { ButtonProps } from "../../atoms/Button";

interface CheckboxTileProps {
  isOpen: boolean;
}

interface ToggleButtonProps extends ButtonProps {
  isOpen: boolean;
}

export const CheckboxTile: FC<CheckboxTileProps> = styled.div`
  border: 1px solid ${constants.BORDER_COLOR};
  border-top-left-radius: ${constants.GRID_UNIT}px;
  border-top-right-radius: ${constants.GRID_UNIT}px;
  border-bottom-left-radius: ${constants.GRID_UNIT}px;
  border-bottom-right-radius: ${constants.GRID_UNIT}px;
  overflow: hidden;

  label {
    padding: ${constants.GRID_UNIT * 2.5}px ${constants.GRID_UNIT * 4}px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: -${pxToRem(40)};
      background: ${constants.MAIN_BG_COLOR};
    }
  }

  label:hover:before,
  label:active:before {
    background: ${constants.COLOR_GRAY_50};
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 0;
    `}
`;

CheckboxTile.displayName = "CheckboxTile";

export const ToggleButton: FC<ToggleButtonProps> = styled(Button)`
  svg {
    font-size: ${pxToRem(20)};
  }

  z-index: 1;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}

  .ddb-keyboard-navigation-on &:focus {
    outline-offset: -${constants.GRID_UNIT * 0.5}px;
  }
`;

ToggleButton.displayName = "ToggleButton";

export const NestedCheckboxesTile: FC<TileProps> = styled(Tile)`
  box-shadow: none;
  border: 1px solid ${constants.BORDER_COLOR};
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: ${constants.GRID_UNIT}px;
  border-bottom-right-radius: ${constants.GRID_UNIT}px;
  padding-top: 0;

  label {
    border-radius: ${constants.GRID_UNIT}px;
    padding-left: ${constants.GRID_UNIT * 2}px;
  }

  label:hover,
  label:active {
    background: ${constants.COLOR_GRAY_100};
  }
`;

NestedCheckboxesTile.displayName = "NestedCheckboxesTile";
