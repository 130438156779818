import React, { VFC, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "../../../util/helpers";
import { useFocus } from "../../../util/hooks";
import Box from "../../particles/Box";
import Tile from "../../atoms/Tile";
import Text from "../../atoms/Text";
import IconColor from "../../atoms/IconColor";
import Switch from "../../atoms/Switch";
import Separator from "../../atoms/Separator";
import ErrorMessage from "../../organisms/ErrorMessage";
import { PageLink, ControlWrap } from "./styled";

interface ConnectedPageProps {
  isCommunity: boolean;
  id: number;
  remote_id?: string;
  name?: string;
  titleAs?: string;
  url: string;
  author?: string;
  type: "facebook" | "instagram";
  enabled: boolean;
  syncError: boolean;
  authError: boolean;
  onSwitch: (
    id: number,
    name: string,
    type: "facebook" | "instagram",
    enabled: boolean,
    callback?: () => void
  ) => void;
  reconnectAction: (id?: string) => void;
}

const ConnectedPage: VFC<ConnectedPageProps> = ({
  isCommunity,
  id,
  remote_id,
  name,
  titleAs,
  url,
  author,
  type,
  enabled,
  syncError,
  authError,
  onSwitch: _onSwitch,
  reconnectAction: _reconnectAction
}) => {
  const { t } = useTranslation();

  const [switchRef, setSwitchFocus] = useFocus();
  const [mobileSwitchRef, setMobileSwitchFocus] = useFocus();

  const [isProcessing, setIsProcessing] = useState(false);

  const error = useMemo(() => {
    if (authError) return "authError";
    if (syncError) return "syncError";
  }, [syncError, authError]);

  const title = name || url;
  const pageIcon = type === "instagram" ? "instagram_48" : type;
  const switchLabelId = `switch_page_${id}`;

  const onSwitch = useCallback(() => {
    setIsProcessing(true);
    return _onSwitch(id, name, type, enabled, () => {
      setIsProcessing(false);
      setSwitchFocus();
      setMobileSwitchFocus();
    });
  }, [_onSwitch, id, name, enabled]);

  const reconnectAction = useCallback(
    () => _reconnectAction(remote_id),
    [_reconnectAction, remote_id]
  );

  return (
    <>
      <Tile
        relative
        marginV={2}
        paddingH={3}
        paddingV={2}
        inverted={!enabled}
        border={enabled}
        radius={1}
        overflowHidden={false}
        className="ddb-desktop ddb-desktop-s"
      >
        <PageLink
          href={url}
          target="_blank"
          label={`${
            isCommunity
              ? t("general.ad.url_label_lo_com", {
                  type: type,
                  title: title
                })
              : t("general.ad.url_label", {
                  type: type,
                  title: title
                })
          } ${t("accessibility.new_tab")}`}
        />
        <Box flex justifyRight>
          <Box flexDynamic flex marginT={0.5} marginR={4}>
            <Box flexStatic flex marginB={0.5}>
              <IconColor name={pageIcon} size="3rem" />
            </Box>
            <Box inlineBlock marginL={1.5}>
              <Text as={titleAs} styledAs="h6" spacing="0.5px" break={!name}>
                {title}
              </Text>
              <Text fontSize={pxToRem(14)} lineHeight={pxToRem(24)}>
                {author}
              </Text>
            </Box>
          </Box>

          {enabled && (
            <>
              {error === "authError" && (
                <Box flexStatic width={52} marginR={4}>
                  <ErrorMessage
                    title={t("errors.connection_token_error_title")}
                    titleAs="h4"
                    ctaText={t("errors.reconnect_btn")}
                    ctaAction={reconnectAction}
                  />
                </Box>
              )}

              {error === "syncError" && (
                <Box flexStatic width={52} marginR={4}>
                  <ErrorMessage
                    title={t("errors.connection_sync_error_title")}
                    titleAs="h4"
                    text={t("errors.connection_sync_error_body")}
                    ctaText={t("errors.reconnect_btn")}
                    ctaAction={reconnectAction}
                  />
                </Box>
              )}
            </>
          )}

          <Box flexStatic>
            <Box>
              <Box inlineBlock marginR={2}>
                <Text id={switchLabelId} small>
                  {t("general.connections.fetching_content")}
                </Text>
              </Box>
              <Box inlineBlock>
                <ControlWrap>
                  <Switch
                    switchRef={switchRef}
                    checked={enabled}
                    onClick={onSwitch}
                    labelId={switchLabelId}
                    disabled={isProcessing}
                  />
                </ControlWrap>
              </Box>
            </Box>
          </Box>
        </Box>
      </Tile>
      <Tile
        relative
        marginV={1}
        paddingH={2}
        paddingT={2}
        paddingB={2.5}
        inverted={!enabled}
        border={enabled}
        radius={1.5}
        overflowHidden={false}
        className="ddb-mobile-l ddb-mobile"
      >
        <PageLink
          href={url}
          target="_blank"
          label={`${
            isCommunity
              ? t("general.ad.url_label_lo_com", {
                  type: type,
                  title: title
                })
              : t("general.ad.url_label", {
                  type: type,
                  title: title
                })
          } ${t("accessibility.new_tab")}`}
        />
        <Box flex alignCenter>
          <Box flexStatic flex>
            <IconColor name={pageIcon} size="3rem" />
          </Box>
          <Box inlineBlock marginL={1.5}>
            <Text
              as={titleAs}
              styledAs="h6"
              spacing="0.5px"
              lineHeight={pxToRem(24)}
              break={!name}
            >
              {title}
            </Text>
            <Text fontSize={pxToRem(14)} lineHeight={pxToRem(24)}>
              {author}
            </Text>
          </Box>
        </Box>
        <Separator marginV={2} />
        <Box>
          <Box flex alignCenter>
            <Box flexGrow marginR={2}>
              <Text id={`${switchLabelId}_mobile`} small>
                {t("general.connections.fetching_content")}
              </Text>
            </Box>
            <ControlWrap>
              <Switch
                switchRef={mobileSwitchRef}
                checked={enabled}
                onClick={onSwitch}
                labelId={`${switchLabelId}_mobile`}
                disabled={isProcessing}
              />
            </ControlWrap>
          </Box>

          {enabled && (
            <>
              {error === "authError" && (
                <Box marginT={1.5}>
                  <ErrorMessage
                    title={t("errors.connection_token_error_title")}
                    titleAs="h4"
                    ctaText={t("errors.reconnect_btn")}
                    ctaAction={reconnectAction}
                  />
                </Box>
              )}

              {error === "syncError" && (
                <Box marginT={1.5}>
                  <ErrorMessage
                    title={t("errors.connection_sync_error_title")}
                    titleAs="h4"
                    text={t("errors.connection_sync_error_body")}
                    ctaText={t("errors.reconnect_btn")}
                    ctaAction={reconnectAction}
                  />
                </Box>
              )}
            </>
          )}
        </Box>
      </Tile>
    </>
  );
};

ConnectedPage.defaultProps = {
  titleAs: "h3"
};

export default ConnectedPage;
