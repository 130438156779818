import React, { VFC, useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { getPages, getWidgets } from "../../util/hooks";
import { AppContext } from "../../components/_functional/AppProvider";
import Loader from "../../components/atoms/Loader";
import DashboardComponent from "./Component";

const DashboardContainer: VFC = () => {
  const { t } = useTranslation();

  const { newspaper, pages, widgets, customer } = useContext(AppContext);

  const { isCommunity } = newspaper;

  const [message, setMessage] = useState(null);

  getPages();
  getWidgets();

  useEffect(() => {
    if (widgets && !widgets.length) {
      setMessage({
        title: t("messages.no_widgets_title"),
        text: t("messages.no_widgets_body")
      });
    } else {
      setMessage(null);
    }
  }, [widgets]);

  const instanceName = newspaper.instanceName || newspaper.name;

  const isLoaded = pages && widgets;

  return (
    <>
      <Helmet>
        <title>{`${customer ? customer.name + ": " : ""}${t(
          "general.dashboard.page_title"
        )} | ${instanceName}`}</title>
      </Helmet>
      <Loader active={!isLoaded}>
        <DashboardComponent
          isCommunity={isCommunity}
          message={message}
          newspaper={newspaper}
          customer={customer}
          widgets={widgets}
          pages={pages}
        />
      </Loader>
    </>
  );
};

export default DashboardContainer;
