import { HTMLProps, FC } from "react";
import styled, { css } from "styled-components";

import constants from "../../_globalStyles/constants";
import { pxToRem } from "../../../util/helpers";
import Box, { BoxProps } from "../../particles/Box";
import Icon, { IconProps } from "../../atoms/Icon";
import Button, { ButtonProps } from "../../atoms/Button";

interface DropdownWrapProps extends BoxProps {
  isOpen: boolean;
  hasValue: boolean;
}

export const TriggerButton: FC<ButtonProps> = styled(Button)`
  width: 100%;
  justify-content: flex-start;
`;

TriggerButton.displayName = "TriggerButton";

export const LabelIcon: FC<IconProps> = styled(Icon)`
  font-size: ${constants.GRID_UNIT * 3}px !important;
  margin-right: ${constants.GRID_UNIT * 1.5}px;
`;

LabelIcon.displayName = "LabelIcon";

export const ResetButton: FC<ButtonProps> = styled(Button)`
  position: absolute;
  top: 0;
  right: ${constants.GRID_UNIT * 4.25}px;
  border: 0;
  box-shadow: none;
  background: transparent !important;
  padding: ${constants.GRID_UNIT * 1.875}px ${constants.GRID_UNIT * 1.5}px;

  &:hover,
  &:active,
  &:focus {
    background: transparent !important;
  }

  .ddb-keyboard-navigation-on &:focus {
    outline-offset: -6px;
  }
`;

ResetButton.displayName = "ResetButton";

export const ResetIcon: FC<IconProps> = styled(Icon)`
  font-size: ${constants.GRID_UNIT * 2.25}px;
  color: ${constants.COLOR_GRAY_350} !important;
`;

ResetIcon.displayName = "ResetIcon";

export const SelectIcon: FC<IconProps> = styled(Icon)`
  font-size: ${constants.GRID_UNIT * 2.25}px;
  position: absolute;
  top: ${constants.GRID_UNIT * 1.875}px;
  right: ${constants.GRID_UNIT * 2}px;
  transform: rotate(-180deg);
`;

SelectIcon.displayName = "SelectIcon";

export const DropdownWrap: FC<DropdownWrapProps> = styled(Box)`
  width: 100%;
  position: relative;
  display: inline-block;
  ${TriggerButton} {
    padding: ${constants.GRID_UNIT * 1.5}px ${constants.GRID_UNIT * 5.75}px
      ${constants.GRID_UNIT * 1.5}px ${constants.GRID_UNIT * 4}px;
    cursor: pointer;

    ${(props) =>
      props.hasValue &&
      css`
        padding-right: ${constants.GRID_UNIT * 9.5}px;
      `}

    ${(props) =>
      props.isOpen &&
      css`
        color: ${constants.BUTTON_INVERTED_PRIMARY_TEXT_COLOR};

        ${LabelIcon},
        ${SelectIcon} {
          color: ${constants.BUTTON_INVERTED_PRIMARY_TEXT_COLOR} !important;
        }

        ${SelectIcon} {
          transform: rotate(0deg);
        }
      `}
  }
`;

DropdownWrap.displayName = "DropdownWrap";

export const DropdownUl = styled.ul`
  position: relative;
  z-index: 100;
  list-style: none;
  margin: 0;
  padding: ${constants.GRID_UNIT}px 0;
  width: 100%;
  outline: none;
`;

DropdownUl.displayName = "DropdownUl";

export const DropdownLi = styled.li`
  width: 100%;
  border: 0;
  outline: none;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
`;

DropdownLi.displayName = "DropdownLi";

export const Popover = styled.div`
  position: absolute;
  top: 100%;
  background: #ffffff;
  border-radius: ${constants.GRID_UNIT * 1.5}px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 4px 16px 0px #0000001f;
  overflow: hidden;
  overflow-y: scroll;
  margin-top: ${constants.GRID_UNIT * 1.5}px;
  width: 100%;
  z-index: 100;
`;

Popover.displayName = "Popover";

interface DropdownOptionProps extends HTMLProps<HTMLAnchorElement> {
  active?: boolean;
  focused?: boolean;
  disabled?: boolean;
}

export const DropdownOption: FC<DropdownOptionProps> = styled.a`
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: ${pxToRem(16)};
  text-decoration: none !important;
  padding: ${constants.GRID_UNIT}px ${constants.GRID_UNIT * 2}px
    ${constants.GRID_UNIT}px ${constants.GRID_UNIT * 5.5}px;
  color: ${constants.COLOR_GRAY_500} !important;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background-color: ${constants.COLOR_GRAY_100};
    outline: none;
    text-decoration: none !important;
  }

  ${(props) =>
    props.active &&
    css`
      color: ${constants.COLOR_BLUE_350} !important;

      &:hover,
      &:active,
      &:focus {
        outline: none;
        background-color: ${constants.COLOR_GRAY_100};
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;

      &:hover,
      &:active,
      &:focus {
        opacity: 0.5;
        background-color: transparent !important;
      }
    `}

  .ddb-keyboard-navigation-on &:focus {
    outline: 3px dotted ${constants.FOCUS_OUTLINE_COLOR};
    outline-offset: -6px;
  }

  transition: background-color 0.2s ease;
`;

DropdownOption.displayName = "DropdownOption";

export const CheckIcon: FC<IconProps> = styled(Icon)`
  position: absolute;
  top: ${constants.GRID_UNIT * 1.25}px;
  left: ${constants.GRID_UNIT * 2}px;
  font-size: ${constants.GRID_UNIT * 2.5}px;
`;

CheckIcon.displayName = "CheckIcon";
