import { FC } from "react";
import styled from "styled-components";

import constants from "../../_globalStyles/constants";

export const LoaderWrap: FC = styled.div`
  margin: ${constants.GRID_UNIT}px auto;
  width: ${constants.GRID_UNIT * 10}px;
  text-align: center;
  animation: loader 1s;
  animation-delay: 0.8s;

  @keyframes loader {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

LoaderWrap.displayName = "LoaderWrap";

export const LoaderBounce: FC = styled.div`
  width: ${constants.GRID_UNIT}px;
  height: ${constants.GRID_UNIT}px;
  margin: 0 ${constants.GRID_UNIT * 0.5}px;
  background-color: ${constants.LOADER_COLOR};

  border-radius: 50%;
  display: inline-block;
  animation: bounce 1.4s infinite ease-in-out both;

  &:nth-of-type(1) {
    animation-delay: -0.32s;
  }

  &:nth-of-type(2) {
    animation-delay: -0.16s;
  }

  @keyframes bounce {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;

LoaderBounce.displayName = "LoaderBounce";
