import styled from "styled-components";

import uniHeaderConstants from "../UnifiedHeader/constants";

export const PageContent = styled.div`
  margin-top: ${uniHeaderConstants.UNI_HEADER_HEIGHT};

  @media (max-width: ${uniHeaderConstants.UNI_BREAKPOINT_HEADER_SMALL}px) {
    margin-top: ${uniHeaderConstants.UNI_HEADER_HEIGHT_SMALL};
  }
`;

PageContent.displayName = "PageContent";
