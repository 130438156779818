import { FC } from "react";
import styled from "styled-components";

import constants from "../../_globalStyles/constants";

export const SwitchAnimationWrap: FC = styled.div`
  position: relative;
  width: ${constants.GRID_UNIT * 9}px;
  height: ${constants.GRID_UNIT * 5}px;
  opacity: 0.6;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: ${constants.GRID_UNIT * 9}px;
    height: ${constants.GRID_UNIT * 5}px;
    background: #fff;
  }
  
  img:first-child {
    animation: switchOn 2s infinite;
    animation-fill-mode: forwards;
    
    @keyframes switchOn {
      0% {
        z-index: 2;
      }
  
      100% {
        z-index: 0;
      }
    }
  }

  img:last-child {
    animation: switchOff 2s infinite;
    animation-delay: 2s;

    @keyframes switchOff {
      0% {
        z-index: 0;
      }
  
      50% {
        z-index: 2;
      }
    }
  }
`;

SwitchAnimationWrap.displayName = "SwitchAnimationWrap";
