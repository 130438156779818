import { FC, HTMLProps } from "react";
import styled, { css } from "styled-components";

import constants from "../_globalStyles/constants";
import { pxToRem, pxToEm } from "../../util/helpers";

interface InputLabelProps extends HTMLProps<HTMLLabelElement> {
  floating?: boolean;
  floated?: boolean;
}

const InputLabel: FC<InputLabelProps> = styled.label`
  display: inline-block;
  color: ${constants.INPUT_LABEL_COLOR};
  font-size: ${pxToRem(12)};
  font-weight: 400;
  padding: ${pxToRem(8)} 0;
  pointer-events: none;
  margin-bottom: 0;
  transition: all 0.05s ease-in;

  ${({ floating }) =>
    floating &&
    css`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: ${pxToRem(16)};
    line-height: ${pxToEm(20, 16)};
    color: ${constants.INPUT_PLACEHOLDER_COLOR};
    
    ${({ floated }) =>
      floated &&
      css`
        bottom: calc(100% - 33px);
        font-size: ${pxToRem(12)};
        color: ${constants.INPUT_LABEL_COLOR};
      `}}
  `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${constants.INPUT_DISABLED_COLOR};
    `}
`;

InputLabel.displayName = "InputLabel";

export default InputLabel;
