import { FC, HTMLProps } from "react";
import styled from "styled-components";

import constants from "../../components/_globalStyles/constants";
import Box, { BoxProps } from "../../components/particles/Box";

export const TabsList: FC<HTMLProps<HTMLElement>> = styled.div`
  display: flex;
  margin: 0 0 ${constants.GRID_UNIT * 3}px;
  padding: 0;
  list-style: none;

  @media (max-width: ${constants.BREAKPOINT_MOBILE_L}px) {
    overflow-x: scroll;
    overflow-y: hidden;

    button:hover {
      background: transparent !important;
    }
  }
`;

TabsList.displayName = "TabsList";

export const WidgetPageBox: FC<BoxProps> = styled(Box)`
  background: ${constants.COLOR_GRAY_100};
`;

WidgetPageBox.displayName = "WidgetPageBox";

export const ActiveAreaBox: FC<BoxProps> = styled(Box)`
  background: ${constants.COLOR_GRAY_100};
`;

ActiveAreaBox.displayName = "ActiveAreaBox";

export const InactiveAreaBox: FC<BoxProps> = styled(Box)`
  background: ${constants.MAIN_BG_COLOR};
  min-height: 320px;
`;

InactiveAreaBox.displayName = "InactiveAreaBox";
