import React, {
  VFC,
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback
} from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";

import { getWidgets } from "../../util/hooks";
import { getRequestErrorMessage } from "../../util/request";
import { requestPromotion } from "../../util/apiRequests";
import { AppContext } from "../../components/_functional/AppProvider";
import Box from "../../components/particles/Box";
import Loader from "../../components/atoms/Loader";
import PageMessage from "../../components/templates/PageMessage";
import AdFormComponent from "./Component";
import { PageBox } from "./styled";
import { composeDefaultValues } from "./helpers";

const AdFormContainer: VFC = () => {
  const { t } = useTranslation();

  const { newspaperSlug, widgetId, id } = useParams();

  const [searchParams] = useSearchParams();
  const customerId = searchParams.get("customer_id");
  const customerToken = searchParams.get("admin_customer_token");

  const { newspaper, widgets, ad, storeAd, resetAd, storePopup } =
    useContext(AppContext);

  const { isCommunity } = newspaper;

  const [error, setError] = useState(null);

  const isNew = !id;

  getWidgets();

  const doGetData = customerToken || (newspaperSlug && customerId);

  const getPromotion = useCallback(() => {
    if (doGetData && id && !ad) {
      return requestPromotion(id, newspaperSlug, customerId)
        .then(storeAd)
        .catch((response) =>
          setError({
            title: t("errors.generic_title"),
            message: getRequestErrorMessage(response, t)
          })
        );
    }
  }, [doGetData, id, newspaperSlug, customerId]);

  useEffect(() => {
    getPromotion();
  }, [getPromotion]);

  useEffect(() => {
    return () => {
      setError(null);
      resetAd();
    };
  }, []);

  useEffect(() => {
    if (isNew) {
      resetAd();
    }
  }, [isNew]);

  const defaultValues = useMemo(
    () => composeDefaultValues(widgets, widgetId, ad, isNew),
    [isNew, ad, widgets, widgetId]
  );

  const instanceName = newspaper.instanceName || newspaper.name;

  const isLoaded = Boolean(newspaper && widgets && (isNew || ad));
  const showMessage = Boolean(widgets && !widgets.length);

  return (
    <>
      <Helmet>
        <title>{`${
          isNew
            ? isCommunity
              ? t("general.ad_form.create_title_lo_com")
              : t("general.ad_form.create_title")
            : isCommunity
            ? t("general.ad_form.edit_title_lo_com")
            : t("general.ad_form.edit_title")
        } | ${instanceName}`}</title>
      </Helmet>

      {error && (
        <Box as="main" marginT={10} marginH={1}>
          <PageMessage
            icon="warning"
            status="warning"
            title={error.title}
            text={error.message}
            ctaAction={error.ctaAction}
            ctaText={error.ctaText}
            cancelAction={error.cancelAction}
            cancelText={error.cancelText}
          />
        </Box>
      )}
      {!error && (
        <>
          {showMessage && (
            <Box as="main" marginT={10} marginH={1}>
              <PageMessage
                icon="no-items"
                title={t("messages.ad_form_no_widgets_title")}
                text={isCommunity ? t("messages.ad_form_no_widgets_body_lo_com") : t("messages.ad_form_no_widgets_body")}
              />
            </Box>
          )}

          {!showMessage && (
            <PageBox as="main">
              <Loader active={!isLoaded}>
                <AdFormComponent
                  isCommunity={isCommunity}
                  isNew={isNew}
                  ad={ad}
                  widgets={widgets}
                  defaultValues={defaultValues}
                  setError={setError}
                  storePopup={storePopup}
                />
              </Loader>
            </PageBox>
          )}
        </>
      )}
    </>
  );
};

export default AdFormContainer;
