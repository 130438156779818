import React, { VFC, useCallback, useContext, useMemo, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import constants from "../../constants";
import { getSearchParams } from "../../util/helpers";
import { acceptInvite } from "../../util/apiRequests";
import { signIn } from "../../util/authentication";
import { AppContext } from "../../components/_functional/AppProvider";
import Box from "../../components/particles/Box";
import InputError from "../../components/atoms/InputError";
import Text from "../../components/atoms/Text";
import Button from "../../components/atoms/Button";
import CheckboxInput from "../../components/organisms/CheckboxInput";
import TextInput from "../../components/organisms/TextInput";
import { PageBox, TileContainer } from "./styled";

const Component: VFC<{
  invitationToken: string;
  redirectUrl?: string;
}> = ({ invitationToken, redirectUrl: _redirectUrl }) => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const userEmail = getSearchParams(location?.search, "email");
  const customerId = searchParams.get("customer_id");

  const { newspaperSlug } = useParams();

  const {
    newspaperSettings,
    storeUser,
    storeNewspaper,
    storeModal,
    resetModal
  } = useContext(AppContext);

  const termsUrl = newspaperSettings?.legalInfo?.terms_and_conditions_url;
  const privacyUrl = newspaperSettings?.legalInfo?.privacy_policy_url;

  const termsLabel = useMemo(() => {
    if (termsUrl && privacyUrl) {
      return t("session.terms_and_policy_label")
        .replace(
          "%terms%",
          `<a
          href="${termsUrl}"
          target="_blank"
          rel="noopener noreferrer"
        >
          ${t("session.terms")}
          <span class="ddb-visually-hidden">
            ${t("accessibility.new_tab")}
          </span>
        </a>`
        )
        .replace(
          "%policy%",
          `<a
          href="${privacyUrl}"
          target="_blank"
          rel="noopener noreferrer"
        >
          ${t("session.privacy_policy")}
          <span class="ddb-visually-hidden">
            ${t("accessibility.new_tab")}
          </span>
        </a>`
        );
    } else if (termsUrl) {
      return t("session.terms_label").replace(
        "%terms%",
        `<a
        href="${termsUrl}"
        target="_blank"
        rel="noopener noreferrer"
      >
        ${t("session.terms")}
        <span class="ddb-visually-hidden">
          ${t("accessibility.new_tab")}
        </span>
      </a>`
      );
    } else if (privacyUrl) {
      return t("session.policy_label").replace(
        "%policy%",
        `<a
        href="${privacyUrl}"
        target="_blank"
        rel="noopener noreferrer"
      >
        ${t("session.privacy_policy")}
        <span class="ddb-visually-hidden">
          ${t("accessibility.new_tab")}
        </span>
      </a>`
      );
    }
    return null;
  }, [termsUrl, privacyUrl, t]);

  const termsLabelText = useMemo(() => {
    if (termsUrl && privacyUrl) {
      return t("session.terms_and_policy_label")
        .replace("%terms%", `${t("session.terms")}`)
        .replace("%policy%", `${t("session.privacy_policy")}`);
    } else if (termsUrl) {
      return t("session.terms_label").replace(
        "%terms%",
        `${t("session.terms")}`
      );
    } else if (privacyUrl) {
      return t("session.policy_label").replace(
        "%policy%",
        `${t("session.privacy_policy")}`
      );
    }
    return null;
  }, [termsUrl, privacyUrl, t]);

  const termsErrorMessage = useMemo(() => {
    if (termsUrl && privacyUrl) return t("session.terms_and_policy_required");
    else if (termsUrl) return t("session.terms_required");
    else if (privacyUrl) return t("session.policy_required");
    return "";
  }, [termsUrl, privacyUrl, t]);

  const defaultValues = {
    email: userEmail
  };

  const formMethods = useForm({
    mode: "onChange",
    defaultValues,
    shouldFocusError: true
  });

  const [formError, setFormError] = useState(null);
  const resetFormError = () => setFormError(null);

  const { formState, handleSubmit, setError: setFieldError } = formMethods;
  const { isSubmitting, isValid } = formState;
  const isSubmissionDisabled = !isValid || !!formError || isSubmitting;

  const onSubmitSuccess = useCallback(
    (data) => {
      storeUser(data);

      const _newspaper = data.newspapers.find((n) => n.slug === newspaperSlug);
      storeNewspaper(_newspaper);
      
      const isCommunity = _newspaper.isCommunity;

      const connectionsUrl = `/${newspaperSlug}/connections?customer_id=${customerId}`;
      const createAdUrl = `/${newspaperSlug}/promotions/new?customer_id=${customerId}`;

      storeModal({
        icon: "hurrah",
        customIcon: true,
        title: t("messages.welcome.title", {
          instance_name: _newspaper.instanceName || _newspaper.name
        }),
        text: isCommunity
          ? t("messages.welcome.body_lo_com")
          : t("messages.welcome.body"),
        ctaText: t("messages.welcome.cta_connect_pages"),
        ctaAction: () => {
          navigate(connectionsUrl);
          resetModal();
        },
        secondaryActionText: isCommunity
          ? t("messages.welcome.cta_create_ad_lo_com")
          : t("messages.welcome.cta_create_ad"),
        secondaryAction: () => {
          navigate(createAdUrl);
          resetModal();
        },
        onClose: resetModal,
        controlsWidth: 40
      });

      const redirectUrl = _redirectUrl || `/${newspaperSlug}/widgets?customer_id=${customerId}`;
      const onSignIn = () => navigate(redirectUrl);

      signIn(
        {
          token: data.jwt_token,
          userEmail: data.email,
          userId: data.id,
          newspaper: _newspaper.slug
        },
        onSignIn
      );
    },
    [newspaperSlug, customerId]
  );

  const onSubmitFail = useCallback((response) => {
    const {
      response: { data }
    } = response;

    if (typeof data.error === "string") {
      setFormError(data.error);
    } else if (data.errors instanceof Array) {
      data.errors.forEach((_error) => {
        if (_error.source?.pointer === "invitation_token") {
          setFormError(t("session.invitation_token_error"));
        } else {
          setFieldError(_error?.source?.pointer, {
            type: "custom",
            message: _error?.message
          });
        }
      });
    }
  }, []);

  const onSubmit = useCallback(
    (data) => {
      const submitData = {
        invitation_token: invitationToken,
        password: data.password
      };

      return acceptInvite(submitData).then(onSubmitSuccess).catch(onSubmitFail);
    },
    [onSubmitSuccess, onSubmitFail, invitationToken]
  );

  return (
    <>
      <Helmet>
        <title>
          {t("session.sign_up_title")} | {t("general.product_name_dashboard_general")}
        </title>
      </Helmet>
      <PageBox>
        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            aria-errormessage={formError ? "ddb_sign_up_form_error" : ""}
          >
            <Box
              as="section"
              className="ddb-max-content-width-s"
              paddingT={5}
              paddingB={2}
              marginHAuto
            >
              <Box marginB={2}>
                <Text as="h1" styledAs="h3" formatted>
                  {t("session.sign_up", {
                    product: t("general.product_name_dashboard_general")
                  })}
                </Text>
              </Box>
              <Box marginB={2}>
                <TileContainer border={false}>
                  <Box>
                    {!!formError && (
                      <Box marginV={2}>
                        <InputError
                          id="ddb_sign_up_form_error"
                          aria-live="polite"
                          data-cy="form-error"
                        >
                          {`${t(
                            "errors.general_form_error_desc"
                          )}: ${formError}`}
                        </InputError>
                      </Box>
                    )}
                    <Box marginB={2}>
                      <TextInput
                        name="email"
                        type="email"
                        autoComplete="email"
                        label={t("session.email")}
                        onChange={resetFormError}
                        required
                        readOnly
                      />
                    </Box>

                    <Box marginB={3}>
                      <TextInput
                        name="password"
                        type="password"
                        autoComplete="new-password"
                        label={t("session.password")}
                        onChange={resetFormError}
                        required
                        requiredErrorMessage={t(
                          "errors.required_specific_field",
                          { input: t("session.password_label") }
                        )}
                        validation={{
                          minLength: {
                            value: constants.USER_PASSWORD_MIN_LEN,
                            message: t("errors.minimum_symbols", {
                              input: t("session.password_label"),
                              number: constants.USER_PASSWORD_MIN_LEN.toString()
                            })
                          }
                        }}
                      />
                    </Box>

                    {(termsUrl || privacyUrl) && (
                      <Box marginT={3}>
                        <CheckboxInput
                          name="terms"
                          value={1}
                          label={
                            <span
                              dangerouslySetInnerHTML={{
                                __html: termsLabel
                              }}
                            />
                          }
                          labelText={termsLabelText}
                          required
                          requiredErrorMessage={termsErrorMessage}
                        />
                      </Box>
                    )}

                    <Box flex justifyRight paddingT={1}>
                      <Button
                        primary
                        type="submit"
                        disabled={isSubmissionDisabled}
                      >
                        {t("session.sign_up_submit")}
                      </Button>
                    </Box>
                  </Box>
                </TileContainer>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </PageBox>
    </>
  );
};

export default Component;
