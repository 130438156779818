import { FC } from "react";
import styled from "styled-components";

import constants from "../../components/_globalStyles/constants";
import Tile, { TileProps } from "../../components/atoms/Tile";

export const TileContainer: FC<TileProps> = styled(Tile)`
  padding: ${constants.GRID_UNIT * 7}px ${constants.GRID_UNIT * 11}px ${constants.GRID_UNIT * 4}px;

  @media (max-width: ${constants.MAX_CONTENT_WIDTH_S}) {
    padding: ${constants.GRID_UNIT * 7}px ${constants.GRID_UNIT * 2}px ${constants.GRID_UNIT * 3}px;
  }
`;

TileContainer.displayName = "TileContainer";
