import { FC, HTMLProps } from "react";
import styled, { css } from "styled-components";

import constants from "../../_globalStyles/constants";
import { pxToRem } from "../../../util/helpers";
import Box, { BoxProps } from "../../particles/Box";
import Tile, { TileProps } from "../../atoms/Tile";

interface ImageInputWrapProps extends HTMLProps<HTMLLabelElement> {
  media: string;
}

interface ImageInputTileProps extends TileProps {
  media: string;
}

interface ImagePreviewBoxProps extends BoxProps {
  media: string;
  readOnly?: boolean;
}

interface TooltipWrapProps extends HTMLProps<HTMLElement> {
  media: string;
  active?: boolean;
}

export const ImageInputWrap: FC<ImageInputWrapProps> = styled.div`
  position: relative;
  display: block;
  width: ${constants.GRID_UNIT * 50}px;
  max-width: 100%;
  overflow: hidden;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  ${({ media }) =>
    media
      ? css`
          height: ${constants.GRID_UNIT * 50}px;

          @media (max-width: ${constants.BREAKPOINT_MOBILE}px) {
            & {
              height: ${constants.GRID_UNIT * 40}px;
            }
          }
        `
      : css``}
  
  transition: background 0.05s ease-in, color 0.05s ease-in;
  cursor: pointer;
`;

ImageInputWrap.displayName = "ImageInputWrap";

export const ImageInputTile: FC<ImageInputTileProps> = styled(Tile)`
  font-size: ${pxToRem(20)};
  line-height: 1.2;
  font-weight: 500;
  color: ${constants.COLOR_GRAY_450};
  text-align: center;
  min-height: ${constants.GRID_UNIT * 20}px;

  .ddb-keyboard-navigation-on ${ImageInputWrap}:focus > & {
    outline: 3px dotted ${constants.FOCUS_OUTLINE_COLOR};
    outline-offset: -${constants.GRID_UNIT * 1.5}px;
  }

  ${({ media }) =>
    media ?
    css`
      background: rgba(0, 0, 0, 0.8) !important;
      color: ${constants.COLOR_WHITE} !important;
      outline-color: ${constants.COLOR_WHITE} !important;
      
      svg {
        color: ${constants.COLOR_WHITE} !important;
      }

      ${ImageInputWrap}:hover > &,
      .ddb-keyboard-navigation-on ${ImageInputWrap}:focus > & {
        z-index: 1;
      }

    ` : css`
      ${ImageInputWrap}:hover > &,
      ${ImageInputWrap}:active > &,
      ${ImageInputWrap}:focus > & {
        background: ${constants.COLOR_GRAY_50};
        color: ${constants.COLOR_GRAY_500};

        svg {
          color: ${constants.COLOR_GRAY_375};
        }
      }

      ${ImageInputWrap}:active > & {
        color: ${constants.COLOR_BLACK};

        svg {
          color: ${constants.COLOR_GRAY_450};
        }
      }
    `}

  transition: background 0.05s ease-in, color 0.05s ease-in;

  svg {
    transition: color 0.05s ease-in;
  }
`;

ImageInputTile.displayName = "ImageInputTile";

export const ImageInfo: FC<HTMLProps<HTMLParagraphElement>> = styled.p`
  margin: 0;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(16)};
  font-weight: 400;
`;

ImageInfo.displayName = "ImageInfo";

export const ImageInputLabel: FC<BoxProps> = styled(Box)`
  cursor: pointer;
`;

ImageInputLabel.displayName = "ImageInputLabel";

export const ImagePreviewBox: FC<ImagePreviewBoxProps> = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: ${({ media }) => media && `url("${media}")`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  ${({ readOnly }) => readOnly && css`
    opacity: .5;
  `}
`;

ImagePreviewBox.displayName = "ImagePreviewBox";

export const TooltipWrap: FC<TooltipWrapProps> = styled.div`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: ${({ media }) => media ? `40px` : `20px`};
  left: 8px;
  right: 8px;
  z-index: 200;

  ${({ active }) =>
    active &&
    css`
      visibility: visible;
      opacity: 1;
    `}

  transition: opacity 0.05s ease-in;
`

TooltipWrap.displayName = "TooltipWrap";
