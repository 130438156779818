import React, { VFC, useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import constants from "../../constants";
import { AppContext } from "../../components/_functional/AppProvider";
import { setNewPassword } from "../../util/apiRequests";
import { signIn } from "../../util/authentication";
import { getCookieByName, deleteCookie } from "../../util/cookieProvider";
import Box from "../../components/particles/Box";
import InputError from "../../components/atoms/InputError";
import Text from "../../components/atoms/Text";
import Button from "../../components/atoms/Button";
import TextInput from "../../components/organisms/TextInput";
import { TileContainer } from "./styled";

const Component: VFC<{
  resetPasswordToken: string;
}> = ({ resetPasswordToken }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { storeUser, storeNewspaper } = useContext(AppContext);

  const formMethods = useForm({
    mode: "onChange",
    shouldFocusError: true
  });

  const [formError, setFormError] = useState(null);

  const {
    formState,
    handleSubmit,
    setError: setFieldError,
    control
  } = formMethods;

  const { isSubmitting, isValid } = formState;
  const isSubmissionDisabled = !isValid || !!formError || isSubmitting;

  const passwordValue = useWatch({
    control,
    name: "password"
  });

  const passwordConfirmValue = useWatch({
    control,
    name: "password_confirmation"
  });

  const validatePasswordEquality = useCallback(() => {
    if (
      !passwordValue ||
      !passwordConfirmValue ||
      passwordValue === passwordConfirmValue
    )
      setFormError(null);
    else setFormError(t("session.passwords_mismatch"));
  }, [passwordValue, passwordConfirmValue]);

  const onSubmitSuccess = useCallback((data) => {
    storeUser(data);

    let _newspaper;
    let redirectUrl = getCookieByName("ddb_sign_in_redirect_url");
    deleteCookie("ddb_sign_in_redirect_url");

    if (redirectUrl) {
      // Check if redirect URL has valid newspaper.slug in it.
      const _nSM = redirectUrl.match(/^\/(.+)\//);
      const _newspaperSlug = _nSM ? _nSM[1] : null;
      _newspaper = data.newspapers.find(n => n.slug === _newspaperSlug);

      if (!_newspaperSlug || !_newspaper) {
        _newspaper = data.newspapers[0];
        redirectUrl = `/${_newspaper.slug}/widgets`;
      }
    } else {
      _newspaper = data.newspapers[0];
      redirectUrl = `/${_newspaper.slug}/widgets`;
    }

    storeNewspaper(_newspaper);

    const onSignIn = () => navigate(redirectUrl);

    signIn(
      {
        token: data.jwt_token,
        userEmail: data.email,
        userId: data.id,
        newspaper: _newspaper.slug
      },
      onSignIn
    );
  }, []);

  const onSubmitFail = useCallback((response) => {
    const {
      response: { data }
    } = response;

    if (typeof data.error === "string") setFormError(data.error);
    else if (data.errors instanceof Array) {
      data.errors.forEach((error) => {
        setFieldError(error?.source?.pointer, {
          type: "custom",
          message: error?.message
        });
      });
    }
  }, []);

  const onSubmit = useCallback(
    (data) => {
      const submitData = {
        ad_user: {
          password: data.password,
          password_confirmation: data.password_confirmation,
          reset_password_token: resetPasswordToken
        }
      };

      return setNewPassword(submitData)
        .then(onSubmitSuccess)
        .catch(onSubmitFail);
    },
    [onSubmitSuccess, onSubmitFail, resetPasswordToken]
  );

  return (
    <>
      <Helmet>
        <title>
          {t("session.new_password_title")} |{" "}
          {t("general.product_name_dashboard_general")}
        </title>
      </Helmet>
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          aria-errormessage={formError ? "ddb_new_password_form_error" : ""}
        >
          <Box
            as="section"
            className="ddb-max-content-width-s"
            paddingT={5}
            paddingB={2}
            marginHAuto
          >
            <Box marginB={2}>
              <Text as="h1" styledAs="h3">
                {t("session.new_password")}
              </Text>
            </Box>
            <Box marginB={2}>
              <TileContainer border={false}>
                <Box>
                  {!!formError && (
                    <Box marginV={2}>
                      <InputError
                        id="ddb_new_password_form_error"
                        aria-live="polite"
                        data-cy="form-error"
                      >
                        {`${t("errors.general_form_error_desc")}: ${formError}`}
                      </InputError>
                    </Box>
                  )}
                  <Box marginB={2}>
                    <TextInput
                      name="password"
                      type="password"
                      autoComplete="new-password"
                      label={t("session.new_password_input")}
                      required
                      requiredErrorMessage={t(
                        "errors.required_specific_field",
                        { input: t("session.new_password_label") }
                      )}
                      validation={{
                        minLength: {
                          value: constants.USER_PASSWORD_MIN_LEN,
                          message: t("errors.minimum_symbols", {
                            input: t("session.new_password_label"),
                            number: constants.USER_PASSWORD_MIN_LEN.toString()
                          })
                        },
                        validate: validatePasswordEquality
                      }}
                    />
                  </Box>

                  <Box marginB={2}>
                    <TextInput
                      name="password_confirmation"
                      type="password"
                      autoComplete="new-password"
                      label={t("session.new_password_confirmation_input")}
                      required
                      requiredErrorMessage={t(
                        "errors.required_specific_field",
                        {
                          input: t("session.new_password_confirmation_label")
                        }
                      )}
                      validation={{
                        minLength: {
                          value: constants.USER_PASSWORD_MIN_LEN,
                          message: t("errors.minimum_symbols", {
                            input: t("session.new_password_confirmation_label"),
                            number: constants.USER_PASSWORD_MIN_LEN.toString()
                          })
                        },
                        validate: validatePasswordEquality
                      }}
                    />
                  </Box>

                  <Box flex justifyRight marginT={4}>
                    <Button
                      primary
                      type="submit"
                      disabled={isSubmissionDisabled}
                    >
                      {t("session.new_password_submit")}
                    </Button>
                  </Box>
                </Box>
              </TileContainer>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default Component;
