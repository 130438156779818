import React, { VFC } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";

import { Newspaper, Customer, WidgetsItem, Page  } from "../../types";
import Box from "../../components/particles/Box";
import Transition from "../../components/particles/Transition";
import PageMessage from "../../components/templates/PageMessage";
import WidgetsAnalytics from "../../components/templates/WidgetsAnalytics";
import WidgetsMessage from "../../components/templates/WidgetsMessage";
import Widget from "../../components/templates/Widget";
import constants from "../../constants";

interface ComponentProps {
  isCommunity: boolean;
  message: {
    title: string;
    text: string;
  };
  newspaper: Newspaper;
  customer: Customer;
  widgets: WidgetsItem[];
  pages: Page[];
}

const DashboardComponent: VFC<ComponentProps> = ({
  isCommunity,
  message,
  newspaper,
  customer,
  widgets,
  pages
}) => {
  const { t } = useTranslation();

  const { newspaperSlug } = useParams();
  const [searchParams] = useSearchParams();

  const showCtaMessage = widgets?.length && widgets.some(_widget => _widget.fetchingEnabled) && !pages?.length;

  const { filteringEnabled, adsCount, analytics: { views, clicks } } = customer;
  const filteringHashtag = newspaper.filteringHashtag;
  
  const ctaMessage = showCtaMessage ? {
    title: t("general.dashboard.link_facebook_message.title"),
    text: isCommunity ? t("general.dashboard.link_facebook_message.body_lo_com") : t("general.dashboard.link_facebook_message.body"),
    ctaText: t("general.dashboard.link_facebook_message.cta"),
    ctaTo: `/${newspaperSlug}/connections?${searchParams}`
  } : null;

  const analytics = {
    title:
      constants.ANALYTICS_SUMMARY_PERIOD > 1
        ? t("general.dashboard.analytics_days", {
            number: constants.ANALYTICS_SUMMARY_PERIOD
          })
        : t("general.dashboard.analytics_day", {
            number: constants.ANALYTICS_SUMMARY_PERIOD
          }),
    adsCount,
    views,
    clicks
  };

  return (
    <Transition>
      <Box as="main" id="ddb_main_content" className="ddb-max-content-width" paddingH={2}>
        {message ? (
          <Box marginT={3} marginH={1}>
            <PageMessage
              icon="no-items"
              title={message.title}
              text={message.text}
            />
          </Box>
        ) : (
          <>
            <Box className="ddb-desktop" marginV={3} flex alignStretch>
              <Box flexDynamic inlineFlex width="50%" marginR={1}>
                <WidgetsAnalytics {...analytics} isCommunity={isCommunity} />
              </Box>
              {ctaMessage && (
                <Box flexDynamic inlineFlex width="50%" marginL={1}>
                  <WidgetsMessage {...ctaMessage} />
                </Box>
              )}
            </Box>
            <Box className="ddb-mobile ddb-mobile-l ddb-desktop-s" marginV={3}>
              <Box width="100%" marginB={3}>
                <WidgetsAnalytics {...analytics} isCommunity={isCommunity} />
              </Box>
              {ctaMessage && (
                <Box width="100%" marginB={3}>
                  <WidgetsMessage {...ctaMessage} />
                </Box>
              )}
            </Box>
            {widgets?.map((widget) => (
              <Box key={widget.id} marginB={3}>
                <Widget
                  {...widget}
                  filteringEnabled={filteringEnabled}
                  filteringHashtag={filteringHashtag}
                  isCommunity={isCommunity}
                  locale={newspaper.locale}
                />
              </Box>
            ))}
          </>
        )}
      </Box>
    </Transition>
  );
};

export default DashboardComponent;
