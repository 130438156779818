/**
 * In case of multiple tooltips on the page use unique id for each.
 */

// TODO: show tooltip with delay (1-5sec).

import React, { FC, ReactNode, useCallback, useState } from "react";

import Text from "../../atoms/Text";
import { TooltipWrap, TooltipTile } from "./styled";

interface TooltipProps {
  id?: string;
  text: string;
  children: ReactNode;
  width?: string | number;
}

const Tooltip: FC<TooltipProps> = ({ id: _id, text, children, width = 32 }) => {
  const id = _id || "tooltip";

  const [active, setActive] = useState(false);

  const showTooltip = useCallback(() => setActive(true), []);
  const hideTooltip = useCallback(() => setActive(false), []);

  return (
    <TooltipWrap tabIndex={0} onFocus={showTooltip} onBlur={hideTooltip}>
      <TooltipTile
        role="tooltip"
        id={id}
        active={active}
        paddingH={1.5}
        paddingV={1}
        width={width}
      >
        <Text small>{text}</Text>
      </TooltipTile>
      <div aria-describedby={id}>{children}</div>
    </TooltipWrap>
  );
};

export default Tooltip;
