import { FC, HTMLProps } from "react";
import styled, { css } from "styled-components";

import constants from "../../_globalStyles/constants";
import Text, { TextProps } from "../../atoms/Text";
import { pxToRem } from "../../../util/helpers";

interface CheckboxProps {
  small?: boolean;
  checked: boolean;
}

interface CheckboxLabelTextProps extends TextProps {
  small?: boolean;
  accent?: boolean;
}

export const CheckboxLabel: FC<HTMLProps<HTMLLabelElement>> = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${constants.GRID_UNIT * 1}px ${constants.GRID_UNIT * 2}px ${constants.GRID_UNIT * 1}px 0;
  cursor: pointer;
`;

CheckboxLabel.displayName = "CheckboxLabel";

export const CheckboxInputBase: FC<HTMLProps<HTMLInputElement>> = styled.input`
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important;
`;

CheckboxInputBase.displayName = "CheckboxInputBase";

export const Checkbox: FC<CheckboxProps> = styled.div`
  position: relative;
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.25rem;
  margin-right: ${constants.GRID_UNIT * 1.5}px;
  border: 1px solid ${constants.CHECKBOX_OFF_BORDER_COLOR};
  background-color: transparent;
  color: transparent;
  font-size: ${pxToRem(12)};

  ${({ checked }) =>
    checked &&
    css`
      border-color: transparent;
      background-color: ${constants.CHECKBOX_ON_COLOR};
      color: ${constants.CHECKBOX_ON_TEXT_COLOR};
    `}

  ${({ small }) =>
    small &&
    css`
      width: 1rem;
      height: 1rem;
      font-size: ${pxToRem(10)};
    `}
`;

Checkbox.displayName = "Checkbox";

export const CheckboxLabelText: FC<CheckboxLabelTextProps> = styled(Text).attrs(
  ({ accent }) => ({
    as: accent ? "b" : "span"
  })
)`
  line-height: ${pxToRem(24)};

  ${({ small }) =>
    small &&
    css`
      font-size: ${pxToRem(14)};
    `}

  .ddb-keyboard-navigation-on input:focus ~ &:after {
    content: "";
    position: absolute;
    top: ${constants.GRID_UNIT * 0.5}px;
    bottom: ${constants.GRID_UNIT * 0.5}px;
    left: ${constants.GRID_UNIT * .5}px;
    right: ${constants.GRID_UNIT * .5}px;
    border: 3px dotted ${constants.FOCUS_OUTLINE_COLOR};
  }

  z-index: 1;
`;

CheckboxLabelText.displayName = "CheckboxLabelText";

export const CheckboxRound: FC = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${constants.GRID_UNIT * 5}px;
  height: ${constants.GRID_UNIT * 5}px;
  border-radius: 50%;
  border: 1px solid ${constants.CHECKBOX_ROUND_OFF_BORDER_COLOR};
  background: ${constants.CHECKBOX_ROUND_OFF_COLOR};
  color: transparent;
  font-size: ${pxToRem(24)};

  input:checked + & {
    border-color: transparent;
    background-color: ${constants.CHECKBOX_ROUND_ON_COLOR};
    color: ${constants.CHECKBOX_ROUND_ON_TEXT_COLOR};
  }

  .ddb-keyboard-navigation-on input:focus + & {
    outline: 2px dotted ${constants.FOCUS_OUTLINE_COLOR};
    outline-offset: 2px;
  }
`;

CheckboxRound.displayName = "CheckboxRound";
