import React, {
  VFC,
  ReactNode,
  useMemo,
  useState,
  useCallback,
  useEffect
} from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import constants from "../../_globalStyles/constants";
import Box from "../../particles/Box";
import Icon from "../../atoms/Icon";
import InputAsterisk from "../../atoms/InputAsterisk";
import InputError from "../../atoms/InputError";
import NoticeMessage from "../NoticeMessage";
import {
  ImageInputWrap,
  ImageInfo,
  ImageInputTile,
  ImageInputLabel,
  ImagePreviewBox,
  TooltipWrap
} from "./styled";

interface ImageInputProps {
  name: string;
  id?: string;
  label?: string;
  required?: boolean;
  format?: string;
  size?: number;
  readOnly?: boolean;
  defaultValue?: string;
  recommendation?: string;
  tooltipMessage?: {
    title: string;
    titleAs?: string;
    text?: string;
    textElement?: ReactNode;
  };
}

const MIME_TYPES = {
  "image/bmp": [".bmp"],
  "image/gif": [".gif"],
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
  "image/webp": [".webp"],
  "image/svg+xml": [".svg"]
};

const ImageInput: VFC<ImageInputProps> = ({
  id: _id,
  name,
  label,
  required,
  format,
  size,
  readOnly,
  defaultValue,
  recommendation,
  tooltipMessage
}) => {
  const id = _id || name;

  const [isTooltipActive, setIsTooltipActive] = useState(false);

  const showTooltip = useCallback(() => setIsTooltipActive(true), []);
  const hideTooltip = useCallback(() => setIsTooltipActive(false), []);

  const onInputTileFocus = useCallback(() => showTooltip(), [showTooltip]);
  const onInputTileBlur = useCallback((e) => !e.currentTarget.contains(e.relatedTarget) && hideTooltip(), [hideTooltip])

  const { t } = useTranslation();

  const mimeTypeArr = format.split(", ");

  const mimeTypeObj = useMemo(() => {
    const obj = {};

    mimeTypeArr.forEach((type) => {
      obj[type] = MIME_TYPES[type];
    });

    return obj;
  }, [mimeTypeArr]);

  const formatText = useMemo(() => {
    let formats = [];

    mimeTypeArr.forEach(
      (type) => (formats = [...formats, ...MIME_TYPES[type]])
    );

    return formats.join(", ");
  }, [mimeTypeArr]);

  const sizeText = useMemo(() => `${Math.round(size / 2000000)}MB`, [size]);

  const {
    control,
    register,
    unregister,
    formState,
    setValue,
    getFieldState,
    trigger
  } = useFormContext();

  const validation = {
    required: required ? t("errors.required") : false
  };

  const { error: RHFError } = getFieldState(name, formState);
  const value = useWatch({ control, name });

  const [previewUrl, setPreviewUrl] = useState(defaultValue);
  const [dropError, setDropError] = useState(null);

  const handleNewPreview = useCallback(
    (_value) => {
      if (_value instanceof File) {
        const reader = new FileReader();
        let preview;

        reader.onabort = () => setDropError("file reading was aborted");
        reader.onerror = () => setDropError("file reading has failed");
        reader.onload = () => {
          preview = reader.result;
          setPreviewUrl(preview);
        };

        reader.readAsDataURL(_value);
      } else {
        setPreviewUrl(_value);
      }
    },
    [previewUrl]
  );

  const handleNewValue = useCallback(
    (_value) => setValue(name, _value),
    [name]
  );

  const getErrorMessage = useCallback(
    ({ errors }) => {
      const error = errors?.[0];
      if (!error) return t("errors.generic_file_upload_error");

      switch (error?.code) {
        case "invalid-format":
          return error.message;
        case "file-too-large":
          return t("errors.bad_file_size");

        default:
          return t("errors.generic_file_upload_error");
      }
    },
    [t]
  );

  const onDropAccepted = useCallback(
    (acceptedFiles) => handleNewValue(acceptedFiles[0]),
    [handleNewPreview, handleNewValue]
  );

  const onDropRejected = useCallback(
    (rejectedFiles) => setDropError(getErrorMessage(rejectedFiles[0])),
    [getErrorMessage]
  );

  useEffect(() => {
    handleNewPreview(value);
    trigger(name);
    setDropError("");
  }, [value]);

  useEffect(() => {
    register(name, validation);
  }, [name, validation]);

  useEffect(() => () => unregister(name), [name]);

  const error = dropError || RHFError?.message;
  const imageUrl = previewUrl || defaultValue;

  const validator = useCallback(
    (file) => {
      if (mimeTypeArr.indexOf(file.type) === -1) {
        return {
          code: "invalid-format",
          message: t("errors.bad_file_format")
        };
      }

      return null;
    },
    [mimeTypeArr, t]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    /**
     * You could provide more specific mime types in accept (with mimeTypeObj).
     * But, due to this behaviour: https://github.com/react-dropzone/react-dropzone/issues/1191#issuecomment-1120134811,
     * which is not very obvious for users, I've allowed all the images selection.
     * The needed format validation is performed by validator func.
     */
    accept: {
      "image/*": []
    },
    multiple: false,
    maxSize: size,
    onDropRejected,
    onDropAccepted,
    validator
  });

  const rootProps = {
    ...getRootProps({
      error,
      onKeyDown: (e) => (e.key === "Enter" || e.key === " ") && open()
    })
  };

  const inputProps = { ...getInputProps({ id }) };

  return readOnly ? (
    <ImageInputWrap media={defaultValue} readOnly onFocus={onInputTileFocus} onBlur={onInputTileBlur} tabIndex={0}>
      <ImageInputTile
        relative
        flex
        alignCenter
        justifyCenter
        paddingV={3.75}
        paddingH={4}
        radius={0}
        border={false}
        media={defaultValue}
        height="100%"
      >
        {!defaultValue && (
          <Box>
            <Box marginB={1}>
              <Icon
                name="add-image"
                color={constants.COLOR_GRAY_340}
                size="4rem"
              />
            </Box>
          </Box>
        )}
        {defaultValue && <ImagePreviewBox media={defaultValue} readOnly />}
      </ImageInputTile>
      
      {tooltipMessage && (
        <TooltipWrap id={`ddb_${id}_tooltip`} role="tooltip" active={isTooltipActive} media={defaultValue} >
          <NoticeMessage {...tooltipMessage} />
        </TooltipWrap>
      )}
    </ImageInputWrap>
  ): (
    <>
      <ImageInputWrap media={imageUrl} {...rootProps}>
        <ImageInputTile
          relative
          flex
          alignCenter
          justifyCenter
          paddingV={3.75}
          paddingH={4}
          radius={0}
          border={false}
          media={imageUrl}
          height="100%"
        >
          <Box flex vertical>
            <input {...inputProps} name={name} />

            <Box inlineFlex justifyCenter>
              <Icon
                name="add-image"
                color={constants.COLOR_GRAY_340}
                size="4rem"
              />
            </Box>
            <ImageInputLabel as="label" htmlFor={id} marginL={required && 1}>
              {label}
              {required && <InputAsterisk />}
            </ImageInputLabel>
            <Box marginT={1}>
              <ImageInfo>
                {t("general.uploader.size")} {sizeText}
              </ImageInfo>
              <ImageInfo>
                {t("general.uploader.format")} {formatText}
              </ImageInfo>
              {recommendation && <ImageInfo>{recommendation}</ImageInfo>}
            </Box>
          </Box>
        </ImageInputTile>
        {imageUrl && <ImagePreviewBox media={imageUrl} />}
      </ImageInputWrap>
      {error && <InputError marginL={2}>{error}</InputError>}
    </>
  );
};

ImageInput.defaultProps = {
  label: "Add image",
  format: "image/jpeg"
};

export default ImageInput;
