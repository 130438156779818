import React, { VFC } from "react";

import { useTranslation } from "react-i18next";

import { pxToRem } from "../../../util/helpers";
import constants from "../../_globalStyles/constants";
import Box from "../../particles/Box";
import Tile from "../../atoms/Tile";
import Text from "../../atoms/Text";
import { CreateAdLink, CreateAdControl } from "./styled";

interface CreateAdProps {
  isCommunity: boolean;
  action?: () => void;
  actionUrl?: string;
  extended?: boolean;
}

const CreateAd: VFC<CreateAdProps> = ({
  isCommunity,
  action,
  actionUrl,
  extended
}) => {
  const { t } = useTranslation();

  return (
    <Tile
      relative
      flex
      alignCenter
      justifyCenter
      border={false}
      vertical
      height={`${
        extended ? constants.GRID_UNIT * 35 : constants.GRID_UNIT * 22.125
      }px`}
      overflowVisible
      overflowHidden={false}
      marginT={2}
    >
      <Text
        textCenter
        color={constants.TEXT_LIGHTER_COLOR}
        lineHeight={pxToRem(20)}
      >
        {isCommunity
          ? t("general.dashboard.create_ad_lo_com")
          : t("general.dashboard.create_ad")}
      </Text>
      <Box marginT={2}>
        {action && (
          <>
            <CreateAdControl onClick={action}>
              {isCommunity
                ? t("general.dashboard.create_ad_btn_lo_com")
                : t("general.dashboard.create_ad_btn")}
            </CreateAdControl>
            <CreateAdLink aria-hidden="true" onClick={action}></CreateAdLink>
          </>
        )}
        {actionUrl && (
          <>
            <CreateAdControl to={actionUrl}>
              {isCommunity
                ? t("general.dashboard.create_ad_btn_lo_com")
                : t("general.dashboard.create_ad_btn")}
            </CreateAdControl>
            <CreateAdLink aria-hidden="true" to={actionUrl}></CreateAdLink>
          </>
        )}
      </Box>
    </Tile>
  );
};

export default CreateAd;
