import React, { VFC, useMemo, useContext } from "react";
import { useMatch, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AppContext } from "../../_functional/AppProvider";
import TabLink from "../../molecules/TabLink";
import { TabNavigationContainer } from "./styled";

const TabNavigation: VFC = () => {
  const { t } = useTranslation();

  const { newspaperSlug } = useParams();

  const [searchParams] = useSearchParams();
  const searchString = searchParams.toString();

  const { customer, widgets, pages, contacts } = useContext(AppContext);

  const isAnalyticsEnabled = customer?.analyticsEnabled;
  const isContactsEnabled = Boolean(contacts?.text?.length);
  const isActiveDashboard = Boolean(useMatch(`/${newspaperSlug}/widgets`));
  const isActiveConnections = Boolean(
    useMatch(`/${newspaperSlug}/connections`)
  );
  const isActiveAnalytics = Boolean(useMatch(`/${newspaperSlug}/analytics`));
  const isActiveContacts = Boolean(useMatch(`/${newspaperSlug}/contacts`));
  const doWarnConnections = pages?.some(
    (_page) => _page.enabled && (_page.syncError || _page.authError)
  );
  const doAccentConnections = Boolean(
    !doWarnConnections &&
      widgets?.length &&
      widgets.some((_widget) => _widget.fetchingEnabled) &&
      pages &&
      !pages.length
  );

  const links = useMemo(() => {
    const _links = [
      {
        title: t("navigation.dashboard"),
        to: `/${newspaperSlug}/widgets?${searchString}`,
        active: isActiveDashboard
      },
      {
        title: t("navigation.connections"),
        to: `/${newspaperSlug}/connections?${searchString}`,
        warning: doWarnConnections,
        accent: doAccentConnections,
        active: isActiveConnections
      }
    ];
    if (isAnalyticsEnabled) {
      _links.push({
        title: t("navigation.analytics"),
        to: `/${newspaperSlug}/analytics?${searchString}`,
        active: isActiveAnalytics
      });
    }
    if (isContactsEnabled) {
      _links.push({
        title: t("navigation.contacts"),
        to: `/${newspaperSlug}/contacts?${searchString}`,
        active: isActiveContacts
      });
    }

    return _links;
  }, [customer, newspaperSlug, contacts, searchString, pages, widgets]);

  return (
    <TabNavigationContainer>
      <div>
        <nav>
          <ul>
            {links?.map((link, index) => (
              <TabLink
                key={index}
                to={link.to}
                title={link.title}
                warning={link.warning}
                accent={link.accent}
                active={link.active}
              />
            ))}
          </ul>
        </nav>
      </div>
    </TabNavigationContainer>
  );
};

export default TabNavigation;
