import React, { VFC, ReactNode } from "react";

import constants from "../../_globalStyles/constants";
import Box from "../../particles/Box";
import Tile from "../../atoms/Tile";
import Text from "../../atoms/Text";
import { MessageBox } from "./styled";

export interface SecondaryNoticeMessageProps {
  title: string;
  titleAs?: string;
  text?: string;
  textElement?: ReactNode;
}

const SecondaryNoticeMessage: VFC<SecondaryNoticeMessageProps> = ({
  title,
  titleAs,
  text,
  textElement
}) => (
  <Tile
    as="section"
    border={false}
    inverted
    paddingH={3}
    paddingV={2}
    flex
    alignCenter
    maxWidth="100%"
  >
    <MessageBox>
      <Text as={titleAs} small color={constants.COLOR_YELLOW_300}>
        {title}
      </Text>
      {text && (
        <Text as="p" small>
          {text}
        </Text>
      )}
      {textElement}
    </MessageBox>
  </Tile>
);

SecondaryNoticeMessage.defaultProps = {
  titleAs: "h3"
};

export default SecondaryNoticeMessage;
