import React, { VFC, useCallback, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Ad as AdProps } from "../../../types";
import stylesConstants from "../../_globalStyles/constants";
import constants from "../../../constants";
import { pxToRem, truncateText, formatNumber } from "../../../util/helpers";
import Box from "../../particles/Box";
import Text from "../../atoms/Text";
import Icon from "../../atoms/Icon";
import IconColor from "../../atoms/IconColor";
import Separator from "../../atoms/Separator";
import Button from "../../atoms/Button";
import {
  AdTile,
  AdMedia,
  AdMediaPlaceholder,
  AdMediaIcon,
  AdLink,
  AdText,
  AdControl
} from "./styled";

const { AD_ROTATION_DAYS_MAX } = constants;

/**
 * TODO:
 * 1) account for video media type;
 * 2) show 'expired on', 'paused on', 'hidden on' statuses when they are added on backend;
 * 3) get rid of editAd action, when 2d phase is removed.
 *  */

interface AdComponentProps extends AdProps {
  isCommunity: boolean;
  widgetId: number;
  rotation: number;
  playAd: (widgetId: number, id: number) => any;
  pauseAd: (widgetId: number, id: number) => any;
  editAd?: (id: number) => any;
  titleAs?: string;
}

const Ad: VFC<AdComponentProps> = ({
  isCommunity,
  widgetId,
  id,
  link,
  type,
  message,
  description,
  media,
  mediaType,
  categories,
  status,
  views,
  clicks,
  publishedAt,
  rotation,
  playAd: _playAd,
  pauseAd: _pauseAd,
  editAd: _editAd,
  titleAs
}) => {
  const { t } = useTranslation();

  const [isProcessing, setIsProcessing] = useState(false);

  const active = status === "active";
  const inactive = status === "inactive";
  const paused = status === "paused";
  const expired = status === "expired";
  const hidden = status === "hidden";
  const accountRotationDays = rotation < AD_ROTATION_DAYS_MAX;
  const showExpiryInfo = accountRotationDays && !expired;

  const expiryInfo = useMemo(() => {
    if (!showExpiryInfo) return "";

    const expTimeEls = [];
    let expTime = "";

    const now = new Date().getTime();
    const published = new Date(publishedAt).getTime();
    const deadline = published + rotation * 1000 * 60 * 60 * 24;

    let diff = Math.abs(deadline - now);
    const y = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
    diff -= y * 1000 * 60 * 60 * 24 * 365;
    const d = Math.floor(diff / (1000 * 60 * 60 * 24));
    diff -= d * 1000 * 60 * 60 * 24;
    const h = Math.floor(diff / (1000 * 60 * 60)) % 24;

    if (y === 1) expTimeEls.push(y + " " + t("time.year"));
    else if (y) expTimeEls.push(y + " " + t("time.years"));
    else {
      if (d === 1) expTimeEls.push(d + " " + t("time.day"));
      else if (d) expTimeEls.push(d + 1 + " " + t("time.days"));
      if ((!d || d === 1) && h) expTimeEls.push(h + t("time.hrs"));
    }

    expTime = expTimeEls.join(" ");

    if (deadline - now > 0) {
      if (h > 0 || d || y) {
        return (
          <>
            {t("general.ad.expire_time_future")}&nbsp;<b>{expTime}</b>
          </>
        );
      } else if (h == 0) {
        return (
          <>
            {t("general.ad.expires")}&nbsp;
            <b>{t("general.ad.expires_in_hour")}</b>
          </>
        );
      }
    } else {
      return <></>;
    }
  }, [showExpiryInfo, publishedAt, rotation]);

  const editAd = useCallback(() => _editAd(id), [_editAd, id]);

  const playAd = useCallback(() => {
    setIsProcessing(true);
    return _playAd(widgetId, id).then(() => setIsProcessing(false));
  }, [_playAd, widgetId, id]);

  const pauseAd = useCallback(() => {
    setIsProcessing(true);
    return _pauseAd(widgetId, id).then(() => setIsProcessing(false));
  }, [_pauseAd, widgetId, id]);

  const getStatus = useCallback(() => {
    if (hidden)
      return (
        <>
          <Box marginR={1} flex alignCenter>
            <Icon
              name="error"
              size={pxToRem(16)}
              color={stylesConstants.COLOR_RED_50}
            />
          </Box>
          <Text small>
            <b>{t("general.ad.hidden")}</b>
          </Text>
        </>
      );
    if (active)
      return (
        <>
          <Box marginR={1} flex alignCenter>
            <Icon
              name="status"
              size={pxToRem(16)}
              color={stylesConstants.COLOR_GREEN_300}
            />
          </Box>
          <Text small>
            <b>{t("general.ad.active")}</b>
          </Text>
        </>
      );
    if (inactive)
      return (
        <>
          <Box marginR={1} flex alignCenter>
            <Icon
              name="status"
              size={pxToRem(16)}
              color={stylesConstants.COLOR_GRAY_350}
            />
          </Box>
          <Text small>
            <b>{t("general.ad.inactive")}</b>
          </Text>
        </>
      );
    if (paused)
      return (
        <>
          <Box marginR={1} flex alignCenter>
            <Icon
              name="pause"
              size={pxToRem(16)}
              color={stylesConstants.COLOR_GRAY_375}
            />
          </Box>
          <Text small>
            <b>{t("general.ad.paused")}</b>
          </Text>
        </>
      );
    if (expired)
      return (
        <>
          <Box marginR={1} flex alignCenter>
            <Icon
              name="expired"
              size={pxToRem(16)}
              color={stylesConstants.COLOR_RED_50}
            />
          </Box>
          <Text small>
            <b>{t("general.ad.expired")}</b>
          </Text>
        </>
      );
  }, [active, inactive, paused, expired, hidden]);

  const adIcon = type === "instagram" ? "instagram_18" : type;
  const adText = message || description;
  const adTextTrunc = useMemo(() => truncateText(adText, 10), [adText]);

  useEffect(
    () => () => {
      setIsProcessing(false);
    },
    []
  );

  return (
    <>
      <AdTile
        as="section"
        className="ddb-desktop ddb-desktop-s"
        paddingV={3}
        paddingH={4}
        active={active}
      >
        <AdLink
          href={link}
          target="_blank"
          label={`${
            isCommunity
              ? t("general.ad.link_lo_com", {
                  text: adTextTrunc
                })
              : t("general.ad.link", {
                  text: adTextTrunc
                })
          }, ${t("accessibility.new_tab")}`}
        />

        {adText && (
          <Text as={titleAs} className="ddb-visually-hidden">
            {adText}
          </Text>
        )}

        <Box flex>
          <Box marginR={4} flexStatic flex>
            {media && <AdMedia src={media} alt="" />}
            {!media && (
              <AdMediaPlaceholder>
                <AdMediaIcon name="no-image" />
              </AdMediaPlaceholder>
            )}
          </Box>

          <Box marginR={5} flexDynamic maxWidth={58.5}>
            {adText && (
              <>
                <AdText>{truncateText(adText, 100)}</AdText>
                <Separator marginV={2} />
              </>
            )}
            <Box inlineBlock marginB={0.5}>
              {!!categories.length && (
                <Text as="span" small>
                  {t("general.ad.categories")}
                  <b>
                    <Box as="span" marginL={0.5}>
                      {categories.join(", ")}
                    </Box>
                    {showExpiryInfo && (
                      <Box as="span" marginH={1}>
                        •
                      </Box>
                    )}
                  </b>
                </Text>
              )}
              {showExpiryInfo && (
                <Text as="span" small>
                  {expiryInfo}
                </Text>
              )}
            </Box>
            <Box flex alignCenter>
              {type === "local_offers" ? (
                <Box flex alignCenter>
                  <Icon
                    name="link"
                    size={pxToRem(24)}
                    color="#297acc"
                    aria-label={t("general.ad.manual_type")}
                  />
                </Box>
              ) : (
                <Box height={3} flex alignCenter>
                  <IconColor
                    name={adIcon}
                    size={pxToRem(18)}
                    aria-label={
                      type === "instagram"
                        ? t("general.ad.instagram_type")
                        : t("general.ad.facebook_type")
                    }
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Box flexStatic flex alignCenter>
            <Box flexStatic marginR={5}>
              <Box flex alignCenter marginB={1}>
                {getStatus()}
              </Box>
              <Text small marginB={1}>
                <b>{formatNumber(views)}</b>{" "}
                {views === 1 ? t("general.ad.view") : t("general.ad.views")}
              </Text>
              <Text small>
                <b>{formatNumber(clicks)}</b>{" "}
                {clicks === 1 ? t("general.ad.click") : t("general.ad.clicks")}
              </Text>
            </Box>
            {!hidden && !expired && (
              <Box flexStatic flex alignCenter width={pxToRem(40 * 2 + 8)}>
                <Box marginR={1}>
                  <AdControl>
                    <Button
                      onClick={editAd}
                      title={
                        isCommunity
                          ? t("general.ad.edit_lo_com")
                          : t("general.ad.edit")
                      }
                      label={
                        isCommunity
                          ? t("general.ad.edit_label_lo_com", {
                              text: adTextTrunc
                            })
                          : t("general.ad.edit_label", {
                              text: adTextTrunc
                            })
                      }
                    >
                      <Icon name="edit" />
                    </Button>
                  </AdControl>
                </Box>

                {active && (
                  <AdControl>
                    <Button
                      onClick={pauseAd}
                      disabled={isProcessing}
                      title={
                        isCommunity
                          ? t("general.ad.pause_lo_com")
                          : t("general.ad.pause")
                      }
                      label={
                        isCommunity
                          ? t("general.ad.pause_label_lo_com", {
                              text: adTextTrunc
                            })
                          : t("general.ad.pause_label", {
                              text: adTextTrunc
                            })
                      }
                    >
                      <Icon name="pause" />
                    </Button>
                  </AdControl>
                )}

                {paused && (
                  <AdControl>
                    <Button
                      onClick={playAd}
                      disabled={isProcessing}
                      title={
                        isCommunity
                          ? t("general.ad.activate_lo_com")
                          : t("general.ad.activate")
                      }
                      label={
                        isCommunity
                          ? t("general.ad.activate_label_lo_com", {
                              text: adTextTrunc
                            })
                          : t("general.ad.activate_label", {
                              text: adTextTrunc
                            })
                      }
                    >
                      <Icon name="play" />
                    </Button>
                  </AdControl>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </AdTile>

      <AdTile
        as="section"
        className="ddb-mobile ddb-mobile-l"
        paddingV={2}
        paddingH={2}
        active={active}
      >
        <AdLink
          href={link}
          target="_blank"
          label={`${t("general.ad.link", {
            text: truncateText(adText, 20)
          })}, ${t("accessibility.new_tab")}`}
        />

        {adText && (
          <Text as={titleAs} className="ddb-visually-hidden">
            {adText}
          </Text>
        )}

        <Box flex marginB={2}>
          <Box marginR={2} flexStatic flex>
            {media && <AdMedia src={media} alt="" />}
            {!media && (
              <AdMediaPlaceholder>
                <AdMediaIcon name="no-image" />
              </AdMediaPlaceholder>
            )}
          </Box>
          <Box flexDynamic>
            <AdText>{truncateText(adText, 100)}</AdText>
          </Box>
        </Box>
        <Box>
          <Box marginB={0.5}>
            {!!categories.length && (
              <Text as="span" small>
                {t("general.ad.categories")}
                <b>
                  <Box as="span" marginL={0.5}>
                    {categories.join(", ")}
                  </Box>
                  {showExpiryInfo && (
                    <Box as="span" marginH={1}>
                      •
                    </Box>
                  )}
                </b>
              </Text>
            )}
            {showExpiryInfo && (
              <Text as="span" small>
                {expiryInfo}
              </Text>
            )}
          </Box>

          <Box flex alignCenter>
            {type === "local_offers" ? (
              <Box>
                <Icon
                  name="link"
                  color="#297acc"
                  size={pxToRem(24)}
                  aria-label={t("general.ad.manual_type")}
                />
              </Box>
            ) : (
              <Box flex alignCenter>
                <IconColor
                  name={adIcon}
                  size={pxToRem(18)}
                  aria-label={
                    type === "instagram"
                      ? t("general.ad.instagram_type")
                      : t("general.ad.facebook_type")
                  }
                />
              </Box>
            )}
          </Box>
        </Box>
        <Separator marginV={2} />
        <Box flex alignCenter>
          <Box flexGrow marginR={2}>
            <Box flex alignCenter marginB={0.5}>
              {getStatus()}
            </Box>
            <Text small marginB={0.5}>
              <b>{formatNumber(views)}</b>{" "}
              {views === 1 ? t("general.ad.view") : t("general.ad.views")}
            </Text>
            <Text small>
              <b>{formatNumber(clicks)}</b>{" "}
              {clicks === 1 ? t("general.ad.click") : t("general.ad.clicks")}
            </Text>
          </Box>
          <Box flexStatic flex alignCenter width={pxToRem(40 * 2 + 8)}>
            {!hidden && !expired && (
              <Box marginR={1}>
                <AdControl>
                  <Button
                    onClick={editAd}
                    title={
                      isCommunity
                        ? t("general.ad.edit_lo_com")
                        : t("general.ad.edit")
                    }
                    label={
                      isCommunity
                        ? t("general.ad.edit_label_lo_com", {
                            text: adTextTrunc
                          })
                        : t("general.ad.edit_label", {
                            text: adTextTrunc
                          })
                    }
                  >
                    <Icon name="edit" />
                  </Button>
                </AdControl>
              </Box>
            )}
            {active && (
              <AdControl>
                <Button
                  onClick={pauseAd}
                  disabled={isProcessing}
                  title={
                    isCommunity
                      ? t("general.ad.pause_lo_com")
                      : t("general.ad.pause")
                  }
                  label={
                    isCommunity
                      ? t("general.ad.pause_label_lo_com", {
                          text: adTextTrunc
                        })
                      : t("general.ad.pause_label", {
                          text: adTextTrunc
                        })
                  }
                >
                  <Icon name="pause" />
                </Button>
              </AdControl>
            )}
            {paused && (
              <AdControl>
                <Button
                  onClick={playAd}
                  disabled={isProcessing}
                  title={
                    isCommunity
                      ? t("general.ad.activate_lo_com")
                      : t("general.ad.activate")
                  }
                  label={
                    isCommunity
                      ? t("general.ad.activate_label_lo_com", {
                          text: adTextTrunc
                        })
                      : t("general.ad.activate_label", {
                          text: adTextTrunc
                        })
                  }
                >
                  <Icon name="play" />
                </Button>
              </AdControl>
            )}
          </Box>
        </Box>
      </AdTile>
    </>
  );
};

Ad.defaultProps = {
  titleAs: "h3"
};

export default Ad;
