import React from "react";

import switch_off from "../../../assets/images/switch_off.png";
import switch_on from "../../../assets/images/switch_on.png";
import { SwitchAnimationWrap } from "./styled";

const SwitchAnimation = () => (
  <SwitchAnimationWrap>
    <img src={switch_off} alt="" />
    <img src={switch_on} alt="" />
  </SwitchAnimationWrap>
)

export default SwitchAnimation;
