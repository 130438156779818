import { VFC, HTMLProps } from "react";
import styled from "styled-components";

import constants from "../_globalStyles/constants";
import { pxToRem, pxToEm } from "../../util/helpers";

interface InputBaseProps extends HTMLProps<HTMLInputElement> {
  error?: boolean;
}

const InputBase: VFC<InputBaseProps> = styled.input`
  position: relative;
  width: 100%;
  flex: 1 1 auto;
  font-size: ${pxToRem(16)};
  line-height: ${pxToEm(20, 16)};
  font-weight: 400;
  padding: ${pxToEm(8, 16)} 0;
  background-color: transparent;
  border: none;
  box-shadow: 0 1px 0 0
    ${({ error }) =>
      error ? constants.ERROR_COLOR : constants.INPUT_BORDER_COLOR};
  appearance: none;
  text-overflow: ellipsis;

  &::placeholder {
    color: rgba(169, 169, 169, 0);
  }
  &::-webkit-input-placeholder {
    color: rgba(169, 169, 169, 0);
  }
  &::-moz-placeholder {
    color: rgba(169, 169, 169, 0);
  }
  &:-moz-placeholder {
    color: rgba(169, 169, 169, 0);
  }
  &:-ms-input-placeholder {
    color: rgba(169, 169, 169, 0);
  }

  &:focus::placeholder {
    color: rgba(169, 169, 169, 1);
  }
  &:focus::-webkit-input-placeholder {
    color: rgba(169, 169, 169, 1);
  }
  &:focus::-moz-placeholder {
    color: rgba(169, 169, 169, 1);
  }
  &:focus:-moz-placeholder {
    color: rgba(169, 169, 169, 1);
  }
  &:focus:-ms-input-placeholder {
    color: rgba(169, 169, 169, 1);
  }

  &:focus,
  &:active,
  &:-webkit-autofill,
  &:-webkit-autofill:active,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0 500px ${constants.MAIN_BG_COLOR} inset,
      0 2px 0 0
        ${({ error }) =>
          error
            ? constants.ERROR_COLOR
            : constants.INPUT_FOCUS_BORDER_COLOR} !important;
    outline: none;
  }

  &:read-only,
  &:disabled {
    box-shadow: 0 1px 0 0 ${constants.INPUT_BORDER_COLOR} !important;
    outline: none !important;
  }

  &:disabled {
    color: ${constants.INPUT_DISABLED_COLOR};
    cursor: not-allowed;
  }

  transition: box-shadow 0.05s ease-in;
`;

InputBase.displayName = "InputBase";

export default InputBase;
