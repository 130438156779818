import { createGlobalStyle } from "styled-components";

import constants from "./constants";
import { pxToRem, pxToEm } from '../../util/helpers';

const GlobalStyle = createGlobalStyle`
  html {
    font-size: ${constants.ROOT_FONT_SIZE}px;
  }

  body {
    font-family: ${constants.ROOT_FONT_FAMILY};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    line-height: 1.5;
    color: ${constants.TEXT_COLOR};
    margin: 0;

    *::-webkit-scrollbar {
      display: none;
    }
  }

  input,
  button,
  select,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    margin: 0.75em 0;
  }

  h1 {
    font-size: ${pxToRem(40)};
    line-height: ${pxToEm(44, 40)};
  }

  h2 {
    font-size: ${pxToRem(32)};
    line-height: ${pxToEm(36, 32)};
  }

  h3 {
    font-size: ${pxToRem(24)};
    line-height: ${pxToEm(28, 24)};
  }

  h4 {
    font-size: ${pxToRem(20)};
    line-height: ${pxToEm(24, 20)};
  }

  h5 {
    font-size: ${pxToRem(18)};
    line-height: ${pxToEm(22, 18)};
  }

  h6 {
    font-size: ${pxToRem(16)};
    line-height: ${pxToEm(24, 16)};
  }

  p {
    font-size: ${pxToRem(16)};
    margin: 1rem 0;
    line-height: ${pxToEm(24, 16)};
  }

  p, span {
    a,
    a:link,
    a:visited {
      color: ${constants.LINK_COLOR};
      text-decoration: none;
    }

    a:hover {
      color: ${constants.LINK_HOVER_COLOR};
    }

    a:active,
    a:focus {
      color: ${constants.LINK_ACTIVE_COLOR};
    }

    a:hover,
    a:active,
    a:focus {
      text-decoration: underline;
    }
  }

  a,
  a:link,
  a:visited,
  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
  }

  a,
  button {
    transition-property: color, background, opacity, border, box-shadow, transform;
    transition-duration: 0.05s;
    transition-timing-function: ease-in;

    svg {
      transition-property: color, opacity, transform;
      transition-duration: 0.05s;
      transition-timing-function: ease-in;
    }
  }

  a:hover,
  a:active,
  a:focus,
  button:hover,
  button:active,
  button:focus,
  img:hover,
  img:active,
  img:focus,
  label:hover,
  label:active,
  label:focus {
    outline: none;
  }

  .ddb-keyboard-navigation-on a:focus,
  .ddb-keyboard-navigation-on button:focus,
  .ddb-keyboard-navigation-on img:focus {
    outline: 3px dotted ${constants.FOCUS_OUTLINE_COLOR};
    outline-offset: 2px;
    text-decoration: none !important;
  }

  * {
    box-sizing: border-box;
  }

  // Use it to hide element visually but keep it for screen readers (e.g. custom inputs, checkboxes etc).
  .ddb-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .ddb-desktop-s:not(.ddb-desktop),
  .ddb-mobile-l:not(.ddb-desktop-s),
  .ddb-mobile {
    display: none !important;
  }
  
  @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
    .ddb-desktop:not(.ddb-desktop-s) {
      display: none !important;
    }

    .ddb-desktop-s {
      && {
        display: block !important;
      }
    }
  }

  @media (max-width: ${constants.BREAKPOINT_DESKTOP_S}px) {
    .ddb-desktop-s:not(.ddb-mobile-l) {
      display: none !important;
    }

    .ddb-mobile-l {
      && {
        display: block !important;
      }
    }
  }

  @media (max-width: ${constants.BREAKPOINT_MOBILE_L}px) {
    .ddb-mobile-l:not(.ddb-mobile) {
      display: none !important;
    }

    .ddb-mobile {
      display: block !important;
    }
  }
  
  .ddb-max-content-width {
    max-width: ${constants.MAX_CONTENT_WIDTH};
  }

  .ddb-max-content-width-m {
    max-width: ${constants.MAX_CONTENT_WIDTH_M};
  }

  .ddb-max-content-width-s {
    max-width: ${constants.MAX_CONTENT_WIDTH_S};
  }
  
  .ddb-max-content-width-xs {
    max-width: ${constants.MAX_CONTENT_WIDTH_XS};
  }
`;

export default GlobalStyle;
