import {
  isEmpty,
  symmetricDifference,
  isNil,
  findIndex,
  propEq,
  sortBy,
  prop,
  equals,
  compose
} from "ramda";

import constants from "../components/_globalStyles/constants";

export const isEqArr = compose(isEmpty, symmetricDifference);

export function pxToEm(px: number, fontSize: number): string {
  return `${px / fontSize}em`;
}

export function pxToRem(px: number): string {
  return `${px / constants.ROOT_FONT_SIZE}rem`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function valueExists(value: any): boolean {
  return !(isEmpty(value) || isNil(value));
}

export function truncateText(text: string, maxLength: number): string {
  if (!text) return "";
  if (text.length <= maxLength) return text;

  return text.slice(0, maxLength) + "...";
}

export function formatNumber(num: number | string): string {
  num = num.toString();
  const numLen = num.length;
  let formatted = "";

  for (let i = 0; i < num.length; i++) {
    if (i > 0 && i % 3 == 0)
      formatted = `${num[numLen - i - 1]}\xa0` + formatted;
    else formatted = num[numLen - i - 1] + formatted;
  }

  return formatted;
}

function getDateFormat(locale: string): string {
  switch (locale) {
    case "no":
    case "fi":
    case "sv_SE":
    case "da":
    case "pl":
    case "uk":
    case "de":
      return "DD.MM.YYYY";
    default:
      return "YYYY-MM-DD";
  }
}

/** Parse formatted date to YYYY-MM-DD: 2020-09-31.
 * Supported formats are:
 * YYYY-MM-DD: 2020-09-31,
 * DD.MM.YYYY: 31.09.2020
 */
export function parseDate(date: string, format = "DD.MM.YYYY"): string {
  if (!date) return "";

  const parts = date.match(/(\d+)/g),
    fmt = {};
  let i = 0;

  format.replace(/(YYYY|DD|MM)/g, function (part) {
    fmt[part] = i++;
    return "";
  });

  return [parts[fmt["YYYY"]], parts[fmt["MM"]], parts[fmt["DD"]]].join("-");
}

/** Format parsed date (YYYY-MM-DD: 2020-09-31).
 * Supported formats are:
 * YYYY-MM-DD: 2020-09-31,
 * DD.MM.YYYY: 31.09.2020
 * */
export function formatDate(date: string, locale = "en"): string {
  if (!date) return "";

  const format = getDateFormat(locale);

  const parts = date.match(/(\d+)/g),
    fmt = {},
    fmtParts = format.match(/(YYYY|DD|MM)/g),
    fmtSeparators = format.match(/(\.|-)/g);
  let i = 0;

  "YYYY-MM-DD".replace(/(YYYY|DD|MM)/g, function (part) {
    fmt[part] = i++;
    return "";
  });

  let fmtDate = "";
  for (let i = 0; i < fmtParts.length; i++)
    fmtDate += parts[fmt[fmtParts[i]]] + (fmtSeparators[i] || "");

  return fmtDate;
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
export function updateObjInArr(
  arr: any,
  objId: number | string,
  newObj: any
): any {
  const objIndex = findIndex(propEq("id", objId))(arr);

  const newArr = [...arr];
  newArr[objIndex] = newObj;

  return newArr;
}

export function getSearchParams(search: string, param: string): string | null {
  if (!search || !param) return;

  const params = {};
  search = search.replace(/^\?/, "");

  const definitions = search.split("&");

  definitions.forEach((val) => {
    const parts = val.split("=", 2);
    params[decodeURIComponent(parts[0])] = parts[1]
      ? decodeURIComponent(parts[1])
      : null;
  });

  return params?.[param] ? params[param] : null;
}

export function genDocumentLang(locale: string): string {
  switch (locale) {
    case "sv_SE":
      return "sv";
    default:
      return locale;
  }
}

export const sortByName = sortBy(prop("name"));
export const sortById = sortBy(prop("id"));

export function isEqual(a: any, b: any) {
  return equals(a, b);
}

export const toFormData = (
  obj: object,
  formKey: string,
  formData?: any
): any => {
  const fd = formData || new FormData();
  let key;

  for (const property in obj) {
    if (typeof obj[property] !== "undefined" && obj[property] !== null) {
      key = `${formKey}[${property}]`;

      if (obj[property] instanceof Array) {
        key = `${key}[]`;

        obj[property].forEach((arrayEl, index) => {
          if (
            typeof arrayEl === "object" &&
            !(arrayEl instanceof File) &&
            !(arrayEl instanceof Date)
          ) {
            fd.append(`${key}[_index]`, index);

            toFormData(arrayEl, key, fd);
          } else {
            fd.append(`${key}`, arrayEl);
          }
        });
      } else if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof File) &&
        !(obj[property] instanceof Date)
      ) {
        toFormData(obj[property], key, fd);
      } else {
        fd.append(`${key}`, obj[property]);
      }
    }
  }

  return fd;
};

export function setDocumentLang(locale: string): void {
  document.documentElement.lang = genDocumentLang(locale);
}

export function setFavicon(favicon: {
  favicon32: string;
  favicon192: string;
}): void {
  const favicon32 = document.getElementById(
    "ddb_favicon_32"
  ) as HTMLLinkElement | null;
  const favicon192 = document.getElementById(
    "ddb_favicon_192"
  ) as HTMLLinkElement | null;

  favicon32.href = favicon.favicon32;
  favicon192.href = favicon.favicon192;
}

export function getPlural(
  locale: "en" | "no" | "sv_SE" | "da" | "fi" | "pl" | "uk" | "de",
  count: number | string
): "one" | "few" | "many" | "other" {
  const c = count.toString();

  if (locale === "uk") {
    if (c.endsWith("1") && !c.endsWith("11")) return "one";
    else if (
      (c.endsWith("2") && !c.endsWith("12")) ||
      (c.endsWith("3") && !c.endsWith("13")) ||
      (c.endsWith("4") && !c.endsWith("14"))
    ) {
      return "few";
    } else return "many";
  }

  if (c === "1") return "one";
  else return "other";
}

export function ratioToPerformance(ratio: number): string {
  const _perN = Math.round(ratio * 100 * 100) / 100;
  const _perS = _perN.toString() + " %";
  return _perS;
}
