import React, { VFC } from "react";

import { useFormContext, useWatch } from "react-hook-form";

import { CheckboxInputProps } from "../CheckboxInput";
import { TileCheckboxInput } from "./styled";

const CheckboxTile: VFC<CheckboxInputProps> = (props) => {
  const { name } = props;
  const { control } = useFormContext();
  const checked = !!useWatch({ control, name });

  return <TileCheckboxInput {...props} checked={checked} />;
};

export default CheckboxTile;
