import { FC } from "react";
import styled, { css } from "styled-components";

import constants from "../../_globalStyles/constants";
import Box, { BoxProps } from "../../particles/Box";
import Text, { TextProps } from "../../atoms/Text";

interface DropdownWrapProps extends BoxProps {
  isOpen: boolean;
}

export const DropdownWrap: FC<DropdownWrapProps> = styled(Box)`
  button {
    border: 0;
    box-shadow: none;
    background: transparent;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      color: ${constants.COLOR_GRAY_450};
      font-size: ${constants.GRID_UNIT * 2.75}px;
      margin-left: ${constants.GRID_UNIT}px;

      ${(props) =>
        props.isOpen &&
        css`
          transform: rotate(-180deg);
        `}
    }
  }
`;

DropdownWrap.displayName = "DropdownWrap";

export const DropdownLi = styled.li`
  border: 0;
  outline: none;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
`;

DropdownLi.displayName = "DropdownLi";

interface DropdownOptionProps extends TextProps {
  active?: boolean;
  focused?: boolean;
}

export const DropdownOption: FC<DropdownOptionProps> = styled(Text)`
  cursor: pointer;

  &:hover,
  &:active {
    background-color: ${constants.COLOR_BLUE_50};
    outline: none;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: ${constants.COLOR_BLUE_50};
    `}

  .ddb-keyboard-navigation-on &.ddb-is-focused {
    outline: 3px dotted ${constants.FOCUS_OUTLINE_COLOR};
    outline-offset: -8px;
  }

  transition: background-color 0.2s ease;
`;

DropdownOption.displayName = "DropdownOption";
