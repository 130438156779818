import { getCookieByName } from "../util/cookieProvider";
import Api from "./api";
import { getSearchParams, toFormData } from "./helpers";
import {
  User,
  Customer,
  WidgetsItem,
  Widget,
  FormAd,
  Newspaper,
  Page,
  AnalyticsAd,
  AnalyticsSummary,
  AnalyticsPagination,
  Contacts
} from "../types";

type InviteData = {
  invitation_token: string;
  password: string;
};

type SignInData = {
  ad_user: {
    email: string;
    password: string;
  };
};

type RequestNewPasswordData = {
  ad_user: {
    email: string;
  };
};

type SetNewPasswordData = {
  ad_user: {
    password: string;
    password_confirmation: string;
    reset_password_token: string;
  };
};

const extendWithCustomerToken = (token?: string, _config?: object) => {
  if (!token) return _config;

  const config = _config ? { ..._config } : {};

  const params = config["params"] ? { ...config["params"] } : {};
  params["customer_token"] = token;

  config["params"] = params;

  return config;
};

const extendWithSessionToken = (_config?: object) => {
  const config = _config ? { ..._config } : {};

  const headers = config["headers"] ? { ...config["headers"] } : {};

  const sessionToken = getCookieByName("ddb_session_token");

  headers["Authorization"] = `Bearer ${sessionToken}`;
  config["headers"] = headers;

  return config;
};

const extendWithMainParams = (
  newspaperSlug?: string,
  customerId?: string | number,
  _config?: object
) => {
  const config = _config ? { ..._config } : {};

  const params = config["params"] ? { ...config["params"] } : {};

  if (newspaperSlug) params["newspaper_id"] = newspaperSlug;
  if (customerId) params["customer_id"] = customerId;

  if (Object.keys(params).length) config["params"] = params;

  return config;
};

const requestNewspaperSettings = (newspaperId: string) => {
  const config = {};
  const params = {};

  const invitationToken = getSearchParams(location?.search, "invitation_token");
  const customerToken = getSearchParams(
    location?.search,
    "admin_customer_token"
  );

  if (invitationToken) params["invitation_token"] = invitationToken;
  else if (customerToken) params["customer_token"] = customerToken;
  else {
    const headers = {};

    const sessionToken = getCookieByName("ddb_session_token");

    if (!sessionToken)
      return Promise.reject(new Error("You are not authorized to proceed."));
    headers["Authorization"] = `Bearer ${sessionToken}`;
    config["headers"] = headers;
  }

  params["newspaper_id"] = newspaperId;
  config["params"] = params;

  return Api.newspaperSettings({
    config
  }).then(({ data: { data } }) => {
    return {
      newspaperInfo: data.newspaper_info,
      branding: data.branding,
      legalInfo: data.legal_info
    };
  });
};

const requestUser = (): Promise<User> => {
  const sessionToken = getCookieByName("ddb_session_token");

  if (!sessionToken)
    return Promise.reject(new Error("You are not authorized to proceed."));

  const config = extendWithSessionToken();

  return Api.user
    .show({
      config
    })
    .then(({ data }) => ({
      id: data.id,
      email: data.email,
      newspapers: data.newspapers.map((n) => ({
        id: n.id,
        name: n.name,
        instanceName: n.instance_name,
        email: n.email,
        slug: n.slug,
        locale: n.locale,
        logo: n.display_settings.logo_url,
        filteringHashtag: n.filtering_hashtag,
        termsUrl: n.details.terms_and_conditions_url,
        policyUrl: n.details.privacy_policy_url,
        isCommunity: n.is_community
      })),
      customers: data.customers.map((c) => ({
        id: c.id,
        name: c.name,
        email: c.email,
        displayLogo: c.display_logo,
        logo: c.logo_thumb_url,
        filteringEnabled: c.filtering_enabled,
        analyticsEnabled: c.analytics_enabled,
        suspended: c.suspended,
        newspaper: {
          id: c.newspaper.id,
          name: c.newspaper.name,
          instanceName: c.newspaper.instance_name,
          email: c.newspaper.email,
          slug: c.newspaper.slug,
          locale: c.newspaper.locale,
          logo: c.newspaper.display_settings.logo_url,
          filteringHashtag: c.newspaper.filtering_hashtag,
          termsUrl: c.newspaper.details.terms_and_conditions_url,
          policyUrl: c.newspaper.details.privacy_policy_url,
          isCommunity: c.newspaper.is_community
        }
      }))
    }));
};

const checkInvitationToken = (token: string): Promise<{ status: string }> =>
  Api.user
    .checkInvitationToken({
      config: {
        params: {
          invitation_token: token
        }
      }
    })
    .then(() => ({ status: "valid" }))
    .catch(() => ({ status: "invalid" }));

const acceptInvite = (data: InviteData): Promise<User> =>
  Api.user
    .acceptInvite({
      config: {
        data
      }
    })
    .then(({ data }) => ({
      id: data.id,
      email: data.email,
      jwt_token: data.jwt_token,
      newspapers: data.newspapers.map((n) => ({
        id: n.id,
        name: n.name,
        instanceName: n.instance_name,
        email: n.email,
        slug: n.slug,
        locale: n.locale,
        logo: n.display_settings.logo_url,
        filteringHashtag: n.filtering_hashtag,
        termsUrl: n.details.terms_and_conditions_url,
        policyUrl: n.details.privacy_policy_url,
        isCommunity: n.is_community
      })),
      customers: data.customers.map((c) => ({
        id: c.id,
        name: c.name,
        email: c.email,
        displayLogo: c.display_logo,
        logo: c.logo_thumb_url,
        filteringEnabled: c.filtering_enabled,
        analyticsEnabled: c.analytics_enabled,
        suspended: c.suspended,
        newspaper: {
          id: c.newspaper.id,
          name: c.newspaper.name,
          instanceName: c.newspaper.instance_name,
          email: c.newspaper.email,
          slug: c.newspaper.slug,
          locale: c.newspaper.locale,
          logo: c.newspaper.display_settings.logo_url,
          filteringHashtag: c.newspaper.filtering_hashtag,
          termsUrl: c.newspaper.details.terms_and_conditions_url,
          policyUrl: c.newspaper.details.privacy_policy_url,
          isCommunity: c.newspaper.is_community
        }
      }))
    }));

const requestSignIn = (data: SignInData): Promise<User> => {
  return Api.user
    .signIn({
      config: {
        data
      }
    })
    .then(({ data }) => ({
      id: data.id,
      email: data.email,
      jwt_token: data.jwt_token,
      newspapers: data.newspapers.map((n) => ({
        id: n.id,
        name: n.name,
        instanceName: n.instance_name,
        email: n.email,
        slug: n.slug,
        locale: n.locale,
        logo: n.display_settings.logo_url,
        filteringHashtag: n.filtering_hashtag,
        termsUrl: n.details.terms_and_conditions_url,
        policyUrl: n.details.privacy_policy_url,
        isCommunity: n.is_community
      })),
      customers: data.customers.map((c) => ({
        id: c.id,
        name: c.name,
        email: c.email,
        displayLogo: c.display_logo,
        logo: c.logo_thumb_url,
        filteringEnabled: c.filtering_enabled,
        analyticsEnabled: c.analytics_enabled,
        suspended: c.suspended,
        newspaper: {
          id: c.newspaper.id,
          name: c.newspaper.name,
          instanceName: c.newspaper.instance_name,
          email: c.newspaper.email,
          slug: c.newspaper.slug,
          locale: c.newspaper.locale,
          logo: c.newspaper.display_settings.logo_url,
          filteringHashtag: c.newspaper.filtering_hashtag,
          termsUrl: c.newspaper.details.terms_and_conditions_url,
          policyUrl: c.newspaper.details.privacy_policy_url,
          isCommunity: c.newspaper.is_community
        }
      }))
    }));
};

const requestNewPassword = (data: RequestNewPasswordData) => {
  return Api.user
    .requestNewPassword({
      config: {
        data
      }
    })
    .then(({ data }) => data);
};

const checkNewPasswordToken = (token: string): Promise<{ status: string }> =>
  Api.user
    .checkNewPasswordToken({
      config: {
        params: {
          reset_password_token: token
        }
      }
    })
    .then(() => ({ status: "valid" }))
    .catch(() => ({ status: "invalid" }));

const setNewPassword = (data: SetNewPasswordData): Promise<User> => {
  return Api.user
    .newPassword({
      config: {
        data
      }
    })
    .then(({ data }) => ({
      id: data.id,
      email: data.email,
      jwt_token: data.jwt_token,
      newspapers: data.newspapers.map((n) => ({
        id: n.id,
        name: n.name,
        instanceName: n.instance_name,
        email: n.email,
        slug: n.slug,
        locale: n.locale,
        logo: n.display_settings.logo_url,
        filteringHashtag: n.filtering_hashtag,
        termsUrl: n.details.terms_and_conditions_url,
        policyUrl: n.details.privacy_policy_url,
        isCommunity: n.is_community
      })),
      customers: data.customers.map((c) => ({
        id: c.id,
        name: c.name,
        email: c.email,
        displayLogo: c.display_logo,
        logo: c.logo_thumb_url,
        filteringEnabled: c.filtering_enabled,
        analyticsEnabled: c.analytics_enabled,
        suspended: c.suspended,
        newspaper: {
          id: c.newspaper.id,
          name: c.newspaper.name,
          instanceName: c.newspaper.instance_name,
          email: c.newspaper.email,
          slug: c.newspaper.slug,
          locale: c.newspaper.locale,
          logo: c.newspaper.display_settings.logo_url,
          filteringHashtag: c.newspaper.filtering_hashtag,
          termsUrl: c.newspaper.details.terms_and_conditions_url,
          policyUrl: c.newspaper.details.privacy_policy_url,
          isCommunity: c.newspaper.is_community
        }
      }))
    }));
};

const requestCustomer = (...params): Promise<Customer> => {
  const config = extendWithSessionToken();

  return Api.customer
    .show({
      params,
      config
    })
    .then(({ data: { data } }) => {
      return {
        id: data.id,
        name: data.name,
        email: data.email,
        logo: data.logo_thumb_url,
        displayLogo: data.display_logo,
        filteringEnabled: data.filtering_enabled,
        analyticsEnabled: data.analytics_enabled,
        FBAppId: data.fb_app_id,
        analytics: data.analytics,
        adsCount: data.published_ads_count,
        suspended: data.suspended
      };
    });
};

const requestDashboard = (): Promise<
  [_newspaper: Newspaper, _customer: Customer, _FBAppId: string]
> => {
  const config = {};

  const customerToken =
    getSearchParams(location?.search, "customer_token") ||
    getSearchParams(location?.search, "admin_customer_token");

  config["params"] = {
    customer_token: customerToken
  };

  return Api.dashboard.index({ config }).then(({ data }) => {
    const _newspaper = {
      id: data.data.newspaper.id,
      name: data.data.newspaper.name,
      instanceName: data.data.newspaper.instance_name,
      logo: data.data.newspaper.display_settings?.logo_url,
      locale: data.data.newspaper.locale,
      email: data.data.newspaper.email,
      termsUrl: data.data.newspaper.details?.terms_and_conditions_url,
      policyUrl: data.data.newspaper.details?.privacy_policy_url,
      filteringHashtag: data.data.newspaper.filtering_hashtag,
      isCommunity: data.data.newspaper.is_community
    };

    const _customer = {
      id: data.data.id,
      name: data.data.name,
      email: data.data.email,
      logo: data.data.logo_thumb_url,
      filteringEnabled: data.data.filtering_enabled,
      analyticsEnabled: data.data.analytics_enabled,
      analytics: data.data.analytics,
      adsCount: data.data.published_ads_count,
      displayLogo: data.data.display_logo,
      suspended: data.data.suspended
    };

    const _FBAppId = data.data.fb_app_id;

    return [_newspaper, _customer, _FBAppId];
  });
};

const requestSources = (
  newspaperSlug?: string,
  customerId?: number | string
): Promise<Page[]> => {
  let config = {};

  const customerToken =
    getSearchParams(location?.search, "customer_token") ||
    getSearchParams(location?.search, "admin_customer_token");

  if (customerToken) config = extendWithCustomerToken(customerToken, config);
  else {
    config = extendWithSessionToken();
    config = extendWithMainParams(newspaperSlug, customerId, config);
  }

  return Api.sources.index({ config }).then(({ data: { data } }) =>
    data.map(
      ({
        id,
        remote_id,
        name,
        source_type,
        url,
        verified,
        fetching_enabled,
        has_sync_errors
      }) => ({
        id,
        remote_id,
        name,
        type: source_type === "business_instagram" ? "instagram" : source_type,
        url,
        enabled: fetching_enabled,
        disabled: !fetching_enabled,
        syncError: has_sync_errors,
        authError: !verified
      })
    )
  );
};

const updateSource = (
  id: number,
  data: any,
  newspaperSlug?: string,
  customerId?: number | string
): Promise<Page> => {
  let config = {};
  config["data"] = data;

  const customerToken =
    getSearchParams(location?.search, "customer_token") ||
    getSearchParams(location?.search, "admin_customer_token");

  if (customerToken) config = extendWithCustomerToken(customerToken, config);
  else {
    config = extendWithSessionToken(config);
    config = extendWithMainParams(newspaperSlug, customerId, config);
  }

  return Api.sources
    .update({
      params: id,
      config
    })
    .then(({ data }) => {
      const {
        id,
        remote_id,
        name,
        source_type,
        url,
        verified,
        fetching_enabled,
        has_sync_errors
      } = data;

      const _page = {
        id,
        remote_id,
        name,
        type: source_type === "business_instagram" ? "instagram" : source_type,
        url,
        enabled: fetching_enabled,
        disabled: !fetching_enabled,
        syncError: has_sync_errors,
        authError: !verified
      };

      return _page;
    });
};

const switchSource = (
  id: number,
  enabled: boolean,
  newspaperSlug?: string,
  customerId?: number | string
) =>
  updateSource(
    id,
    {
      source: {
        fetching_enabled: enabled
      }
    },
    newspaperSlug,
    customerId
  );

const requestWidgets = (
  newspaperSlug?: string,
  customerId?: number | string
): Promise<WidgetsItem[]> => {
  let config = {};

  const customerToken =
    getSearchParams(location?.search, "customer_token") ||
    getSearchParams(location?.search, "admin_customer_token");

  if (customerToken) config = extendWithCustomerToken(customerToken, config);
  else {
    config = extendWithSessionToken();
    config = extendWithMainParams(newspaperSlug, customerId, config);
  }

  return Api.widgets.index({ config }).then(({ data: { data } }) =>
    data.map((_widget) => ({
      id: _widget.id,
      envId: _widget.promotion_environment_id,
      name: _widget.promotion_environment.name,
      logo: _widget.promotion_environment.display_settings?.logo_url,
      color: _widget.promotion_environment.display_settings?.main_color,
      categories: _widget.promotion_environment_categories?.map((c) => ({
        id: c.id,
        name: c.widget_category.data.name
      })),
      rotation: _widget.rotation_days,
      places: _widget.places,
      placesUsed: _widget.places_used,
      fetchingEnabled: _widget.promotion_environment.fetching_enabled,
      ads: _widget.active_promotions.map((p) => ({
        id: p.id,
        type: p.source_type,
        link: p.link,
        description: p.description,
        message: p.message,
        media: p.image?.url,
        mediaType: p.image?.media_type,
        publishedAt: p.published_at,
        customerName: p.customer.name,
        displayLogo: p.customer.display_logo,
        logo: p.customer.logo_thumb_url
      }))
    }))
  );
};

const requestWidget = (
  id: number | string,
  newspaperSlug?: string,
  customerId?: number | string
): Promise<Widget> => {
  let config = {};

  const customerToken =
    getSearchParams(location?.search, "customer_token") ||
    getSearchParams(location?.search, "admin_customer_token");

  if (customerToken) config = extendWithCustomerToken(customerToken, config);
  else {
    config = extendWithSessionToken();
    config = extendWithMainParams(newspaperSlug, customerId, config);
  }

  return Api.widgets
    .show({ params: id, config })
    .then(({ data: { data } }) => ({
      id: data.id,
      envId: data.promotion_environment_id,
      name: data.promotion_environment.name,
      categories: data.promotion_environment_categories?.map(
        (c) => c.widget_category.data.name
      ),
      rotation: data.rotation_days,
      places: data.places,
      placesUsed: data.places_used,
      fetchingEnabled: data.promotion_environment.fetching_enabled,
      ads: data.active_promotions.map((p) => ({
        id: p.id,
        type: p.source_type,
        link: p.link,
        description: p.description,
        message: p.message,
        media: p.image?.url,
        mediaType: p.image?.media_type,
        publishedAt: p.published_at,
        customerName: p.customer.name,
        displayLogo: p.customer.display_logo,
        logo: p.customer.logo_thumb_url
      }))
    }));
};

// TODO: when 3d phase is supported only, move data restructure here
const requestWidgetPromotions = (
  id: number | string,
  params?: any,
  newspaperSlug?: string,
  customerId?: string
) => {
  let config = {};
  config["params"] = params;

  const customerToken =
    getSearchParams(location?.search, "customer_token") ||
    getSearchParams(location?.search, "admin_customer_token");

  if (customerToken) {
    config = extendWithCustomerToken(customerToken, config);
  } else {
    config = extendWithSessionToken(config);
    config = extendWithMainParams(newspaperSlug, customerId, config);
  }
  return Api.widgetPromotions.index({
    params: id,
    config
  });
};

const hideWidgetPromotion = (
  widgetId: number,
  adId: number,
  newspaperSlug?: string,
  customerId?: string
) => {
  let config = {};

  const customerToken =
    getSearchParams(location?.search, "customer_token") ||
    getSearchParams(location?.search, "admin_customer_token");

  if (customerToken) {
    config = extendWithCustomerToken(customerToken, config);
  } else {
    config = extendWithSessionToken();
    config = extendWithMainParams(newspaperSlug, customerId, config);
  }

  return Api.widgetPromotions.hide({ params: [widgetId, adId], config });
};

const displayWidgetPromotion = (
  widgetId: number,
  adId: number,
  newspaperSlug?: string,
  customerId?: string
) => {
  let config = {};

  const customerToken =
    getSearchParams(location?.search, "customer_token") ||
    getSearchParams(location?.search, "admin_customer_token");

  if (customerToken) {
    config = extendWithCustomerToken(customerToken, config);
  } else {
    config = extendWithSessionToken();
    config = extendWithMainParams(newspaperSlug, customerId, config);
  }

  return Api.widgetPromotions.display({ params: [widgetId, adId], config });
};

const parsePromotionUrl = (
  url: string,
  newspaperSlug?: string,
  customerId?: number | string
) => {
  let config = {};
  config["params"] = { url };

  const customerToken =
    getSearchParams(location?.search, "customer_token") ||
    getSearchParams(location?.search, "admin_customer_token");

  if (customerToken) {
    config = extendWithCustomerToken(customerToken, config);
  } else {
    config = extendWithSessionToken(config);
    config = extendWithMainParams(newspaperSlug, customerId, config);
  }

  return Api.promotions.parse({ config }).then(({ data }) => data);
};

const createPromotion = (
  data: any,
  newspaperSlug?: string,
  customerId?: number | string
): Promise<FormAd> => {
  const formData = toFormData(data, "promotion");

  let config = {};

  config["data"] = formData;
  config["headers"] = {
    "Content-Type": `multipart/form-data boundary=${formData._boundary}`
  };

  const customerToken =
    getSearchParams(location?.search, "customer_token") ||
    getSearchParams(location?.search, "admin_customer_token");

  if (customerToken) {
    config = extendWithCustomerToken(customerToken, config);
  } else {
    config = extendWithSessionToken(config);
    config = extendWithMainParams(newspaperSlug, customerId, config);
  }

  return Api.promotions
    .create({
      config
    })
    .then(({ data: { data } }) => data);
};

const requestPromotion = (
  id: number | string,
  newspaperSlug?: string,
  customerId?: number | string
): Promise<FormAd> => {
  let config = {};

  const customerToken =
    getSearchParams(location?.search, "customer_token") ||
    getSearchParams(location?.search, "admin_customer_token");

  if (customerToken) {
    config = extendWithCustomerToken(customerToken, config);
  } else {
    config = extendWithSessionToken();
    config = extendWithMainParams(newspaperSlug, customerId, config);
  }

  return Api.promotions.edit({ params: id, config }).then(({ data }) => ({
    id: data.id,
    external_id: data.external_id,
    image: data.image?.url,
    link: data.link,
    description: data.description,
    message: data.message,
    source_url: data.source_url,
    customer_promotion_environments: data.customer_promotion_environments?.map(
      (cpe) => ({
        id: cpe.id,
        name: cpe.promotion_environment.name
      })
    ),
    promotion_environment_categories:
      data.promotion_environment_categories?.map((pec) => ({
        id: pec.id,
        name: pec.widget_category.data.name
      })),
    type:
      data.source_type === "business_instagram" ? "instagram" : data.source_type
  }));
};

const updatePromotion = (
  id: number | string,
  data: any,
  newspaperSlug?: string,
  customerId?: number | string
): Promise<FormAd> => {
  const formData = toFormData(data, "promotion");

  let config = {};

  config["data"] = formData;
  config["headers"] = {
    "Content-Type": `multipart/form-data boundary=${formData._boundary}`
  };

  const customerToken =
    getSearchParams(location?.search, "customer_token") ||
    getSearchParams(location?.search, "admin_customer_token");

  if (customerToken) {
    config = extendWithCustomerToken(customerToken, config);
  } else {
    config = extendWithSessionToken(config);
    config = extendWithMainParams(newspaperSlug, customerId, config);
  }

  return Api.promotions
    .update({
      params: id,
      config
    })
    .then(({ data: { data } }) => data);
};

const requestAnalytics = (
  params?: any,
  newspaperSlug?: string,
  customerId?: number | string
): Promise<{
  ads: AnalyticsAd[];
  summary: AnalyticsSummary;
  pagination: AnalyticsPagination;
}> => {
  let config = {};
  const sortingOrder = function () {
    if (params.sortBy === "views") return params.viewsOrder;
    if (params.sortBy === "clicks") return params.clicksOrder;
    if (params.sortBy === "ratio") return params.ratioOrder;
    if (params.sortBy === "date") return params.dateOrder;
    return "";
  };

  config["params"] = {
    page: params.page,
    per: params.per,
    sort_by: params.sortBy,
    order: sortingOrder(),
    from: params.from,
    to: params.to,
    "env[ids]": params.envIds,
    "env[type]": params.envType,
    text: params.text
  };

  const customerToken = getSearchParams(
    location?.search,
    "admin_customer_token"
  );

  if (customerToken) config = extendWithCustomerToken(customerToken, config);
  else {
    config = extendWithSessionToken(config);
    config = extendWithMainParams(newspaperSlug, customerId, config);
  }

  return Api.analytics
    .index({ config })
    .then(({ data: { data, totals, meta } }) => {
      const ads = data.map((ad) => ({
        id: ad.id,
        link: ad.link,
        type:
          ad.source_type === "business_instagram"
            ? "instagram"
            : ad.source_type,
        description: ad.description,
        message: ad.message,
        media: ad.image,
        views: ad.analytics.views,
        clicks: ad.analytics.clicks,
        ratio: ad.analytics.ratio,
        publishedAt: ad.published_at
      }));

      return {
        ads,
        summary: totals,
        pagination: meta
      };
    });
};

const requestContacts = (
  newspaperSlug?: string,
  customerId?: number | string
): Promise<Contacts> => {
  let config = {};

  const customerToken =
    getSearchParams(location?.search, "customer_token") ||
    getSearchParams(location?.search, "admin_customer_token");

  if (customerToken) config = extendWithCustomerToken(customerToken, config);
  else {
    config = extendWithSessionToken();
    config = extendWithMainParams(newspaperSlug, customerId, config);
  }

  return Api.contacts.show({ config }).then(({ data }) => ({
    newspaperName: data.newspaper_name,
    text: data.contact_us
  }))
};

export {
  requestNewspaperSettings,
  requestUser,
  checkInvitationToken,
  acceptInvite,
  requestSignIn,
  requestNewPassword,
  checkNewPasswordToken,
  setNewPassword,
  requestCustomer,
  requestDashboard,
  requestSources,
  updateSource,
  switchSource,
  requestWidgets,
  requestWidget,
  requestWidgetPromotions,
  hideWidgetPromotion,
  displayWidgetPromotion,
  parsePromotionUrl,
  createPromotion,
  requestPromotion,
  updatePromotion,
  requestAnalytics,
  requestContacts
};
