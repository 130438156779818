import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./assets/locales/en.json";
import translationNO from "./assets/locales/no.json";
import translationDA from "./assets/locales/da.json";
import translationFI from "./assets/locales/fi.json";
import translationPL from "./assets/locales/pl.json";
import translationSV from "./assets/locales/sv-SE.json";
import translationUK from "./assets/locales/uk.json";
import translationDE from "./assets/locales/de.json";

const resources = {
  en: {
    translation: translationEN
  },
  no: {
    translation: translationNO
  },
  da: {
    translation: translationDA
  },
  fi: {
    translation: translationFI
  },
  sv_SE: {
    translation: translationSV
  },
  pl: {
    translation: translationPL
  },
  uk: {
    translation: translationUK
  },
  de: {
    translation: translationDE
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  },
  react: {
    bindI18n: "languageChanged"
  }
});

export default i18n;
