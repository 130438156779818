import { setCookie, deleteCookie } from "./cookieProvider";

const signIn = (
  data: { token: string; userEmail: string, userId: number, newspaper: string },
  callback: () => void
) => {
  if (!data.token) return Promise.reject(new Error("You are not authorized to proceed."))

  const days = 1;
  const date = days * 3600 * 24;

  setCookie("ddb_session_token", data.token, { path: "/", expires: date });
  setCookie("ddb_user_email", data.userEmail, { path: "/", expires: date });
  setCookie("ddb_user_id", data.userId, { path: "/", expires: date });
  setCookie("ddb_current_newspaper", data.newspaper, { path: "/", expires: date });

  callback();
};

const signOut = () => {
  deleteCookie("ddb_session_token");
  deleteCookie("ddb_user_email");
  deleteCookie("ddb_user_id");
  deleteCookie("ddb_current_newspaper");
  deleteCookie("ddb_sign_in_redirect_url");

  window.location.reload();
};

export { signIn, signOut };
