import React, { VFC } from "react";
import { useTranslation } from "react-i18next";

import connections_1 from "../../../assets/images/connections_1.png";
import connections_2 from "../../../assets/images/connections_2.png";
import connections_3 from "../../../assets/images/connections_3.png";
import connections_4 from "../../../assets/images/connections_4.png";
import connections_5 from "../../../assets/images/connections_5.png";
import Text from "../../atoms/Text";
import Slider from "../../organisms/Slider";

interface ConnectionsOnboardingSliderProps {
  isCommunity: boolean;
  filteringEnabled: boolean;
  filteringHashtag?: string;
  connectAction: () => void;
}

const ConnectionsOnboardingSlider: VFC<ConnectionsOnboardingSliderProps> = ({
  isCommunity,
  filteringEnabled,
  filteringHashtag,
  connectAction
}) => {
  const { t } = useTranslation();

  const slides = [
    {
      title: t("general.connections_slider.title"),
      icon: "facebook",
      text: filteringEnabled && filteringHashtag ? (
        <>
          <Text marginB={2}>
            {isCommunity ? t("general.connections_slider.slide_1_filtering_enabled.text_1_lo_com") : t("general.connections_slider.slide_1_filtering_enabled.text_1")}
          </Text>
          <Text
            big
            marginB={2}
            dangerouslySetInnerHTML={{
              __html: t(
                "general.connections_slider.slide_1_filtering_enabled.text_2"
              ).replace("%hashtag%", `<b>#${filteringHashtag}</b>`)
            }}
          />
          <Text>
            {t("general.connections_slider.slide_1_filtering_enabled.text_3")}
          </Text>
        </>
      ) : (
        <>
          <Text marginB={2}>
            {isCommunity ? t("general.connections_slider.slide_1.text_1_lo_com") : t("general.connections_slider.slide_1.text_1")}
          </Text>
          <Text>{t("general.connections_slider.slide_1.text_2")}</Text>
        </>
      ),
      ctaText: t("general.connections_slider.cta_btn"),
      ctaAction: connectAction
    },
    {
      title: t("general.connections_slider.title"),
      text: (
        <>
          <Text marginB={2}>
            {t("general.connections_slider.slide_2.text_1")}
          </Text>
          <Text>{isCommunity ? t("general.connections_slider.slide_2.text_2_lo_com") : t("general.connections_slider.slide_2.text_2")}</Text>
        </>
      ),
      image: {
        src: connections_1,
        alt: t("general.connections_slider.slide_2.image_alt")
      },
      ctaText: t("general.connections_slider.cta_btn"),
      ctaAction: connectAction
    },
    {
      title: t("general.connections_slider.title"),
      text: <Text marginB={2}>{t("general.connections_slider.slide_3.text")}</Text>,
      image: {
        src: connections_2,
        alt: t("general.connections_slider.slide_3.image_alt")
      },
      ctaText: t("general.connections_slider.cta_btn"),
      ctaAction: connectAction
    },
    {
      title: t("general.connections_slider.title"),
      text: <Text marginB={2}>{isCommunity ? t("general.connections_slider.slide_4.text_lo_com") : t("general.connections_slider.slide_4.text")}</Text>,
      image: {
        src: connections_3,
        alt: t("general.connections_slider.slide_4.image_alt")
      },
      ctaText: t("general.connections_slider.cta_btn"),
      ctaAction: connectAction
    },
    {
      title: t("general.connections_slider.title"),
      text: <Text>{isCommunity ? t("general.connections_slider.slide_5.text_lo_com") : t("general.connections_slider.slide_5.text")}</Text>,
      image: {
        src: connections_4,
        alt: t("general.connections_slider.slide_5.image_alt")
      },
      ctaText: t("general.connections_slider.cta_btn"),
      ctaAction: connectAction
    },
    {
      title: t("general.connections_slider.title"),
      text: (
        <>
          <Text marginB={2}>
            {t("general.connections_slider.slide_6.text_1")}
          </Text>
          <Text marginB={2}>
            {t("general.connections_slider.slide_6.text_2")}
          </Text>
          <Text>{t("general.connections_slider.slide_6.text_3")}</Text>
        </>
      ),
      image: {
        src: connections_5,
        alt: t("general.connections_slider.slide_6.image_alt")
      },
      ctaText: t("general.connections_slider.cta_btn"),
      ctaAction: connectAction
    },
    {
      title: t("general.connections_slider.title"),
      text: <Text big textCenter marginT={12}>{t("general.connections_slider.slide_7.text")}</Text>,
      ctaText: t("general.connections_slider.cta_btn"),
      ctaAction: connectAction
    }
  ];

  return <Slider slides={slides} />;
};

export default ConnectionsOnboardingSlider;
