import { FC, HTMLProps } from "react";
import styled from "styled-components";

import constants from "../../_globalStyles/constants";
import Text, { TextProps } from "../../atoms/Text";
import Icon, { IconProps } from "../../atoms/Icon";

export const AdMedia: FC<HTMLProps<HTMLImageElement>> = styled.img`
  width: ${constants.GRID_UNIT * 8}px;
  height: ${constants.GRID_UNIT * 8}px;
  object-fit: cover;
  border-radius: ${constants.GRID_UNIT}px;
  overflow: hidden;
`;

AdMedia.displayName = "AdMedia";

export const AdMediaPlaceholder: FC = styled.div`
  position: relative;
  width: ${constants.GRID_UNIT * 8}px;
  height: ${constants.GRID_UNIT * 8}px;
  background: ${constants.COLOR_GRAY_100};
  border-radius: ${constants.GRID_UNIT}px;
  overflow: hidden;
`;

AdMediaPlaceholder.displayName = "AdMediaPlaceholder";

export const AdMediaIcon: FC<IconProps> = styled(Icon)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: ${constants.NO_MEDIA_ICON_COLOR};
  font-size: ${constants.GRID_UNIT * 4}px;
`;

AdMediaIcon.displayName = "AdMediaIcon";

export const AdLink: FC<HTMLProps<HTMLAnchorElement>> = styled.a`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

AdLink.displayName = "AdLink";

export const AdText: FC<TextProps> = styled(Text)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  @media (max-width: ${constants.BREAKPOINT_MOBILE_L}px) {
    -webkit-line-clamp: 3;
  }
`;

AdText.displayName = "AdText";
