import React, { VFC } from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "../../../util/helpers";
import constants from "../../_globalStyles/constants";
import Box from "../../particles/Box";
import Text from "../../atoms/Text";
import IconColor from "../../atoms/IconColor";
import { CreateAdTile, CreateAdLink, CreateAdButton } from "./styled";

interface CreateWidgetAdProps {
  isCommunity: boolean;
  url: string;
}

const CreateWidgetAd: VFC<CreateWidgetAdProps> = ({ isCommunity, url }) => {
  const { t } = useTranslation();

  return (
    <CreateAdTile
      flex
      alignCenter
      justifyCenter
      border={false}
      vertical
      paddingH={2.5}
    >
      <IconColor name="create-ad" size={pxToRem(108)} />
      <Text
        small
        medium
        textCenter
        color={constants.COLOR_GRAY_450}
        marginB={2}
      >
        {isCommunity
          ? t("general.dashboard.create_ad_lo_com")
          : t("general.dashboard.create_ad")}
      </Text>
      <Box marginB={1}>
        <CreateAdButton to={url} ctaInverted>
          {isCommunity
            ? t("general.dashboard.create_ad_btn_lo_com")
            : t("general.dashboard.create_ad_btn")}
        </CreateAdButton>
      </Box>
      <CreateAdLink
        to={url}
        label={
          isCommunity
            ? t("general.dashboard.create_ad_btn_lo_com")
            : t("general.dashboard.create_ad_btn")
        }
      />
    </CreateAdTile>
  );
};

export default CreateWidgetAd;
