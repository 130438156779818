import { FC, HTMLProps } from "react";
import styled from "styled-components";

import constants from "../../_globalStyles/constants";
import { pxToRem, pxToEm } from "../../../util/helpers";
import Box, { BoxProps } from "../../particles/Box";

export const MessageBox: FC<BoxProps> = styled(Box)`
  p:first-of-type {
    padding-top: ${constants.GRID_UNIT * 3}px;
  }

  @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
    p:first-of-type {
      padding-top: 0 !important;
    }
  }

  @media (max-width: ${constants.BREAKPOINT_MOBILE_L}px) {
    padding-bottom: ${constants.GRID_UNIT * 16}px;

    p {
      font-size: ${pxToRem(16)} !important;
      line-height: ${pxToEm(24, 16)} !important;
    }
  }
`;

MessageBox.displayName = "MessageBox";

export const MessageImage: FC<HTMLProps<HTMLImageElement>> = styled.img`
  width: 100%;
  height: auto;
`;

MessageImage.displayName = "MessageImage";

export const CtaBox: FC<BoxProps> = styled(Box)`
  @media (min-width: ${constants.BREAKPOINT_MOBILE_L}px) {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: ${constants.BREAKPOINT_MOBILE_L}px) {
    position: fixed;
    bottom: ${constants.GRID_UNIT}px;
    left: ${constants.GRID_UNIT * 2}px;
    right: ${constants.GRID_UNIT * 2}px;
  }

  &:after {
    @media (max-width: ${constants.BREAKPOINT_MOBILE_L}px) {
      content: "";
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      height: ${constants.GRID_UNIT * 20}px;
      background: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1) ${constants.GRID_UNIT * 4}px
      );
      z-index: 50;
    }
  }

  * {
    z-index: 100;
  }
`;

CtaBox.displayName = "CtaBox";
