import React, { VFC, FC, ReactNode } from "react";
import styled from "styled-components";

import constants from "../../_globalStyles/constants";
import Box, { BoxProps } from "../../particles/Box";
import Tile, { TileProps } from "../../atoms/Tile";
import Text from "../../atoms/Text";
import IconBadge from "../../molecules/IconBadge";

interface NoticeMessageProps {
  title: string;
  titleAs?: string;
  text?: string;
  textElement?: ReactNode;
}

const NoticeTile: FC<TileProps> = styled(Tile)`
  box-shadow: 0px ${constants.GRID_UNIT}px ${constants.GRID_UNIT * 5}px 0px
      #00000033,
    inset 0 0 0 1px ${constants.BORDER_COLOR};
`;

NoticeTile.displayName = "NoticeTile";

const NoticeIconBox: FC<BoxProps> = styled(Box)`
  @media (max-width: ${constants.BREAKPOINT_MOBILE}px) {
    display: none;
  }
`;

NoticeIconBox.displayName = "NoticeIconBox";

const NoticeMessage: VFC<NoticeMessageProps> = ({
  title,
  titleAs,
  text,
  textElement
}) => (
  <NoticeTile
    as="section"
    paddingH={2}
    paddingV={1}
    flex
    alignCenter
    marginH={2}
    marginV={2}
    maxWidth={46}
  >
    <NoticeIconBox marginR={2}>
      <IconBadge inverted status="warning" />
    </NoticeIconBox>
    <Box>
      <Text as={titleAs} small>
        {title}
      </Text>
      {text && (
        <Text as="p" small>
          {text}
        </Text>
      )}
      {textElement}
    </Box>
  </NoticeTile>
);

NoticeMessage.defaultProps = {
  titleAs: "h3"
};

export default NoticeMessage;
