import { FC } from "react";
import styled from "styled-components";

import constants from "../../_globalStyles/constants";

export const TabNavigationContainer: FC = styled.div`
  position: relative;
  margin-left: -${constants.GRID_UNIT * 2}px;

  &:after {
    content: "";
    position: absolute;
    left: ${constants.GRID_UNIT * 2}px;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: ${constants.UNDERLINE_COLOR};
  }

  > div {
    position: relative;
    padding: 0 0 0 ${constants.GRID_UNIT * 2}px;
    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  nav {
    position: relative;
    padding: ${constants.GRID_UNIT * 2.5}px 0 ${constants.GRID_UNIT * 2.5 - 2}px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
`;

TabNavigationContainer.displayName = "TabNavigationContainer";
