import { FC } from "react";
import styled from "styled-components";

import { pxToRem } from "../../../util/helpers";
import constants from "../../_globalStyles/constants";
import Box, { BoxProps } from "../../particles/Box";

interface LogoProps extends BoxProps {
  logo: string;
}

export const Logo: FC<LogoProps> = styled(Box)`
  width: ${constants.GRID_UNIT * 8}px;
  height: ${constants.GRID_UNIT * 8}px;
  border-radius: 50%;
  background-color: ${constants.ICON_BADGE_COLOR};
  color: ${constants.ICON_BADGE_TEXT_COLOR};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${pxToRem(32)};

  ${({ logo }) =>
    logo &&
    `
    background-color: #fff;
    background-image: url(${logo});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    
    svg {
      display: none;
    }
  `}
`;

Logo.displayName = "Logo";
