import { FC } from "react"; 
import styled from "styled-components";

const Transition: FC<{ duration?: string }> = styled.div`
  animation: ${({ duration }) => `smooth ${duration || ".1s"} ease-in`};

  @keyframes smooth {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

Transition.displayName = "Transition";

export default Transition;
