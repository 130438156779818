import { FC, HTMLProps } from "react";
import styled, { css } from "styled-components";

interface TooltipWrapProps extends HTMLProps<HTMLElement> {
  active?: boolean;
}

export const TextareaWrap: FC = styled.div`
  position: relative;
`;

TextareaWrap.displayName = "TextareaWrap";

export const TooltipWrap: FC<TooltipWrapProps> = styled.div`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: -8px;
  left: 8px;
  right: 8px;
  z-index: 200;

  ${({ active }) =>
    active &&
    css`
      visibility: visible;
      opacity: 1;
    `}

  transition: opacity 0.05s ease-in;
`;

TooltipWrap.displayName = "TooltipWrap";
