import React, { VFC } from "react";

import GlobalStyle from "./components/_globalStyles/globalStyles";
import AppProvider from "./components/_functional/AppProvider";
import KeyboardNavigationHandler from "./components/_functional/KeyboardNavigationHandler";

// TODO: Components ending with 2Ph (=2d phase) as well as class 'ddb-2ph' should be removed after
// 3d phase is delivered and all the newspapers switch to Dashboard.

import { getSearchParams } from "./util/helpers";

import App2Ph from "./App2Ph";
import App3Ph from "./App3Ph";

const App: VFC = () => {
  const customerToken = getSearchParams(location?.search, "customer_token");

  return (
    <>
      <AppProvider>
        <KeyboardNavigationHandler />
        <GlobalStyle />

        {customerToken ? <App2Ph /> : <App3Ph />}
      </AppProvider>
    </>
  );
};

export default App;
