import React, { useContext, VFC } from "react";
import { useParams, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { AppContext } from "../../components/_functional/AppProvider";
import Loader from "../../components/atoms/Loader";
import ContactsComponent from "./Component";

const ContactsContainer: VFC = () => {
  const { t } = useTranslation();

  const { newspaperSlug } = useParams();

  const { contacts, newspaper } = useContext(AppContext);

  const instanceName = newspaper.instanceName || newspaper.name;
  const contactsDisabled = contacts && !contacts.text?.length;

  return (
    <>
      <Helmet>
        <title>{`${t("general.contacts.page_title")} | ${instanceName}`}</title>
      </Helmet>
      <Loader active={!contacts}>
        {contactsDisabled ? (
          <Navigate to={`/${newspaperSlug}/widgets`} replace />
        ) : (
          <ContactsComponent contacts={contacts} />
        )}
      </Loader>
    </>
  );
};

export default ContactsContainer;
