import React, { FC, ReactNode } from "react";

import { syncNewspaperWithUrl } from "../../util/hooks";

interface SyncNewspaperWithUrlProps {
  children: ReactNode;
}

const SyncNewspaperWithUrl: FC<SyncNewspaperWithUrlProps> = ({ children }) => {
  syncNewspaperWithUrl();

  return <>{children}</>;
};

export default SyncNewspaperWithUrl;
