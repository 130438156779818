import React, { FC, HTMLProps } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { pxToRem } from "../../../util/helpers";
import constants from "../../_globalStyles/constants";
import Icon from "../Icon";

export interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  to?: string;
  primary?: boolean;
  secondary?: boolean;
  warning?: boolean;
  cta?: boolean;
  ctaInverted?: boolean;
  clean?: boolean;
  big?: boolean;
  small?: boolean;
  dark?: boolean;
  hint?: boolean;
  more?: boolean;
  label?: string;
  buttonRef?: HTMLProps<HTMLButtonElement>
}

interface StyledButtonProps extends ButtonProps {
  iconType?: boolean;
  iconPosition?: "start" | "end";
}

const getIconType = (props: ButtonProps): boolean => {
  if (props.hint) return true;
  if (!props.children) return false;

  if (
    Object.prototype.hasOwnProperty.call(props.children, "type") &&
    // @ts-ignore: there's a check for 'type' property presence in the previous line.
    props.children.type === Icon
  )
    return true;

  return false;
};

const getIconPosition = (props: ButtonProps): "start" | "end" => {
  if (!props.children) return;

  let icon;

  if (props.children instanceof Array) {
    props.children.forEach((child, index) => {
      if (child?.type === Icon && index === 0) icon = "start";
      else if (child?.type === Icon) icon = "end";
    });
  }

  return icon;
};

const StyledButton: FC<StyledButtonProps> = styled.button.attrs(({ to }) => ({
  as: to ? "div" : "button"
}))`
  display: inline-flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(20)};
  font-weight: normal;
  padding: ${pxToRem(10)} ${pxToRem(24)};
  cursor: pointer;
  border-radius: ${constants.BUTTON_BORDER_RADIUS};
  border: 0;
  box-shadow: 0 0 0 1px ${constants.BORDER_COLOR} inset;

  &,
  &:link,
  &:visited {
    background: ${constants.BUTTON_SECONDARY_COLOR};
    color: ${constants.BUTTON_SECONDARY_TEXT_COLOR};

    svg {
      font-size: ${pxToRem(20)};
      color: ${constants.BUTTON_SECONDARY_TEXT_COLOR};
    }
  }

  &:hover:enabled {
    background: ${constants.BUTTON_SECONDARY_HOVER_COLOR};
  }

  &:active:enabled {
    background: ${constants.BUTTON_SECONDARY_ACTIVE_COLOR};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${(props) =>
    (props.big || props.cta || props.ctaInverted || props.warning) &&
    css`
      line-height: ${pxToRem(20)};
      padding: ${pxToRem(14)} ${pxToRem(24)};
    `}

  ${(props) =>
    props.warning &&
    css`
      font-weight: 700;
      &,
      &:link,
      &:visited,
      &:hover,
      &:active {
        color: ${constants.BUTTON_WARNING_COLOR};
      }
    `}

  ${(props) =>
    (props.primary || props.cta) &&
    css`
      &,
      &:link,
      &:visited {
        background: ${constants.BUTTON_PRIMARY_COLOR};
        color: ${constants.BUTTON_PRIMARY_TEXT_COLOR};
        box-shadow: none;
      }

      &:hover:enabled {
        background: ${constants.BUTTON_PRIMARY_HOVER_COLOR};
      }

      &:active:enabled {
        background: ${constants.BUTTON_PRIMARY_ACTIVE_COLOR};
      }

      &:disabled {
        background: #a8cbec;
        opacity: 1;
      }

      svg {
        color: ${constants.BUTTON_PRIMARY_TEXT_COLOR};
      }
    `}

  ${(props) =>
    props.primary &&
    css`
      font-weight: 500;
      letter-spacing: 0.2px;
    `}

  ${(props) =>
    props.ctaInverted &&
    css`
      &,
      &:link,
      &:visited,
      &:hover,
      &:active {
        color: ${constants.BUTTON_INVERTED_PRIMARY_TEXT_COLOR};
      }

      &:disabled {
        opacity: 0.3;
      }

      svg {
        color: ${constants.BUTTON_INVERTED_PRIMARY_TEXT_COLOR};
      }
    `}

  ${(props) =>
    (props.cta || props.ctaInverted) &&
    css`
      font-size: ${pxToRem(18)};
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 700;
    `}

  ${(props) =>
    props.iconType &&
    css`
      height: ${pxToRem(40)};
      width: ${pxToRem(40)};
      padding: 0;
      border-radius: 50%;
      font-size: ${pxToRem(24)};

      &,
      &:link,
      &:visited {
        svg {
          color: ${constants.BUTTON_ICON_TEXT_COLOR};
        }
      }

      &:hover svg {
        color: ${constants.BUTTON_ICON_TEXT_HOVER_COLOR};
      }

      &:active svg {
        color: ${constants.BUTTON_ICON_TEXT_ACTIVE_COLOR};
      }
    `}

  ${(props) =>
    props.iconType &&
    props.primary &&
    css`
      &,
      &:link,
      &:visited,
      &:hover,
      &:active {
        svg {
          color: ${constants.BUTTON_PRIMARY_TEXT_COLOR};
        }
      }
    `}

  ${(
    props // TODO: correct colors for hover, active state
  ) =>
    props.iconType &&
    props.dark &&
    css`
      &,
      &:link,
      &:visited {
        box-shadow: none;
        background: ${constants.BUTTON_ICON_DARK_COLOR};

        svg {
          color: ${constants.BUTTON_ICON_DARK_TEXT_COLOR};
        }
      }

      &:hover:not(:disabled) {
        box-shadow: none;
        background: ${constants.BUTTON_ICON_DARK_HOVER_COLOR};
        svg {
          color: ${constants.BUTTON_ICON_DARK_TEXT_HOVER_COLOR};
        }
      }

      &:active:not(:disabled) {
        box-shadow: none;
        background: ${constants.BUTTON_ICON_DARK_ACTIVE_COLOR};
        svg {
          color: ${constants.BUTTON_ICON_DARK_TEXT_ACTIVe_COLOR};
        }
      }
    `}

  ${(props) =>
    props.iconType &&
    props.hint &&
    css`
      &,
      &:link,
      &:visited {
        background: ${constants.COLOR_YELLOW_70};
      }

      &:hover:enabled {
        background: ${constants.COLOR_YELLOW_100};
      }

      &:active:enabled {
        background: ${constants.COLOR_YELLOW_100};
      }

      &,
      &:link,
      &:visited,
      &:hover,
      &:active {
        svg {
          color: ${constants.COLOR_WHITE};
        }
      }
    `}
  
  ${(props) =>
    props.iconType &&
    props.clean &&
    css`
      box-shadow: none;
      &,
      &:link,
      &:visited,
      &:hover,
      &:active {
        background: transparent !important;
      }

      &,
      &:link,
      &:visited {
        svg {
          color: ${constants.COLOR_GRAY_375};
        }
      }

      &:hover svg {
        color: ${constants.COLOR_GRAY_500};
      }

      &:active svg {
        color: ${constants.COLOR_BLACK};
      }

      svg {
        font-size: ${pxToRem(24)};
      }
    `}

  ${(props) =>
    props.iconType &&
    props.big &&
    css`
      height: ${pxToRem(48)};
      width: ${pxToRem(48)};

      svg {
        font-size: ${pxToRem(24)};
      }
    `}

  ${(props) =>
    props.iconType &&
    props.small &&
    css`
      height: ${pxToRem(32)};
      width: ${pxToRem(32)};

      svg {
        font-size: ${pxToRem(24)};
      }
    `}

  ${(props) =>
    props.more &&
    css`
      width: 100%;
      border-radius: 12px;
      padding: 1.125em 2em;
      box-shadow: 0 0 0 2px ${constants.BORDER_COLOR} inset;
    `}

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}

  ${(props) =>
    props.iconPosition === "start" &&
    css`
      padding-left: 1.25em;
      svg {
        margin-right: 0.25em;
      }
    `}

  ${(props) =>
    props.iconPosition === "end" &&
    css`
      padding-right: 1.25em;
      svg {
        margin-left: 0.25em;
      }
    `}
`;

StyledButton.displayName = "StyledButton";

const ButtonBase: FC<ButtonProps> = ({label, ...props}) => {
  const iconPosition = getIconPosition(props);
  const iconType = getIconType(props);

  return (
    <StyledButton {...props} iconType={iconType} iconPosition={iconPosition}>
      {props.children}
      {label && (
        <span className="ddb-visually-hidden">{label}</span>
      )}
      {props.hint && <Icon name="warning" />}
    </StyledButton>
  );
};

const Button: FC<ButtonProps> = (props) =>
  props.to ? (
    <Link to={props.to} state={{ fromDashboard: true }}>
      <ButtonBase {...props} />
    </Link>
  ) : (
    <ButtonBase {...props} />
  );

Button.defaultProps = {
  secondary: true,
  type: "button"
};

export default Button;
