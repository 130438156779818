import React, { VFC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "../../../util/helpers";
import Icon from "../../atoms/Icon";
import {
  PaginationNav,
  Page,
  Ellipsis,
  PrevButton,
  NextButton
} from "./styled";

interface PaginationProps {
  activePage: number;
  pagesNumber: number;
  size?: string;
  navigate: (page: number) => void;
}

const Pagination: VFC<PaginationProps> = ({
  activePage,
  pagesNumber,
  size,
  navigate: _navigate
}) => {
  const { t } = useTranslation();

  const navigate = useCallback((e, page) => {
    e.preventDefault();
    _navigate(page);
  }, [_navigate])

  const renderPages = useCallback(() => {
    const pages = Array.from(Array(pagesNumber + 1).keys());
    pages.shift();

    if (size === "small") {
      return pages.map((page) => {
        if (Math.abs(activePage - page) < 2) {
          const active = page === activePage;

          return (
            <Page
              key={page.toString()}
              type="button"
              onClick={(e) => navigate(e, page)}
              aria-current={active ? "page" : "false"}
              active={active}
              aria-label={
                active
                  ? t("general.pagination.current_page", { number: page })
                  : t("general.pagination.n_page", { number: page })
              }
            >
              {page}
            </Page>
          );
        }
        return null;
      });
    }

    let isEllipsis = false;
    const delta = size === "medium" ? 3 : 5;

    return pages.map((page) => {
      if (Math.abs(activePage - page) < delta || page === 1) {
        const active = page === activePage;

        return (
          <Page
            key={page.toString()}
            type="button"
            onClick={(e) => navigate(e, page)}
            aria-current={active ? "page" : "false"}
            active={active}
            aria-label={
              active
                ? t("general.pagination.current_page", { number: page })
                : t("general.pagination.n_page", { number: page })
            }
          >
            {page}
          </Page>
        );
      } else if (!isEllipsis) {
        isEllipsis = true;
        return <Ellipsis key="ellipsis">...</Ellipsis>;
      }
      return null;
    });
  }, [activePage, pagesNumber, size, navigate]);

  return (
    <PaginationNav aria-label={t("general.pagination.label")}>
      {activePage !== 1 && (
        <PrevButton
          type="button"
          onClick={(e) => navigate(e, activePage - 1)}
          aria-label={t("general.pagination.prev_page")}
        >
          <Icon name="arrow-toggle" size={pxToRem(24)} />
        </PrevButton>
      )}
      {renderPages()}
      {activePage !== pagesNumber && (
        <NextButton
          type="button"
          onClick={(e) => navigate(e, activePage + 1)}
          aria-label={t("general.pagination.next_page")}
        >
          <Icon name="arrow-toggle" size={pxToRem(24)} />
        </NextButton>
      )}
    </PaginationNav>
  );
};

Pagination.defaultProps = {
  size: "small"
};

export default Pagination;
