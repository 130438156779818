/**
  Colors naming is based on similar approach as in sl-design-system.
  AA, AAA stand for level of WCAG compliance (if used in combination with white color).
  If there is no level mentioned, the colour is not complaint with any.
  "Large" text is the text starting from ~18px.
  All the texts should have colors with at least AA compliance.
**/

const COLOR_WHITE = "#FFFFFF";
const COLOR_BLACK = "#000000"; // AAA
const COLOR_BLUE_50 = "#EEF5FF"; 
const COLOR_BLUE_100 = "#B2D5F6";
const COLOR_BLUE_300 = "#188DF2";
const COLOR_BLUE_325 = "#006CD6"; // AA
const COLOR_BLUE_350 = "#0073E5"; // AA
const COLOR_BLUE_400 = "#1450C7"; // AAA
const COLOR_RED_50 = "#B20000"; // AAA
const COLOR_RED_200 = "#E52525"; // AA
const COLOR_GREEN_50 = "#57D957"; // AA compliant if used on const COLOR_gray450 background.
const COLOR_GREEN_300 = "#00A300";
const COLOR_GREEN_400 = "#008A00"; // AA
const COLOR_GREEN_500 = "#006600"; // AAA
const COLOR_YELLOW_70 = "#FFBF00";
const COLOR_YELLOW_100 = "#FFAA00";
const COLOR_YELLOW_200 = "#B25900"; // AA
const COLOR_YELLOW_300 = '#936710'; // AA
const COLOR_GRAY_50 = "#FAFAFA";
const COLOR_GRAY_100 = "#F5F5F5";
const COLOR_GRAY_150 = "#F0F0F0";
const COLOR_GRAY_190 = "#E6E6E6";
const COLOR_GRAY_250 = "#CCCCCC";
const COLOR_GRAY_300 = "#B3B3B3";
const COLOR_GRAY_340 = "#949494"; // AA, only for large text.
const COLOR_GRAY_350 = "#808080"; // AA, only for large text.
const COLOR_GRAY_375 = "#737373"; // AA
const COLOR_GRAY_450 = "#4D4D4D"; // AAA
const COLOR_GRAY_500 = "#333333"; // AAA
const COLOR_GRAY_700 = "#1A1A1A"; // AAA

const ROOT_FONT_FAMILY = "'Roboto', Arial, sans-serif";
const ROOT_FONT_SIZE = 16;
const GRID_UNIT = 8;
const BORDER_COLOR = "rgba(0, 0, 0, 0.08)";
const UNDERLINE_COLOR = "rgba(0, 0, 0, 0.16)";
const INPUT_BORDER_COLOR = COLOR_GRAY_340;
const INPUT_FOCUS_BORDER_COLOR = COLOR_GRAY_500;
const INPUT_PLACEHOLDER_COLOR = COLOR_GRAY_375;
const FOCUS_OUTLINE_COLOR = "rgba(0, 0, 0, 0.8)";
const BORDER_WARNING_COLOR = "rgba(255, 170, 0, 0.5)";
const BUTTON_BORDER_RADIUS = `${40 / ROOT_FONT_SIZE}rem`;
const BREAKPOINT_DESKTOP = 1200;
const BREAKPOINT_DESKTOP_S = 960;
const BREAKPOINT_MOBILE_L = 768;
const BREAKPOINT_MOBILE = 420;
const MAX_CONTENT_WIDTH = `${GRID_UNIT * 164}px`; // = 1312px - paddings = 1280px
const MAX_CONTENT_WIDTH_M = `${GRID_UNIT * 121}px`; // = 968px - paddings = 944px
const MAX_CONTENT_WIDTH_S = `${GRID_UNIT * 77.75}px`; // = 622px
const MAX_CONTENT_WIDTH_XS = `${GRID_UNIT * 50}px`; // = 400px

const constants = {
  BUTTON_PRIMARY_COLOR: COLOR_BLUE_350,
  BUTTON_PRIMARY_HOVER_COLOR: COLOR_BLUE_325,
  BUTTON_PRIMARY_ACTIVE_COLOR: COLOR_BLUE_400,
  BUTTON_PRIMARY_DISABLED_COLOR: COLOR_BLUE_100,
  BUTTON_PRIMARY_TEXT_COLOR: COLOR_WHITE,
  BUTTON_SECONDARY_COLOR: COLOR_WHITE,
  BUTTON_SECONDARY_HOVER_COLOR: COLOR_GRAY_100,
  BUTTON_SECONDARY_ACTIVE_COLOR: COLOR_GRAY_190,
  BUTTON_SECONDARY_TEXT_COLOR: COLOR_GRAY_500,
  BUTTON_INVERTED_PRIMARY_COLOR: COLOR_WHITE,
  BUTTON_INVERTED_PRIMARY_HOVER_COLOR: COLOR_GRAY_100,
  BUTTON_INVERTED_PRIMARY_ACTIVE_COLOR: COLOR_GRAY_190,
  BUTTON_INVERTED_PRIMARY_TEXT_COLOR: COLOR_BLUE_350,
  BUTTON_ICON_TEXT_COLOR: COLOR_GRAY_375,
  BUTTON_ICON_TEXT_HOVER_COLOR: COLOR_GRAY_450,
  BUTTON_ICON_TEXT_ACTIVE_COLOR: COLOR_GRAY_500,
  BUTTON_ICON_DARK_COLOR: COLOR_GRAY_100,
  BUTTON_ICON_DARK_TEXT_COLOR: COLOR_GRAY_500,
  BUTTON_ICON_DARK_HOVER_COLOR: COLOR_GRAY_150,
  BUTTON_ICON_DARK_TEXT_HOVER_COLOR: COLOR_GRAY_500,
  BUTTON_ICON_DARK_ACTIVE_COLOR: COLOR_GRAY_190,
  BUTTON_ICON_DARK_TEXT_ACTIVe_COLOR: COLOR_GRAY_500,
  BUTTON_WARNING_COLOR: COLOR_RED_50,
  PRIMARY_COLOR: COLOR_BLUE_350,
  ERROR_COLOR: COLOR_RED_200,
  DARK_ERROR_COLOR: COLOR_RED_50,
  TEXT_COLOR: COLOR_GRAY_500,
  TEXT_LIGHT_COLOR: COLOR_WHITE,
  TEXT_LIGHTER_COLOR: COLOR_GRAY_450,
  TAB_TEXT_COLOR: COLOR_GRAY_350,
  LINK_COLOR: COLOR_BLUE_350,
  LINK_HOVER_COLOR: COLOR_BLUE_400,
  LINK_ACTIVE_COLOR: COLOR_BLUE_400,
  INPUT_PLACEHOLDER_COLOR,
  INPUT_BORDER_COLOR,
  INPUT_FOCUS_BORDER_COLOR,
  INPUT_LABEL_COLOR: COLOR_GRAY_450,
  INPUT_DISABLED_COLOR: COLOR_GRAY_375,
  INPUT_READONLY_COLOR: COLOR_GRAY_375,
  MAIN_BG_COLOR: COLOR_WHITE,
  CHECKBOX_OFF_BORDER_COLOR: COLOR_GRAY_375,
  CHECKBOX_ON_COLOR: COLOR_BLUE_350,
  CHECKBOX_ON_TEXT_COLOR: COLOR_WHITE,
  CHECKBOX_ROUND_OFF_BORDER_COLOR: COLOR_GRAY_375,
  CHECKBOX_ROUND_OFF_COLOR: COLOR_GRAY_150,
  CHECKBOX_ROUND_ON_COLOR: COLOR_GREEN_400,
  CHECKBOX_ROUND_ON_TEXT_COLOR: COLOR_WHITE,
  SWITCH_ON_BG_COLOR: COLOR_WHITE,
  SWITCH_ON_BG_HOVER_COLOR: COLOR_GRAY_100,
  SWITCH_OFF_BG_COLOR: COLOR_GRAY_100,
  SWITCH_OFF_BG_HOVER_COLOR: COLOR_WHITE,
  SWITCH_ON_COLOR: COLOR_GREEN_400,
  SWITCH_OFF_COLOR: COLOR_GRAY_150,
  SWITCH_ON_TEXT_COLOR: COLOR_WHITE,
  SWITCH_OFF_TEXT_COLOR: COLOR_GRAY_450,
  SWITCH_BORDER_COLOR: COLOR_GRAY_250,
  ICON_BADGE_COLOR: COLOR_GRAY_100,
  ICON_BADGE_TEXT_COLOR: COLOR_GRAY_375,
  ICON_ERROR_COLOR: COLOR_RED_200,
  ICON_ERROR_DARK_COLOR: COLOR_RED_50,
  ICON_WARNING_COLOR: COLOR_YELLOW_200,
  ICON_HINT_BORDER_COLOR: COLOR_GRAY_190,
  ICON_HINT_TEXT_COLOR: COLOR_BLACK,
  SUBHEADER_COLOR: COLOR_WHITE,
  INVERTED_TILE_COLOR: COLOR_GRAY_100,
  LOADER_COLOR: COLOR_GRAY_190,
  MEDIA_BG_COLOR: COLOR_GRAY_100,
  NO_MEDIA_ICON_COLOR: COLOR_GRAY_300,
  BORDER_WARNING_COLOR,
  FOCUS_OUTLINE_COLOR,
  BORDER_COLOR,
  UNDERLINE_COLOR,
  ROOT_FONT_FAMILY,
  ROOT_FONT_SIZE,
  GRID_UNIT,
  BUTTON_BORDER_RADIUS,
  BREAKPOINT_DESKTOP,
  BREAKPOINT_DESKTOP_S,
  BREAKPOINT_MOBILE_L,
  BREAKPOINT_MOBILE,
  MAX_CONTENT_WIDTH,
  MAX_CONTENT_WIDTH_M,
  MAX_CONTENT_WIDTH_S,
  MAX_CONTENT_WIDTH_XS,
  COLOR_WHITE,
  COLOR_BLACK,
  COLOR_BLUE_50,
  COLOR_BLUE_100,
  COLOR_BLUE_300,
  COLOR_BLUE_325,
  COLOR_BLUE_350,
  COLOR_BLUE_400,
  COLOR_RED_50,
  COLOR_RED_200,
  COLOR_GREEN_50,
  COLOR_GREEN_300,
  COLOR_GREEN_400,
  COLOR_GREEN_500,
  COLOR_YELLOW_70,
  COLOR_YELLOW_100,
  COLOR_YELLOW_200,
  COLOR_YELLOW_300,
  COLOR_GRAY_50,
  COLOR_GRAY_100,
  COLOR_GRAY_150,
  COLOR_GRAY_190,
  COLOR_GRAY_250,
  COLOR_GRAY_300,
  COLOR_GRAY_340,
  COLOR_GRAY_350,
  COLOR_GRAY_375,
  COLOR_GRAY_450,
  COLOR_GRAY_500,
  COLOR_GRAY_700
};

export default constants;
