import React, { FC, ReactNode, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { OverlayProvider } from "react-aria";
import { useTranslation } from "react-i18next";

import { setCookie } from "../../../util/cookieProvider";
import { usePrevious } from "../../../util/hooks";
import { setDocumentLang, setFavicon } from "../../../util/helpers";
import { getRequestErrorMessage } from "../../../util/request";
import { requestNewspaperSettings } from "../../../util/apiRequests";
import { AppContext } from "../../_functional/AppProvider";
import Loader from "../../atoms/Loader";
import Modal from "../../organisms/Modal";
import Popup from "../../organisms/Popup";
import UnifiedHeader from "../UnifiedHeader";
import { PageContent } from "./styled";

interface PageLayoutProps {
  children: ReactNode;
}

const PageLayout: FC<PageLayoutProps> = ({ children }) => {
  const { t, i18n } = useTranslation();

  const { newspaperSlug } = useParams();
  const prevNewspaperSlug = usePrevious(newspaperSlug);

  const {
    newspaperSettings,
    user,
    modal,
    popup,
    storeNewspaperSettings,
    storeModal,
    resetModal,
    resetPopup
  } = useContext(AppContext);

  useEffect(() => {
    if (newspaperSlug) setCookie("ddb_current_newspaper", newspaperSlug);
  }, []);

  useEffect(() => {
    if (newspaperSlug && newspaperSlug !== prevNewspaperSlug) {
      requestNewspaperSettings(newspaperSlug)
        .then(storeNewspaperSettings)
        .catch((error) => {
          const {
            response: { data, status }
          } = error;

          if (error instanceof Error) {
            console.error("DDB message: ", error.message);
          } else {
            console.error(
              "DDB message: ",
              status,
              data?.message,
              data?.details,
              data?.errors?.[0] || data?.error
            );
          }

          storeModal({
            icon: status === 404 ? "not-found" : "warning",
            title: t("errors.generic_title"),
            text: getRequestErrorMessage(error, t),
            ctaAction: resetModal,
            ctaText: t("errors.close_error"),
            onClose: resetModal
          });
        });
    }
  }, [newspaperSlug, prevNewspaperSlug]);

  useEffect(() => {
    if (newspaperSettings) {
      const { newspaperInfo, branding } = newspaperSettings;

      i18n.changeLanguage(newspaperInfo?.locale);
      setDocumentLang(newspaperInfo?.locale);

      setFavicon({
        favicon32: branding?.favicon32,
        favicon192: branding?.favicon192
      });
    }
  }, [newspaperSettings]);

  const isLoaded = Boolean(newspaperSettings);

  return (
    <>
      {!!isLoaded && (
        <UnifiedHeader user={user} newspaperSettings={newspaperSettings} />
      )}

      <OverlayProvider>
        <PageContent>
          <Loader active={!isLoaded}>{children}</Loader>
        </PageContent>

        <div aria-live="polite">
          {!!modal && <Modal onClose={resetModal} {...modal} />}
        </div>
        <div aria-live="polite">
          {!!popup && <Popup onClose={resetPopup} {...popup} />}
        </div>
      </OverlayProvider>
    </>
  );
};

export default PageLayout;
