import React, { FC, ReactNode, useContext, useMemo, useCallback } from "react";
import { Link, useParams, useSearchParams, useMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AppContext } from "../../_functional/AppProvider";
import { usePrevious } from "../../../util/hooks";
import GetContacts from "../../_functional/GetContacts";
import Box from "../../particles/Box";
import Text from "../../atoms/Text";
import MainPageTitle from "../MainPageTitle";
import TabNavigation from "../TabNavigation";

interface PageNavigationProps {
  children: ReactNode;
}

const PageNavigation: FC<PageNavigationProps> = ({ children }) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchString = searchParams.toString();
  const customerId = searchParams.get("customer_id");
  const customerToken = searchParams.get("admin_customer_token");

  const { newspaperSlug } = useParams();

  const { newspaper, customer, user, resetWidgets, resetPages, contacts } =
    useContext(AppContext);

  const isCustomerSuspended = customer?.suspended;
  const isContactsPage = useMatch("/:newspaperSlug/contacts");
  const showContactUsLink = !isContactsPage && contacts?.text;
  const { isCommunity } = newspaper;

  if (customerToken) {
    return (
      <Box as="section" className="ddb-max-content-width" marginHAuto>
        <Box paddingH={2}>
          <Box paddingV={2}>
            <MainPageTitle
              title={customer?.name}
              description={
                isCommunity
                  ? t("general.dashboard.title_desc_lo_com")
                  : t("general.dashboard.title_desc")
              }
              logo={customer?.logo}
            />
          </Box>
          <GetContacts>
            <TabNavigation />
          </GetContacts>
        </Box>
        {children}
      </Box>
    );
  }

  const newspaperCustomers = useMemo(
    () => user.customers.filter((_c) => _c.newspaper.slug === newspaperSlug),
    [user, newspaperSlug]
  );

  const prevCustomerId = usePrevious(customerId);

  const updateUrlWithCustomer = useCallback(
    (id, replace = false) => setSearchParams(`customer_id=${id}`, { replace }),
    [setSearchParams]
  );

  const onTitleOptionSelect = useCallback(
    (id) => {
      if (id && id !== customerId && id !== prevCustomerId) {
        resetWidgets();
        resetPages();
        updateUrlWithCustomer(id);
      }
    },
    [customerId, prevCustomerId, updateUrlWithCustomer]
  );

  const titleOptions = useMemo(
    () =>
      newspaperCustomers.map((c) => ({
        title: c.name,
        value: c.id
      })),
    [newspaperCustomers]
  );

  const titleObj = useMemo(
    () =>
      titleOptions.length > 1
        ? {
            label: isCommunity
              ? t("general.customer_switcher_label_lo_com")
              : t("general.customer_switcher_label"),
            options: titleOptions,
            selectedOption: customerId,
            onSelect: onTitleOptionSelect
          }
        : null,
    [titleOptions, customerId, onTitleOptionSelect, isCommunity]
  );

  const title = useMemo(
    () => (newspaperCustomers.length === 1 ? newspaperCustomers[0].name : null),
    [newspaperCustomers]
  );

  return (
    <Box as="section" className="ddb-max-content-width" marginHAuto>
      <Box paddingH={2}>
        <Box paddingV={2}>
          {!!(title || titleObj) && (
            <MainPageTitle
              noticeMessage={
                isCustomerSuspended
                  ? {
                      title: t("messages.customer_suspended_title"),
                      textElement: showContactUsLink ? (
                        <Text as="p" small>
                          {t("messages.customer_suspended_body")}
                          <Box inlineBlock marginL={0.5}>
                            <Link
                              to={`/${newspaperSlug}/contacts?${searchString}`}
                            >
                              {t("messages.customer_suspended_link")}
                            </Link>
                          </Box>
                        </Text>
                      ) : (
                        <Text as="p" small>
                          {t("messages.customer_suspended_body")}
                        </Text>
                      )
                    }
                  : null
              }
              title={title}
              titleObj={titleObj}
              description={
                isCommunity
                  ? t("general.dashboard.title_desc_lo_com")
                  : t("general.dashboard.title_desc")
              }
              logo={customer?.logo}
            />
          )}
        </Box>
        <GetContacts>
          <TabNavigation />
        </GetContacts>
      </Box>
      {children}
    </Box>
  );
};

export default PageNavigation;
