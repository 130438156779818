import { createGlobalStyle } from "styled-components";

import constants from "./constants";

const Style = createGlobalStyle`
  .ddb-uni-header {
    position: fixed;
    z-index: 300;
    top: 0px;
    left: 0;
    right: 0;
    max-width: 100vw;
    box-sizing: border-box;
    width: 100%;
    min-height: ${constants.UNI_HEADER_HEIGHT};
    background-color: ${constants.UNI_LIGHT_BG_COLOR};
    padding: 0 8px;
    
    .ddb-uni-header--content {
      margin: 0 auto;
      width: 100%;
      max-width: ${constants.UNI_HEADER_CONTENT_MAX_WIDTH};
      height: ${constants.UNI_HEADER_HEIGHT};
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    @media (max-width: ${constants.UNI_BREAKPOINT_HEADER_SMALL}px) {
      min-height: ${constants.UNI_HEADER_HEIGHT_SMALL};

      .ddb-uni-header--content {
        height: ${constants.UNI_HEADER_HEIGHT_SMALL};
      }
    }

    .ddb-uni-header--action-wrapper {
      display: flex;
      align-items: center;

      &.ddb-uni-header--action-wrapper__main {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto; /* Properties are set separately for IE */
        margin-right: 8px;

        .ddb-uni-header--service-wrapper,
        .ddb-uni-header--newspaper-name {
          margin-right: 16px;
        }
      }

      &.ddb-uni-header--action-wrapper__newspapers {
        flex: 0 0 auto;
      }

      &.ddb-uni-header--action-wrapper__profile {
        flex: 0 0 auto;
      }

      @media (max-width: ${constants.UNI_BREAKPOINT_DESKTOP_S}px) {
        &.ddb-uni-header--action-wrapper__main,
        &.ddb-uni-header--action-wrapper__main .ddb-uni-header--service-wrapper,
        &.ddb-uni-header--action-wrapper__main .ddb-uni-header--newspaper-name {
          margin-right: 12px;
        }
      }

      @media (max-width: ${constants.UNI_BREAKPOINT_MOBILE_XS}px) {
        &.ddb-uni-header--action-wrapper__main .ddb-uni-header--service-wrapper,
        &.ddb-uni-header--action-wrapper__main .ddb-uni-header--newspaper-name {
          margin-right: 8px;
        }
      }

      @media (max-width: ${constants.UNI_BREAKPOINT_MOBILE_XXS}px) {
        &.ddb-uni-header--action-wrapper__main {
          margin-right: 8px;
        }
        &.ddb-uni-header--action-wrapper__main .ddb-uni-header--service-wrapper,
        &.ddb-uni-header--action-wrapper__main .ddb-uni-header--newspaper-name {
          margin-right: 6px;
        }
      }
    }
  
    @media (max-width: ${constants.UNI_BREAKPOINT_HEADER_SMALL}px) {
      min-height: ${constants.UNI_HEADER_HEIGHT_SMALL};
      
      .ddb-uni-header--content {
        height: ${constants.UNI_HEADER_HEIGHT_SMALL};
      }
    }
  }

  .ddb-uni-header--cta-wrapper {
    flex: 0 0 auto;
  
    .ddb-uni-header--cta {
      &.ddb-uni-header--cta-mobile {
        display: none;
      }
    }
  }

  @media (max-width: ${constants.UNI_BREAKPOINT_DESKTOP_S}px) {
    .ddb-uni-header--cta-wrapper {
      flex: 1 1 auto;
      display: flex;
      justify-content: flex-end;

      .ddb-uni-header--cta.ddb-uni-header--cta-desktop {
        display: none;
      }
  
      .ddb-uni-header--cta.ddb-uni-header--cta-mobile {
        display: inline-flex;
      }
    }
  }

  .ddb-uni-header--logo-wrapper {
    position: relative;
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    max-width: ${constants.UNI_HEADER_LOGO_MAX_WIDTH}
    max-height: ${constants.UNI_HEADER_LOGO_MAX_HEIGHT};
    margin-right: 25px;
    font-size: 10px;
  
    &:hover,
    &:active {
      outline: none;
    }
  
    .ddb-keyboard-navigation-on &:focus {
      outline: 2px dotted ${constants.UNI_COLOR_FOCUS_OUTLINE};
      outline-offset: 2px;
    }
  
    &:after {
      content: "";
      position: absolute;
      top: calc((100% - 24px) / 2);
      right: -17px;
      height: 24px;
      width: 1px;
      display: inline-flex;
      align-self: center;
      background: rgba(0, 0, 0, 0.3);
    }
    
    @media (max-width: ${constants.UNI_BREAKPOINT_DESKTOP_S}px) {
      margin-right: 17px;
  
      &:after {
        right: -13px;
        display: none; // This property is added only in dashboard unified header, as there is no services menu here.
      }
    }
  
    @media (max-width: ${constants.UNI_BREAKPOINT_MOBILE_S}px) {
      margin-right: 8px;
  
      &:after {
        display: none;
      }
    }
  
    img.ddb-uni-header--logo {
      display: inline-flex;
      width: auto;
      max-width: 100%;
  
      &.ddb-uni-header--logo-desktop {
        max-height: ${constants.UNI_HEADER_LOGO_MAX_HEIGHT};
      }
  
      &.ddb-uni-header--logo-mobile {
        max-height: ${constants.UNI_HEADER_LOGO_MAX_HEIGHT_MOBILE};
        display: none;
      }
    }
  
    @media (max-width: ${constants.UNI_BREAKPOINT_DESKTOP_S}px) {
      max-width: ${constants.UNI_HEADER_LOGO_MAX_WIDTH_MOBILE};
      max-height: ${constants.UNI_HEADER_LOGO_MAX_HEIGHT_MOBILE};
  
      img.ddb-uni-header--logo {
        max-width: ${constants.UNI_HEADER_LOGO_MAX_WIDTH_MOBILE};
  
        &.ddb-uni-header--logo-desktop {
          display: none;
        }
  
        &.ddb-uni-header--logo-mobile {
          display: inline-flex;
        }
      }
    }
  }
  
  .ddb-uni-header__invert {
    background-color: ${constants.UNI_LIGHT_BG_COLOR} !important;
    color: ${constants.UNI_TEXT_COLOR};
    border-bottom: 1px solid ${constants.UNI_BORDER_COLOR};
  }
  
  .ddb-uni-header__default {
    color: ${constants.UNI_INVERTED_TEXT_COLOR};
    
    .ddb-uni-header--logo-wrapper:after {
      background: rgba(255, 255, 255, 0.5);
    }
  
    .ddb-uni-button {
      color: ${constants.UNI_INVERTED_TEXT_COLOR};
      background-color: ${constants.UNI_INVERTED_DEFAULT_BTN_BG_COLOR};
    }
  
    .ddb-uni-button:hover:not(:disabled) {
      color: ${constants.UNI_INVERTED_TEXT_COLOR};
      background-color: ${constants.UNI_INVERTED_DEFAULT_HOVER_BTN_BG_COLOR};
    }
  
    .ddb-uni-button:focus:not(:disabled) {
      color: ${constants.UNI_INVERTED_TEXT_COLOR};
      background-color: ${constants.UNI_INVERTED_DEFAULT_FOCUS_BTN_BG_COLOR};
    }
    
    .ddb-uni-button:active:not(:disabled),
    .ddb-uni-dropdown.open .ddb-uni-button {
      color: ${constants.UNI_INVERTED_TEXT_COLOR};
      background-color: ${constants.UNI_INVERTED_DEFAULT_ACTIVE_BTN_BG_COLOR};
    }

    .ddb-uni-button__cta {
      color: ${constants.UNI_CTA_TEXT_COLOR};
      background-color: ${constants.UNI_INVERTED_CTA_BTN_BG_COLOR};
  
      &:hover:not(:disabled) {
        color: ${constants.UNI_CTA_HOVER_TEXT_COLOR};
        background-color: ${constants.UNI_INVERTED_CTA_HOVER_BTN_BG_COLOR};
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
      }
  
      &:focus:not(:disabled) {
        color: ${constants.UNI_CTA_FOCUS_TEXT_COLOR};
        background-color: ${constants.UNI_INVERTED_CTA_FOCUS_BTN_BG_COLOR};
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
      }
  
      &:active:not(:disabled) {
        color: ${constants.UNI_CTA_ACTIVE_TEXT_COLOR};
        background-color: ${constants.UNI_INVERTED_CTA_ACTIVE_BTN_BG_COLOR};
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.24);
      }
    }

    .ddb-uni-button__cta:disabled {
      opacity: 1 !important;
      color: #737373;
    }
  
    .ddb-uni-button__text {
      background: ${constants.UNI_INVERTED_TEXT_BTN_BG_COLOR};
    }
  
    .ddb-uni-button__text:hover {
      background-color: ${constants.UNI_INVERTED_TEXT_HOVER_BTN_BG_COLOR};
    }
  
    .ddb-uni-button__text:focus {
      background-color: ${constants.UNI_INVERTED_TEXT_FOCUS_BTN_BG_COLOR};
    }
  
    .ddb-uni-button__text:active,
    .ddb-uni-dropdown.open .ddb-uni-button__text {
      background-color: ${constants.UNI_INVERTED_TEXT_ACTIVE_BTN_BG_COLOR};
    }

    .ddb-keyboard-navigation-on & .ddb-uni-header--logo-wrapper:focus,
    .ddb-keyboard-navigation-on & .ddb-uni-dropdown:focus,
    .ddb-keyboard-navigation-on & .ddb-uni-button:focus,
    .ddb-keyboard-navigation-on & .ddb-uni-button__cta:focus {
      outline-color: rgba(255, 255, 255, 0.8);
    }
  }


  // Skip navigation link.

  .ddb-uni-skip-navigation {
    position: absolute;
    top: calc(${constants.UNI_HEADER_HEIGHT} + 8px);
    left: 0;
    transform: translateX(-100%);
    text-align: center;
    display: flex;
    align-items: center;
    justify-items: center;
    font-family: ${constants.UNI_FONT_FAMILY};
    font-size: 14px;
    line-height: 14px;
    box-sizing: border-box;
    height: ${constants.UNI_BTN_HEIGHT};
    border: 0;
    border-top-right-radius: ${constants.UNI_BORDER_RADIUS_L};
    border-bottom-right-radius: ${constants.UNI_BORDER_RADIUS_L};
    padding: 9px 14px 10px 14px;
    color: ${constants.UNI_SKIP_NAVIGATION_COLOR};
    background-color: ${constants.UNI_SKIP_NAVIGATION_BG_COLOR};
    z-index: 300;
    cursor: pointer;
    transition: transform 0.2s ease;
    
    &:link,
    &:visited,
    &:hover,
    &:active {
      text-decoration: none;
    }
    
    &:focus {
      transform: translateX(0);
      outline: none;
    }

    .ddb-keyboard-navigation-on &:focus {
      outline: 2px dotted ${constants.UNI_COLOR_FOCUS_OUTLINE};
      outline-offset: 2px;
    }
  
    @media (max-width: ${constants.UNI_BREAKPOINT_HEADER_SMALL}px) {
      top: calc(${constants.UNI_HEADER_HEIGHT_SMALL} + 8px);
    }
  }

  // Buttons.

  .ddb-uni-button {
    text-align: center;
    display: flex;
    align-items: center;
    justify-items: center;
    font-family: ${constants.UNI_FONT_FAMILY};
    font-weight: ${constants.UNI_FONT_WEIGHT_700};
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    box-sizing: border-box;
    height: ${constants.UNI_BTN_HEIGHT};
    border: 0;
    border-radius: ${constants.UNI_BORDER_RADIUS_L};
    padding: 9px 12px;
    color: ${constants.UNI_TEXT_COLOR};
    background-color: ${constants.UNI_DEFAULT_BTN_BG_COLOR};
  
    cursor: pointer;
    transition-property: color, background-color, border, border-color, opacity, transform;
    transition-duration: 0.2s;
    transition-timing-function: ease;
  
    &:link,
    &:visited,
    &:hover,
    &:active {
      text-decoration: none;
    }
  
    &:hover:not(:disabled) {
      background-color: ${constants.UNI_DEFAULT_HOVER_BTN_BG_COLOR};
    }
  
    &:focus:not(:disabled) {
      background-color: ${constants.UNI_DEFAULT_FOCUS_BTN_BG_COLOR};
    }
  
    &:active:not(:disabled),
    .ddb-uni-dropdown.open & {
      background-color: ${constants.UNI_DEFAULT_ACTIVE_BTN_BG_COLOR};
    }
  
    &:focus {
      outline: none;
    }

    .ddb-keyboard-navigation-on &:focus {
      outline: 2px dotted ${constants.UNI_COLOR_FOCUS_OUTLINE};
      outline-offset: 2px;
    }
  }
  
  .ddb-uni-button__cta {
    color: ${constants.UNI_INVERTED_TEXT_COLOR};
    background-color: ${constants.UNI_CTA_BTN_BG_COLOR};
  
    &:hover:not(:disabled) {
      color: ${constants.UNI_INVERTED_TEXT_COLOR};
      background-color: ${constants.UNI_CTA_HOVER_BTN_BG_COLOR};
    }
  
    &:focus:not(:disabled) {
      color: ${constants.UNI_INVERTED_TEXT_COLOR};
      background-color: ${constants.UNI_CTA_FOCUS_BTN_BG_COLOR};
    }
  
    &:active:not(:disabled) {
      color: ${constants.UNI_INVERTED_TEXT_COLOR};
      background-color: ${constants.UNI_CTA_ACTIVE_BTN_BG_COLOR};
    }
  }
  
  // Button used as a title for services menu.
  .ddb-uni-button__text {
    height: auto;
    min-height: ${constants.UNI_BTN_HEIGHT};
    display: flex;
    align-items: center;
    text-align: left;
    padding: 7px 8px 9px;
    text-transform: none;
    font-size: 20px;
    letter-spacing: 0.01em;
    line-height: 16px;
    background: transparent;
  
    &:hover:not(:disabled) {
      background-color: ${constants.UNI_TEXT_HOVER_BTN_BG_COLOR};
    }
  
    &:focus:not(:disabled) {
      background-color: ${constants.UNI_TEXT_FOCUS_BTN_BG_COLOR};
    }
  
    &:active:not(:disabled),
    .ddb-uni-dropdown.open & {
      background-color: ${constants.UNI_TEXT_ACTIVE_BTN_BG_COLOR};
    }
  
    &.ddb-uni-button__text-plain {
      cursor: default;
  
      &:hover:not(:disabled),
      &:active:not(:disabled),
      &:focus:not(:disabled) {
        background-color: transparent;
      }
    }
  
    @media (max-width: ${constants.UNI_BREAKPOINT_MOBILE}px) {
      font-size: 14px;
      line-height: 1.1;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  
    @media (max-width: ${constants.UNI_BREAKPOINT_MOBILE_S}px) {
      font-size: 3.7vw;
    }
  
    @media (max-width: ${constants.UNI_BREAKPOINT_MOBILE_XS}px) {
      font-size: 3.1vw;
    }
  }
  
  .ddb-uni-button__icon {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: ${constants.UNI_BTN_HEIGHT};
    width: ${constants.UNI_BTN_HEIGHT};
    padding: 0;
  
    .ddb-uni-icon {
      font-size: ${constants.UNI_ICON_SIZE};
    }
  }

  .ddb-uni-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  // Dropdowns.

  .ddb-uni-dropdown {
    position: relative;
    display: inline-block;
    max-width: 100%;
    font-family: ${constants.UNI_FONT_FAMILY};
    z-index: 110;
    cursor: default;
    outline: none;
  
    .ddb-keyboard-navigation-on &:focus {
      outline: 2px dotted ${constants.UNI_COLOR_FOCUS_OUTLINE};
      outline-offset: 2px;
    }
  
    > .ddb-uni-dropdown--title {
      outline: none;
    }
  }
  
  .ddb-uni-dropdown--title {
    position: relative;
    max-width: 100%;
    display: inline-flex;
    z-index: 120;
  
    .ddb-uni-dropdown--icon {
      font-size: 12px;
      position: absolute;
      top: calc((100% - 12px) / 2);
      right: 10px;
    }
  
    &.ddb-uni-button {
      padding-right: 28px;
    }
  
    &.ddb-uni-button__text {
      padding-right: 24px;
    }
  
    &.ddb-uni-button__icon {
      padding-right: 0;
    }
  }
  
  .ddb-uni-dropdown--menu {
    position: absolute;
    top: calc(100% + 20px);
    min-width: 240px;
    max-width: calc(100vw - 72px);
    border-radius: ${constants.UNI_BORDER_RADIUS_M};
    background-color: ${constants.UNI_LIGHT_BG_COLOR};
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06), 0 4px 16px 0 rgba(0, 0, 0, 0.12);
  
    box-sizing: border-box;
    margin: 0;
    padding: 8px;
    visibility: hidden;
    opacity: 0;

    /* 'visibility: hidden' is used, not the alternative 'display: none',
      cause the width of dropdown menu has to be calculated when it's rendered.
      (To check if menu will be cut by the window edge when dropdown is open).
  
      'opacity' is set to hide all descendent menu elements, including these which have visibility set to 'visible'.
    */
  
    max-height: calc(100vh - 80px);
    overflow-y: auto;
    z-index: 130;
    opacity: 1;
  
    ul {
      margin: 0;
      padding: 0;
    }
  
    li {
      list-style: none;
    }
  
    @media (max-width: ${constants.UNI_BREAKPOINT_MOBILE}) and (min-height: 600px) {
      max-height: calc(100vh - 180px);
    }
  }

  .ddb-uni-dropdown.open .ddb-uni-dropdown--menu {
    visibility: visible;
    opacity: 1;
  }
  
  .ddb-uni-dropdown__newspapers-menu {
    margin-right: 8px;
    // TODO:
    @media (max-width: ${constants.UNI_BREAKPOINT_DESKTOP_S}px) {
      margin-right: 12px;
    }
  }

  .ddb-uni-dropdown__user-menu .ddb-uni-dropdown--menu,
  .ddb-uni-dropdown__newspapers-menu .ddb-uni-dropdown--menu {
    min-width: 256px;
  }
  
  .ddb-uni-dropdown--menu__ltr {
    left: -16px;
  }
  
  .ddb-uni-dropdown--menu__rtl {
    right: 0;
  }
  
  .ddb-uni-dropdown--item-link {
    display: block;
    text-decoration: none;
    color: ${constants.UNI_TEXT_COLOR};
    transition: color 0.2s ease, background-color 0.2s ease, opacity 0.2s ease;
    outline: none;
    cursor: pointer;
  
    &:hover:not(:disabled),
    &:active:not(:disabled),
    &:focus:not(:disabled) {
      background-color: ${constants.UNI_MENU_HOVER_ITEM_BG_COLOR};
      text-decoration: none;
    }
  }
  
  .ddb-uni-dropdown--item-link__image {
    display: inline-block;
    background: transparent;
    padding: 4px 0;
  
    &:hover:not(:disabled),
    &:active:not(:disabled),
    &:focus:not(:disabled) {
      background: transparent;
    }
  }
  
  .ddb-uni-dropdown--app-img {
    transition: box-shadow 0.2s ease, opacity 0.2s ease;
  
    :hover:not(:disabled) > &,
    :active:not(:disabled) > & {
      border-radius: 7px;
    }
  }
  
  .ddb-uni-dropdown--item__main {
    .ddb-uni-dropdown--item-link {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 12px 16px;
      border-radius: ${constants.UNI_BORDER_RADIUS_S};
    }
  
    .ddb-uni-dropdown--item-link-with-icon {
      flex-wrap: nowrap;
      padding-left: 12px;
    }

    .ddb-uni-dropdown--item-link-with-small-icon {
      flex-wrap: nowrap;
      padding-left: 6px;
    }
  
    /* Use this class along with the .ddb-uni-dropdown--item-icon */
    .ddb-uni-dropdown--item-text-content {
      flex: 1 1 100px;
    }
  
    .ddb-uni-dropdown--item-title {
      flex: 1 0 55%;
      color: ${constants.UNI_TEXT_COLOR};
      font-size: 16px;
      line-height: 1.25em;
      font-weight: ${constants.UNI_FONT_WEIGHT_700};
    }
  
    .ddb-uni-dropdown--item-subtitle {
      flex: 1 0 55%;
      margin: 4px 0 0;
      color: ${constants.UNI_TEXT_COLOR}-gray375;
      font-size: 13px;
      line-height: 16px;
    }
  
    .ddb-uni-dropdown--item-icon {
      display: inline-flex;
      box-sizing: border-box;
      height: ${constants.UNI_ICON_THUMB_SIZE};
      width: ${constants.UNI_ICON_THUMB_SIZE};
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: ${constants.UNI_LIGHT_BG_COLOR};
      border: 1px solid ${constants.UNI_BORDER_COLOR};
      font-size: 24px;
      transition: color 0.2s ease, opacity 0.2s ease, background-color 0.2s ease;
  
      margin-right: 24px;

      > div {
        display: inline-flex;
      }
    }

    .ddb-uni-dropdown--item-small-icon {
      display: flex;
      box-sizing: border-box;
      font-size: 24px;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      
      .ddb-uni-icon {
        display: inline-flex;
        color: #E0E0E0;
        transition: color 0.1s ease;
      }
    }

    .ddb-uni-dropdown--item-link:hover:not(:disabled) .ddb-uni-dropdown--item-small-icon .ddb-uni-icon,
    .ddb-uni-dropdown--item-link:active:not(:disabled) .ddb-uni-dropdown--item-small-icon .ddb-uni-icon,
    .ddb-uni-dropdown--item-link:focus:not(:disabled) .ddb-uni-dropdown--item-small-icon .ddb-uni-icon {
      color: #CCCCCC;
    }

    .ddb-uni-dropdown--item-small-image {
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      object-fit: contain;
      margin-right: 8px;
      height: 24px;
      width: 24px;
      border-radius: 4px;
      background: #E0E0E0;
      transition: background 0.1s ease;
    }

    .ddb-uni-dropdown--item-link:hover:not(:disabled) .ddb-uni-dropdown--item-small-image,
    .ddb-uni-dropdown--item-link:active:not(:disabled) .ddb-uni-dropdown--item-small-image,
    .ddb-uni-dropdown--item-link:focus:not(:disabled) .ddb-uni-dropdown--item-small-image {
      background: #CCCCCC;
    }
  
    .ddb-uni-dropdown--item-link:hover:not(:disabled),
    .ddb-uni-dropdown--item-link:active:not(:disabled),
    .ddb-uni-dropdown--item-link:focus:not(:disabled) {
      .ddb-uni-dropdown--item-icon {
        border-color: transparent;
      }
    }
  }
  
  /* Use this style for small links in user menu. */
  .ddb-uni-dropdown--item__small {
    .ddb-uni-dropdown--item-link,
    .ddb-uni-dropdown--item-text {
      color: ${constants.UNI_TEXT_COLOR_GRAY_450};
      font-size: 12px;
      line-height: 16px;
      font-weight: normal;
      padding: 8px 16px;
      border-radius: ${constants.UNI_BORDER_RADIUS_S};
    }
  
    &:last-of-type {
      margin-bottom: 12px;
    }
  }
  
  .ddb-uni-dropdown--item__main + .ddb-uni-dropdown--item__small {
    margin-top: 8px;
  }
  
  /* Use this style for custom links in services menu. */
  .ddb-uni-dropdown--item__custom {
    .ddb-uni-dropdown--item-link {
      position: relative;
      color: ${constants.UNI_TEXT_COLOR};
      font-size: 14px;
      line-height: 24px;
      font-weight: normal;
      padding: 4px 40px 4px 16px;
      border-radius: ${constants.UNI_BORDER_RADIUS_S};
  
      .ddb-uni-icon {
        position: absolute;
        top: calc((100% - 24px) / 2);
        right: 16px;
        font-size: 24px;
        color: #000;
        opacity: 0.6;
      }
    }
  }
  
  .ddb-uni-dropdown--item-extended-content {
    padding: 4px 16px 4px;
    font-size: 0;
  }
  
  .ddb-uni-dropdown--item-caption {
    color: ${constants.UNI_TEXT_COLOR_GRAY_450};
    font-size: 10px;
    line-height: 1.2em;
    font-weight: normal;
    margin: 4px 0;
  }
  
  .ddb-uni-dropdown--item__separator {
    position: relative;
    margin: 8px 0 0 0;
    padding: 9px 0 0 0;
  
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 16px;
      right: 16px;
      height: 1px;
      background: ${constants.UNI_BORDER_COLOR};
    }
  }
  
  /* IE */
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .ddb-uni-dropdown--menu {
      width: 240px;
      overflow-wrap: break-word;
    }
  
    .ddb-uni-dropdown__user-menu .ddb-uni-dropdown--menu,
    .ddb-uni-dropdown__newspapers-menu .ddb-uni-dropdown--menu {
      width: 256px;
    }
  }
  

  // Icons.

  .ddb-uni-icon {
    display: inline-block;

    svg {
      display: inline-block;
      width: 1em;
      height: 1em;
      stroke-width: 0;
      stroke: currentColor;
      fill: currentColor;
      transition: color 0.2s ease, opacity 0.2s ease, background-color 0.2s ease;
      pointer-events: none;
    }
  }
  
  .ddb-uni-icon__thumb {
    box-sizing: border-box;
    height: ${constants.UNI_ICON_THUMB_SIZE};
    width: ${constants.UNI_ICON_THUMB_SIZE};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: ${constants.UNI_LIGHT_BG_COLOR};
    border: 1px solid ${constants.UNI_BORDER_COLOR};
    font-size: 24px;
    transition: color 0.2s ease, opacity 0.2s ease, background-color 0.2s ease;
  }
  
  .ddb-uni-icon--user {
    width: auto;
  }

  .ddb-uni-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;

export default Style;
