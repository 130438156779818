import React, { FC, ReactNode, useContext } from "react";

import { getDashboardDataByToken } from "../../util/hooks";
import { AppContext } from "./AppProvider";
import Loader from "../atoms/Loader";

const GetDashboardData: FC<{ children: ReactNode }> = ({ children }) => {
  getDashboardDataByToken();

  const { newspaper, customer, FBAppId } = useContext(AppContext);
  
  const isLoaded = Boolean(newspaper && customer && FBAppId);

  return <Loader active={!isLoaded}>{children}</Loader>;
};

export default GetDashboardData;
