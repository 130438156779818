import React, { useState, useCallback, FC, FormEvent } from "react";

import {
  InputContainer,
  InputBase,
  SearchButton,
  SearchIcon,
  ResetIcon
} from "./styled";

interface SearchInputProps {
  id?: string;
  name: string;
  placeholder: string;
  defaultValue?: string;
  onChange?: (e: FormEvent<HTMLInputElement>) => void;
  onFocus?: (e: FormEvent<HTMLInputElement>) => void;
  onBlur?: (e: FormEvent<HTMLInputElement>) => void;
  onSearch?: (value?: string) => void;
  autoComplete?: string;
}

const SearchInput: FC<SearchInputProps> = ({
  id: _id,
  onChange: _onChange,
  onFocus: _onFocus,
  onBlur: _onBlur,
  defaultValue,
  onSearch: _onSearch,
  ...props
}) => {
  const { name, placeholder } = props;

  const id = _id || name;

  const [value, setValue] = useState(defaultValue);

  const onChange = useCallback(
    ({ target: { value } }) => {
      setValue(value);
      _onChange && _onChange(value);
    },
    [_onChange]
  );

  const onSearch = useCallback(
    (e) => onSearch && onSearch(value),
    [_onSearch, value]
  );

  const onFocus = useCallback((e) => _onFocus && _onFocus(e), [_onFocus]);
  const onBlur = useCallback((e) => _onBlur && _onBlur(e), [_onBlur]);

  return (
    <InputContainer>
      <SearchButton label={placeholder} type="button" onClick={onSearch}>
        <SearchIcon name="search" />
      </SearchButton>
      <InputBase
        id={id}
        data-cy="search-input"
        type="search"
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        defaultValue={defaultValue}
        {...props}
      />
      {!!value && <ResetIcon name="close" />}
    </InputContainer>
  );
};

export default SearchInput;
