import { FC } from "react";
import styled from "styled-components";

import constants from "../../components/_globalStyles/constants";
import Box, { BoxProps } from "../../components/particles/Box";
import Button, { ButtonProps } from "../../components/atoms/Button";

export const PageBox: FC<BoxProps> = styled(Box)`
  min-height: 100vh;
  background: ${constants.COLOR_GRAY_100};
  padding: 0 ${constants.GRID_UNIT}px;
`;

PageBox.displayName = "PageBox";

export const Fieldset: FC = styled.fieldset`
  border: 0;
  margin: 0;
  padding: 0;
`;

Fieldset.displayName = "Fieldset";

export const SubmitButton: FC<ButtonProps> = styled(Button)`
  position: fixed;
  bottom: ${constants.GRID_UNIT * 2}px;
  left: 50%;
  transform: translate(-50%);
  z-index: 100;
`;

SubmitButton.displayName = "SubmitButton";
