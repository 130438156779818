import React, { VFC } from "react";

import Box from "../../particles/Box";
import Text from "../../atoms/Text";
import Icon from "../../atoms/Icon";
import TextDropdown from "../../organisms/TextDropdown";
import SecondaryNoticeMessage, { SecondaryNoticeMessageProps } from "../../organisms/SecondaryNoticeMessage";

import { Logo } from "./styled";

interface MainPageTitleProps {
  noticeMessage?: SecondaryNoticeMessageProps;
  title?: string;
  titleObj?: {
    label: string;
    options: {
      title: string;
      value: number | string;
    }[];
    selectedOption?: number | string;
    onSelect: (value: number | string) => void;
  };
  description?: string;
  logo?: string;
}

const MainPageTitle: VFC<MainPageTitleProps> = ({
  noticeMessage,
  title,
  titleObj,
  description,
  logo
}) => (
  <Box flex alignCenter justifySpaceBetween paddingT={2}>
    <Box flexStatic flex alignCenter>
      <Logo logo={logo} inlineBlock flexStatic marginR={2}>
        <Icon name="user" />
      </Logo>
      <Box inlineBlock>
        {description && <Text details>{description}</Text>}
        {title && !titleObj && (
          <Text as="h1" styledAs="h2">
            {title}
          </Text>
        )}
        {!title && titleObj && (
          <TextDropdown
            label={titleObj.label}
            options={titleObj.options}
            selectedOption={titleObj.selectedOption}
            onSelect={titleObj.onSelect}
          />
        )}
      </Box>
    </Box>
    {noticeMessage && (
      <Box className="ddb-desktop ddb-desktop-s ddb-mobile-l" flexDynamic marginL={2} width="60%" maxWidth={46}>
        <SecondaryNoticeMessage {...noticeMessage} titleAs="h2"/>
      </Box>
    )}
  </Box>
);

export default MainPageTitle;
