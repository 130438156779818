import React from "react";

const KeyboardNavigationHandler = () => {
  const keyboardNavigationOnEvent = new CustomEvent("keyboardNavigationOn", {
    bubbles: true
  });
  const mouseNavigationOnEvent = new CustomEvent("mouseNavigationOn", {
    bubbles: true
  });

  const keyboardNavigationOn = (e) => {
    // If tab is pressed.
    if (
      e.keyCode === 9 &&
      !document.body.classList.contains("ddb-keyboard-navigation-on")
    ) {
      document.body.classList.add("ddb-keyboard-navigation-on");

      document.body.dispatchEvent(keyboardNavigationOnEvent);

      document.body.removeEventListener("keydown", keyboardNavigationOn);
      document.body.addEventListener("mousedown", mouseNavigationOn);
    }
  };

  const mouseNavigationOn = (e) => {
    if (
      e.type === "mousedown" &&
      document.body.classList.contains("ddb-keyboard-navigation-on")
    ) {
      document.body.classList.remove("ddb-keyboard-navigation-on");

      document.body.dispatchEvent(mouseNavigationOnEvent);

      document.body.removeEventListener("mousedown", mouseNavigationOn);
      document.body.addEventListener("keydown", keyboardNavigationOn);
    }
  };

  document.body.addEventListener("keydown", keyboardNavigationOn);
  document.body.addEventListener("mousedown", mouseNavigationOn);

  return <></>;
};

export default KeyboardNavigationHandler;
