import { FC } from "react";
import styled from "styled-components";

import constants from "../../_globalStyles/constants";
import Box, { BoxProps } from "../../particles/Box";
import Tile, { TileProps } from "../../atoms/Tile";
import Button, { ButtonProps } from "../../atoms/Button";

export const PopupContainer: FC<BoxProps> = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;
`;

PopupContainer.displayName = "PopupContainer";

export const PopupTile: FC<TileProps> = styled(Tile)`
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.2);
`;

PopupTile.displayName = "PopupTile";

export const CloseButton: FC<ButtonProps> = styled(Button)`
  position: absolute;
  top: ${constants.GRID_UNIT * 2}px;
  right: ${constants.GRID_UNIT * 2}px;
`;

CloseButton.displayName = "CloseButton";
