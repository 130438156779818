import { FC, HTMLProps } from "react";
import styled, { css } from "styled-components";

import constants from "../../_globalStyles/constants";
import { pxToRem } from "../../../util/helpers";
import Icon, { IconProps } from "../../atoms/Icon";
import Box, { BoxProps } from "../../particles/Box";
import Button, { ButtonProps } from "../../atoms/Button";

export const DateRangeInputWrapper: FC<BoxProps> = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    font-family: "Roboto", sans-serif;
    border: none;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.04), 0px 4px 32px rgba(0, 0, 0, 0.2);
    color: ${constants.TEXT_COLOR}!important;
    display: flex;
    flex-wrap: wrap;
    width: 482px;
    border-radius: ${0.5 * constants.GRID_UNIT}px;
    overflow: hidden;
  }

  .react-datepicker__current-month {
    font-weight: normal;
    font-size: ${2 * constants.GRID_UNIT}px;
    padding: ${1.5 * constants.GRID_UNIT}px 0;
    display: block;
  }

  .react-datepicker__header {
    background: #fff;
    border-bottom: 1px solid ${constants.COLOR_GRAY_190};
  }

  .react-datepicker__month-container {
    background: ${constants.COLOR_WHITE};
    flex: 0 0 ${30 * constants.GRID_UNIT + 1}px;
  }

  .react-datepicker__navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid ${constants.COLOR_GRAY_190};
    height: ${3 * constants.GRID_UNIT}px;
    width: ${3 * constants.GRID_UNIT}px;
    top: ${2 * constants.GRID_UNIT}px;
    margin: 0 ${1.5 * constants.GRID_UNIT}px;
  }

  .react-datepicker__navigation-icon:before {
    width: 5px;
    height: 5px;
    border-color: ${constants.COLOR_BLACK};
    border-width: 1px 1px 0 0;
    top: ${1.5 * constants.GRID_UNIT}px;
  }

  .react-datepicker__navigation-icon--previous::before {
    right: -2px;
  }

  .react-datepicker__navigation-icon--next::before {
    left: -2px;
  }

  .react-datepicker__day-name {
    width: ${3 * constants.GRID_UNIT}px !important;
    line-height: ${3 * constants.GRID_UNIT}px !important;
    margin: 2px !important;
    font-size: ${pxToRem(11)};
  }

  .react-datepicker__day {
    width: ${3 * constants.GRID_UNIT}px !important;
    line-height: ${3 * constants.GRID_UNIT}px !important;
    margin: 2px !important;
    font-size: ${pxToRem(11)};
  }

  .react-datepicker__day--keyboard-selected {
    color: #fff !important;
    background-color: #2a87d0;

    &:hover {
      color: #fff !important;
      background-color: #1d5d90;
    }
  }
`;

DateRangeInputWrapper.displayName = "DateRangeInputWrapper";

interface ButtonWrapperProps extends HTMLProps<HTMLDivElement> {
  hasValue: boolean;
}

export const ButtonWrapper: FC<ButtonWrapperProps> = styled.div`
  position: relative;
  width: 100%;
  display: inline-block;
`;

ButtonWrapper.displayName = "ButtonWrapper";

interface TriggerButtonProps extends ButtonProps {
  hasValue: boolean;
  isOpen: boolean;
}

export const TriggerButton: FC<TriggerButtonProps> = styled(Button)`
  width: 100%;
  justify-content: flex-start;
  padding: ${constants.GRID_UNIT * 1.5}px ${constants.GRID_UNIT * 5.75}px
    ${constants.GRID_UNIT * 1.5}px ${constants.GRID_UNIT * 4}px;

  ${(props) =>
    props.hasValue &&
    css`
      padding-right: ${constants.GRID_UNIT * 9.5}px;
    `}

  ${(props) =>
    props.isOpen &&
    css`
      color: ${constants.BUTTON_INVERTED_PRIMARY_TEXT_COLOR};

      ${CalendarIcon},
      ${SelectIcon} {
        color: ${constants.BUTTON_INVERTED_PRIMARY_TEXT_COLOR} !important;
      }

      ${SelectIcon} {
        transform: rotate(0deg);
      }
    `}
`;

TriggerButton.displayName = "TriggerButton";

export const CalendarIcon: FC<IconProps> = styled(Icon)`
  font-size: ${constants.GRID_UNIT * 3}px !important;
  margin-right: ${constants.GRID_UNIT * 1.5}px;
`;

CalendarIcon.displayName = "CalendarIcon";

export const ResetButton: FC<ButtonProps> = styled(Button)`
  position: absolute;
  top: 0;
  right: ${constants.GRID_UNIT * 4.25}px;
  border: 0;
  box-shadow: none;
  background: transparent !important;
  padding: ${constants.GRID_UNIT * 1.875}px ${constants.GRID_UNIT * 1.5}px;
  z-index: 10;

  &:hover,
  &:active,
  &:focus {
    background: transparent !important;
  }

  .ddb-keyboard-navigation-on &:focus {
    outline-offset: -6px;
  }
`;

ResetButton.displayName = "ResetButton";

export const ResetIcon: FC<IconProps> = styled(Icon)`
  font-size: ${constants.GRID_UNIT * 2.25}px;
  color: ${constants.COLOR_GRAY_350} !important;
`;

ResetIcon.displayName = "ResetIcon";

export const SelectIcon: FC<IconProps> = styled(Icon)`
  font-size: ${constants.GRID_UNIT * 2.25}px;
  position: absolute;
  top: ${constants.GRID_UNIT * 1.875}px;
  right: ${constants.GRID_UNIT * 2}px;
  transform: rotate(-180deg);
`;

SelectIcon.displayName = "SelectIcon";

export const PrefilledRangesWrapper: FC<BoxProps> = styled(Box)`
  border-top: 1px solid ${constants.COLOR_GRAY_190};
  flex: 1 0 100%;
  padding: ${1.5 * constants.GRID_UNIT}px ${2 * constants.GRID_UNIT}px;
  display: flex;
  justify-content: space-between;
`;

PrefilledRangesWrapper.displayName = "PrefilledRangesWrapper";

interface RangeButtonProps extends ButtonProps {
  active: boolean;
}

export const RangeButton: FC<RangeButtonProps> = styled.button`
  border: 0;
  box-shadow: none;
  background: transparent;

  padding: ${constants.GRID_UNIT}px ${constants.GRID_UNIT * 1.5}px;

  color: ${(props) =>
    props.active ? constants.BUTTON_INVERTED_PRIMARY_TEXT_COLOR : constants.TEXT_COLOR};

  &:hover,
  &:active,
  &:focus {
    color: ${constants.BUTTON_INVERTED_PRIMARY_TEXT_COLOR};
  }

  cursor: pointer;
`;

RangeButton.displayName = "RangeButton";
