import React, { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AppContext } from "../../components/_functional/AppProvider";
import { requestSignIn } from "../../util/apiRequests";
import { signIn } from "../../util/authentication";
import { getCookieByName, deleteCookie } from "../../util/cookieProvider";
import Box from "../../components/particles/Box";
import LinkBase from "../../components/atoms/LinkBase";
import InputError from "../../components/atoms/InputError";
import Text from "../../components/atoms/Text";
import Button from "../../components/atoms/Button";
import TextInput from "../../components/organisms/TextInput";
import { PageBox, TileContainer } from "./styled";

const Component = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { storeUser, storeNewspaper } = useContext(AppContext);

  const formMethods = useForm({
    mode: "onChange",
    shouldFocusError: true
  });

  const [formError, setFormError] = useState(null);
  const resetFormError = () => setFormError(null);

  const { formState, handleSubmit, setError: setFieldError } = formMethods;
  const { isSubmitting, isValid } = formState;
  const isSubmissionDisabled = !isValid || !!formError || isSubmitting;

  const onSubmitSuccess = useCallback((data) => {
    storeUser(data);
    
    let _newspaper;
    let redirectUrl = getCookieByName("ddb_sign_in_redirect_url");
    deleteCookie("ddb_sign_in_redirect_url");

    if (redirectUrl) {
      // Check if redirect URL has valid newspaper.slug in it.
      const _nSM = redirectUrl.match(/^\/(.+)\//);
      const _newspaperSlug = _nSM ? _nSM[1] : null;
      _newspaper = data.newspapers.find(n => n.slug === _newspaperSlug);

      if (!_newspaperSlug || !_newspaper) {
        _newspaper = data.newspapers[0];
        redirectUrl = `/${_newspaper.slug}/widgets`;
      }
    } else {
      _newspaper = data.newspapers[0];
      redirectUrl = `/${_newspaper.slug}/widgets`;
    }

    storeNewspaper(_newspaper);

    const onSignIn = () => navigate(redirectUrl);

    signIn(
      {
        token: data.jwt_token,
        userEmail: data.email,
        userId: data.id,
        newspaper: _newspaper.slug
      },
      onSignIn
    );
  }, []);

  const onSubmitFail = useCallback((response) => {
    const {
      response: { data }
    } = response;

    if (typeof data.error === "string") setFormError(data.error);
    else if (data.errors instanceof Array) {
      data.errors.forEach((error) => {
        if (error?.source?.pointer) {
          setFieldError(error?.source?.pointer, {
            type: "custom",
            message: error?.message
          });
        } else if (error?.message) {
          setFormError(error?.message);
        }
      });
    }
  }, []);

  const onSubmit = useCallback(
    (data) => {
      const submitData = {
        ad_user: {
          email: data.email,
          password: data.password
        }
      };

      return requestSignIn(submitData)
        .then(onSubmitSuccess)
        .catch(onSubmitFail);
    },
    [onSubmitSuccess, onSubmitFail]
  );

  return (
    <>
      <Helmet>
        <title>
          {t("session.sign_in_title")} | {t("general.product_name_dashboard_general")}
        </title>
      </Helmet>
      <PageBox>
        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            aria-errormessage={formError ? "ddb_sign_in_form_error" : ""}
          >
            <Box
              as="section"
              className="ddb-max-content-width-s"
              paddingT={5}
              paddingB={2}
              marginHAuto
            >
              <Box marginB={2}>
                <Text as="h1" styledAs="h3">
                  {t("session.sign_in", {
                    product: t("general.product_name_dashboard_general")
                  })}
                </Text>
              </Box>
              <Box marginB={2}>
                <TileContainer border={false}>
                  <Box>
                    {!!formError && (
                      <Box marginV={2}>
                        <InputError
                          id="ddb_sign_in_form_error"
                          aria-live="polite"
                          data-cy="form-error"
                        >
                          {`${t(
                            "errors.general_form_error_desc"
                          )}: ${formError}`}
                        </InputError>
                      </Box>
                    )}
                    <Box marginB={2}>
                      <TextInput
                        name="email"
                        type="email"
                        autoComplete="email"
                        label={t("session.email")}
                        onChange={resetFormError}
                        required
                        requiredErrorMessage={t(
                          "errors.required_specific_field",
                          { input: t("session.email_label") }
                        )}
                      />
                    </Box>

                    <Box marginB={2}>
                      <TextInput
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        label={t("session.password")}
                        onChange={resetFormError}
                        required
                        requiredErrorMessage={t(
                          "errors.required_specific_field",
                          { input: t("session.password_label") }
                        )}
                      />
                    </Box>

                    <Box flex alignCenter marginT={4}>
                      <Box flexGrow>
                        <LinkBase to="/request_new_password" styledAs="a">
                          {t("session.reset_password")}
                        </LinkBase>
                      </Box>
                      <Box flexStatic>
                        <Button
                          primary
                          type="submit"
                          disabled={isSubmissionDisabled}
                        >
                          {t("session.sign_in_submit")}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </TileContainer>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </PageBox>
    </>
  );
};

export default Component;
