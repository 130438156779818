import React, { useEffect, VFC } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useParams
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getCookieByName, setCookie } from "./util/cookieProvider";
import AuthRedirect from "./components/_functional/AuthRedirect";
import Dashboard from "./pages/Dashboard";
import Widget from "./pages/Widget";
import AdForm from "./pages/AdForm";
import Connections from "./pages/Connections";
import Analytics from "./pages/Analytics";
import Contacts from "./pages/Contacts";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import RequestNewPassword from "./pages/RequestNewPassword";
import NewPassword from "./pages/NewPassword";
import Error from "./pages/Error";

// Admin from SL will access all the routes with the additional admin_customer_token in them.

const RedirectToMainPage = () => {
  const navigate = useNavigate();

  let newspaperSlug = useParams().newspaperSlug;
  if (newspaperSlug) setCookie("ddb_current_newspaper", newspaperSlug);
  else newspaperSlug = getCookieByName("ddb_current_newspaper");

  const redirectUrl = newspaperSlug ? `/${newspaperSlug}/widgets` : "/sign_in";

  useEffect(() => navigate(redirectUrl), []);
  return <></>;
};

const App3Ph: VFC = () => {
  const { t } = useTranslation();

  return (
    <Router>
      <Routes>
        <Route path="/sign_in" element={<SignIn />} />
        <Route path="/" element={<RedirectToMainPage />} />
        <Route path="/request_new_password" element={<RequestNewPassword />} />
        <Route path="/new_password" element={<NewPassword />} />
        <Route path="/:newspaperSlug/sign_up" element={<SignUp />} />

        <Route path="/:newspaperSlug" element={<RedirectToMainPage />} />
        <Route
          path="/:newspaperSlug/widgets"
          element={
            <AuthRedirect>
              <Dashboard />
            </AuthRedirect>
          }
        />
        <Route
          path="/:newspaperSlug/widgets/:id"
          element={
            <AuthRedirect>
              <Widget />
            </AuthRedirect>
          }
        />
        <Route
          path="/:newspaperSlug/promotions/new"
          element={
            <AuthRedirect>
              <AdForm />
            </AuthRedirect>
          }
        />
        <Route
          path="/:newspaperSlug/widgets/:widgetId/promotions/new"
          element={
            <AuthRedirect>
              <AdForm />
            </AuthRedirect>
          }
        />
        <Route
          path="/:newspaperSlug/widgets/:widgetId/promotions/:id"
          element={
            <AuthRedirect>
              <AdForm />
            </AuthRedirect>
          }
        />
        <Route
          path="/:newspaperSlug/connections"
          element={
            <AuthRedirect>
              <Connections />
            </AuthRedirect>
          }
        />
        <Route
          path="/:newspaperSlug/analytics"
          element={
            <AuthRedirect>
              <Analytics />
            </AuthRedirect>
          }
        />
        <Route
          path="/:newspaperSlug/contacts"
          element={
            <AuthRedirect>
              <Contacts />
            </AuthRedirect>
          }
        />

        <Route
          path="*"
          element={
            <Error
              status="warning"
              icon="warning"
              title={t("errors.generic_title")}
              titleAs="h1"
              text={t("errors.not_found")}
            />
          }
        />
      </Routes>
    </Router>
  );
};

export default App3Ph;
