import React, { VFC } from "react";
import { useTranslation } from "react-i18next";

import { WidgetsItemAd as WidgetAdProps } from "../../../types";
import constants from "../../_globalStyles/constants";
import { truncateText, pxToRem, pxToEm } from "../../../util/helpers";
import Box from "../../particles/Box";
import Text from "../../atoms/Text";
import {
  AdLink,
  AdTile,
  AdLogo,
  AdMedia,
  AdImageLoader,
  AdPlayIcon,
  AdMediaIcon,
  AdContentBox
} from "./styled";

const WidgetAd: VFC<WidgetAdProps> = ({
  link,
  customerName,
  displayLogo,
  logo,
  message,
  description,
  media,
  mediaType,
  titleAs
}) => {
  const { t } = useTranslation();

  return (
    <AdTile as="article" border={false}>
      <AdMedia media={media}>
        {media && (
          <>
            <AdImageLoader />
            {mediaType === "video" && <AdPlayIcon />}
          </>
        )}
        {!media && <AdMediaIcon name="no-image" />}
      </AdMedia>
      <AdContentBox paddingV={1} paddingH={1.25} overflowHidden>
        <Box flex alignCenter marginB={0.5}>
          {displayLogo && (
            <Box inlineFlex flexStatic marginR={1.5}>
              <AdLogo src={logo} alt="" />
            </Box>
          )}
          <Text
            as={titleAs}
            bold
            fontSize={pxToRem(16)}
            lineHeight={pxToEm(19.2, 16)}
            color={constants.COLOR_GRAY_700}
            clip={2}
          >
            {truncateText(customerName, 30)}
          </Text>
        </Box>
        <Text as="p" fontSize={pxToRem(12)} lineHeight="1.3" clip={displayLogo || customerName.length > 20 ? 1 : 2}>
          {truncateText(message || description, 50)}
        </Text>
      </AdContentBox>
      <AdLink href={link || "#"} target="_blank" rel="noopener noreferrer">
        <span className="ddb-visually-hidden">{`Ad: '${truncateText(
          message || description,
          10
        )}' ${t("accessibility.new_tab")}`}</span>
      </AdLink>
    </AdTile>
  );
};

WidgetAd.defaultProps = {
  titleAs: "h4"
};

export default WidgetAd;