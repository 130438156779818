import React, {
  VFC,
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo
} from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import constants from "../../constants";
import { getRequestErrorMessage } from "../../util/request";
import {
  requestWidget,
  requestWidgetPromotions,
  hideWidgetPromotion,
  displayWidgetPromotion
} from "../../util/apiRequests";
import { AppContext } from "../../components/_functional/AppProvider";
import Loader from "../../components/atoms/Loader";
import WidgetComponent from "./Component";

const WidgetContainer: VFC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { id, newspaperSlug } = useParams();
  const [searchParams] = useSearchParams();
  const customerId = searchParams.get("customer_id");
  const customerToken = searchParams.get("admin_customer_token");

  const { newspaper, customer, storeModal, resetModal, resetPopup } =
    useContext(AppContext);

  const { isCommunity } = newspaper;

  const [widget, setWidget] = useState(null);
  const [widgetLoaded, setWidgetLoaded] = useState(false);
  const [activeAds, setActiveAds] = useState([]);
  const [inactiveAds, setInactiveAds] = useState([]);
  const [activeAdsLoaded, setActiveAdsLoaded] = useState(false);
  const [inactiveAdsLoaded, setInactiveAdsLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState("inactive");

  const [paginationPage, setPaginationPage] = useState(0);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const [altMessage, setAltMessage] = useState(null);

  const filteringEnabled = customer?.filteringEnabled;
  const filteringHashtag = newspaper?.filteringHashtag;

  const doGetData = customerToken || (newspaperSlug && customerId);

  const getWidget = useCallback(() => {
    return requestWidget(id, newspaperSlug, customerId)
      .then((_widget) => {
        setWidget(_widget);
        setWidgetLoaded(true);
      })
      .catch((response) =>
        storeModal({
          title: t("errors.generic_title"),
          text: getRequestErrorMessage(response, t),
          onClose: resetModal
        })
      );
  }, [id, newspaperSlug, customerId]);

  const getActiveAds = useCallback(() => {
    return requestWidgetPromotions(
      id,
      {
        status: "active"
      },
      newspaperSlug,
      customerId
    )
      .then(({ data }) => {
        const _ads = data.data.map((ad) => ({
          id: ad.id,
          link: ad.link,
          type:
            ad.source_type === "business_instagram"
              ? "instagram"
              : ad.source_type,
          description: ad.description,
          message: ad.message,
          categories:
            ad.promotion_environment_categories?.map(
              (pec) => pec.widget_category.data.name
            ) || [],
          media: ad.small_image?.url || ad.image?.url,
          mediaType: ad.small_image?.media_type || ad.image?.media_type,
          views: ad.analytics?.views,
          clicks: ad.analytics?.clicks,
          publishedAt: ad.published_at,
          status: "active"
        }));

        setActiveAds(_ads);
        setActiveAdsLoaded(true);
      })
      .catch((response) =>
        storeModal({
          title: t("errors.generic_title"),
          text: getRequestErrorMessage(response, t),
          onClose: resetModal
        })
      );
  }, [id, newspaperSlug, customerId]);

  const getInactiveAds = useCallback(
    (_tab, _page) => {
      return requestWidgetPromotions(
        id,
        {
          status: _tab,
          limit: constants.ADS_PER_PAGE,
          offset: _page * constants.ADS_PER_PAGE
        },
        newspaperSlug,
        customerId
      )
        .then(({ data }) => {
          let _ads = data.data.map((ad) => ({
            id: ad.id,
            link: ad.link,
            type:
              ad.source_type === "business_instagram"
                ? "instagram"
                : ad.source_type,
            description: ad.description,
            message: ad.message,
            categories:
              ad.promotion_environment_categories?.map(
                (pec) => pec.widget_category.data.name
              ) || [],
            media: ad.small_image?.url || ad.image?.url,
            mediaType: ad.small_image?.media_type || ad.image?.media_type,
            views: ad.analytics?.views,
            clicks: ad.analytics?.clicks,
            publishedAt: ad.published_at,
            status: _tab
          }));

          setShowLoadMoreButton(_ads.length === constants.ADS_PER_PAGE);

          if (_page > 0) {
            _ads = [...inactiveAds, ..._ads];
          }

          setInactiveAds(_ads);
          setInactiveAdsLoaded(true);
        })
        .catch((response) => {
          storeModal({
            title: t("errors.generic_title"),
            text: getRequestErrorMessage(response, t),
            onClose: resetModal
          });
          setInactiveAdsLoaded(true);
        });
    },
    [id, inactiveAds, newspaperSlug, customerId]
  );

  const getAds = useCallback(
    (_page) => {
      getActiveAds();
      getInactiveAds(activeTab, _page);
    },
    [getActiveAds, getInactiveAds, activeTab]
  );

  const loadMoreInactiveAds = useCallback(() => {
    const _page = paginationPage + 1;
    setPaginationPage(_page);
    getInactiveAds(activeTab, _page);
  }, [activeTab, paginationPage, getInactiveAds]);

  const onTabClick = useCallback(
    (_tab) => {
      setInactiveAdsLoaded(false);
      setActiveTab(_tab);
      const _page = 0;
      setPaginationPage(_page);
      getInactiveAds(_tab, _page);
    },
    [getInactiveAds]
  );

  const inactiveAdsTabs = useMemo(() => {
    const _tabs = [
      {
        id: "ddb_inactive_ads_tab",
        title: isCommunity
          ? t("general.widget.inactive_ads_tab_lo_com")
          : t("general.widget.inactive_ads_tab"),
        active: activeTab === "inactive",
        onClick: () => onTabClick("inactive"),
      },
      {
        id: "ddb_paused_ads_tab",
        title: isCommunity
          ? t("general.widget.paused_ads_tab_lo_com")
          : t("general.widget.paused_ads_tab"),
        active: activeTab === "paused",
        onClick: () => onTabClick("paused")
      },
      {
        id: "ddb_hidden_ads_tab",
        title: isCommunity
          ? t("general.widget.hidden_ads_tab_lo_com")
          : t("general.widget.hidden_ads_tab"),
        active: activeTab === "hidden",
        onClick: () => onTabClick("hidden")
      },
      {
        id: "ddb_expired_ads_tab",
        title: isCommunity
          ? t("general.widget.expired_ads_tab_lo_com")
          : t("general.widget.expired_ads_tab"),
        active: activeTab === "expired",
        onClick: () => onTabClick("expired")
      }
    ];

    return _tabs;
  }, [isCommunity, activeTab, onTabClick]);

  const editAd = useCallback(
    (adId) => navigate(`promotions/${adId}?${searchParams.toString()}`),
    []
  );

  const playAd = useCallback(
    (widgetId, adId) =>
      displayWidgetPromotion(widgetId, adId, newspaperSlug, customerId).then(
        () => {
          setAltMessage(
            isCommunity
              ? t("general.ad.ad_activated_lo_com")
              : t("general.ad.ad_activated")
          );
          const _page = 0;
          setPaginationPage(_page);
          getAds(_page);
          getWidget();
        }
      ),
    [getAds, getWidget, newspaperSlug, customerId]
  );

  const pauseAd = useCallback(
    (widgetId, adId) =>
      hideWidgetPromotion(widgetId, adId, newspaperSlug, customerId).then(
        () => {
          setAltMessage(
            isCommunity
              ? t("general.ad.ad_paused_lo_com")
              : t("general.ad.ad_paused")
          );
          const _page = 0;
          setPaginationPage(_page);
          getAds(_page);
          getWidget();
        }
      ),
    [getAds, getWidget, newspaperSlug, customerId]
  );

  useEffect(() => {
    if (doGetData || !widget) getWidget();
  }, [doGetData]);

  useEffect(() => {
    if (doGetData) getAds(0);
  }, [doGetData]);

  useEffect(
    () => () => {
      resetModal();
      resetPopup();
      setWidget(null);
      setWidgetLoaded(false);
      setActiveAdsLoaded(false);
      setInactiveAdsLoaded(false);
    },
    []
  );

  const instanceName = newspaper.instanceName || newspaper.name;

  const isLoaded = widgetLoaded && activeAdsLoaded;

  return (
    <>
      <Helmet>
        <title>{`${widget ? widget.name + ": " : ""}${
          isCommunity
            ? t("general.widget.page_title_lo_com")
            : t("general.widget.page_title")
        } | ${instanceName}`}</title>
      </Helmet>

      <Loader active={!isLoaded}>
        <div className="ddb-visually-hidden" aria-live="polite">
          {altMessage}
        </div>

        <WidgetComponent
          {...widget}
          isCommunity={isCommunity}
          filteringEnabled={filteringEnabled}
          filteringHashtag={filteringHashtag}
          activeAds={activeAds}
          inactiveAds={inactiveAds}
          loadMoreAds={loadMoreInactiveAds}
          showLoadMoreButton={showLoadMoreButton}
          editAd={editAd}
          playAd={playAd}
          pauseAd={pauseAd}
          inactiveAdsTabs={inactiveAdsTabs}
          activeTab={activeTab}
          inactiveAdsLoaded={inactiveAdsLoaded}
        />
      </Loader>
    </>
  );
};

export default WidgetContainer;
