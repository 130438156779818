import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import InputError from "../../components/atoms/InputError";

interface RHFErrorInputProps {
  name: string;
  elementId: string;
  label?: string;
}

const RHFInputError: FC<RHFErrorInputProps> = ({ name, elementId, label }) => {
  const {
    formState: { errors }
  } = useFormContext();

  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <InputError id={elementId} aria-live="polite" data-cy="error">
          <span className="ddb-visually-hidden">{label}:</span>
          {message || "This field has an error"}
        </InputError>
      )}
    />
  );
};

export default RHFInputError;
