import { FC } from "react";
import styled from "styled-components";

import constants from "../../_globalStyles/constants";
import Button, { ButtonProps } from "../../atoms/Button";
import Tile, { TileProps } from "../../atoms/Tile";
import LinkBase, { LinkBaseProps } from "../../atoms/LinkBase";

export const CreateAdTile: FC<TileProps> = styled(Tile)`
  position: relative;
  width: 236px;
  height: 316px;
  padding: 0;
  overflow: visible;
`;

CreateAdTile.displayName = "CreateAdTile";

export const CreateAdLink: FC<LinkBaseProps> = styled(LinkBase)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: ${constants.GRID_UNIT * 1.5}px;

  &:hover,
  &:active {
    box-shadow: 0 ${constants.GRID_UNIT / 2}px ${constants.GRID_UNIT * 3}px 0
      rgba(0, 0, 0, 0.1);
  }

  cursor: pointer;
  transition: box-shadow 0.05s ease-in;
`;

CreateAdLink.displayName = "CreateAdLink";

export const CreateAdButton: FC<ButtonProps> = styled(Button)`
  font-size: .875rem;
`;

CreateAdButton.displayName = "CreateAdButton";