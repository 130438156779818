import { FC } from "react";
import styled from "styled-components";

import constants from "../../_globalStyles/constants";
import Box, { BoxProps } from "../../particles/Box";

export const SubheaderWrap: FC<BoxProps> = styled(Box)`
  background: ${constants.SUBHEADER_COLOR};
`;

SubheaderWrap.displayName = "SubheaderWrap";

export const SubheaderContent: FC<BoxProps> = styled(Box)`
  max-width: ${constants.MAX_CONTENT_WIDTH_M};
  margin: 0 auto;
  padding: ${constants.GRID_UNIT * 2}px ${constants.GRID_UNIT * 1.5}px;

  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: ${constants.TEXT_COLOR} !important;
    cursor: pointer;
  }
`;

SubheaderContent.displayName = "SubheaderContent";