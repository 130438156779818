import React, { VFC, ReactNode, useContext } from "react";

import { AppContext } from "../../_functional/AppProvider";
import Popup from "../../organisms/Popup";
import PageMessage from "../PageMessage";
import { PageBox } from "./styled";

interface CleanPageLayoutProps {
  children: ReactNode;
}

const CleanPageLayout: VFC<CleanPageLayoutProps> = ({ children }) => {
  const { popup, message } = useContext(AppContext);

  return (
    <PageBox>
      {!message && <>{ children }</>}
      {!!popup && <Popup {...popup} />}
      {!!message && <PageMessage {...message} />}
    </PageBox>
  );
};

export default CleanPageLayout;
