import { FC, HTMLProps } from "react";
import styled from "styled-components";

import constants from "../../_globalStyles/constants";
import { pxToRem } from "../../../util/helpers";
import Icon, { IconProps } from "../../atoms/Icon";
import Button, { ButtonProps } from "../../atoms/Button";

export const InputContainer: FC<HTMLProps<HTMLDivElement>> = styled.div`
  position: relative;
  width: 100%;
`;

InputContainer.displayName = "InputContainer";

export const InputBase: FC<HTMLProps<HTMLInputElement>> = styled.input`
  display: flex;
  margin: 0;
  font-size: ${pxToRem(18)};
  line-height: ${pxToRem(24)};
  font-weight: 400;
  width: 100%;
  border-radius: ${constants.BUTTON_BORDER_RADIUS};
  border: 0;
  box-shadow: 0 0 0 1px ${constants.BORDER_COLOR} inset;
  background: transparent;
  appearance: none;
  
  padding: ${constants.GRID_UNIT * 1.5}px ${constants.GRID_UNIT * 5.75}px
    ${constants.GRID_UNIT * 1.5}px ${constants.GRID_UNIT * 8}px;

  &::placeholder {
    color: rgba(115, 115, 115, 1) !important;
  }
  &::-webkit-input-placeholder {
    color: rgba(115, 115, 115, 1) !important;
  }
  &::-moz-placeholder {
    color: rgba(115, 115, 115, 1) !important;
  }
  &:-moz-placeholder {
    color: rgba(115, 115, 115, 1) !important;
  }
  &:-ms-input-placeholder {
    color: rgba(115, 115, 115, 1) !important;
  }

  &:focus::placeholder {
    color: rgba(115, 115, 115, 1) !important;
  }
  &:focus::-webkit-input-placeholder {
    color: rgba(115, 115, 115, 1) !important;
  }
  &:focus::-moz-placeholder {
    color: rgba(115, 115, 115, 1) !important;
  }
  &:focus:-moz-placeholder {
    color: rgba(115, 115, 115, 1) !important;
  }
  &:focus:-ms-input-placeholder {
    color: rgba(115, 115, 115, 1) !important;
  }

  &:focus {
    outline: none;
  }

  .ddb-keyboard-navigation-on &:focus {
    outline: 3px dotted ${constants.FOCUS_OUTLINE_COLOR};
    outline-offset: 2px;
  }

  &::-webkit-search-cancel-button {
    position: relative;
    right: -${constants.GRID_UNIT * 4.5}px;
    -webkit-appearance: none;
    height: ${constants.GRID_UNIT * 3}px;
    width: ${constants.GRID_UNIT * 3}px;
    background: transparent;
    z-index: 5;
  }

  &,
  &:hover,
  &:-webkit-autofill,
  &:-webkit-autofill:hover {
    box-shadow: 0 0 0 1px ${constants.BORDER_COLOR}, 0 0 0 500px white inset !important;
  }

  &:focus,
  &:active,
  &:-webkit-autofill:active,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0 1px ${constants.COLOR_BLUE_350}, 0 0 0 500px white inset !important;
  }

  &:not(:placeholder-shown),
  &:not(:placeholder-shown):-webkit-autofill {
    box-shadow: 0 0 0 1px ${constants.BORDER_COLOR}, 0 0 0 500px white inset !important;
    -webkit-text-fill-color: ${constants.TEXT_COLOR};
  }
`;

InputBase.displayName = "InputBase";

export const SearchButton: FC<ButtonProps> = styled(Button)`
  position: absolute;
  top: ${constants.GRID_UNIT * 1.625}px;
  left: ${constants.GRID_UNIT * 3.75}px;
  padding: ${constants.GRID_UNIT * 0.25}px;
  border: 0;
  box-shadow: none;

  &,
  &:hover,
  &:active,
  &:focus {
    background: transparent !important;
  }
`;

SearchButton.displayName = "SearchButton";

export const SearchIcon: FC<IconProps> = styled(Icon)`
  font-size: ${constants.GRID_UNIT * 2.5}px;
  color: ${constants.TEXT_COLOR} !important;
  z-index: 1;
`;

SearchIcon.displayName = "SearchIcon";

export const ResetIcon: FC<IconProps> = styled(Icon)`
  position: absolute;
  top: ${constants.GRID_UNIT * 1.875}px;
  right: ${constants.GRID_UNIT * 2}px;
  font-size: ${constants.GRID_UNIT * 2.25}px;
  color: ${constants.COLOR_GRAY_350} !important;
  z-index: 1;
`;

ResetIcon.displayName = "ResetIcon";
