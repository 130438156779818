import React, {
  useMemo,
  useCallback,
  useState,
  useRef,
  useEffect,
  VFC
} from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import constants from "../../constants";
import { isEqArr } from "../../util/helpers";
import { AnalyticsAd, WidgetsItem } from "../../types";
import Box from "../../components/particles/Box";
import Transition from "../../components/particles/Transition";
import SearchInput from "../../components/organisms/SearchInput";
import MultiDropdown from "../../components/organisms/MultiDropdown";
import DateRangeInput from "../../components/organisms/DateRangeInput";
import Pagination from "../../components/organisms/Pagination";
import AnalyticsDetails from "../../components/templates/AnalyticsDetails";
import AnalyticsTable from "../../components/templates/AnalyticsTable";

type Params = {
  page: string;
  per: string;
  sortBy: string;
  viewsOrder: string;
  clicksOrder: string;
  ratioOrder: string;
  dateOrder: string;
  from: string;
  to: string;
  envIds: string[];
  envType: string;
  text: string;
};

interface AnalyticsComponentProps {
  isCommunity: boolean;
  views: number;
  clicks: number;
  adsCount: number;
  ads: AnalyticsAd[];
  activePage: number;
  pagesNumber: number;
  widgets: WidgetsItem[];
  params: Params;
  searchParams: any;
  setSearchParams: (params: string) => void;
  locale: string;
}

const AnalyticsComponent: VFC<AnalyticsComponentProps> = ({
  isCommunity,
  views,
  clicks,
  adsCount,
  ads,
  activePage,
  pagesNumber,
  widgets,
  params,
  searchParams,
  setSearchParams,
  locale
}) => {
  const { t } = useTranslation();

  const paginationRef = useRef<HTMLDivElement>(null);
  const [paginationSize, setPaginationSize] = useState("small");
  const breakpointM = 960;
  const breakpointS = 560;

  useEffect(() => {
    let _paginationSize;
    if (paginationRef.current?.offsetWidth <= breakpointS) {
      _paginationSize = "small";
    } else if (paginationRef.current?.offsetWidth <= breakpointM) {
      _paginationSize = "medium";
    } else {
      _paginationSize = "big";
    }

    setPaginationSize(_paginationSize);
  }, [paginationRef]);

  const widgetsOptions = useMemo(() => {
    const options = widgets.map((w) => ({
      label: w.name,
      value: w.envId.toString(),
      disabled: false
    }));

    if (!options.length) {
      options.unshift({
        label: t("general.analytics.no_widget_to_select"),
        value: "",
        disabled: true
      });
    }
    else if (options.length > 1) {
      options.unshift({
        label: t("general.analytics.widgets_filter_all_option"),
        value: "all",
        disabled: false
      });
    }

    return options;
  }, [widgets]);

  const onWidgetSelect = useCallback(
    (_values) => {
      const values = _values.reduce(
        (acc, curr) => (curr !== "all" ? acc.concat(curr) : acc),
        []
      );

      const isNewVal = !isEqArr(values, params.envIds);

      if (isNewVal) {
        searchParams.delete("env[ids]");

        if (!values.length) {
          searchParams.delete("env[type]");
        } else {
          values.forEach((value) => searchParams.append("env[ids]", value));
          searchParams.set("env[type]", "Widget");
        }

        searchParams.set("page", "1");

        setSearchParams(searchParams);
      }
    },
    [params, searchParams]
  );

  const onDateSelect = useCallback(
    (_value) => {
      if (_value.startDate && _value.endDate) {
        const from = format(_value.startDate, "yyyy-MM-dd");
        const to = format(_value.endDate, "yyyy-MM-dd");
        searchParams.set("from", from);
        searchParams.set("to", to);
      } else if (!_value.startDate && !_value.endDate) {
        searchParams.delete("from");
        searchParams.delete("to");
      }

      searchParams.set("page", "1");
      setSearchParams(searchParams);
    },
    [searchParams, params]
  );

  const toggleViewsSorting = useCallback(() => {
    searchParams.set("page", "1");
    searchParams.set("sort_by", "views");
    searchParams.set(
      "views_order",
      params.viewsOrder === "desc" ? "asc" : "desc"
    );
    searchParams.delete("clicks_order");
    searchParams.delete("ratio_order");
    searchParams.delete("date_order");

    setSearchParams(searchParams);
  }, [searchParams, params, setSearchParams]);

  const toggleClicksSorting = useCallback(() => {
    searchParams.set("page", "1");
    searchParams.set("sort_by", "clicks");
    searchParams.set(
      "clicks_order",
      params.clicksOrder === "desc" ? "asc" : "desc"
    );
    searchParams.delete("views_order");
    searchParams.delete("ratio_order");
    searchParams.delete("date_order");
    setSearchParams(searchParams);
  }, [searchParams, params, setSearchParams]);

  const toggleRatioSorting = useCallback(() => {
    searchParams.set("page", "1");
    searchParams.set("sort_by", "ratio");
    searchParams.set(
      "ratio_order",
      params.ratioOrder === "desc" ? "asc" : "desc"
    );
    searchParams.delete("views_order");
    searchParams.delete("clicks_order");
    searchParams.delete("date_order");
    setSearchParams(searchParams);
  }, [searchParams, params, setSearchParams]);

  const toggleDateSorting = useCallback(() => {
    searchParams.set("page", "1");
    searchParams.set("sort_by", "date");
    searchParams.set(
      "date_order",
      params.dateOrder === "desc" ? "asc" : "desc"
    );
    searchParams.delete("clicks_order");
    searchParams.delete("ratio_order");
    searchParams.delete("views_order");
    setSearchParams(searchParams);
  }, [searchParams, params, setSearchParams]);

  const onPageNavigate = useCallback(
    (page) => {
      searchParams.set("page", page);
      searchParams.set("per", constants.ANALYTICS_ADS_PER_PAGE);
      setSearchParams(searchParams);
    },
    [searchParams]
  );

  const maxDate = new Date();

  const selectedDate = useMemo(() => {
    if (params.from && params.to)
      return {
        startDate: new Date(params.from),
        endDate: new Date(params.to)
      };
  }, [params]);

  // When clicking on 'search icon' button submit search value.
  const submitSearch = useCallback(
    (_value) => {
      searchParams.set("page", "1");
      searchParams.set("text", _value);
      setSearchParams(searchParams);
    },
    [searchParams]
  );

  // When typing in search field submit value every 1s, unless it's reset.
  const searchChangeTimeout = useRef(null);

  const onSearchChange = useCallback(
    (_value) => {
      clearTimeout(searchChangeTimeout?.current);
      if (!_value) submitSearch(_value);
      else {
        searchChangeTimeout.current = setTimeout(() => {
          submitSearch(_value);
        }, 1000);
      }
    },
    [submitSearch]
  );

  return (
    <Transition>
      <Box
        as="main"
        id="ddb_main_content"
        className="ddb-max-content-width"
        marginT={3}
        paddingH={2}
      >
        <div ref={paginationRef}>
          <Box className="ddb-desktop">
            <Box marginB={2} flex>
              <Box inlineFlex flexGrow width="40%" paddingR={2}>
                <AnalyticsDetails
                  isCommunity={isCommunity}
                  views={views}
                  clicks={clicks}
                  adsCount={adsCount}
                />
              </Box>
              <Box flexGrow width="60%" flex vertical>
                <Box marginB={1.5} flex alignCenter>
                  <Box width="50%" inlineFlex flexStatic>
                    <MultiDropdown
                      id="analytics_widgets"
                      name="widgets"
                      label={t("general.analytics.widgets_filter_label")}
                      selectionLabel={t(
                        "general.analytics.widgets_filter_selection_label"
                      )}
                      icon="widget"
                      options={widgetsOptions}
                      selectedOptions={params.envIds}
                      onSelect={onWidgetSelect}
                    />
                  </Box>
                  <Box width="50%" marginL={1} inlineFlex flexDynamic>
                    <SearchInput
                      id="analytics_search"
                      name="text"
                      placeholder={isCommunity ? t("general.analytics.search_lo_com") : t("general.analytics.search")}
                      defaultValue={params.text}
                      onChange={onSearchChange}
                      onSearch={submitSearch}
                    />
                  </Box>
                </Box>
                <Box width="50%" flexStatic>
                  <DateRangeInput
                    id="analytics_date"
                    name="date"
                    label={t("general.analytics.date_filter_label")}
                    onChange={onDateSelect}
                    initialValue={selectedDate}
                    maxDate={maxDate}
                    locale={locale}
                  />
                </Box>
              </Box>
            </Box>
            <AnalyticsTable
              isCommunity={isCommunity}
              ads={ads}
              toggleViewsSorting={toggleViewsSorting}
              toggleClicksSorting={toggleClicksSorting}
              toggleRatioSorting={toggleRatioSorting}
              toggleDateSorting={toggleDateSorting}
              viewsOrder={params.viewsOrder}
              clicksOrder={params.clicksOrder}
              ratioOrder={params.ratioOrder}
              dateOrder={params.dateOrder}
              locale={locale}
            />
            <Box textCenter marginV={2}>
              <Pagination
                activePage={activePage}
                pagesNumber={pagesNumber}
                size={paginationSize}
                navigate={onPageNavigate}
              />
            </Box>
          </Box>
          <Box className="ddb-desktop-s ddb-mobile-l">
            <Box marginB={2} flex>
              <Box inlineFlex flexGrow width="40%" paddingR={2}>
                <AnalyticsDetails
                  isCommunity={isCommunity}
                  views={views}
                  clicks={clicks}
                  adsCount={adsCount}
                />
              </Box>
              <Box flexGrow width="60%" flex vertical>
                <Box marginB={1.5} width="100%">
                  <MultiDropdown
                    id="analytics_widgets_mobile_l"
                    name="widgets"
                    label={t("general.analytics.widgets_filter_label")}
                    selectionLabel={t(
                      "general.analytics.widgets_filter_selection_label"
                    )}
                    icon="widget"
                    options={widgetsOptions}
                    selectedOptions={params.envIds}
                    onSelect={onWidgetSelect}
                  />
                </Box>
                <Box marginB={1.5} width="100%">
                  <SearchInput
                    id="analytics_search_mobile_l"
                    name="text"
                    placeholder={isCommunity ? t("general.analytics.search_lo_com") : t("general.analytics.search")}
                    defaultValue={params.text}
                    onChange={onSearchChange}
                    onSearch={submitSearch}
                  />
                </Box>
                <Box width="100%">
                  <DateRangeInput
                    id="analytics_date_mobile_l"
                    name="date"
                    label={t("general.analytics.date_filter_label")}
                    onChange={onDateSelect}
                    initialValue={selectedDate}
                    maxDate={maxDate}
                    locale={locale}
                  />
                </Box>
              </Box>
            </Box>
            <AnalyticsTable
              isCommunity={isCommunity}
              ads={ads}
              toggleViewsSorting={toggleViewsSorting}
              toggleClicksSorting={toggleClicksSorting}
              toggleDateSorting={toggleDateSorting}
              toggleRatioSorting={toggleRatioSorting}
              viewsOrder={params.viewsOrder}
              clicksOrder={params.clicksOrder}
              ratioOrder={params.ratioOrder}
              dateOrder={params.dateOrder}
              locale={locale}
            />
            <Box textCenter marginV={2}>
              <Pagination
                activePage={activePage}
                pagesNumber={pagesNumber}
                size={paginationSize}
                navigate={onPageNavigate}
              />
            </Box>
          </Box>
          <Box className="ddb-mobile">
            <Box marginB={2}>
              <AnalyticsDetails
                isCommunity={isCommunity}
                views={views}
                clicks={clicks}
                adsCount={adsCount}
              />
            </Box>
            <Box marginB={2} flex vertical>
              <Box marginB={1}>
                <MultiDropdown
                  id="analytics_widgets_mobile"
                  name="widgets"
                  label={t("general.analytics.widgets_filter_label")}
                  selectionLabel={t(
                    "general.analytics.widgets_filter_selection_label"
                  )}
                  icon="widget"
                  options={widgetsOptions}
                  selectedOptions={params.envIds}
                  onSelect={onWidgetSelect}
                />
              </Box>
              <Box marginB={1}>
                <SearchInput
                  id="analytics_search_mobile"
                  name="text"
                  placeholder={isCommunity ? t("general.analytics.search_lo_com") : t("general.analytics.search")}
                  defaultValue={params.text}
                  onChange={onSearchChange}
                  onSearch={submitSearch}
                />
              </Box>
              <Box>
                <DateRangeInput
                  id="analytics_date_mobile"
                  name="date"
                  label={t("general.analytics.date_filter_label")}
                  onChange={onDateSelect}
                  initialValue={selectedDate}
                  maxDate={maxDate}
                  locale={locale}
                />
              </Box>
            </Box>
            <AnalyticsTable
              isCommunity={isCommunity}
              ads={ads}
              toggleViewsSorting={toggleViewsSorting}
              toggleClicksSorting={toggleClicksSorting}
              toggleRatioSorting={toggleRatioSorting}
              toggleDateSorting={toggleDateSorting}
              viewsOrder={params.viewsOrder}
              ratioOrder={params.ratioOrder}
              clicksOrder={params.clicksOrder}
              dateOrder={params.dateOrder}
              locale={locale}
            />
            <Box textCenter marginV={2}>
              <Pagination
                activePage={activePage}
                pagesNumber={pagesNumber}
                size={paginationSize}
                navigate={onPageNavigate}
              />
            </Box>
          </Box>
        </div>
      </Box>
    </Transition>
  );
};

export default AnalyticsComponent;
