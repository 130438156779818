import React, {
  VFC,
  FormEvent,
  useState,
  useCallback,
  useEffect,
  useMemo
} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { valueExists } from "../../../util/helpers";
import RHFInputError from "../../_decorators/withRHFError";
import InputAsterisk from "../../atoms/InputAsterisk";
import InputLabel from "../../atoms/InputLabel";
import InputBase from "../../atoms/InputBase";
import { InputContainer } from "./styled";

interface TextInputProps {
  id?: string;
  name: string;
  label: string;
  placeholder?: string;
  type?: string;
  required?: boolean;
  pattern?: object | string;
  disabled?: boolean;
  readOnly?: boolean;
  defaultValue?: string | number;
  onChange?: (e: FormEvent<HTMLInputElement>) => void;
  onFocus?: (e: FormEvent<HTMLInputElement>) => void;
  onBlur?: (e: FormEvent<HTMLInputElement>) => void;
  validate?: () => void | object;
  validation?: any;
  requiredErrorMessage?: string;
  autoComplete?: string;
}

const TextInput: VFC<TextInputProps> = ({
  id: _id,
  name,
  label,
  placeholder,
  type,
  required,
  pattern,
  readOnly,
  disabled,
  onChange: _onChange,
  onFocus: _onFocus,
  onBlur: _onBlur,
  defaultValue,
  validate,
  validation: _validation = {},
  requiredErrorMessage,
  autoComplete
}) => {
  const { t } = useTranslation();

  const id = _id || name;
  const errorId = `${id}_error`;

  const { register, formState, getFieldState, control } = useFormContext();

  const value = useWatch({ name, control, defaultValue });
  const { error } = getFieldState(name, formState);

  const [isValue, setIsValue] = useState(valueExists(value));

  const validation = useMemo(() => {
    if (validate) _validation.validate = validate;
    if (required) _validation.required = requiredErrorMessage || t("errors.required");
    if (pattern) _validation.pattern = pattern;
    return _validation;
  }, [_validation, validate, required, pattern, requiredErrorMessage]);

  const RHFInputProps = register(name, validation);

  const onChange = useCallback(
    (_value) => {
      RHFInputProps.onChange(_value);
      _onChange && _onChange(_value);
    },
    [RHFInputProps, _onChange]
  );

  const onFocus = useCallback((e) => _onFocus && _onFocus(e), [_onFocus]);

  const onBlur = useCallback(
    (e) => {
      RHFInputProps.onBlur(e);
      _onBlur && _onBlur(e);
    },
    [_onBlur]
  );

  useEffect(() => setIsValue(valueExists(value)), []);

  useEffect(() => setIsValue(valueExists(value)), [value]);

  return (
    <>
      <InputContainer>
        <InputBase
          id={id}
          data-cy="input"
          type={type}
          placeholder={placeholder}
          readOnly={readOnly}
          disabled={disabled}
          error={!!error}
          defaultValue={defaultValue}
          autoComplete={autoComplete}
          {...RHFInputProps}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          aria-invalid={!!error}
          aria-required={required}
          aria-errormessage={error ? errorId : ""}
        />
        {label && (
          <InputLabel data-cy="label" htmlFor={id} floating floated={isValue}>
            {label}
            {required && <InputAsterisk />}
          </InputLabel>
        )}
      </InputContainer>

      <RHFInputError name={name} elementId={errorId} label={label} />
    </>
  );
};

TextInput.defaultProps = {
  type: "text"
}

export default TextInput;
