import { FC, HTMLProps } from "react";
import styled from "styled-components";

import constants from "../../_globalStyles/constants";
import { pxToRem } from "../../../util/helpers";

interface TabProps extends HTMLProps<HTMLLIElement> {
  warning?: boolean;
  active?: boolean;
  accent?: boolean;
}

export const Tab: FC<TabProps> = styled.li`
  display: inline-block;

  button {
    border: none;
    box-shadow: 0;
  }

  a,
  button {
    font-size: ${pxToRem(20)};
    line-height: ${pxToRem(24)};
    font-weight: 700;
    color: ${constants.TAB_TEXT_COLOR};
    white-space: nowrap;
    transition: color 0.05s ease-in;
    position: relative;
    padding: ${pxToRem(8)} ${pxToRem(16)};
    margin: 0 ${pxToRem(16)} 0 -${pxToRem(16)};
    border-radius: ${constants.BUTTON_BORDER_RADIUS};
    background: transparent;

    .ddb-keyboard-navigation-on &:focus {
      outline-offset: -4px;
    }
  }

  &:last-child a,
  &:last-child button {
    margin-right: 0;
  }

  a:before,
  button:before {
    content: "";
    position: absolute;
    left: ${pxToRem(16)};
    right: ${pxToRem(16)};
    bottom: -${pxToRem(8 + 3)};
    height: 3px;
    background: ${constants.TEXT_COLOR};
    opacity: 0;
    transition: opacity 0.05s ease-in;
  }

  a:after,
  button:after {
    content: "";
    position: absolute;
    top: 0;
    right: ${pxToRem(8)};
    width: ${pxToRem(8)};
    height: ${pxToRem(8)};
    margin-left: 2px;
    border-radius: 50%;
    opacity: 0;
  }

  a:hover,
  button:hover {
    cursor: pointer;
  }

  ${({ active }) =>
    active
      ? `
      a,
      button {
        color: ${constants.TEXT_COLOR};
      }
      a:before,
      button:before {
        opacity: 1;
      }
    `
      : `
      a:hover,
      a:focus,
      button:hover,
      button:focus {
        color: ${constants.TEXT_COLOR};
        background: ${constants.BUTTON_SECONDARY_HOVER_COLOR};
      }
    `}

  ${({ accent }) =>
    accent && `
      a:after,
      button:after {
        opacity: 1; 
        background: ${constants.PRIMARY_COLOR};
      }
    `}
    
  ${({ warning }) =>
    warning && `
      a:after,
      button:after {
        opacity: 1; 
        background: ${constants.ERROR_COLOR};
      }
    `}
`;

Tab.displayName = "Tab";
