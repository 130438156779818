import React, { FC, ReactNode, useContext, useEffect } from "react";

import { usePrevious } from "../../util/hooks";
import { initFBSDK } from "../../util/facebookAPIHelpers";
import { AppContext } from "../../components/_functional/AppProvider";
import Loader from "../../components/atoms/Loader";

interface FBInitProps {
  children: ReactNode;
}

const FBInit: FC<FBInitProps> = ({ children }) => {
  const { FBAppId, FBAppStatus, storeFBAppStatus } = useContext(AppContext);
  const prevFBAppId = usePrevious(FBAppId);

  useEffect(() => {
    if (FBAppId && FBAppStatus === "unset") {
      storeFBAppStatus("initializing");

      return initFBSDK(FBAppId).then(() => storeFBAppStatus("initialized"));
    }
  }, [FBAppId, prevFBAppId]);

  const isLoaded = FBAppStatus === "initialized";

  return <Loader active={!isLoaded}>{children}</Loader>;
};

export default FBInit;
