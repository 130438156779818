import React, { FC, ReactNode, useContext, useCallback } from "react";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useMatch,
  Navigate
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { AppContext } from "./AppProvider";
import Transition from "../particles/Transition";
import Box from "../particles/Box";
import PageMessage from "../templates/PageMessage";

interface CheckSuspensionProps {
  children: ReactNode;
}

const CheckSuspension: FC<CheckSuspensionProps> = ({ children }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { newspaperSlug } = useParams();
  const [searchParams] = useSearchParams();
  const searchString = searchParams.toString();

  const { newspaper, customer, contacts } = useContext(AppContext);

  const isCustomerSuspended = customer.suspended;
  const isWidgetPage = useMatch("/:newspaperSlug/widgets/:id");
  const isNewWidgetAdPage = useMatch(
    "/:newspaperSlug/widgets/:widgetId/promotions/new"
  );
  const isEditWidgetAdPage = useMatch(
    "/:newspaperSlug/widgets/:widgetId/promotions/:id"
  );
  const isNewAdPage = useMatch("/:newspaperSlug/promotions/new");
  const redirectToWidgets =
    isWidgetPage || isNewWidgetAdPage || isEditWidgetAdPage || isNewAdPage;
  const isContactsEnabled = Boolean(contacts?.text?.length);
  const instanceName = newspaper.instanceName || newspaper.name;

  const ctaAction = useCallback(
    () => navigate(`/${newspaperSlug}/contacts?${searchString}`),
    [navigate, newspaperSlug, searchParams]
  );

  return isCustomerSuspended ? (
    <>
      {redirectToWidgets ? (
        <Navigate to={`/${newspaperSlug}/widgets?${searchString}`} replace />
      ) : (
        <>
          <Helmet>
            <title>{`${customer ? customer.name + ": " : ""}${t(
              "general.dashboard.page_title"
            )} | ${instanceName}`}</title>
          </Helmet>
          <Transition>
            <Box
              as="main"
              id="ddb_main_content"
              className="ddb-max-content-width"
              paddingH={2}
            >
              <Box marginT={3} marginH={1}>
                {isContactsEnabled ? (
                  <PageMessage
                    icon="suspension"
                    title={t("errors.customer_suspended_title")}
                    text={t("errors.customer_suspended_body")}
                    ctaText={t("errors.customer_suspended_cta")}
                    ctaAction={ctaAction}
                  />
                ) : (
                  <PageMessage
                    icon="suspension"
                    title={t("errors.customer_suspended_title")}
                    text={t("errors.customer_suspended_body")}
                  />
                )}
              </Box>
            </Box>
          </Transition>
        </>
      )}
    </>
  ) : (
    <>{children}</>
  );
};

export default CheckSuspension;
