import { FC } from "react";
import styled, { css } from "styled-components";

import constants from "../../_globalStyles/constants";
import Box, { BoxProps } from "../../particles/Box";

export interface TileProps extends BoxProps {
  radius?: number;
  border?: boolean;
  inverted?: boolean;
  resetPadding?: boolean;
}

const Tile: FC<TileProps> = styled(Box)`
  border-radius: ${({ radius }) => `${radius * constants.GRID_UNIT}px`};
  background: ${({ inverted }) =>
    inverted ? constants.INVERTED_TILE_COLOR : constants.MAIN_BG_COLOR};
  ${({ border }) =>
    border &&
    css`
      box-shadow: inset 0 0 0 1px ${constants.BORDER_COLOR};
    `};
  padding: ${({ resetPadding }) => resetPadding ? 0 : `${2 * constants.GRID_UNIT}px`};
`;

Tile.displayName = "Tile";

Tile.defaultProps = {
  radius: 1.5,
  border: true,
  inverted: false,
  resetPadding: false,
  overflowHidden: true
};

export default Tile;
