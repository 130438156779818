import React, {
  useEffect,
  useContext,
  useCallback,
  useState,
  useRef,
  VFC
} from "react";
import { Helmet } from "react-helmet";
import { useParams, useSearchParams, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import constants from "../../constants";
import { isEqArr } from "../../util/helpers";
import { getWidgets } from "../../util/hooks";
import { requestAnalytics } from "../../util/apiRequests";
import { getRequestErrorMessage } from "../../util/request";
import { AppContext } from "../../components/_functional/AppProvider";
import Loader from "../../components/atoms/Loader";
import AnalyticsComponent from "./Component";

const AnalyticsContainer: VFC = () => {
  const { t } = useTranslation();

  const { newspaperSlug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchString = searchParams.toString();

  const customerToken = searchParams.get("admin_customer_token");
  const customerId = searchParams.get("customer_id");
  const page = searchParams.get("page");
  const per = searchParams.get("per");
  const sortBy = searchParams.get("sort_by");
  const viewsOrder = searchParams.get("views_order");
  const clicksOrder = searchParams.get("clicks_order");
  const ratioOrder = searchParams.get("ratio_order");
  const dateOrder = searchParams.get("date_order");
  const from = searchParams.get("from");
  const to = searchParams.get("to");
  const envIds = searchParams.getAll("env[ids]");
  const envType =
    envIds && envIds.length ? searchParams.get("env[type]") : null;
  const text = searchParams.get("text");

  const [params, setParams] = useState({
    page,
    per,
    sortBy,
    viewsOrder,
    clicksOrder,
    ratioOrder,
    dateOrder,
    from,
    to,
    envIds,
    envType,
    text
  });

  useEffect(() => {
    // @ts-ignore
    if (searchParams.size <= 1) { // If there is no search params beside customer_id (which should always be)
      searchParams.set("page", "1");
      searchParams.set("per", constants.ANALYTICS_ADS_PER_PAGE);
      searchParams.set("sort_by", constants.ANALYTICS_SORT_BY);
      searchParams.set("views_order", constants.ANALYTICS_SORT_ORDER);
      searchParams.set("from", constants.ANALYTICS_INITIAL_START_DATE);
      searchParams.set("to", constants.ANALYTICS_INITIAL_END_DATE);

      setSearchParams(searchParams);
    }
  }, [setSearchParams]);

  useEffect(() => {
    if (!isEqArr(envIds, params.envIds)) {
      setParams({ ...params, envIds, envType, page });
    }
  }, [envIds, envType, page, params]);

  useEffect(() => {
    if (page !== params.page) {
      setParams({ ...params, page });
    }
  }, [page, params]);

  useEffect(() => {
    if (per !== params.per) {
      setParams({ ...params, per, page });
    }
  }, [per, page, params]);

  useEffect(() => {
    if (
      sortBy !== params.sortBy ||
      viewsOrder !== params.viewsOrder ||
      clicksOrder !== params.clicksOrder ||
      ratioOrder !== params.ratioOrder ||
      dateOrder !== params.dateOrder
    ) {
      setParams({
        ...params,
        viewsOrder,
        clicksOrder,
        ratioOrder,
        dateOrder,
        sortBy,
        page
      });
    }
  }, [sortBy, viewsOrder, clicksOrder, ratioOrder, dateOrder, page, params]);

  useEffect(() => {
    if (from !== params.from || to !== params.to) {
      setParams({ ...params, from, to, page });
    }
  }, [from, to, page, params]);

  useEffect(() => {
    if (text !== params.text) {
      setParams({ ...params, text, page });
    }
  }, [text, page, params]);

  const [analytics, setAnalytics] = useState(null);
  const [analyticsLoaded, setAnalyticsLoaded] = useState(false);

  const { newspaper, customer, widgets, storeModal, resetModal } =
    useContext(AppContext);

  const { isCommunity } = newspaper
  const isAnalyticsEnabled = customer.analyticsEnabled;

  const instanceName = newspaper.instanceName || newspaper.name;
  const locale = newspaper.locale;

  const doGetData = customerToken || (newspaperSlug && customerId);

  const analyticsRequestTimeout = useRef(null);

  const getAnalytics = useCallback(() => {
    clearTimeout(analyticsRequestTimeout?.current);

    analyticsRequestTimeout.current = setTimeout(() => {
      return requestAnalytics(params, newspaperSlug, customerId)
        .then((analytics) => {
          setAnalytics(analytics);
          setAnalyticsLoaded(true);
        })
        .catch((response) =>
          storeModal({
            title: t("errors.generic_title"),
            text: getRequestErrorMessage(response, t),
            onClose: resetModal
          })
        );
    }, 500);
  }, [params, newspaperSlug, customerId, analyticsRequestTimeout]);

  getWidgets();

  useEffect(() => {
    if (doGetData) getAnalytics();
  }, [doGetData, getAnalytics]);

  useEffect(
    () => () => {
      resetModal();
      setAnalytics(null);
      setAnalyticsLoaded(false);
    },
    []
  );

  const isLoaded = analyticsLoaded && Boolean(widgets) && Boolean(customer);

  return (
    <>
      <Helmet>
        <title>{`${t(
          "general.analytics.page_title"
        )} | ${instanceName}`}</title>
      </Helmet>
      <Loader active={!isLoaded}>
        {isAnalyticsEnabled ? (
          <AnalyticsComponent
            isCommunity={isCommunity}
            views={analytics?.summary?.views}
            clicks={analytics?.summary?.clicks}
            adsCount={analytics?.summary?.ads}
            ads={analytics?.ads}
            activePage={analytics?.pagination?.current}
            pagesNumber={analytics?.pagination?.total}
            widgets={widgets}
            params={params}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            locale={locale}
          />
        ) : (
          <Navigate to={`/${newspaperSlug}/widgets?${searchString}`} />
        )}
      </Loader>
    </>
  );
};

export default AnalyticsContainer;
