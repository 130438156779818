import { FC, HTMLProps } from "react";
import styled, { css } from "styled-components";

import constants from "../../_globalStyles/constants";
import { pxToRem } from "../../../util/helpers";
import Icon, { IconProps } from "../../atoms/Icon";
import Tile, { TileProps } from "../../atoms/Tile";

export const AnalyticsTile: FC<TileProps> = styled(Tile)`
  padding: 0 0 0 ${constants.GRID_UNIT * 6}px;

  @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
    padding: 0 0 0 ${constants.GRID_UNIT * 2}px;
    overflow: scroll;
  }
`;

AnalyticsTile.displayName = "AnalyticsTile";

export const Table: FC = styled.table`
  width: 100%;
  border-spacing: 0;
  text-align: right;
`;

Table.displayName = "Table";

export const TableHead: FC = styled.thead`
  border: 1px solid gray;
`;

TableHead.displayName = "TableHead";

export const TableBody: FC = styled.tbody`
  padding: ${constants.GRID_UNIT * 2}px 0;
`;

TableBody.displayName = "TableBody";

export const TR: FC = styled.tr``;

TR.displayName = "TR";

export const TH: FC = styled.th`
  position: relative;
  border-bottom: 1px solid ${constants.BORDER_COLOR};
  padding: ${constants.GRID_UNIT * 2}px;
  width: 15%;
  min-width: ${constants.GRID_UNIT * 25}px;
  max-width: ${constants.GRID_UNIT * 27.5}px;

  &:first-child {
    padding: ${constants.GRID_UNIT * 2}px 0;
    width: 20%;
    text-align: left;
  }

  @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
    font-size: ${pxToRem(14)};
  }

  @media (max-width: ${constants.BREAKPOINT_DESKTOP_S}px) {
    &:first-child {
      width: 15%;
    }
  }
`;

TH.displayName = "TH";

interface TDProps {
  index: number;
  itemsLength: number;
}

export const TD: FC<TDProps> = styled.td`
  padding: ${constants.GRID_UNIT * 2}px;

  ${({ index }) =>
    index === 0 &&
    css`
      padding-top: ${constants.GRID_UNIT * 4}px;
    `}

  ${({ index, itemsLength }) =>
    index === itemsLength - 1 &&
    css`
      padding-bottom: ${constants.GRID_UNIT * 4}px;
    `}

  width: 15%;
  min-width: ${constants.GRID_UNIT * 25}px;
  max-width: ${constants.GRID_UNIT * 27.5}px;
  vertical-align: top;

  &:first-child {
    padding: ${constants.GRID_UNIT * 2}px 0;
    width: 20%;
    text-align: left;
  }
`;

TD.displayName = "TD";

export const SortingButton: FC<HTMLProps<HTMLButtonElement>> = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: 0;
  box-shadow: none;
  background: transparent;
  color: inherit;
  margin: 0;
  padding: 0 ${constants.GRID_UNIT * 2}px;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    color: ${constants.COLOR_BLUE_350};
  }

  &:active,
  &:focus {
    color: ${constants.COLOR_BLUE_325};
  }

  transition: color 0.05s ease-in;

  svg {
    flex: 0 0 auto;
  }
`;

SortingButton.displayName = "SortingButton";

interface SortingIconProps extends IconProps {
  order: string;
}

export const SortingIcon: FC<SortingIconProps> = styled(Icon)`
  ${({ order }) =>
    order === "asc"
      ? css`
          transform: rotate(180deg);
        `
      : ""}
`;

SortingIcon.displayName = "SortingIcon";
