import React, { FC, useState, ReactNode } from "react";

import { pxToRem } from "../../../util/helpers";
import Box from "../../particles/Box";
import Tile from "../../atoms/Tile";
import Separator from "../../atoms/Separator";
import Text from "../../atoms/Text";
import { SectionTileButton } from "./styled";

interface SectionProps {
  title?: string;
  titleElement?: ReactNode;
  titleAs?: string;
  expandable?: boolean;
  isOpen?: boolean;
  overflowHidden? : boolean;
}

const Section: FC<SectionProps> = ({
  title,
  titleElement,
  titleAs,
  children,
  expandable,
  isOpen: _isOpen,
  overflowHidden
}) => {
  const [isOpen, setIsOpen] = useState(_isOpen);

  return (
    <>
      {expandable ? (
        <>
          <Tile as="section" border={false} resetPadding>
            <SectionTileButton type="button" data-cy="section-toggle" onClick={() => setIsOpen(!isOpen)}>
              {titleElement && titleElement}
              {title && (
                <Text as="span" styledAs="h6">
                  {title}
                </Text>
              )}
            </SectionTileButton>
            <Text as={titleAs} className="ddb-visually-hidden">
              {titleElement && titleElement}
              {title}
            </Text>
            {isOpen && (
              <>
                <Separator />
                <Box paddingH={2} paddingB={2} paddingT={pxToRem(16)} data-cy="section-body">
                  {children}
                </Box>
              </>
            )}
          </Tile>
        </>
      ) : (
        <Tile as="section" border={false} resetPadding overflowHidden={overflowHidden}>
          <Box paddingH={2} paddingV={2}>
            {titleElement && titleElement}
            {title && (
              <Text as={titleAs} styledAs="h6">
                {title}
              </Text>
            )}
          </Box>
          <Separator />
          <Box paddingH={2} paddingV={2}>
            {children}
          </Box>
        </Tile>
      )}
    </>
  );
};

Section.defaultProps = {
  titleAs: "h2",
  isOpen: true
};

export default Section;
