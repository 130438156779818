import { VFC } from "react";
import styled, { css } from "styled-components";

import constants from "../../_globalStyles/constants";
import Box, { BoxProps } from "../../particles/Box";

interface SeparatorProps extends BoxProps {
  horizontal?: boolean;
}

const Separator: VFC<SeparatorProps> = styled(Box).attrs(({ horizontal }) => ({
  as: horizontal ? "span" : "hr"
}))`
  border: 0;
  border-bottom: 1px solid ${constants.BORDER_COLOR};

  ${({ horizontal }) =>
    horizontal
      ? css`
          display: inline-block;
          border: 0;
          border-left: 1px solid ${constants.BORDER_COLOR};
          margin: 0 ${constants.GRID_UNIT * 2}px;
        `
      : css`
          margin: 0;
          height: 0;
        `}
`;

Separator.displayName = "Separator";

Separator.defaultProps = {
  horizontal: false
};

export default Separator;
