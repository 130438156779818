import React, { VFC } from "react";
import { useTranslation } from "react-i18next";

import { truncateText } from "../../../util/helpers";
import Box from "../../particles/Box";
import Text from "../../atoms/Text";
import { AnalyticsAd as _AnalyticsAdProps } from "../../../types";
import { AdMedia, AdMediaPlaceholder, AdMediaIcon, AdLink, AdText } from "./styled";

interface AnalyticsAdProps extends _AnalyticsAdProps {
  titleAs?: string;
}

const AnalyticsAd: VFC<AnalyticsAdProps> = ({
  link,
  media,
  message,
  description,
  type,
  titleAs
}) => {
  const { t } = useTranslation();

  return (
    <Box flex relative>
      <Box flexStatic marginR={2}>
        {media && <AdMedia src={media} alt="" />}
        {!media && (
          <AdMediaPlaceholder>
            <AdMediaIcon name="no-image" />
          </AdMediaPlaceholder>
        )}
      </Box>
      <Box>
        <AdText as={titleAs} styledAs="h6">
          {truncateText(message || description, 50)}
        </AdText>
        {type !== "local_offers" && (
          <Text details smallest>
            {type}
          </Text>
        )}
      </Box>
      <AdLink href={link} target="_blank" rel="noopener noreferrer">
        <span className="ddb-visually-hidden">{`${truncateText(
          message || description,
          10
        )} ${t("accessibility.new_tab")}`}</span>
      </AdLink>
    </Box>
  );
};

AnalyticsAd.defaultProps = {
  titleAs: "h3"
};

export default AnalyticsAd;
