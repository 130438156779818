const UNI_BREAKPOINT_DESKTOP_L = 1440;
const UNI_BREAKPOINT_DESKTOP_M = 1280;
const UNI_BREAKPOINT_DESKTOP_S = 768;
const UNI_BREAKPOINT_MOBILE = 480;
const UNI_BREAKPOINT_MOBILE_S = 375;
const UNI_BREAKPOINT_MOBILE_XS = 320;
const UNI_BREAKPOINT_MOBILE_XXS = 280;
const UNI_BREAKPOINT_HEADER_SMALL = UNI_BREAKPOINT_DESKTOP_S;

const UNI_HEADER_CONTENT_MAX_WIDTH = "1280px";
const UNI_HEADER_HEIGHT = "56px";
const UNI_HEADER_HEIGHT_SMALL = "48px";

const UNI_HEADER_LOGO_MAX_HEIGHT = "32px";
const UNI_HEADER_LOGO_MAX_WIDTH = "240px";
const UNI_HEADER_LOGO_MAX_HEIGHT_MOBILE = "32px";
const UNI_HEADER_LOGO_MAX_WIDTH_MOBILE = "32px";

const UNI_FONT_FAMILY = "'Roboto', Arial, sans-serif";
const UNI_FONT_WEIGHT_700 = 700;
const UNI_FONT_WEIGHT_500 = 500;

const UNI_LIGHT_BG_COLOR = "#FFFFFF";
const UNI_TEXT_COLOR = "#000000";
const UNI_INVERTED_TEXT_COLOR = "#FFFFFF";
const UNI_TEXT_COLOR_GRAY_375 = "#737373";
const UNI_TEXT_COLOR_GRAY_400 = "#666666";
const UNI_TEXT_COLOR_GRAY_450 = "#4D4D4D";
const UNI_CTA_TEXT_COLOR = "#008A00";
const UNI_CTA_HOVER_TEXT_COLOR = "#008000";
const UNI_CTA_FOCUS_TEXT_COLOR = "#008000";
const UNI_CTA_ACTIVE_TEXT_COLOR = "#007500";
const UNI_DEFAULT_BTN_BG_COLOR = "rgba(0, 0, 0, 0.08)";
const UNI_DEFAULT_HOVER_BTN_BG_COLOR = "rgba(0, 0, 0, 0.12)";
const UNI_DEFAULT_FOCUS_BTN_BG_COLOR = "rgba(0, 0, 0, 0.12)";
const UNI_DEFAULT_ACTIVE_BTN_BG_COLOR = "rgba(0, 0, 0, 0.12)";
const UNI_INVERTED_DEFAULT_BTN_BG_COLOR = "rgba(255, 255, 255, 0.2)";
const UNI_INVERTED_DEFAULT_HOVER_BTN_BG_COLOR = "rgba(255, 255, 255, 0.3)";
const UNI_INVERTED_DEFAULT_FOCUS_BTN_BG_COLOR = "rgba(255, 255, 255, 0.3)";
const UNI_INVERTED_DEFAULT_ACTIVE_BTN_BG_COLOR = "rgba(0, 0, 0, 0.2)";
const UNI_TEXT_BTN_BG_COLOR = "transparent";
const UNI_TEXT_HOVER_BTN_BG_COLOR = "rgba(0, 0, 0, 0.04)";
const UNI_TEXT_FOCUS_BTN_BG_COLOR = "transparent";
const UNI_TEXT_ACTIVE_BTN_BG_COLOR = "rgba(0, 0, 0, 0.12)";
const UNI_INVERTED_TEXT_BTN_BG_COLOR = "transparent";
const UNI_INVERTED_TEXT_HOVER_BTN_BG_COLOR = "rgba(0, 0, 0, 0.08)";
const UNI_INVERTED_TEXT_FOCUS_BTN_BG_COLOR = "rgba(0, 0, 0, 0.08)";
const UNI_INVERTED_TEXT_ACTIVE_BTN_BG_COLOR = "rgba(0, 0, 0, 0.2)";
const UNI_CTA_BTN_BG_COLOR = "#008A00";
const UNI_CTA_HOVER_BTN_BG_COLOR = "#008000";
const UNI_CTA_FOCUS_BTN_BG_COLOR = "#008000";
const UNI_CTA_ACTIVE_BTN_BG_COLOR = "#007500";
const UNI_INVERTED_CTA_BTN_BG_COLOR = "#FFFFFF";
const UNI_INVERTED_CTA_HOVER_BTN_BG_COLOR = UNI_INVERTED_CTA_BTN_BG_COLOR;
const UNI_INVERTED_CTA_FOCUS_BTN_BG_COLOR = UNI_INVERTED_CTA_BTN_BG_COLOR;
const UNI_INVERTED_CTA_ACTIVE_BTN_BG_COLOR = UNI_INVERTED_CTA_BTN_BG_COLOR;
const UNI_BORDER_COLOR = "rgba(0, 0, 0, 0.08)";
const UNI_MENU_HOVER_ITEM_BG_COLOR = "rgba(0, 0, 0, 0.04)";
const UNI_SKIP_NAVIGATION_COLOR = "#FFFFFF";
const UNI_SKIP_NAVIGATION_BG_COLOR = "#1450C7";
const UNI_COLOR_FOCUS_OUTLINE = "rgba(0, 0, 0, 0.8)";

const UNI_BTN_HEIGHT = "32px";
const UNI_ICON_SIZE = "24px";
const UNI_ICON_THUMB_SIZE = "40px";
const UNI_ICON_THUMB_SIZE_SMALL = "32px";

const UNI_BORDER_RADIUS_L = "24px";
const UNI_BORDER_RADIUS_M = "16px";
const UNI_BORDER_RADIUS_S = "8px";

const constants = {
  UNI_BREAKPOINT_DESKTOP_L,
  UNI_BREAKPOINT_DESKTOP_M,
  UNI_BREAKPOINT_DESKTOP_S,
  UNI_BREAKPOINT_MOBILE,
  UNI_BREAKPOINT_MOBILE_S,
  UNI_BREAKPOINT_MOBILE_XS,
  UNI_BREAKPOINT_MOBILE_XXS,
  UNI_BREAKPOINT_HEADER_SMALL,
  UNI_HEADER_CONTENT_MAX_WIDTH,
  UNI_HEADER_HEIGHT,
  UNI_HEADER_HEIGHT_SMALL,
  UNI_HEADER_LOGO_MAX_HEIGHT,
  UNI_HEADER_LOGO_MAX_WIDTH,
  UNI_HEADER_LOGO_MAX_HEIGHT_MOBILE,
  UNI_HEADER_LOGO_MAX_WIDTH_MOBILE,
  UNI_FONT_FAMILY,
  UNI_FONT_WEIGHT_700,
  UNI_FONT_WEIGHT_500,
  UNI_LIGHT_BG_COLOR,
  UNI_TEXT_COLOR,
  UNI_INVERTED_TEXT_COLOR,
  UNI_TEXT_COLOR_GRAY_375,
  UNI_TEXT_COLOR_GRAY_400,
  UNI_TEXT_COLOR_GRAY_450,
  UNI_CTA_TEXT_COLOR,
  UNI_CTA_HOVER_TEXT_COLOR,
  UNI_CTA_FOCUS_TEXT_COLOR,
  UNI_CTA_ACTIVE_TEXT_COLOR,
  UNI_DEFAULT_BTN_BG_COLOR,
  UNI_DEFAULT_HOVER_BTN_BG_COLOR,
  UNI_DEFAULT_FOCUS_BTN_BG_COLOR,
  UNI_DEFAULT_ACTIVE_BTN_BG_COLOR,
  UNI_INVERTED_DEFAULT_BTN_BG_COLOR,
  UNI_INVERTED_DEFAULT_HOVER_BTN_BG_COLOR,
  UNI_INVERTED_DEFAULT_FOCUS_BTN_BG_COLOR,
  UNI_INVERTED_DEFAULT_ACTIVE_BTN_BG_COLOR,
  UNI_TEXT_BTN_BG_COLOR,
  UNI_TEXT_HOVER_BTN_BG_COLOR,
  UNI_TEXT_FOCUS_BTN_BG_COLOR,
  UNI_TEXT_ACTIVE_BTN_BG_COLOR,
  UNI_INVERTED_TEXT_BTN_BG_COLOR,
  UNI_INVERTED_TEXT_HOVER_BTN_BG_COLOR,
  UNI_INVERTED_TEXT_FOCUS_BTN_BG_COLOR,
  UNI_INVERTED_TEXT_ACTIVE_BTN_BG_COLOR,
  UNI_CTA_BTN_BG_COLOR,
  UNI_CTA_HOVER_BTN_BG_COLOR,
  UNI_CTA_FOCUS_BTN_BG_COLOR,
  UNI_CTA_ACTIVE_BTN_BG_COLOR,
  UNI_INVERTED_CTA_BTN_BG_COLOR,
  UNI_INVERTED_CTA_HOVER_BTN_BG_COLOR,
  UNI_INVERTED_CTA_FOCUS_BTN_BG_COLOR,
  UNI_INVERTED_CTA_ACTIVE_BTN_BG_COLOR,
  UNI_BORDER_COLOR,
  UNI_MENU_HOVER_ITEM_BG_COLOR,
  UNI_SKIP_NAVIGATION_COLOR,
  UNI_SKIP_NAVIGATION_BG_COLOR,
  UNI_COLOR_FOCUS_OUTLINE,
  UNI_BTN_HEIGHT,
  UNI_ICON_SIZE,
  UNI_ICON_THUMB_SIZE,
  UNI_ICON_THUMB_SIZE_SMALL,
  UNI_BORDER_RADIUS_L,
  UNI_BORDER_RADIUS_M,
  UNI_BORDER_RADIUS_S
};

export default constants;
