import React, { FC, ReactNode, useContext } from "react";

import { getUser } from "../../util/hooks";
import { AppContext } from "./AppProvider";
import Loader from "../atoms/Loader";

interface GetUserProps {
  children: ReactNode;
}

const GetUser: FC<GetUserProps> = ({ children }) => {
  getUser();

  const { user } = useContext(AppContext);

  return <Loader active={!user}>{children}</Loader>;
};

export default GetUser;
