import React, { VFC } from "react";

import constants from "../../_globalStyles/constants";
import Box, { BoxProps } from "../../particles/Box";

import Text from "../../atoms/Text";
import Button from "../../atoms/Button";
import IconBadge from "../../molecules/IconBadge";
import { ErrorTile, ErrorIconBox, ErrorButtonBox } from "./styled";

interface ErrorMessageProps extends BoxProps {
  title: string;
  titleAs?: string;
  text?: string;
  ctaText?: string;
  ctaAction?: () => void;
}

const ErrorMessage: VFC<ErrorMessageProps> = ({
  title,
  titleAs,
  text,
  ctaText,
  ctaAction
}) => (
  <ErrorTile as="section" radius={2} flex>
    <ErrorIconBox marginR={1.5}>
      <IconBadge inverted status="error" />
    </ErrorIconBox>
    <Box>
      <Text as={titleAs} small color={constants.DARK_ERROR_COLOR}>
        {title}
      </Text>
      {text && (
        <Text as="p" small formatted>
          {text}
        </Text>
      )}
      {ctaText && ctaAction && (
        <ErrorButtonBox>
          <Button primary onClick={ctaAction}>
            {ctaText}
          </Button>
        </ErrorButtonBox>
      )}
    </Box>
  </ErrorTile>
);

ErrorMessage.defaultProps = {
  titleAs: "h4"
};

export default ErrorMessage;
