import React, {
  VFC,
  ReactNode,
  FormEvent,
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo
} from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import RHFInputError from "../../_decorators/withRHFError";
import InputAsterisk from "../../atoms/InputAsterisk";
import InputLabel from "../../atoms/InputLabel";
import TextareaBase from "../../atoms/TextareaBase";
import NoticeMessage from "../NoticeMessage";
import { TextareaWrap, TooltipWrap } from "./styled";

interface TextareaProps {
  id?: string;
  name: string;
  label: string;
  hiddenLabel?: boolean;
  required?: boolean;
  maxLength?: number;
  readOnly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  defaultValue?: string;
  onChange?: (value: FormEvent<HTMLInputElement>) => void;
  validate?: any;
  validation?: any;
  tooltipMessage?: {
    title: string;
    titleAs?: string;
    text?: string;
    textElement?: ReactNode;
  };
}

const Textarea: VFC<TextareaProps> = ({
  id: _id,
  name,
  label,
  hiddenLabel,
  required,
  maxLength,
  readOnly,
  disabled,
  placeholder,
  defaultValue,
  onChange: _onChange,
  validate,
  validation: _validation = {},
  tooltipMessage
}) => {
  const { t } = useTranslation();

  const id = _id || name;
  const errorId = `${id}_error`;

  const [isTooltipActive, setIsTooltipActive] = useState(false);

  const showTooltip = useCallback(() => setIsTooltipActive(true), []);
  const hideTooltip = useCallback(() => setIsTooltipActive(false), []);

  const { register, formState, getFieldState, control } = useFormContext();

  const value = useWatch({ name, control, defaultValue });
  const { error } = getFieldState(name, formState);

  const validation = useMemo(() => {
    if (validate) _validation.validate = validate;
    if (required) _validation.required = t("errors.required");
    if (maxLength) _validation.maxLength = {
      value: maxLength,
      message: t("errors.max_length", { number: maxLength })
    }

    return _validation;
  }, [_validation, validate, required]);

  const RHFInputProps = register(name, validation);

  const onChange = useCallback(
    (_value) => {
      RHFInputProps.onChange(_value);
      _onChange && _onChange(_value);
    },
    [RHFInputProps, _onChange]
  );

  // Textarea resize.
  const textareaRef = useRef(null);

  const ref = useCallback(
    (_ref) => {
      textareaRef.current = _ref;
      RHFInputProps.ref(_ref);
    },
    [textareaRef, RHFInputProps]
  );

  let resizeTimeout;
  const initWidth = textareaRef?.current?.offsetWidth;
  const [width, setWidth] = useState(initWidth);
  const [isResized, setIsResized] = useState(false);

  const resize = useCallback(() => {
    if (!textareaRef.current) return;

    setIsResized(false);
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
    setIsResized(true);
  }, [textareaRef]);

  const onWindowResize = useCallback(() => {
    clearTimeout(resizeTimeout);

    resizeTimeout = setTimeout(() => {
      const newWidth = textareaRef?.current?.offsetWidth;

      if (newWidth !== width) {
        resize();
        setWidth(newWidth);
      }
    }, 100);
  }, [resizeTimeout, textareaRef, width, resize]);

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    return () => window.removeEventListener("resize", onWindowResize);
  }, [onWindowResize]);

  useEffect(() => {
    resize();
  }, [value]);

  useEffect(() => {
    if (!isResized) resize();
  }, []);

  const onTextareaWrapFocus = useCallback(() => showTooltip(), [showTooltip]);
  const onTextareaWrapBlur = useCallback((e) => !e.currentTarget.contains(e.relatedTarget) && hideTooltip(), [hideTooltip])

  const textareaWrapProps = tooltipMessage ? {
    onFocus: onTextareaWrapFocus,
    onBlur: onTextareaWrapBlur
  } : {};

  return (
    <TextareaWrap {...textareaWrapProps}>
      {hiddenLabel ? (
        <label data-cy="label" htmlFor={id} className="ddb-visually-hidden">
          {label}
        </label>
      ) : (
        <InputLabel data-cy="label" htmlFor={id}>
          {label}
          {required && <InputAsterisk />}
        </InputLabel>
      )}

      <TextareaBase
        id={id}
        data-cy="textarea"
        readOnly={readOnly}
        disabled={disabled}
        placeholder={placeholder}
        error={!!error}
        defaultValue={defaultValue}
        {...RHFInputProps}
        ref={ref}
        onChange={onChange}
        aria-required={required}
        aria-invalid={!!error}
        aria-errormessage={error ? errorId : ""}
      />

      {tooltipMessage && (
        <TooltipWrap id={`ddb_${id}_tooltip`} role="tooltip" active={isTooltipActive}>
          <NoticeMessage {...tooltipMessage} />
        </TooltipWrap>
      )}

      <RHFInputError name={name} elementId={errorId} label={label} />
    </TextareaWrap>
  );
};

export default Textarea;
