import { VFC } from "react";
import styled from "styled-components";

import constants from "../_globalStyles/constants";
import Box, { BoxProps } from "../particles/Box";

const InputAsterisk: VFC<BoxProps> = styled(Box).attrs({ as: "sup" })`
  display: inline-block;
  margin-left: 2px;
  font-size: 14px;
  line-height: 1.1;
  vertical-align: text-top;
  color: ${constants.ERROR_COLOR};
  &:after {
    content: "*";
  }
`;

InputAsterisk.displayName = "InputAsterisk";

export default InputAsterisk;
