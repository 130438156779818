import { FC } from "react";
import styled from "styled-components";

import constants from "../../components/_globalStyles/constants";
import unifiedHeaderConstants from "../../components/templates/UnifiedHeader/constants";
import Box, { BoxProps } from "../../components/particles/Box";
import Tile, { TileProps } from "../../components/atoms/Tile";

export const PageBox: FC<BoxProps> = styled(Box)`
  min-height: calc(100vh - ${unifiedHeaderConstants.UNI_HEADER_HEIGHT});
  background: ${constants.COLOR_GRAY_100};
  padding: 0 ${constants.GRID_UNIT}px;

  @media (max-width: ${unifiedHeaderConstants.UNI_BREAKPOINT_HEADER_SMALL}px) {
    min-height: calc(100vh - ${unifiedHeaderConstants.UNI_HEADER_HEIGHT_SMALL});
  }
`;

PageBox.displayName = "PageBox";

export const TileContainer: FC<TileProps> = styled(Tile)`
  padding: ${constants.GRID_UNIT * 7}px ${constants.GRID_UNIT * 11}px ${constants.GRID_UNIT * 4}px;

  @media (max-width: ${constants.MAX_CONTENT_WIDTH_S}) {
    padding: ${constants.GRID_UNIT * 7}px ${constants.GRID_UNIT * 2}px ${constants.GRID_UNIT * 3}px;
  }
`;

TileContainer.displayName = "TileContainer";
