import React, { VFC } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import Box from "../../components/particles/Box";
import PageMessage, {
  PageMessageProps
} from "../../components/templates/PageMessage";

const Error: VFC<PageMessageProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`${t("errors.generic_page_title")} | ${t(
          "general.product_name_dashboard_general"
        )}`}</title>
      </Helmet>
      <Box paddingT={7} paddingH={1} paddingB={2}>
        <PageMessage {...props} />
      </Box>
    </>
  );
};

export default Error;
