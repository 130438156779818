import React, { VFC, useCallback, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams
} from "react-router-dom";
import { DevTool } from "@hookform/devtools";

import { FormAd, DefaultAdFormValues } from "../../types";
import { usePrevious } from "../../util/hooks";
import request from "../../util/request";
import {
  parsePromotionUrl,
  createPromotion,
  updatePromotion
} from "../../util/apiRequests";
import stylesConstants from "../../components/_globalStyles/constants";
import constants from "../../constants";
import Transition from "../../components/particles/Transition";
import Box from "../../components/particles/Box";
import Tile from "../../components/atoms/Tile";
import Text from "../../components/atoms/Text";
import Button from "../../components/atoms/Button";
import Icon from "../../components/atoms/Icon";
import Separator from "../../components/atoms/Separator";
import InputAsterisk from "../../components/atoms/InputAsterisk";
import InputError from "../../components/atoms/InputError";
import Section from "../../components/molecules/Section";
import ImageInput from "../../components/organisms/ImageInput";
import TextInput from "../../components/organisms/TextInput";
import Textarea from "../../components/organisms/Textarea";
import CheckboxGroupTile from "../../components/organisms/CheckboxGroupTile";
import CheckboxTile from "../../components/organisms/CheckboxTile";
import UrlSection from "../../components/templates/UrlSection";
import { SubmitButton, Fieldset } from "./styled";
import { composeSubmitData } from "./helpers";

interface AdFormComponentProps {
  isCommunity: boolean;
  isNew: boolean;
  ad?: FormAd;
  widgets: {
    id: number;
    envId: number;
    name: string;
    categories: {
      id: number;
      name: string;
    }[];
  }[];
  defaultValues?: DefaultAdFormValues | any;
  setError: (error: object) => void;
  storePopup: (popup: object) => void;
}

const AdFormComponent: VFC<AdFormComponentProps> = ({
  isCommunity,
  isNew,
  ad,
  widgets,
  defaultValues,
  storePopup
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { state } = useLocation() as any;

  const { newspaperSlug, widgetId } = useParams();
  const [searchParams] = useSearchParams();
  const searchString = searchParams.toString();
  const customerId = searchParams.get("customer_id");

  const prevIsNew = usePrevious(isNew);

  const closeUrl = widgetId
    ? `/${newspaperSlug}/widgets/${widgetId}?${searchString}`
    : `/${newspaperSlug}/widgets?${searchString}`;

  const onClosePage = useCallback(() => {
    if (state?.fromDashboard) navigate(-1);
    else navigate(closeUrl);
  }, [navigate, state, closeUrl]);

  const formMethods = useForm({
    mode: "all",
    defaultValues,
    shouldFocusError: true,
    shouldUnregister: true
  });

  const {
    formState,
    handleSubmit,
    getFieldState,
    getValues,
    setValue,
    setError: setFormError,
    trigger,
    control,
    unregister,
    resetField
  } = formMethods;
  const { isSubmitting, isValid, errors } = formState;
  const isSubmissionDisabled = !isValid || isSubmitting;

  useEffect(() => {
    if (
      typeof isNew === "boolean" &&
      typeof prevIsNew === "boolean" &&
      isNew !== prevIsNew &&
      isNew
    ) {
      resetField("parse_url", {
        defaultValue: "",
        keepError: false,
        keepDirty: false,
        keepTouched: false
      });
      unregister("parse_url");
      resetField("image", {
        defaultValue: "",
        keepError: false,
        keepDirty: false,
        keepTouched: false
      });
      unregister("image");
      resetField("description", {
        defaultValue: "",
        keepError: false,
        keepDirty: false,
        keepTouched: false
      });
      unregister("description");
      resetField("link", {
        defaultValue: "",
        keepError: false,
        keepDirty: false,
        keepTouched: false
      });
      unregister("link");
      resetField("customer_promotion_environments", {
        defaultValue: {},
        keepError: false,
        keepDirty: false,
        keepTouched: false
      });
      unregister("customer_promotion_environments");
      resetField("promotion_environment_categories", {
        defaultValue: {},
        keepError: false,
        keepDirty: false,
        keepTouched: false
      });
      unregister("promotion_environment_categories");
      resetField("customer_promotion_environments_all", {
        defaultValue: false,
        keepError: false,
        keepDirty: false,
        keepTouched: false
      });
      unregister("customer_promotion_environments_all");
    }
  }, [prevIsNew, isNew, resetField, unregister]);

  const showSelectAllButton = useMemo(
    () => widgets?.length > 1 || !!widgets[0]?.categories?.length,
    [widgets]
  );

  const onUrlSubmit = useCallback(
    (url: string) =>
      parsePromotionUrl(url, newspaperSlug, customerId)
        .then(({ body, image, url }) => {
          setValue("description", body);
          trigger("description");
          setValue("link", url);

          return request
            .get(image, { responseType: "blob" })
            .then((response) => {
              const file = new File([response.data], "image.png", {
                type: response.data.type
              });
              setValue("image", file);
            })
            .catch(() => {
              setFormError(
                "image",
                {
                  type: "parse",
                  message: t("errors.invalid_parse_image")
                },
                {
                  shouldFocus: true
                }
              );
            });
        })
        .catch(() =>
          setFormError(
            "parse_url",
            {
              type: "parse",
              message: t("errors.invalid_parse_url")
            },
            {
              shouldFocus: true
            }
          )
        ),
    [setValue, setFormError, newspaperSlug, customerId]
  );

  const onAllCPECheck = useCallback(
    ({ target: { checked } }) => {
      widgets?.forEach((widget) => {
        setValue(`customer_promotion_environments[${widget.id}]`, checked);

        widget.categories?.forEach((category) => {
          setValue(`promotion_environment_categories[${category.id}]`, checked);
        });
      });

      trigger(["customer_promotion_environments"]);
    },
    [widgets, setValue, trigger]
  );

  const updateSelectAllValue = useCallback(
    (_checked: boolean) => {
      if (!_checked) setValue("customer_promotion_environments_all", false);
      else {
        const _values = getValues();

        const isAllChecked =
          !Object.values(_values.customer_promotion_environments).includes(
            false
          ) &&
          !Object.values(_values.promotion_environment_categories).includes(
            false
          );

        setValue("customer_promotion_environments_all", isAllChecked);
      }
    },
    [setValue, getValues]
  );

  const updateCounterpartPECValue = useCallback(
    (_checked: boolean, _value: string) => {
      if (!_checked) {
        widgets.forEach((widget) => {
          if (widget.id.toString() === _value) {
            widget.categories?.forEach((category) => {
              setValue(
                `promotion_environment_categories[${category.id}]`,
                false
              );
            });
          }
        });
      }
    },
    [widgets, setValue]
  );

  const updateCounterpartCPEValue = useCallback(
    (_checked: boolean, _value: string) => {
      if (_checked) {
        widgets.forEach((widget) => {
          if (
            widget.categories?.some(
              (category) => category.id.toString() === _value
            )
          ) {
            setValue(`customer_promotion_environments[${widget.id}]`, true);
          }
        });

        trigger(["customer_promotion_environments"]);
      }
    },
    [widgets, setValue]
  );

  const onCPEChange = useCallback(
    ({ target: { checked, value } }) => {
      if (checked) trigger(["customer_promotion_environments"]);

      updateCounterpartPECValue(checked, value);
      updateSelectAllValue(checked);
    },
    [trigger, updateCounterpartPECValue, updateSelectAllValue]
  );

  const onPECChange = useCallback(
    ({ target: { checked, value } }) => {
      updateCounterpartCPEValue(checked, value);
      updateSelectAllValue(checked);
    },
    [updateCounterpartCPEValue, updateSelectAllValue]
  );

  const validateCPE = useCallback(() => {
    const _values = getValues();
    const isAnyChecked = Object.values(
      _values.customer_promotion_environments
    ).some((val) => val);

    return isAnyChecked;
  }, [getValues]);

  const onSubmitFail = useCallback((response) => {
    const {
      response: {
        data: { errors: _errors }
      }
    } = response;

    if (!_errors) return;

    _errors.forEach((_error) => {
      setFormError(_error?.source?.pointer, {
        type: "custom",
        message: _error?.message
      });
    });
  }, []);

  const onSubmit = useCallback(
    (data) => {
      const submitData = composeSubmitData(data, widgets, ad, isNew);

      if (isNew) {
        return createPromotion(submitData, newspaperSlug, customerId)
          .then(() => {
            storePopup({
              title: isCommunity ? t("messages.ad_created_title_lo_com") : t("messages.ad_created_title"),
              text: isCommunity ? t("messages.ad_created_body_lo_com") : t("messages.ad_created_body")
            });
            navigate(closeUrl);
          })
          .catch(onSubmitFail);
      }

      return updatePromotion(ad.id, submitData, newspaperSlug, customerId)
        .then(() => {
          storePopup({
            title: isCommunity ? t("messages.ad_updated_title_lo_com") : t("messages.ad_updated_title"),
            text: isCommunity ? t("messages.ad_updated_body_lo_com") : t("messages.ad_updated_body")
          });
          navigate(closeUrl);
        })
        .catch(onSubmitFail);
    },
    [widgets, isNew, closeUrl, onSubmitFail, ad, newspaperSlug, customerId]
  );

  const isAdFetched = useMemo(
    () => !isNew && ad?.type !== "local_offers",
    [isNew, ad]
  );

  const readOnlyNoticeMessage = useMemo(
    () =>
      isAdFetched
        ? {
            title: isCommunity ? t("messages.manual_mode_readonly.title_lo_com") : t("messages.manual_mode_readonly.title"),
            textElement: (
              <Text
                as="p"
                small
                dangerouslySetInnerHTML={{
                  __html: isCommunity ? t("messages.manual_mode_readonly.text_lo_com").replace(
                    "%page%",
                    `<a href=${
                      ad.source_url
                    } target="_blank" rel="noopener noreferrer">${t(
                      "messages.manual_mode_readonly.page"
                    )}</a>`
                  ) : t("messages.manual_mode_readonly.text").replace(
                    "%page%",
                    `<a href=${
                      ad.source_url
                    } target="_blank" rel="noopener noreferrer">${t(
                      "messages.manual_mode_readonly.page"
                    )}</a>`
                  )
                }}
              />
            )
          }
        : null,
    [isAdFetched, ad]
  );

  return (
    <Transition>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            as="section"
            className="ddb-max-content-width-xs"
            paddingT={2}
            paddingB={10}
            marginHAuto
          >
            <Box flex alignCenter marginB={2}>
              <Box flexGrow>
                <Text as="h1" styledAs="h3">
                  {isNew
                    ? (isCommunity ? t("general.ad_form.create_title_lo_com") : t("general.ad_form.create_title"))
                    : (isCommunity ? t("general.ad_form.edit_title_lo_com") : t("general.ad_form.edit_title"))}
                </Text>
              </Box>
              <Box flexStatic marginL={2}>
                <Button
                  onClick={onClosePage}
                  dark
                  small
                  label={t("general.ad_form.close_btn")}
                >
                  <Icon name="close" />
                </Button>
              </Box>
            </Box>

            {isNew && (
              <Box marginB={2}>
                <UrlSection
                  title={isCommunity ? t("general.ad_form.url_section.title_lo_com") : t("general.ad_form.url_section.title")}
                  inputName="parse_url"
                  inputLabel={t("general.ad_form.labels.url")}
                  submitText={t("general.ad_form.url_section.btn")}
                  onSubmit={onUrlSubmit}
                  getFieldState={getFieldState}
                  control={control}
                />
              </Box>
            )}

            <Box marginB={2}>
              <Tile resetPadding border={false}>
                <ImageInput
                  name="image"
                  required={!isAdFetched}
                  size={constants.AD_IMAGE_MAX_SIZE}
                  format={constants.AD_IMAGE_FORMATS}
                  defaultValue={defaultValues?.image}
                  label={isCommunity ? t("general.ad_form.labels.image_lo_com") : t("general.ad_form.labels.image")}
                  recommendation={t("general.ad_form.image_recommendation")}
                  readOnly={isAdFetched}
                  tooltipMessage={readOnlyNoticeMessage}
                />
                <Separator />

                <Box paddingV={2} paddingH={2}>
                  <Box marginB={2}>
                    <Textarea
                      name="description"
                      label={isCommunity ? t("general.ad_form.labels.message_lo_com") : t("general.ad_form.labels.message")}
                      required={!isAdFetched}
                      maxLength={constants.AD_DESCRIPTION_MAX_LEN}
                      readOnly={isAdFetched}
                      tooltipMessage={readOnlyNoticeMessage}
                    />
                  </Box>

                  <Box marginB={2}>
                    <TextInput
                      name="link"
                      label={isCommunity ? t("general.ad_form.labels.source_url_lo_com") : t("general.ad_form.labels.source_url")}
                      type="url"
                      required
                      pattern={{
                        value: constants.URL_REG,
                        message: t("errors.invalid_url")
                      }}
                    />
                  </Box>
                </Box>
              </Tile>
            </Box>

            <Box>
              <Section
                titleElement={
                  <>
                    <Text as="span" styledAs="h6">
                      {t("general.ad_form.widgets_section.title")}
                    </Text>
                    <InputAsterisk />
                    {errors?.customer_promotion_environments && (
                      <Box>
                        <InputError
                          aria-live="polite"
                          id="customer_promotion_environments_error"
                        >
                          {t("errors.select_one_widget")}
                        </InputError>
                      </Box>
                    )}
                  </>
                }
              >
                <Fieldset>
                  <legend className="ddb-visually-hidden">
                    {t("general.ad_form.widgets_section.title")}
                  </legend>
                  {widgets?.map((widget) => (
                    <Box key={widget.id} marginB={1}>
                      <CheckboxGroupTile
                        input={{
                          name: `customer_promotion_environments[${widget.id}]`,
                          value: widget.id,
                          label: widget.name,
                          onChange: onCPEChange,
                          validate: validateCPE,
                          errorId: "customer_promotion_environments_error"
                        }}
                        nestedInputs={widget.categories?.map((category) => ({
                          name: `promotion_environment_categories[${category.id}]`,
                          value: category.id,
                          label: category.name,
                          onChange: onPECChange,
                          errorId: "customer_promotion_environments_error"
                        }))}
                        toggleTileLabels={{
                          open: t("general.ad_form.widgets_section.btn_open"),
                          close: t("general.ad_form.widgets_section.btn_close")
                        }}
                      />
                    </Box>
                  ))}
                  {showSelectAllButton && (
                    <CheckboxTile
                      name="customer_promotion_environments_all"
                      value="all"
                      label={t("general.ad_form.labels.all_widgets")}
                      onChange={onAllCPECheck}
                      errorId="customer_promotion_environments_error"
                    />
                  )}
                </Fieldset>
              </Section>
            </Box>
          </Box>

          <SubmitButton
            type="submit"
            disabled={isSubmissionDisabled}
            cta
            width={`${stylesConstants.GRID_UNIT * 35}px`}
          >
            {isCommunity ? t("general.ad_form.submit_btn_lo_com") : t("general.ad_form.submit_btn")}
          </SubmitButton>
        </form>
      </FormProvider>
      <DevTool control={formMethods.control} />
    </Transition>
  );
};

export default AdFormComponent;
