import React, { VFC } from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "../../../util/helpers";
import constants from "../../_globalStyles/constants";
import Box from "../../particles/Box";
import Text from "../../atoms/Text";
import Separator from "../../atoms/Separator";
import Icon from "../../atoms/Icon";
import { SubheaderWrap, SubheaderContent } from "./styled";

interface SubheaderProps {
  title: string;
  backUrl?: string;
  backUrlAction?: () => void;
}

const Subheader: VFC<SubheaderProps> = ({ title, backUrl, backUrlAction }) => {
  const { t } = useTranslation();

  return (
    <SubheaderWrap>
      <SubheaderContent flex alignCenter>
        <Box flexStatic>
          {backUrl && (
            <Text
              as="a"
              href={backUrl}
              color={constants.TEXT_COLOR}
              flex
              alignCenter
            >
              <Icon name="back" size={pxToRem(24)} />
              <span className="ddb-visually-hidden">
                {t("general.navigation.back")}
              </span>
            </Text>
          )}
          {backUrlAction && (
            <Text
              as="button"
              type="button"
              onClick={backUrlAction}
              color={constants.TEXT_COLOR}
              flex
              alignCenter
            >
              <Icon name="back" size={pxToRem(24)} />
              <span className="ddb-visually-hidden">
                {t("general.navigation.back")}
              </span>
            </Text>
          )}
        </Box>
        <Separator horizontal height={pxToRem(32)} />
        <Box inlineBlock marginH={1}>
          <Text as="h1" styledAs="h4">
            {title}
          </Text>
        </Box>
      </SubheaderContent>
    </SubheaderWrap>
  );
};

export default Subheader;
