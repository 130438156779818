import { FC, HTMLProps } from "react";
import styled, { css } from "styled-components";

import constants from "../../_globalStyles/constants";
import Tile, { TileProps } from "../../atoms/Tile";

interface TooltipTileProps extends TileProps {
  active: boolean;
}

export const TooltipWrap: FC<HTMLProps<HTMLDivElement>> = styled.div`
  display: inline-block;
  position: relative;
  cursor: pointer;

  .ddb-keyboard-navigation-on &:focus {
    outline: 2px dotted ${constants.FOCUS_OUTLINE_COLOR};
    outline-offset: 2px;
  }
`;

TooltipWrap.displayName = "TooltipWrap";

export const TooltipTile: FC<TooltipTileProps> = styled(Tile)`
  box-shadow: 0px 4px 12px 0px #00000014;
  position: absolute;
  bottom: calc(100% + ${constants.GRID_UNIT * 1.25}px);
  left: -${constants.GRID_UNIT * 2}px;
  visibility: hidden;
  opacity: 0;
  z-index: 300;

  ${TooltipWrap}:hover & {
    visibility: visible;
    opacity: 1;
  }

  ${({ active }) =>
    active &&
    css`
      visibility: visible;
      opacity: 1;
    `}

  transition: opacity 0.05s ease-in;
`;

TooltipTile.displayName = "TooltipTile";
