import { FC, HTMLProps } from "react";
import styled, { css } from "styled-components";

import constants from "../_globalStyles/constants";

const { GRID_UNIT } = constants;

export interface BoxProps extends HTMLProps<HTMLElement> {
  key?: string | number;
  height?: string | number;
  minHeight?: string | number;
  maxHeight?: string | number;
  width?: string | number;
  minWidth?: string | number;
  maxWidth?: string | number;
  marginHAuto?: boolean;
  marginV?: number | string;
  marginH?: number | string;
  marginT?: number | string;
  marginB?: number | string;
  marginL?: number | string;
  marginR?: number | string;
  paddingV?: number | string;
  paddingH?: number | string;
  paddingT?: number | string;
  paddingB?: number | string;
  paddingL?: number | string;
  paddingR?: number | string;
  textRight?: boolean;
  textCenter?: boolean;
  textLeft?: boolean;
  flexWrap?: boolean;
  vertical?: boolean;
  flexBasis?: string;
  flex?: boolean;
  inlineFlex?: boolean;
  inlineBlock?: boolean;
  inline?: boolean;
  flexShrink?: boolean;
  flexGrow?: boolean;
  flexStatic?: boolean;
  flexDynamic?: boolean;
  justifyCenter?: boolean;
  justifyRight?: boolean;
  justifySpaceBetween?: boolean;
  justifyStart?: boolean;
  alignCenter?: boolean;
  alignBottom?: boolean;
  alignStart?: boolean;
  alignStretch?: boolean;
  selfCenter?: boolean;
  selfStretch?: boolean;
  selfEnd?: boolean;
  selfStart?: boolean;
  relative?: boolean;
  absolute?: boolean;
  fixed?: boolean;
  overflowHidden?: boolean;
  overflowVisible?: boolean;
  overflowScroll?: boolean;
  overflowAuto?: boolean;
  floatRight?: boolean;
  floatLeft?: boolean;
}

const getSize = (size) => {
  if (typeof size === "number") return `${size * GRID_UNIT}px`;
  if (typeof size === "string") return size;
  return null;
};

const getTextAlign = (props) => {
  if (props.textRight) return "right";
  if (props.textCenter) return "center";
  if (props.textLeft) return "left";
  return null;
};

const getDisplay = (props) => {
  if (props.flex) return "flex";
  if (props.inlineFlex) return "inline-flex";
  if (props.inlineBlock) return "inline-block";
  if (props.as === "span" || props.as === "a" || props.as === "b")
    return "inline";
  return null;
};

const getFlex = (props) => {
  if (props.flexShrink) return "0 1 auto";
  if (props.flexGrow) return "1 0 auto";
  if (props.flexStatic) return "0 0 auto";
  if (props.flexDynamic) return "1 1 auto";
  return null;
};

const getJustifyContent = (props) => {
  if (props.justifyCenter) return "center";
  if (props.justifyRight) return "flex-end";
  if (props.justifySpaceBetween) return "space-between";
  if (props.justifyStart) return "flex-start";
  return null;
};

const getAlignItems = (props) => {
  if (props.alignCenter) return "center";
  if (props.alignBottom) return "flex-end";
  if (props.alignStart) return "flex-start";
  if (props.alignStretch) return "stretch";
  return null;
};

const getAlignSelf = (props) => {
  if (props.selfCenter) return "center";
  if (props.selfStretch) return "stretch";
  if (props.selfEnd) return "flex-end";
  if (props.selfStart) return "flex-start";
  return null;
};

const getPosition = (props) => {
  if (props.relative) return "relative";
  if (props.absolute) return "absolute";
  if (props.fixed) return "fixed";
  return null;
};

const getOverflow = (props) => {
  if (props.overflowHidden) return "hidden";
  if (props.overflowVisible) return "visible";
  if (props.overflowScroll) return "scroll";
  if (props.overflowAuto) return "auto";
  return null;
};

const getFloat = (props) => {
  if (props.floatRight) return "right";
  if (props.floatLeft) return "left";
  return null;
};

const getMPProperty = (space) => {
  if (typeof space === "number") return `${space * GRID_UNIT}px !important`;
  else if (typeof space === "string") return `${space} !important`;
  return null;
};

const Box: FC<BoxProps> = styled.div`
  box-sizing: border-box;
  height: ${({ height }) => getSize(height)};
  width: ${({ width }) => getSize(width)};
  min-height: ${({ minHeight }) => getSize(minHeight)};
  max-height: ${({ maxHeight }) => getSize(maxHeight)};
  min-width: ${({ minWidth }) => getSize(minWidth)};
  max-width: ${({ maxWidth }) => getSize(maxWidth)};
  margin-top: ${({ marginV, marginT }) => getMPProperty(marginV || marginT)};
  margin-bottom: ${({ marginV, marginB }) => getMPProperty(marginV || marginB)};
  margin-left: ${({ marginH, marginL }) => getMPProperty(marginH || marginL)};
  margin-right: ${({ marginH, marginR }) => getMPProperty(marginH || marginR)};
  padding-top: ${({ paddingV, paddingT }) => getMPProperty(paddingV || paddingT)};
  padding-bottom: ${({ paddingV, paddingB }) => getMPProperty(paddingV || paddingB)};
  padding-left: ${({ paddingH, paddingL }) => getMPProperty(paddingH || paddingL)};
  padding-right: ${({ paddingH, paddingR }) => getMPProperty(paddingH || paddingR)};
  position: ${getPosition};
  display: ${getDisplay};
  text-align: ${getTextAlign};
  float: ${getFloat};
  overflow: ${getOverflow};
  justify-content: ${getJustifyContent};
  align-items: ${getAlignItems};
  align-self: ${getAlignSelf};
  flex: ${getFlex};
  flex-wrap: ${({ flexWrap }) => (flexWrap ? "wrap" : null)};
  flex-direction: ${({ vertical }) => (vertical ? "column" : null)};
  flex-basis: ${({ flexBasis }) => flexBasis || null};

  ${({ marginHAuto }) =>
    marginHAuto &&
    css`
      margin-right: auto;
      margin-left: auto;
    `}
`;

Box.displayName = "Box";

export default Box;
