import React, { VFC } from "react";
import { useTranslation } from "react-i18next";

import constants from "../../../constants";
import Box from "../../particles/Box";
import Text from "../../atoms/Text";
import Section from "../../molecules/Section";
import Tooltip from "../../organisms/Tooltip";

const { AD_ROTATION_DAYS_MAX } = constants;

interface WidgetLicenseProps {
  isCommunity: boolean;
  places: number;
  placesUsed: number;
  rotation: number;
  fetchingEnabled: boolean;
  filteringEnabled: boolean;
  filteringHashtag?: string;
  categories: string[];
  titleAs?: string;
}

const WidgetLicense: VFC<WidgetLicenseProps> = ({
  isCommunity,
  places,
  placesUsed,
  rotation,
  fetchingEnabled,
  filteringEnabled,
  filteringHashtag,
  categories,
  titleAs
}) => {
  const { t } = useTranslation();

  const accountRotationDays = rotation < AD_ROTATION_DAYS_MAX;

  return (
    <Section
      title={isCommunity ? t("general.dashboard.license_terms_lo_com") : t("general.dashboard.license_terms")}
      titleAs={titleAs}
      overflowHidden={false}
    >
      <Box>
        <Box className="ddb-desktop ddb-desktop-s ddb-mobile-l" marginB={1.5}>
          <Text>
            <Tooltip
              id="ddb_slots_tooltip"
              text={
                places === 1
                  ? isCommunity
                    ? t("general.dashboard.tooltip.places_sing_lo_com")
                    : t("general.dashboard.tooltip.places_sing")
                  : isCommunity
                  ? t("general.dashboard.tooltip.places_pl_lo_com", {
                      amount: places
                    })
                  : t("general.dashboard.tooltip.places_pl", { amount: places })
              }
            >
              {isCommunity
                ? t("general.dashboard.available_slots_lo_com")
                : t("general.dashboard.available_slots")}{" "}
              – <b>{`${places - placesUsed} of ${places}`}</b>
            </Tooltip>
            <Box as="span" marginH={1.5}>
              •
            </Box>{" "}
            {accountRotationDays ? (
              <Tooltip
                id="ddb_rotation_tooltip"
                text={
                  isCommunity
                    ? t("general.dashboard.tooltip.lifetime_lo_com", {
                        period:
                          rotation === 1
                            ? `1\u00a0${t("time.day")}`
                            : `${rotation}\u00a0${t("time.days")}`
                      })
                    : t("general.dashboard.tooltip.lifetime", {
                        period:
                          rotation === 1
                            ? `1\u00a0${t("time.day")}`
                            : `${rotation}\u00a0${t("time.days")}`
                      })
                }
              >
                {isCommunity
                  ? t("general.dashboard.ads_visibility_lo_com")
                  : t("general.dashboard.ads_visibility")}{" "}
                – <b>{rotation} </b>
              </Tooltip>
            ) : (
              <Tooltip
                id="ddb_rotation_tooltip"
                text={
                  isCommunity
                    ? t("general.dashboard.tooltip.lifetime_unlimited_lo_com")
                    : t("general.dashboard.tooltip.lifetime_unlimited")
                }
              >
                {isCommunity
                  ? t("general.dashboard.ads_visibility_lo_com")
                  : t("general.dashboard.ads_visibility")}{" "}
                – <b>{t("general.dashboard.ads_visibility_unlimited")}</b>
              </Tooltip>
            )}
            <Box as="span" marginH={1.5}>
              •
            </Box>{" "}
            {fetchingEnabled ? (
              <>
                {filteringEnabled ? (
                  <Tooltip
                    id="ddb_hashtag_tooltip"
                    text={
                      isCommunity
                        ? t("general.dashboard.tooltip.hashtag_lo_com", {
                            hashtag: filteringHashtag
                          })
                        : t("general.dashboard.tooltip.hashtag", {
                            hashtag: filteringHashtag
                          })
                    }
                  >
                    {t("general.dashboard.hashtag")} –{" "}
                    <b>#{filteringHashtag}</b>
                  </Tooltip>
                ) : (
                  <Tooltip
                    id="ddb_hashtag_tooltip"
                    text={t("general.dashboard.tooltip.no_hashtag", {
                      hashtag: filteringHashtag
                    })}
                  >
                    <b>{t("general.dashboard.no_filtering_with_hashtag")}</b>
                  </Tooltip>
                )}
              </>
            ) : (
              <Tooltip
                id="ddb_no_fetching_tooltip"
                text={
                  isCommunity
                    ? t("general.dashboard.tooltip.manual_lo_com")
                    : t("general.dashboard.tooltip.manual")
                }
              >
                <b>
                  {isCommunity
                    ? t("general.dashboard.no_social_ads_included_lo_com")
                    : t("general.dashboard.no_social_ads_included")}
                </b>
              </Tooltip>
            )}
          </Text>
        </Box>
        <Text as="div" className="ddb-mobile">
          <Box>
            <Tooltip
              id="ddb_slots_tooltip_mobile"
              text={
                places === 1
                  ? isCommunity
                    ? t("general.dashboard.tooltip.places_sing_lo_com")
                    : t("general.dashboard.tooltip.places_sing")
                  : isCommunity
                  ? t("general.dashboard.tooltip.places_pl_lo_com", {
                      amount: places
                    })
                  : t("general.dashboard.tooltip.places_pl", { amount: places })
              }
            >
              {isCommunity
                ? t("general.dashboard.available_slots_lo_com")
                : t("general.dashboard.available_slots")}{" "}
              – <b>{`${places - placesUsed} of ${places}`} </b>
            </Tooltip>
          </Box>

          <Box marginT={1}>
            {accountRotationDays ? (
              <Tooltip
                id="ddb_rotation_tooltip_mobile"
                text={
                  isCommunity
                    ? t("general.dashboard.tooltip.lifetime_lo_com", {
                        period:
                          rotation === 1
                            ? `1\u00a0${t("time.day")}`
                            : `${rotation}\u00a0${t("time.days")}`
                      })
                    : t("general.dashboard.tooltip.lifetime", {
                        period:
                          rotation === 1
                            ? `1\u00a0${t("time.day")}`
                            : `${rotation}\u00a0${t("time.days")}`
                      })
                }
              >
                {isCommunity
                  ? t("general.dashboard.ads_visibility_lo_com")
                  : t("general.dashboard.ads_visibility")}{" "}
                – <b>{rotation} </b>
              </Tooltip>
            ) : (
              <Tooltip
                id="ddb_rotation_tooltip_mobile"
                text={
                  isCommunity
                    ? t("general.dashboard.tooltip.lifetime_unlimited_lo_com")
                    : t("general.dashboard.tooltip.lifetime_unlimited")
                }
              >
                {isCommunity
                  ? t("general.dashboard.ads_visibility_lo_com")
                  : t("general.dashboard.ads_visibility")}{" "}
                – <b>{t("general.dashboard.ads_visibility_unlimited")}</b>
              </Tooltip>
            )}
          </Box>

          <Box marginT={1}>
            {fetchingEnabled ? (
              <>
                {filteringEnabled ? (
                  <Tooltip
                    id="ddb_hashtag_tooltip_mobile"
                    text={
                      isCommunity
                        ? t("general.dashboard.tooltip.hashtag_lo_com", {
                            hashtag: filteringHashtag
                          })
                        : t("general.dashboard.tooltip.hashtag", {
                            hashtag: filteringHashtag
                          })
                    }
                  >
                    {t("general.dashboard.hashtag")} –{" "}
                    <b>#{filteringHashtag}</b>
                  </Tooltip>
                ) : (
                  <Tooltip
                    id="ddb_hashtag_tooltip_mobile"
                    text={t("general.dashboard.tooltip.no_hashtag", {
                      hashtag: filteringHashtag
                    })}
                  >
                    <b>{t("general.dashboard.no_filtering_with_hashtag")}</b>
                  </Tooltip>
                )}
              </>
            ) : (
              <Tooltip
                id="ddb_no_fetching_tooltip_mobile"
                text={
                  isCommunity
                    ? t("general.dashboard.tooltip.manual_lo_com")
                    : t("general.dashboard.tooltip.manual")
                }
              >
                <b>
                  {isCommunity
                    ? t("general.dashboard.no_social_ads_included_lo_com")
                    : t("general.dashboard.no_social_ads_included")}
                </b>
              </Tooltip>
            )}
          </Box>
        </Text>
      </Box>
      {!!categories?.length && (
        <Box marginT={1}>
          <Text capitalize>
            {t("general.dashboard.categories")} – <b>{categories.join(", ")}</b>
          </Text>
        </Box>
      )}
    </Section>
  );
};

WidgetLicense.defaultProps = {
  titleAs: "h2"
};

export default WidgetLicense;
