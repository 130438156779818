import React, {
  FC,
  useState,
  useCallback,
  useMemo,
  useRef,
  useContext
} from "react";
import { useTranslation } from "react-i18next";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useMatch
} from "react-router-dom";

import { User, NewspaperSettings } from "../../../types";
import { AppContext } from "../../_functional/AppProvider";
import { signOut } from "../../../util/authentication";
import { setCookie, getCookieByName } from "../../../util/cookieProvider";
import Icon from "../../atoms/Icon";
import Style from "./styles";

interface UnifiedHeaderProps {
  user: User;
  newspaperSettings: NewspaperSettings;
}

const UnifiedHeader: FC<UnifiedHeaderProps> = ({ user, newspaperSettings }) => {
  const { t } = useTranslation();

  const { newspaper, resetWidgets, resetPages, customer } = useContext(AppContext);

  const { branding, legalInfo, newspaperInfo } = newspaperSettings;
  
  const isCommunity = newspaper?.isCommunity;

  const navigate = useNavigate();
  const { newspaperSlug } = useParams();
  const [searchParams] = useSearchParams();
  const adminCustomerToken = searchParams.get("admin_customer_token");

  const isUserAuthenticated = Boolean(getCookieByName("ddb_session_token"));
  const userEmail = getCookieByName("ddb_user_email");

  const inverted = branding ? branding.theme === "white" : "white";
  const color = branding ? branding.color : "#ffffff";

  const currentYear = new Date().getFullYear();

  const showNewspaperLogo = Boolean(
    branding && branding.logo_url && branding.logo_mobile_url
  );

  const showNewspapersMenu = user?.newspapers.length > 1;
  const showNewspaperName = Boolean(
    !showNewspaperLogo &&
      (user?.newspapers.length === 1 ||
        (adminCustomerToken && newspaperInfo?.name))
  );

  const showUserMenu = Boolean(
    !adminCustomerToken ||
      (legalInfo &&
        (legalInfo.accessibility_terms_url ||
          legalInfo.advertising_terms_url ||
          legalInfo.privacy_policy_url ||
          legalInfo.terms_and_conditions_url ||
          legalInfo.business_name))
  );

  const newspaperName = user?.newspapers[0]?.name || newspaperInfo?.name;
  const isCustomerSuspended = customer?.suspended;

  const [isNewspapersMenuOpen, setIsNewspapersMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const newspapersMenuRef = useRef(null);
  const userMenuRef = useRef(null);

  const isDashboardPage = useMatch("/:newspaperSlug/widgets");
  const isWidgetPage = useMatch("/:newspaperSlug/widgets/:id");
  const isNewWidgetAdPage = useMatch(
    "/:newspaperSlug/widgets/:widgetId/promotions/new"
  );
  const isEditWidgetAdPage = useMatch(
    "/:newspaperSlug/widgets/:widgetId/promotions/:id"
  );
  const isNewAdPage = useMatch("/:newspaperSlug/promotions/new");
  const isConnectionsPage = useMatch("/:newspaperSlug/connections");
  const isAnalyticsPage = useMatch("/:newspaperSlug/analytics");
  const isContactsPage = useMatch("/:newspaperSlug/contacts");

  const redirectToWidgets =
    isDashboardPage ||
    isWidgetPage ||
    isNewWidgetAdPage ||
    isEditWidgetAdPage ||
    isNewAdPage;
  const redirectToConnections = isConnectionsPage;
  const redirectToAnalytics = isAnalyticsPage;
  const redirectToContacts = isContactsPage;

  const genRedirectUrl = useCallback(
    (_newspaperSlug) => {
      if (redirectToWidgets) return `/${_newspaperSlug}/widgets`;
      else if (redirectToConnections) return `/${_newspaperSlug}/connections`;
      else if (redirectToAnalytics) return `/${_newspaperSlug}/analytics`;
      else if (redirectToContacts) return `/${_newspaperSlug}/contacts`;
    },
    [redirectToWidgets, redirectToConnections]
  );

  const openMenu = (menu) => {
    if (menu === "newspapers") {
      setIsNewspapersMenuOpen(true);
      window.addEventListener("click", closeNewspapersMenuOnClickOutside);
    }

    if (menu === "user") {
      setIsUserMenuOpen(true);
      window.addEventListener("click", closeUserMenuOnClickOutside);
    }

    window.addEventListener("keyup", closeMenuOnEsc);
  };

  const closeMenu = (menu) => {
    if (menu === "all" || menu === "newspapers") {
      setIsNewspapersMenuOpen(false);
      window.removeEventListener("click", closeUserMenuOnClickOutside);
    }

    if (menu === "all" || menu === "user") {
      setIsUserMenuOpen(false);
      window.removeEventListener("click", closeNewspapersMenuOnClickOutside);
    }

    window.removeEventListener("keyup", closeMenuOnEsc);
  };

  const closeMenuOnEsc = useCallback(
    (e) => {
      if (e.key === "Escape" || e.key === "Esc") {
        closeMenu("all");
      }
    },
    [closeMenu]
  );

  const closeMenuOnFocusOut = useCallback(
    (e) => {
      if (!e.currentTarget.contains(e.relatedTarget)) {
        closeMenu("all");
      }
    },
    [closeMenu]
  );

  const closeMenuOnClickOutside = useCallback(
    (menu, e) => {
      if (
        menu === "newspapers" &&
        newspapersMenuRef.current &&
        !newspapersMenuRef.current.contains(e.target)
      )
        closeMenu("newspapers");
      if (
        menu === "user" &&
        userMenuRef.current &&
        !userMenuRef.current.contains(e.target)
      )
        closeMenu("user");
    },
    [newspapersMenuRef, userMenuRef, closeMenu]
  );

  const closeNewspapersMenuOnClickOutside = useCallback(
    (e) => closeMenuOnClickOutside("newspapers", e),
    [closeMenuOnClickOutside]
  );

  const closeUserMenuOnClickOutside = useCallback(
    (e) => closeMenuOnClickOutside("user", e),
    [closeMenuOnClickOutside]
  );

  const initSignIn = useCallback(() => {
    closeMenu("user");
    const redirectUrl = genRedirectUrl(newspaperSlug);

    setCookie("ddb_login_redirect_url", redirectUrl, {
      path: "/"
    });

    navigate("/sign_in");
  }, [closeMenu, genRedirectUrl, newspaperSlug]);

  const newspapersMenuOptions = useMemo(() => {
    if (!user?.newspapers.length) return;

    return user.newspapers.map((n) => ({
      label: n.name,
      value: n.slug,
      logo: n.logo
    }));
  }, [user]);

  const onNewspaperMenuItemSelect = useCallback(
    (_newspaperSlug) => {
      if (_newspaperSlug === newspaperSlug) return;
      const redirectUrl = genRedirectUrl(_newspaperSlug);
      setCookie("ddb_current_newspaper", _newspaperSlug);
      navigate(redirectUrl);
      resetWidgets();
      resetPages();
      closeMenu("newspapers");
    },
    [closeMenu, genRedirectUrl, newspaperSlug]
  );

  const onCreateAd = useCallback(
    () =>
      navigate(`/${newspaperSlug}/promotions/new?${searchParams.toString()}`),
    [newspaperSlug, searchParams]
  );

  return (
    <>
      <Style />
      <header
        style={{ background: color }}
        className={`ddb-uni-header${
          inverted ? " ddb-uni-header__invert" : " ddb-uni-header__default"
        }`}
      >
        <div className="ddb-uni-header--content">
          <a href="#ddb_main_content" className="ddb-uni-skip-navigation">
            {t("general.header.skip_link")}
          </a>

          <div className="ddb-uni-header--action-wrapper ddb-uni-header--action-wrapper__main">
            {/* Logo. */}

            {!!branding?.logo_url && (
              <a
                href={branding.logo_url || ""}
                rel="noopener noreferrer"
                className="ddb-uni-header--logo-wrapper"
              >
                <img
                  src={branding.logo_desktop_url}
                  alt={t("general.header.front_page")}
                  className="ddb-uni-header--logo ddb-uni-header--logo-desktop"
                />
                <img
                  src={branding.logo_mobile_url}
                  alt={t("general.header.front_page")}
                  className="ddb-uni-header--logo ddb-uni-header--logo-mobile"
                />
              </a>
            )}

            {/* Product name */}
            {/* <div className="ddb-uni-button ddb-uni-button__text ddb-uni-button__text-plain ddb-uni-header--service-wrapper">
              <span>{t("general.product_name")}</span>
            </div> */}

            {/* Newspaper name */}
            {showNewspaperName && (
              <div className="ddb-uni-button ddb-uni-button__text ddb-uni-button__text-plain ddb-uni-header--newspaper-name">
                <span>{newspaperName}</span>
              </div>
            )}

            <div className="ddb-uni-header--cta-wrapper">
              <button
                type="button"
                disabled={!adminCustomerToken && isCustomerSuspended}
                onClick={onCreateAd}
                className="ddb-uni-button ddb-uni-button__cta ddb-uni-header--cta ddb-uni-header--cta-desktop"
              >
                {isCommunity ? t("general.header.create_ad_btn_lo_com") : t("general.header.create_ad_btn")}
              </button>
              <button
                type="button"
                disabled={!adminCustomerToken && isCustomerSuspended}
                onClick={onCreateAd}
                className="ddb-uni-button ddb-uni-button__cta ddb-uni-header--cta ddb-uni-button__icon ddb-uni-header--cta-mobile"
              >
                <span className="ddb-visually-hidden">
                  {isCommunity ? t("general.header.create_ad_btn_lo_com") : t("general.header.create_ad_btn")}
                </span>
                <Icon name="plus" className="ddb-uni-icon" />
              </button>
            </div>
          </div>

          {/* Newspaper switcher */}
          {showNewspapersMenu && (
            <div className="ddb-uni-header--action-wrapper ddb-uni-header--action-wrapper__newspapers">
              <nav
                ref={newspapersMenuRef}
                aria-labelledby="newspapers_menu_label"
                className={isNewspapersMenuOpen ? "open" : ""}
                onBlur={closeMenuOnFocusOut}
              >
                <div
                  id="newspapers_menu_label"
                  className="ddb-uni-visually-hidden"
                >
                  {isCommunity ? t("general.header.newspapers_navigation_lo_com") : t("general.header.newspapers_navigation")}
                </div>

                <div
                  className={`ddb-uni-dropdown ddb-uni-dropdown__newspapers-menu${
                    isNewspapersMenuOpen ? " open" : ""
                  }`}
                >
                  <button
                    type="button"
                    onClick={() =>
                      isNewspapersMenuOpen
                        ? closeMenu("newspapers")
                        : openMenu("newspapers")
                    }
                    className="ddb-uni-button ddb-uni-dropdown--title"
                    aria-labelledby="newspapers_menu_label"
                    aria-haspopup="menu"
                    aria-expanded={isNewspapersMenuOpen}
                  >
                    <span>{newspaperSlug}</span>
                    <div className="ddb-uni-icon ddb-uni-dropdown--icon">
                      <Icon name="select" />
                    </div>
                  </button>

                  <ul
                    className="ddb-uni-dropdown--menu ddb-uni-dropdown--menu__rtl"
                    aria-labelledby="newspapers_menu_label"
                    role="menu"
                  >
                    {newspapersMenuOptions.map((_newspaper, i) => (
                      <li
                        key={_newspaper.value}
                        className="ddb-uni-dropdown--item ddb-uni-dropdown--item__main"
                        role="menuitem"
                      >
                        <a
                          href="javascript:void(0)"
                          onClick={() =>
                            onNewspaperMenuItemSelect(_newspaper.value)
                          }
                          className="ddb-uni-dropdown--item-link ddb-uni-dropdown--item-link-with-small-icon"
                        >
                          {_newspaper.logo ? (
                            <img
                              className="ddb-uni-dropdown--item-small-image"
                              src={_newspaper.logo}
                              aria-hidden
                              alt=""
                            />
                          ) : (
                            <div className="ddb-uni-dropdown--item-small-icon">
                              <div className="ddb-uni-icon">
                                <Icon name="newspaper" />
                              </div>
                            </div>
                          )}
                          <div className="ddb-uni-dropdown--item-title">
                            {_newspaper.label}
                          </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </nav>
            </div>
          )}

          {/* User menu */}
          {showUserMenu && (
            <div className="ddb-uni-header--action-wrapper ddb-uni-header--action-wrapper__profile">
              <nav
                ref={userMenuRef}
                aria-labelledby="site_menu_label"
                className={isUserMenuOpen ? "open" : ""}
                onBlur={closeMenuOnFocusOut}
              >
                <div id="site_menu_label" className="ddb-uni-visually-hidden">
                  {t("general.header.main_site_navigation")}
                </div>

                <div
                  className={`ddb-uni-dropdown ddb-uni-dropdown__user-menu${
                    isUserMenuOpen ? " open" : ""
                  }`}
                >
                  <button
                    type="button"
                    onClick={() =>
                      isUserMenuOpen ? closeMenu("user") : openMenu("user")
                    }
                    className="ddb-uni-button ddb-uni-button__icon ddb-uni-dropdown--title"
                    aria-labelledby="site_menu_label"
                    aria-haspopup="menu"
                    aria-expanded={isUserMenuOpen}
                  >
                    <div className="ddb-uni-icon ddb-uni-icon--user">
                      <Icon name="user" />
                    </div>
                  </button>

                  <ul
                    className="ddb-uni-dropdown--menu ddb-uni-dropdown--menu__rtl"
                    aria-labelledby="site_menu_label"
                    role="menu"
                  >
                    {/* Authentication */}
                    {!adminCustomerToken && (
                      <>
                        {isUserAuthenticated ? (
                          <li
                            key="authentication"
                            className="ddb-uni-dropdown--item ddb-uni-dropdown--item__main"
                            role="menuitem"
                          >
                            <a
                              href="javascript:void(0)"
                              onClick={signOut}
                              className="ddb-uni-dropdown--item-link ddb-uni-dropdown--item-link-with-icon"
                            >
                              <div className="ddb-uni-dropdown--item-icon">
                                <div className="ddb-uni-icon">
                                  <Icon name="logout" />
                                </div>
                              </div>
                              <div className="ddb-uni-dropdown--item-text-content">
                                <div className="ddb-uni-dropdown--item-title">
                                  {t("general.header.logout")}
                                </div>
                                <div className="ddb-uni-dropdown--item-subtitle">
                                  {userEmail}
                                </div>
                              </div>
                            </a>
                          </li>
                        ) : (
                          <li
                            key="authentication"
                            className="ddb-uni-dropdown--item ddb-uni-dropdown--item__main"
                            role="menuitem"
                          >
                            <a
                              href="javascript:void(0)"
                              onClick={initSignIn}
                              className="ddb-uni-dropdown--item-link ddb-uni-dropdown--item-link-with-icon"
                            >
                              <div className="ddb-uni-dropdown--item-icon">
                                <div className="ddb-uni-icon">
                                  <Icon name="login" />
                                </div>
                              </div>
                              <div className="ddb-uni-dropdown--item-title">
                                {t("general.header.login")}
                              </div>
                            </a>
                          </li>
                        )}
                      </>
                    )}

                    {/* Accessibility */}
                    {!!legalInfo?.accessibility_terms_url && (
                      <li
                        key="accessibility"
                        className="ddb-uni-dropdown--item ddb-uni-dropdown--item__small"
                        role="menuitem"
                      >
                        <a
                          href={legalInfo.accessibility_terms_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ddb-uni-dropdown--item-link"
                        >
                          {t("general.header.accessibility")}
                          <span className="ddb-visually-hidden">
                            {t("accessibility.new_tab")}
                          </span>
                        </a>
                      </li>
                    )}

                    {/* Advertising */}
                    {!!legalInfo?.advertising_terms_url && (
                      <li
                        key="advertising"
                        className="ddb-uni-dropdown--item ddb-uni-dropdown--item__small"
                        role="menuitem"
                      >
                        <a
                          href={legalInfo.advertising_terms_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ddb-uni-dropdown--item-link"
                        >
                          {t("general.header.advertising")}
                          <span className="ddb-visually-hidden">
                            {t("accessibility.new_tab")}
                          </span>
                        </a>
                      </li>
                    )}

                    {/* Privacy Policy */}
                    {!!legalInfo?.privacy_policy_url && (
                      <li
                        key="privacy"
                        className="ddb-uni-dropdown--item ddb-uni-dropdown--item__small"
                        role="menuitem"
                      >
                        <a
                          href={legalInfo.privacy_policy_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ddb-uni-dropdown--item-link"
                        >
                          {t("general.header.privacy_policy")}
                          <span className="ddb-visually-hidden">
                            {t("accessibility.new_tab")}
                          </span>
                        </a>
                      </li>
                    )}

                    {/* Terms of Use */}
                    {!!legalInfo?.terms_and_conditions_url && (
                      <li
                        key="terms"
                        className="ddb-uni-dropdown--item ddb-uni-dropdown--item__small"
                        role="menuitem"
                      >
                        <a
                          href={legalInfo.terms_and_conditions_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ddb-uni-dropdown--item-link"
                        >
                          {t("general.header.terms")}
                          <span className="ddb-visually-hidden">
                            {t("accessibility.new_tab")}
                          </span>
                        </a>
                      </li>
                    )}

                    {/* Company name */}
                    {!!legalInfo?.business_name && (
                      <li
                        key="company_name"
                        className="ddb-uni-dropdown--item ddb-uni-dropdown--item__small"
                        role="none"
                      >
                        <div className="ddb-uni-dropdown--item-text">{`${t(
                          "general.header.copyright"
                        )} ${legalInfo.business_name} ${currentYear}`}</div>
                      </li>
                    )}
                  </ul>
                </div>
              </nav>
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default UnifiedHeader;
