import React, { VFC } from "react";

import Icon from "../../atoms/Icon";
import IconColor from "../../atoms/IconColor";
import { IconBadgeProps, IconBadgeWrap } from "./styled";

const getName = (props: IconBadgeProps): string => {
  if (
    props.status === "warning" ||
    (props.status === "error" && props.inverted)
  )
    return "warning";
  if (props.status === "error" || props.status === "success")
    return props.status;
  return props.name;
};

const getColor = (props: IconBadgeProps): boolean => {
  if (props.inverted) return false;
  return true;
};

const getAccentProp = (props: IconBadgeProps): boolean => {
  if (props.name === "facebook" || props.custom || props.hint || props.inverted)
    return false;
  return true;
};

const IconBadge: VFC<IconBadgeProps> = (props) => {
  const name = getName(props);
  const color = getColor(props);
  const accent = getAccentProp(props);

  return (
    <IconBadgeWrap {...props} accent={accent}>
      {color && <IconColor name={name} />}
      {!color && <Icon name={name} />}
    </IconBadgeWrap>
  );
};

export default IconBadge;
