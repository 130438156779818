import React, { VFC } from "react";
import Box from "../../particles/Box";
import Tile from "../../atoms/Tile";
import Text from "../../atoms/Text";
import Button from "../../atoms/Button";

interface WidgetsMessageProps {
  title: string;
  titleAs?: string;
  text: string;
  ctaText?: string;
  ctaTo?: string;
}

const WidgetsMessage: VFC<WidgetsMessageProps> = ({
  title,
  titleAs,
  text,
  ctaText,
  ctaTo
}) => {
  return (
    <>
      <Tile paddingV={3} paddingH={6} width="100%" className="ddb-desktop ddb-desktop-s ddb-mobile-l">
        <Text as={titleAs} styledAs="h4" marginB={1}>
          {title}
        </Text>
        <Text>{text}</Text>
        {ctaText && ctaTo && (
          <Box marginT={2}>
            <Button primary to={ctaTo}>
              {ctaText}
            </Button>
          </Box>
        )}
      </Tile>
      <Tile paddingV={3} paddingH={3} width="100%" className="ddb-mobile">
        <Text as={titleAs} styledAs="h4" marginB={1}>
          {title}
        </Text>
        <Text>{text}</Text>
        {ctaText && ctaTo && (
          <Box marginT={2}>
            <Button primary to={ctaTo}>
              {ctaText}
            </Button>
          </Box>
        )}
      </Tile>
    </>
  );
}

WidgetsMessage.defaultProps = {
  titleAs: "h3"
};

export default WidgetsMessage;
