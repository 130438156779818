import React, { VFC, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { WidgetsItem } from "../../../types";
import stylesConstants from "../../_globalStyles/constants";
import constants from "../../../constants";
import { getPlural } from "../../../util/helpers";
import Box from "../../particles/Box";
import Text from "../../atoms/Text";
import Button from "../../atoms/Button";
import Separator from "../../atoms/Separator";
import Tooltip from "../../organisms/Tooltip";
import WidgetAd from "../WidgetAd";
import CreateWidgetAd from "../CreateWidgetAd";
import { WidgetBox, WidgetHeader, WidgetLogo, WidgetAdsList } from "./styled";

const { AD_ROTATION_DAYS_MAX } = constants;

interface WidgetProps extends WidgetsItem {
  isCommunity: boolean;
  filteringEnabled?: boolean;
  filteringHashtag?: string;
  locale: "en" | "no" | "fi" | "sv_SE" | "da" | "pl" | "uk" | "de";
}

const Widget: VFC<WidgetProps> = ({
  isCommunity,
  locale,
  envId,
  name,
  logo,
  color,
  places,
  placesUsed,
  rotation,
  fetchingEnabled,
  filteringEnabled,
  filteringHashtag,
  ads
}) => {
  const { t } = useTranslation();

  const { newspaperSlug } = useParams();
  const [searchParams] = useSearchParams();

  const widgetUrl = `/${newspaperSlug}/widgets/${envId}?${searchParams.toString()}`;
  const createWidgetAdUrl = `/${newspaperSlug}/widgets/${envId}/promotions/new?${searchParams.toString()}`;

  const accountRotationDays = rotation < AD_ROTATION_DAYS_MAX;

  const rotationTip = useMemo(() => {
    const plural = getPlural(locale, rotation);

    if (isCommunity) {
      return t("general.dashboard.tooltip.lifetime_lo_com", {
        period: `${rotation}\u00a0${t(`time.day.${plural}`)}`
      });
    } else {
      return t("general.dashboard.tooltip.lifetime", {
        period: `${rotation}\u00a0${t(`time.day.${plural}`)}`
      });
    }
  }, [isCommunity, locale, rotation]);

  const placesTip = useMemo(() => {
    const plural = getPlural(locale, places);

    if (isCommunity) {
      if (places === 1) {
        return t("general.dashboard.tooltip.places", {
          amount: `${places}\u00a0${t(`general.ad_item_lo_com.${plural}`)}`
        });
      }
      return t("general.dashboard.tooltip.places_pl", {
        amount: `${places}\u00a0${t(`general.ad_item_lo_com.${plural}`)}`
      });
    } else {
      if (places === 1) {
        return t("general.dashboard.tooltip.places", {
          amount: `${places}\u00a0${t(`general.ad_item.${plural}`)}`
        });
      }
      return t("general.dashboard.tooltip.places_pl", {
        amount: `${places}\u00a0${t(`general.ad_item.${plural}`)}`
      })
    }
  }, [isCommunity, locale, places]);

  return (
    <>
      <WidgetBox as="section">
        <WidgetHeader color={color}>
          <Box flex alignCenter>
            <Box flexDynamic>
              <WidgetLogo src={logo} alt={name}></WidgetLogo>
              <h3 className="ddb-visually-hidden">{name}</h3>
            </Box>
            <Box flexStatic>
              <Button to={widgetUrl}>
                {isCommunity
                  ? t("general.dashboard.manage_ads_lo_com")
                  : t("general.dashboard.manage_ads")}
              </Button>
            </Box>
          </Box>
        </WidgetHeader>
        <Box paddingH={3} paddingV={2}>
          <Text
            small
            lineHeight="1rem"
            color={stylesConstants.COLOR_GRAY_450}
            marginB={1}
          >
            {isCommunity
              ? t("general.dashboard.license_terms_lo_com")
              : t("general.dashboard.license_terms")}
          </Text>
          <Box className="ddb-desktop ddb-desktop-s ddb-mobile-l">
            <Text>
              <Tooltip
                id="ddb_slots_tooltip"
                text={placesTip}
              >
                <div>
                  {isCommunity
                    ? t("general.dashboard.available_slots_lo_com")
                    : t("general.dashboard.available_slots")}{" "}
                  –{" "}
                  <b>
                    {`${places - placesUsed} ${t(
                      "general.dashboard.available_slots_of"
                    )} ${places}`}{" "}
                  </b>
                </div>
              </Tooltip>
              <Box as="span" marginH={1.5}>
                •
              </Box>{" "}
              {accountRotationDays ? (
                <Tooltip
                  id="ddb_rotation_tooltip"
                  text={rotationTip}
                >
                  {isCommunity
                    ? t("general.dashboard.ads_visibility_lo_com")
                    : t("general.dashboard.ads_visibility")}{" "}
                  – <b>{rotation} </b>
                </Tooltip>
              ) : (
                <Tooltip
                  id="ddb_rotation_tooltip"
                  text={
                    isCommunity
                      ? t("general.dashboard.tooltip.lifetime_unlimited_lo_com")
                      : t("general.dashboard.tooltip.lifetime_unlimited")
                  }
                >
                  {isCommunity
                    ? t("general.dashboard.ads_visibility_lo_com")
                    : t("general.dashboard.ads_visibility")}{" "}
                  – <b>{t("general.dashboard.ads_visibility_unlimited")} </b>
                </Tooltip>
              )}
              <Box as="span" marginH={1.5}>
                •
              </Box>{" "}
              {fetchingEnabled ? (
                <>
                  {filteringEnabled ? (
                    <Tooltip
                      id="ddb_hashtag_tooltip"
                      text={
                        isCommunity
                          ? t("general.dashboard.tooltip.hashtag_lo_com", {
                              hashtag: filteringHashtag
                            })
                          : t("general.dashboard.tooltip.hashtag", {
                              hashtag: filteringHashtag
                            })
                      }
                    >
                      {t("general.dashboard.hashtag")} –{" "}
                      <b>#{filteringHashtag}</b>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      id="ddb_hashtag_tooltip"
                      text={t("general.dashboard.tooltip.no_hashtag", {
                        hashtag: filteringHashtag
                      })}
                    >
                      <b>{t("general.dashboard.no_filtering_with_hashtag")}</b>
                    </Tooltip>
                  )}
                </>
              ) : (
                <Tooltip
                  id="ddb_no_fetching_tooltip"
                  text={
                    isCommunity
                      ? t("general.dashboard.tooltip.manual_lo_com")
                      : t("general.dashboard.tooltip.manual")
                  }
                >
                  <b>
                    {isCommunity
                      ? t("general.dashboard.no_social_ads_included_lo_com")
                      : t("general.dashboard.no_social_ads_included")}
                  </b>
                </Tooltip>
              )}
            </Text>
          </Box>
          <Box className="ddb-mobile">
            <Text>
              <div>
                <Tooltip
                  id="ddb_slots_tooltip_mobile"
                  text={placesTip}
                >
                  {isCommunity
                    ? t("general.dashboard.available_slots_lo_com")
                    : t("general.dashboard.available_slots")}{" "}
                  –{" "}
                  <b>
                    {`${places - placesUsed} ${t(
                      "general.dashboard.available_slots_of"
                    )} ${places}`}{" "}
                  </b>
                </Tooltip>
              </div>

              <div>
                {accountRotationDays ? (
                  <Tooltip
                    id="ddb_rotation_tooltip_mobile"
                    text={rotationTip}
                  >
                    {isCommunity
                      ? t("general.dashboard.ads_visibility_lo_com")
                      : t("general.dashboard.ads_visibility")}{" "}
                    – <b>{rotation} </b>
                  </Tooltip>
                ) : (
                  <Tooltip
                    id="ddb_rotation_tooltip_mobile"
                    text={
                      isCommunity
                        ? t(
                            "general.dashboard.tooltip.lifetime_unlimited_lo_com"
                          )
                        : t("general.dashboard.tooltip.lifetime_unlimited")
                    }
                  >
                    {isCommunity
                      ? t("general.dashboard.ads_visibility_lo_com")
                      : t("general.dashboard.ads_visibility")}{" "}
                    – <b>{t("general.dashboard.ads_visibility_unlimited")} </b>
                  </Tooltip>
                )}
              </div>

              <div>
                {fetchingEnabled ? (
                  <>
                    {filteringEnabled ? (
                      <Tooltip
                        id="ddb_hashtag_tooltip_mobile"
                        text={
                          isCommunity
                            ? t("general.dashboard.tooltip.hashtag_lo_com", {
                                hashtag: filteringHashtag
                              })
                            : t("general.dashboard.tooltip.hashtag", {
                                hashtag: filteringHashtag
                              })
                        }
                      >
                        {t("general.dashboard.hashtag")} –{" "}
                        <b>#{filteringHashtag}</b>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        id="ddb_hashtag_tooltip_mobile"
                        text={t("general.dashboard.tooltip.no_hashtag", {
                          hashtag: filteringHashtag
                        })}
                      >
                        <b>
                          {t("general.dashboard.no_filtering_with_hashtag")}
                        </b>
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <Tooltip
                    id="ddb_no_fetching_tooltip_mobile"
                    text={
                      isCommunity
                        ? t("general.dashboard.tooltip.manual_lo_com")
                        : t("general.dashboard.tooltip.manual")
                    }
                  >
                    <b>
                      {isCommunity
                        ? t("general.dashboard.no_social_ads_included_lo_com")
                        : t("general.dashboard.no_social_ads_included")}
                    </b>
                  </Tooltip>
                )}
              </div>
            </Text>
          </Box>
        </Box>
        <Separator />
        <Box paddingH={2} paddingV={2}>
          <Text
            small
            lineHeight="1rem"
            color={stylesConstants.COLOR_GRAY_450}
            marginB={1}
          >
            {isCommunity
              ? t("general.dashboard.active_ads_lo_com")
              : t("general.dashboard.active_ads")}
          </Text>
          <Box>
            <WidgetAdsList>
              {ads.map((ad) => (
                <Box as="li" key={ad.id} marginH={1} marginB={2}>
                  <WidgetAd {...ad} />
                </Box>
              ))}
              <Box as="li" key="create_ad" marginH={1} marginB={2}>
                <CreateWidgetAd
                  url={createWidgetAdUrl}
                  isCommunity={isCommunity}
                />
              </Box>
            </WidgetAdsList>
          </Box>
        </Box>
      </WidgetBox>
    </>
  );
};

export default Widget;
