import {
  format,
  subMonths
} from "date-fns";

const USER_PASSWORD_MIN_LEN = 6;

const ANALYTICS_SUMMARY_PERIOD = 30;

const POST_URL_REG = /https:\/\/www\.facebook\.com\/.+|https:\/\/www\.instagram\.com\//;
const URL_REG = /^(http[s]?:\/\/)(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/;

const AD_DESCRIPTION_MAX_LEN = 200;
const AD_IMAGE_MAX_SIZE = 1000000;
const AD_IMAGE_FORMATS = "image/jpeg, image/png, image/webp";
const AD_ROTATION_DAYS_MAX = 10000;

const ADS_PER_PAGE = 10;

const ANALYTICS_ADS_PER_PAGE = 10;
const ANALYTICS_INITIAL_START_DATE = format(subMonths(new Date(), 1), "yyyy-MM-dd");
const ANALYTICS_INITIAL_END_DATE = format(new Date(), "yyyy-MM-dd");
const ANALYTICS_ENV_TYPE = "Widget";
const ANALYTICS_SORT_BY = "views";
const ANALYTICS_SORT_ORDER = "desc";

const constants = {
  USER_PASSWORD_MIN_LEN,
  ANALYTICS_SUMMARY_PERIOD,
  POST_URL_REG,
  URL_REG,
  AD_DESCRIPTION_MAX_LEN,
  AD_IMAGE_MAX_SIZE,
  AD_IMAGE_FORMATS,
  AD_ROTATION_DAYS_MAX,
  ADS_PER_PAGE,
  ANALYTICS_ADS_PER_PAGE,
  ANALYTICS_INITIAL_START_DATE,
  ANALYTICS_INITIAL_END_DATE,
  ANALYTICS_ENV_TYPE,
  ANALYTICS_SORT_BY,
  ANALYTICS_SORT_ORDER
};

export default constants;
