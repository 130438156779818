const getCookieByName = (name: string) => {
  const matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" + name.replace(/([.$?*|{}()[\]\\/+^])/g, "\\$1") + "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

const setCookie = (
  name: string,
  value: string | number,
  options?: {
    path?: string
    expires?: Date | string | number
  }
) => {
  const _options = { ...options, path: "/" } || { path: "/" };
  
  let expires = _options.expires;
  
  if (expires) {
    if (typeof expires === "number") {
      const d = new Date();
      d.setTime(d.getTime() + expires * 1000);
      expires = d;
    }
  
    if (expires instanceof Date) {
      _options.expires = expires.toUTCString();
    }
  }

  value = encodeURIComponent(value);
  
  let updatedCookie = name + "=" + value;

  for (const propName in _options) {
    updatedCookie += "; " + propName;
    const propValue = _options[propName];

    if (propValue !== true) {
      updatedCookie += "=" + propValue;
    }
  }

  document.cookie = updatedCookie;
}

const deleteCookie = (name: string) => {
  setCookie(name, "", { expires: -1 });
}

export { getCookieByName, setCookie, deleteCookie };
