import React, { useCallback, useContext } from "react";
import { Helmet } from "react-helmet";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AppContext } from "../../components/_functional/AppProvider";
import { requestNewPassword } from "../../util/apiRequests";
import Box from "../../components/particles/Box";
import Text from "../../components/atoms/Text";
import Button from "../../components/atoms/Button";
import TextInput from "../../components/organisms/TextInput";
import Popup from "../../components/organisms/Popup";
import { PageBox, TileContainer } from "./styled";

const Component = () => {
  const { t } = useTranslation();

  const { storePopup, resetPopup, popup } = useContext(AppContext);

  const formMethods = useForm({
    mode: "onChange"
  });

  const { formState, handleSubmit } = formMethods;
  const { isSubmitting, isValid } = formState;
  const isSubmissionDisabled = !isValid || isSubmitting;

  const onSubmitSuccess = useCallback(
    () =>
      storePopup({
        title: t("session.reset_success_message_title"),
        text: t("session.reset_success_message")
      }),
    []
  );

  const onSubmit = useCallback(
    (data) => {
      const submitData = {
        ad_user: {
          email: data.email
        }
      };

      return requestNewPassword(submitData)
        .then(onSubmitSuccess)
        .catch(onSubmitSuccess);
    },
    [onSubmitSuccess]
  );

  return (
    <>
      <Helmet>
        <title>
          {t("session.request_new_password_title")} |{" "}
          {t("general.product_name_dashboard_general")}
        </title>
      </Helmet>
      <PageBox>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              as="section"
              className="ddb-max-content-width-s"
              paddingT={5}
              paddingB={2}
              marginHAuto
            >
              <Box marginB={2}>
                <Text as="h1" styledAs="h3">
                  {t("session.request_new_password", {
                    product: t("general.product_name_dashboard_general")
                  })}
                </Text>
              </Box>
              <Box marginB={2}>
                <TileContainer border={false}>
                  <Box>
                    <Text marginV={2}>
                      {t("session.request_new_password_desc")}
                    </Text>

                    <Box marginB={2}>
                      <TextInput
                        name="email"
                        type="email"
                        autoComplete="email"
                        label={t("session.email")}
                        required
                        requiredErrorMessage={t(
                          "errors.required_specific_field",
                          { input: t("session.email_label") }
                        )}
                      />
                    </Box>

                    <Box flex justifyRight marginT={4}>
                      <Button
                        primary
                        type="submit"
                        disabled={isSubmissionDisabled}
                      >
                        {t("session.request_new_password_submit")}
                      </Button>
                    </Box>
                  </Box>
                </TileContainer>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </PageBox>
      <div aria-live="polite">
        {!!popup && <Popup {...popup} onClose={resetPopup} titleAs="h2" />}
      </div>
    </>
  );
};

export default Component;
