import React, { VFC, useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getRequestErrorMessage } from "../../../util/request";
import { switchSource } from "../../../util/apiRequests";
import { updateObjInArr } from "../../../util/helpers";
import Box from "../../particles/Box";
import Text from "../../atoms/Text";
import Button from "../../atoms/Button";
import ConnectedPage from "../ConnectedPage";

type Page = {
  id: number;
  name?: string;
  url: string;
  remote_id?: string;
  type: "facebook" | "instagram";
  enabled: boolean;
  syncError: boolean;
  authError: boolean;
};

interface ConnectedPagesProps {
  isCommunity: boolean;
  connectAction: () => void;
  reconnectAction: (id?: string) => void;
  pages: Page[];
  setPages: (pages: Page[]) => void;
  storeModal: (modal: {
    title: string;
    text: string;
    ctaAction?: () => void;
    ctaText?: string;
    secondaryActionText?: string;
    secondaryAction?: () => void;
    onClose: () => void;
  }) => void;
  resetModal: () => void;
  isEnabledDuplicate: (pageName: string) => boolean;
}

const ConnectedPages: VFC<ConnectedPagesProps> = ({
  isCommunity,
  connectAction,
  reconnectAction,
  pages,
  setPages,
  storeModal,
  resetModal,
  isEnabledDuplicate
}) => {
  const { t } = useTranslation();

  const { newspaperSlug } = useParams();

  const [searchParams] = useSearchParams();
  const customerId = searchParams.get("customer_id");

  const switchPage = useCallback(
    (id, enabled, callback) => {
      resetModal();

      return switchSource(id, !enabled, newspaperSlug, customerId)
        .then((_page) => {
          const _pages = updateObjInArr(pages, id, _page);
          setPages(_pages);
        })
        .catch((response) =>
          storeModal({
            title: t("errors.generic_title"),
            text: getRequestErrorMessage(response, t),
            ctaAction: () => resetModal(),
            ctaText: t("errors.close_error"),
            onClose: () => {
              resetModal();
              callback();
            }
          })
        )
        .finally(callback);
    },
    [pages, newspaperSlug, customerId]
  );

  const onSwitchPage = useCallback(
    (id, name, type, enabled, callback) => {
      if (!enabled && isEnabledDuplicate(name)) {
        storeModal({
          title: t("messages.duplicates.title"),
          text: isCommunity
            ? t("messages.duplicates.body_lo_com", {
                type: type === "facebook" ? "Instagram" : "Facebook",
                name
              })
            : t("messages.duplicates.body", {
                type: type === "facebook" ? "Instagram" : "Facebook",
                name
              }),
          ctaAction: () => {
            resetModal();
            callback();
          },
          ctaText: t("messages.duplicates.cta_btn"),
          secondaryAction: () => switchPage(id, enabled, callback),
          secondaryActionText: t("messages.duplicates.secondary_btn"),
          onClose: () => {
            resetModal();
            callback();
          }
        });
      } else {
        return switchPage(id, enabled, callback);
      }
    },
    [isEnabledDuplicate, switchPage]
  );

  return (
    <Box marginHAuto paddingT={2} paddingB={3}>
      <Box flex justifyRight alignCenter marginB={2}>
        <Box flexDynamic marginT={1} marginR={2}>
          <Text as="h2" styledAs="h3">
            {t("general.connections.page_title")}
          </Text>
        </Box>

        <Box flex justifyRight marginT={1}>
          <Button onClick={connectAction}>
            <span className="ddb-desktop ddb-desktop-s ddb-mobile-l">
              {t("general.connections.connect_btn")}
            </span>
            <span className="ddb-mobile">
              {t("general.connections.connect_btn_mobile")}
            </span>
          </Button>
        </Box>
      </Box>

      {pages.map((page) => (
        <Box key={page.id}>
          <ConnectedPage
            {...page}
            isCommunity={isCommunity}
            onSwitch={onSwitchPage}
            reconnectAction={reconnectAction}
          />
        </Box>
      ))}
    </Box>
  );
};

export default ConnectedPages;
