import React, { VFC, useRef, useEffect } from "react";
import {
  useSelect,
  useListBox,
  useOption,
  DismissButton,
  Overlay,
  usePopover,
  useButton
} from "react-aria";
import type { AriaPopoverProps } from "react-aria";
import { Item, useSelectState } from "react-stately";
import type { OverlayTriggerState } from "react-stately";

import constants from "../../_globalStyles/constants";
import { pxToRem, pxToEm } from "../../../util/helpers";
import Text from "../../atoms/Text";
import Icon from "../../atoms/Icon";
import { DropdownWrap, DropdownLi, DropdownOption } from "./styled";

const Button = (props) => {
  const ref = props.buttonRef;
  const { buttonProps } = useButton(props, ref);

  return (
    <button {...buttonProps} ref={ref}>
      {props.children}
    </button>
  );
};

const Option = ({ item, state }) => {
  const ref = useRef(null);
  const { optionProps, isSelected, isFocused } = useOption(
    { key: item.key },
    state,
    ref
  );

  return (
    <DropdownLi {...optionProps} ref={ref}>
      <DropdownOption
        as="div"
        medium
        fontSize={pxToRem(16)}
        lineHeight={pxToEm(20, 16)}
        color={constants.COLOR_BLACK}
        paddingV={1.25}
        paddingH={2}
        active={isSelected || isFocused}
        className={isFocused ? "ddb-is-focused" : ""}
      >
        {item.rendered}
      </DropdownOption>
    </DropdownLi>
  );
};

const ListBox = (props) => {
  const ref = useRef(null);
  const { listBoxRef = ref, state } = props;
  const { listBoxProps } = useListBox(props, state, listBoxRef);

  return (
    <ul
      {...listBoxProps}
      ref={listBoxRef}
      style={{
        listStyle: "none",
        margin: "0",
        padding: "0",
        outline: "none"
      }}
    >
      {[...state.collection].map((item) => (
        <Option key={item.key} item={item} state={state} />
      ))}
    </ul>
  );
};

interface PopoverProps extends Omit<AriaPopoverProps, "popoverRef"> {
  children: React.ReactNode;
  state: OverlayTriggerState;
}

const Popover = ({ children, state, ...props }: PopoverProps) => {
  const popoverRef = useRef(null);
  const { popoverProps, underlayProps } = usePopover(
    {
      ...props,
      popoverRef
    },
    state
  );

  return (
    <Overlay>
      <div {...underlayProps} style={{ position: "fixed", inset: 0 }} />
      <div
        {...popoverProps}
        ref={popoverRef}
        style={{
          ...popoverProps.style,
          background: "#ffffff",
          borderRadius: `${constants.GRID_UNIT * 1.5}px`,
          border: "1px solid rgba(0, 0, 0, 0.12)",
          boxShadow: "0px 4px 16px 0px #0000001F",
          overflow: "hidden",
          overflowY: "scroll",
          marginTop: `${constants.GRID_UNIT * 1.5}px`,
          maxWidth: `${constants.GRID_UNIT * 60}px`,
          width: "100%"
        }}
      >
        <DismissButton onDismiss={state.close} />
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
};

const Select = (props) => {
  const { label, onSelect, defaultSelectedKey } = props;
  const state = useSelectState(props);
  const { setSelectedKey } = state;
  const { selectedKey } = state;

  const ref = useRef(null);

  const { isOpen } = state;

  const { labelProps, triggerProps, valueProps, menuProps } = useSelect(
    props,
    state,
    ref
  );

  useEffect(() => {
    onSelect &&  onSelect(selectedKey)
  }, [onSelect, selectedKey]);

  useEffect(() => {
    setSelectedKey(defaultSelectedKey);
  }, [defaultSelectedKey])

  return (
    <DropdownWrap isOpen={isOpen}>
      <div {...labelProps} className="ddb-visually-hidden">
        {label}
      </div>

      <Button type="button" buttonRef={ref} {...triggerProps}>
        <Text as="span" styledAs="h2" {...valueProps}>
          {state.selectedItem ? state.selectedItem.rendered : label}
        </Text>
        <Icon name="select" />
      </Button>

      {state.isOpen && (
        <Popover state={state} triggerRef={ref} placement="bottom start">
          <ListBox {...menuProps} state={state} />
        </Popover>
      )}
    </DropdownWrap>
  );
};

interface TextDropdownProps {
  label: string;
  options: {
    title: string;
    value: number | string;
  }[];
  selectedOption?: number | string;
  onSelect: (value: number | string) => void;
}

const TextDropdown: VFC<TextDropdownProps> = ({
  label,
  options,
  selectedOption,
  onSelect
}) => {
  return (
    <Select
      label={label}
      defaultSelectedKey={selectedOption && selectedOption.toString()}
      onSelect={onSelect}
    >
      {options.map((option) => (
        <Item key={option.value}>{option.title}</Item>
      ))}
    </Select>
  );
};

export default TextDropdown;
