import React, { VFC, ReactNode } from "react";

import { LoaderWrap, LoaderBounce } from "./styled";

interface LoaderProps {
  children: ReactNode;
  active: boolean;
}

const Loader: VFC<LoaderProps> = ({ children, active }) => {
  return active ? (
    <LoaderWrap>
      <LoaderBounce />
      <LoaderBounce />
      <LoaderBounce />
    </LoaderWrap>
  ) : (
    <>{children}</>
  );
};

export default Loader;
