import React, { FC, VFC, ReactNode, useState, useCallback } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";

import { pxToRem } from "../../../util/helpers";
import constants from "../../_globalStyles/constants";
import Box, { BoxProps } from "../../particles/Box";
import Button, { ButtonProps } from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import SliderMessage from "../SliderMessage";

interface SliderProps {
  slides: {
    icon?: string;
    title: string;
    titleAs?: string;
    text: string | ReactNode;
    image?: {
      src: string;
      alt?: string;
    };
    ctaText?: string;
    ctaLink?: string;
    ctaAction?: () => void;
  }[];
}

const SliderBox: FC<BoxProps> = styled(Box)`
  padding: 0 ${constants.GRID_UNIT * 17}px;

  @media (max-width: ${constants.BREAKPOINT_MOBILE_L}px) {
    padding: ${constants.GRID_UNIT}px 0 0; //  TODO: top padding is needed only until unified header is implemented.
  }
`;

SliderBox.displayName = "SliderBox";

const NavButton: FC<ButtonProps> = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);

  svg {
    font-size: ${pxToRem(32)};
  }

  @media (max-width: ${constants.BREAKPOINT_MOBILE_L}px) {
    position: fixed;
    top: calc(100% - ${constants.GRID_UNIT * 15}px);
    transform: translate(0, 0);
  }
`;

const PrevButton: FC<ButtonProps> = styled(NavButton)`
  left: ${constants.GRID_UNIT * 4}px;

  svg {
    transform: translate(-3px);
  }

  @media (max-width: ${constants.BREAKPOINT_MOBILE_L}px) {
    left: ${constants.GRID_UNIT * 2}px;
  }
`;

PrevButton.displayName = "PrevButton";

const NextButton: FC<ButtonProps> = styled(NavButton)`
  right: ${constants.GRID_UNIT * 4}px;

  svg {
    transform: translate(3px) rotate(180deg);
  }

  @media (max-width: ${constants.BREAKPOINT_MOBILE_L}px) {
    right: ${constants.GRID_UNIT * 2}px;
  }
`;

NextButton.displayName = "NextButton";

const TransitionWrap = styled.div`
  ${({ visible }) =>
    !visible &&
    css`
      display: none;
    `}
`;

TransitionWrap.displayName = "TransitionWrap";

const Slider: VFC<SliderProps> = ({ slides }) => {
  const { t } = useTranslation();

  const [activePage, setActivePage] = useState(1);
  const onPageClick = useCallback((page) => setActivePage(page), []);
  const pagesNumber = slides.length;

  const pagination = {
    pagesNumber,
    activePage,
    onPageClick
  };

  return (
    <SliderBox
      relative
      width={`${constants.GRID_UNIT * 128}px`}
      maxWidth="100%"
      marginHAuto
    >
      {slides.map((slide, index) => (
        <TransitionWrap key={index} visible={index + 1 === activePage}>
          <SliderMessage key={index} {...slide} pagination={pagination} />
        </TransitionWrap>
      ))}

      <TransitionWrap visible={activePage !== 1}>
        <PrevButton
          onClick={() => onPageClick(activePage - 1)}
          big
          dark
          label={t("general.slider.prev_slide")}
        >
          <Icon name="arrow" />
        </PrevButton>
      </TransitionWrap>

      <TransitionWrap visible={activePage !== pagesNumber}>
        <NextButton
          onClick={() => onPageClick(activePage + 1)}
          big
          dark
          label={t("general.slider.next_slide")}
        >
          <Icon name="arrow" />
        </NextButton>
      </TransitionWrap>
    </SliderBox>
  );
};

export default Slider;
