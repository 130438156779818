import { FC, HTMLProps } from "react";
import styled from "styled-components";

import constants from "../../_globalStyles/constants";
import Box, { BoxProps } from "../../particles/Box";
import Tile, { TileProps } from "../../atoms/Tile";
import Icon, { IconProps } from "../../atoms/Icon";

interface AdMediaProps {
  media?: string;
}

export const AdLink: FC<HTMLProps<HTMLAnchorElement>> = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border-radius: ${constants.GRID_UNIT * 1.5}px;

  &:hover,
  &:active {
    box-shadow: 0 ${constants.GRID_UNIT / 2}px ${constants.GRID_UNIT * 3}px 0
      rgba(0, 0, 0, 0.1);
  }

  transition: box-shadow 0.05s ease-in;
`;

AdLink.displayName = "AdLink";

export const AdTile: FC<TileProps> = styled(Tile)`
  position: relative;
  width: 236px;
  height: 309px;
  padding: 0;
  display: flex;
  flex-direction: column;
  background: ${constants.MEDIA_BG_COLOR};
  overflow: visible;
  z-index: 0;
`;

AdTile.displayName = "AdTile";

export const AdLogo: FC<HTMLProps<HTMLImageElement>> = styled.img`
  border-radius: 50%;
  overflow: hidden;
  width: 2.125rem;
  height: 2.125rem;
  object-fit: contain;
`;

AdLogo.displayName = "AdLogo";

export const AdMedia: FC<AdMediaProps> = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  height: 0;
  background-image: ${({ media }) => media && `url("${media}")`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-top-left-radius: ${constants.GRID_UNIT * 1.5}px;
  border-top-right-radius: ${constants.GRID_UNIT * 1.5}px;
  overflow: hidden;
`;

AdMedia.displayName = "AdMedia";

export const AdImageLoader: FC = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  width: 40px;
  height: 40px;
  color: ${constants.LOADER_COLOR};
  border: 0.2em solid currentcolor;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: loader 1s linear infinite;
  font-size: 20px;

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  z-index: -1;
`;

AdImageLoader.displayName = "AdImageLoader";

export const AdPlayIcon: FC = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 60px;
  height: 60px;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA1NiA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTU0LjUgMjhDNTQuNSA0Mi42MzU1IDQyLjYzNTUgNTQuNSAyOCA1NC41QzEzLjM2NDUgNTQuNSAxLjUgNDIuNjM1NSAxLjUgMjhDMS41IDEzLjM2NDUgMTMuMzY0NSAxLjUgMjggMS41QzQyLjYzNTUgMS41IDU0LjUgMTMuMzY0NSA1NC41IDI4WiIgZmlsbD0iYmxhY2siIGZpbGwtb3BhY2l0eT0iMC4xNiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIzIi8+CjxwYXRoIGQ9Ik00Mi4wNjg0IDI5Ljc4OTVMMjEuNzY1NCA0Mi43MjgxQzIwLjg3OTEgNDMuMjkyOSAxOS43NSA0Mi45NDk1IDE5LjI0MzUgNDEuOTYxQzE5LjA4MzkgNDEuNjQ5NiAxOSA0MS4yOTcxIDE5IDQwLjkzODNWMTUuMDYxM0MxOSAxMy45MjI5IDE5LjgyNzUgMTMgMjAuODQ4MyAxM0MyMS4xNyAxMyAyMS40ODYxIDEzLjA5MzYgMjEuNzY1NCAxMy4yNzE2TDQyLjA2ODQgMjYuMjEwMUM0Mi45NTQ3IDI2Ljc3NDkgNDMuMjYyNiAyOC4wMzQxIDQyLjc1NjIgMjkuMDIyNUM0Mi41OTI0IDI5LjM0MjEgNDIuMzU0OSAyOS42MDY5IDQyLjA2ODQgMjkuNzg5NVYyOS43ODk1WiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==)
    no-repeat center;
  transform: translate(-50%, -50%);
`;

AdPlayIcon.displayName = "AdPlayIcon";

export const AdMediaIcon: FC<IconProps> = styled(Icon)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: ${constants.NO_MEDIA_ICON_COLOR};
  font-size: ${constants.GRID_UNIT * 8}px;
`;

AdMediaIcon.displayName = "AdMediaIcon";

export const AdContentBox: FC<BoxProps> = styled(Box)`
  height: 100%;
  background: ${constants.MAIN_BG_COLOR};
  border-bottom-left-radius: ${constants.GRID_UNIT * 1.5}px;
  border-bottom-right-radius: ${constants.GRID_UNIT * 1.5}px;
  overflow: hidden;
`;

AdContentBox.displayName = "AdContentBox";
