import React, { VFC, useContext } from "react";
import { Navigate } from "react-router-dom";

import { AppContext } from "../../components/_functional/AppProvider";
import { getCookieByName } from "../../util/cookieProvider";
import Box from "../../components/particles/Box";
import PageMessage from "../../components/templates/PageMessage";
import Component from "./Component";

const RequestNewPassword: VFC = () => {
  const { message } = useContext(AppContext);

  const isUserAuthenticated = Boolean(getCookieByName("ddb_session_token"));

  return isUserAuthenticated ? (
    <Navigate to="/" replace />
  ) : (
    <>
      <div aria-live="polite">
        {!!message && (
          <Box paddingT={7} paddingH={1} paddingB={1}>
            <PageMessage {...message} />
          </Box>
        )}
      </div>
      {!message && <Component />}
    </>
  );
};

export default RequestNewPassword;
