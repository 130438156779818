import React, { VFC, useContext } from "react";
import { useSearchParams } from "react-router-dom";

import { AppContext } from "../../components/_functional/AppProvider";
import GetDashboardData from "../../components/_functional/GetDashboardData";
import GetUser from "../../components/_functional/GetUser";
import SetDefaultCustomer from "../../components/_functional/SetDefaultCustomer";
import GetCustomer from "../../components/_functional/GetCustomer";
import SyncNewspaperWithUrl from "../../components/_functional/SyncNewspaperWithUrl";
import CheckSuspension from "../../components/_functional/CheckSuspension";
import FBInit from "../../components/_functional/FBInit";
import Box from "../../components/particles/Box";
import PageMessage from "../../components/templates/PageMessage";
import PageLayout from "../../components/templates/PageLayout";
import PageNavigation from "../../components/templates/PageNavigation";
import ConnectionsContainer from "./Container";

const Connections: VFC = () => {
  const [searchParams] = useSearchParams();
  const customerToken = searchParams.get("admin_customer_token");

  const { message } = useContext(AppContext);

  return (
    <>
      <div aria-live="polite">
        {!!message && (
          <Box paddingT={7} paddingH={1} paddingB={1}>
            <PageMessage {...message} />
          </Box>
        )}
      </div>
      {customerToken ? (
        <>
          {!message && (
            <GetDashboardData>
              <PageLayout>
                <PageNavigation>
                  <FBInit>
                    <ConnectionsContainer />
                  </FBInit>
                </PageNavigation>
              </PageLayout>
            </GetDashboardData>
          )}
        </>
      ) : (
        <>
          {!message && (
            <GetUser>
              <SyncNewspaperWithUrl>
                <SetDefaultCustomer>
                  <PageLayout>
                    <PageNavigation>
                      <GetCustomer>
                        <CheckSuspension>
                          <FBInit>
                            <ConnectionsContainer />
                          </FBInit>
                        </CheckSuspension>
                      </GetCustomer>
                    </PageNavigation>
                  </PageLayout>
                </SetDefaultCustomer>
              </SyncNewspaperWithUrl>
            </GetUser>
          )}
        </>
      )}
    </>
  );
};

export default Connections;
