import React, { useCallback, useEffect, VFC, ReactNode } from "react";
import { FocusScope, useModal, usePreventScroll } from "react-aria";
import { useTranslation } from "react-i18next";

import Box from "../../particles/Box";
import Text from "../../atoms/Text";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import IconBadge from "../../molecules/IconBadge";
import { ModalTile, ModalOverlay, CloseButton } from "./styled";

export interface ModalProps {
  onClose: () => void;
  title?: string;
  text?: string;
  icon?: string;
  ctaText?: string;
  ctaAction?: () => void;
  secondaryActionText?: string;
  secondaryAction?: () => void;
  controlElement?: ReactNode;
  status?: "success" | "warning" | "error";
  customIcon?: boolean;
  customContent?: ReactNode;
  textLeft?: boolean;
  controlsWidth?: number | string;
}

const Modal: VFC<ModalProps> = ({
  onClose,
  title,
  text,
  icon,
  ctaText,
  ctaAction,
  secondaryActionText,
  secondaryAction,
  controlElement,
  customIcon,
  status,
  customContent,
  textLeft,
  controlsWidth
}) => {
  const { t } = useTranslation();

  usePreventScroll();
  const { modalProps } = useModal();

  const closeOnEsc = useCallback(
    (e) => {
      e = e || window.event;
      if (e.keyCode == 27) close();
    },
    [close]
  );

  useEffect(() => {
    document.addEventListener("keyup", closeOnEsc);

    return () => {
      document.removeEventListener("keyup", closeOnEsc);
    };
  }, []);

  return (
    <>
      <ModalOverlay />
      <FocusScope contain restoreFocus autoFocus>
        <div {...modalProps}>
          {customContent ? (
            <ModalTile
              relative
              paddingT={2.75}
              paddingB={2}
              paddingH={2}
              radius={4}
            >
              <CloseButton
                clean
                onClick={onClose}
                label={t("general.modal.close")}
              >
                <Icon name="close" />
              </CloseButton>

              {customContent}
            </ModalTile>
          ) : (
            <>
              <ModalTile
                role="dialog"
                aria-labelledby="dialog_title_desk"
                aria-describedby="dialog_desc_desk"
                relative
                paddingT={8}
                paddingB={9}
                paddingH={11}
                radius={4}
                className="ddb-desktop ddb-desktop-s ddb-mobile-l"
              >
                <CloseButton
                  clean
                  onClick={onClose}
                  label={t("general.modal.close")}
                >
                  <Icon name="close" />
                </CloseButton>

                <Box textCenter>
                  {icon && (
                    <IconBadge
                      name={icon}
                      status={status}
                      custom={customIcon}
                    />
                  )}
                  <Box marginT={4} marginB={2}>
                    <Text id="dialog_title_desk" as="h1" styledAs="h1">
                      {title}
                    </Text>
                  </Box>
                  {text && (
                    <Text
                      id="dialog_desc_desk"
                      as="p"
                      big
                      formatted
                      textLeft={textLeft}
                      lineHeight="1.4"
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                  )}
                  <Box width={controlsWidth || 30} marginT={6} marginHAuto>
                    {ctaText && ctaAction && (
                      <Button cta width="100%" onClick={ctaAction}>
                        {ctaText}
                      </Button>
                    )}
                    {secondaryActionText && secondaryAction && (
                      <Box marginT={1}>
                        <Button big width="100%" onClick={secondaryAction}>
                          {secondaryActionText}
                        </Button>
                      </Box>
                    )}
                    {controlElement && controlElement}
                  </Box>
                </Box>
              </ModalTile>
              <ModalTile
                role="dialog"
                aria-labelledby="dialog_title_mobile"
                aria-describedby="dialog_desc_mobile"
                relative
                paddingT={4}
                paddingB={2}
                paddingH={3}
                className="ddb-mobile"
              >
                <CloseButton
                  clean
                  onClick={onClose}
                  label={t("general.modal.close")}
                >
                  <Icon name="close" />
                </CloseButton>

                <Box textCenter>
                  {icon && (
                    <IconBadge
                      name={icon}
                      status={status}
                      custom={customIcon}
                      small
                    />
                  )}
                  <Box marginT={3} marginB={1}>
                    <Text id="dialog_title_mobile" as="h1" styledAs="h4">
                      {title}
                    </Text>
                  </Box>
                  {text && (
                    <Text
                      id="dialog_desc_mobile"
                      as="p"
                      formatted
                      textLeft={textLeft}
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                  )}
                  <Box marginT={3} marginHAuto>
                    {ctaText && ctaAction && (
                      <Box marginB={1} marginH={1} inlineBlock>
                        <Button primary onClick={ctaAction}>
                          {ctaText}
                        </Button>
                      </Box>
                    )}
                    {secondaryActionText && secondaryAction && (
                      <Button onClick={secondaryAction}>{secondaryActionText}</Button>
                    )}
                    {controlElement && controlElement}
                  </Box>
                </Box>
              </ModalTile>
            </>
          )}
        </div>
      </FocusScope>
    </>
  );
};

export default Modal;
