import React, { FC, ReactNode, createContext, useReducer } from "react";

// TODO: remove widget related code
import { PopupProps } from "../organisms/Popup";
import { ModalProps } from "../organisms/Modal";
import { PageMessageProps } from "../templates/PageMessage";
import {
  NewspaperSettings,
  Newspaper,
  User,
  Customer,
  Page,
  WidgetsItem,
  Widget,
  Ad,
  FormAd,
  Contacts
} from "../../types";

export interface ActionProps {
  type: string;
  payload?: any;
}

// TODO: remove message related code when 2d phase is not supported anymore;
// TODO: remove widgetsAds related code when 2d phase is not supported anymore.
interface StateProps {
  user: User;
  FBAppId: string;
  FBAppStatus: "initializing" | "initialized" | "unset";
  newspaperSettings: NewspaperSettings;
  newspaper: Newspaper;
  customer: Customer;
  widget: Widget;
  widgets: WidgetsItem[];
  widgetsAds: any;
  ads: Ad[];
  ad: FormAd;
  pages: Page[];
  popup: PopupProps;
  modal: ModalProps;
  message: PageMessageProps;
  contacts: Contacts
}

interface ActionsProps {
  storeUser: (arg: User) => void;
  resetUser: () => void;
  storeFBAppId: (arg: string) => void;
  resetFBAppId: () => void;
  storeFBAppStatus: (arg: "initializing" | "initialized" | "unset") => void;
  storeNewspaperSettings: (arg: NewspaperSettings) => void;
  resetNewspaperSettings: () => void;
  storeNewspaper: (arg: Newspaper) => void;
  resetNewspaper: () => void;
  storeCustomer: (arg: Customer) => void;
  resetCustomer: () => void;
  storeWidget: (arg: Widget) => void;
  resetWidget: () => void;
  storeWidgets: (arg: WidgetsItem[]) => void;
  resetWidgets: () => void;
  storeWidgetsAds: (arg: any) => void;
  resetWidgetsAds: () => void;
  storeAds: (arg: Ad[]) => void;
  resetAds: () => void;
  storeAd: (arg: FormAd) => void;
  resetAd: () => void;
  storePages: (arg: Page[]) => void;
  resetPages: () => void;
  storeContacts: (arg: Contacts) => void;
  resetContacts: () => void;
  storePopup: (arg: PopupProps) => void;
  resetPopup: () => void;
  storeModal: (arg: ModalProps) => void;
  resetModal: () => void;
  storeMessage: (arg: PageMessageProps) => void;
  resetMessage: () => void;
}

interface AppProviderProps {
  children: ReactNode;
}

const initialState = {
  user: null,
  FBAppId: null,
  FBAppStatus: "unset",
  newspaperSettings: null,
  newspaper: null,
  customer: null,
  widget: null,
  widgets: null,
  widgetsAds: null,
  ads: null,
  ad: null,
  pages: null,
  contacts: null,
  popup: null,
  modal: null,
  message: null
};

const reducer = (state: StateProps, action: ActionProps) => {
  switch (action.type) {
    case "store_user":
      return {
        ...state,
        user: action.payload
      };
    case "reset_user":
      return {
        ...state,
        user: null
      };
    case "store_FB_app_id":
      return {
        ...state,
        FBAppId: action.payload
      };
    case "reset_FB_app_id":
      return {
        ...state,
        FBAppId: null
      };
    case "store_FB_app_status":
      return {
        ...state,
        FBAppStatus: action.payload
      };
    case "store_newspaper_settings":
      return {
        ...state,
        newspaperSettings: action.payload
      };
    case "reset_newspaper_settings":
      return {
        ...state,
        newspaperSettings: null
      };
    case "store_newspaper":
      return {
        ...state,
        newspaper: action.payload
      };
    case "reset_newspaper":
      return {
        ...state,
        newspaper: null
      };
    case "store_customer":
      return {
        ...state,
        customer: action.payload
      };
    case "reset_customer":
      return {
        ...state,
        customer: null
      };
    case "store_widget":
      return {
        ...state,
        widget: action.payload
      };
    case "reset_widget":
      return {
        ...state,
        widget: null
      };
    case "store_widgets":
      return {
        ...state,
        widgets: action.payload
      };
    case "reset_widgets":
      return {
        ...state,
        widgets: null
      };
    case "store_widgets_ads":
      return {
        ...state,
        widgetsAds: action.payload
      };
    case "reset_widgets_ads":
      return {
        ...state,
        widgetsAds: null
      };
    case "store_ads":
      return {
        ...state,
        ads: action.payload
      };
    case "reset_ads":
      return {
        ...state,
        ads: null
      };
    case "store_ad":
      return {
        ...state,
        ad: action.payload
      };
    case "reset_ad":
      return {
        ...state,
        ad: null
      };
    case "store_pages":
      return {
        ...state,
        pages: action.payload
      };
    case "reset_pages":
      return {
        ...state,
        pages: null
      };
    case "store_contacts":
      return {
        ...state,
        contacts: action.payload
      };
    case "reset_contacts":
      return {
        ...state,
        contacts: null
      };
    case "store_popup":
      return {
        ...state,
        popup: action.payload
      };
    case "reset_popup":
      return {
        ...state,
        popup: null
      };
    case "store_modal":
      return {
        ...state,
        modal: action.payload
      };
    case "reset_modal":
      return {
        ...state,
        modal: null
      };
    case "store_message":
      return {
        ...state,
        message: action.payload
      };
    case "reset_message":
      return {
        ...state,
        message: null
      };
    default:
      return state;
  }
};

export const AppContext = createContext({} as StateProps & ActionsProps);

const AppProvider: FC<AppProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    ...state,
    storeUser: (arg) => dispatch({ type: "store_user", payload: arg }),
    resetUser: () => dispatch({ type: "reset_user" }),
    storeFBAppId: (arg) => dispatch({ type: "store_FB_app_id", payload: arg }),
    resetFBAppId: () => dispatch({ type: "reset_FB_app_id" }),
    storeFBAppStatus: (arg) =>
      dispatch({ type: "store_FB_app_status", payload: arg }),
    storeNewspaperSettings: (arg) =>
      dispatch({ type: "store_newspaper_settings", payload: arg }),
    resetNewspaperSettings: () =>
      dispatch({ type: "reset_newspaper_settings" }),
    storeNewspaper: (arg) =>
      dispatch({ type: "store_newspaper", payload: arg }),
    resetNewspaper: () => dispatch({ type: "reset_newspaper" }),
    storeCustomer: (arg) => dispatch({ type: "store_customer", payload: arg }),
    resetCustomer: () => dispatch({ type: "reset_customer" }),
    storeWidget: (arg) => dispatch({ type: "store_widget", payload: arg }),
    resetWidget: () => dispatch({ type: "reset_widget" }),
    storeWidgets: (arg) => dispatch({ type: "store_widgets", payload: arg }),
    resetWidgets: () => dispatch({ type: "reset_widgets" }),
    storeWidgetsAds: (arg) =>
      dispatch({ type: "store_widgets_ads", payload: arg }),
    resetWidgetsAds: () => dispatch({ type: "reset_widgets_ads" }),
    storeAds: (arg) => dispatch({ type: "store_ads", payload: arg }),
    resetAds: () => dispatch({ type: "reset_ads" }),
    storeAd: (arg) => dispatch({ type: "store_ad", payload: arg }),
    resetAd: () => dispatch({ type: "reset_ad" }),
    storePages: (arg) => dispatch({ type: "store_pages", payload: arg }),
    resetPages: () => dispatch({ type: "reset_pages" }),
    storeContacts: (arg) => dispatch({ type: "store_contacts", payload: arg }),
    resetContacts: () => dispatch({ type: "reset_contacts" }),
    storePopup: (arg) => dispatch({ type: "store_popup", payload: arg }),
    resetPopup: () => dispatch({ type: "reset_popup" }),
    storeModal: (arg) => dispatch({ type: "store_modal", payload: arg }),
    resetModal: () => dispatch({ type: "reset_modal" }),
    storeMessage: (arg) => dispatch({ type: "store_message", payload: arg }),
    resetMessage: () => dispatch({ type: "reset_message" })
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
