import React, { VFC, ReactNode } from "react";

import constants from "../../_globalStyles/constants";
import Box from "../../particles/Box";
import Text from "../../atoms/Text";
import Button from "../../atoms/Button";
import IconBadge from "../../molecules/IconBadge";
import UnorderedPagination from "../UnorderedPagination";
import { MessageBox, MessageImage, CtaBox } from "./styled";

interface SliderMessageProps {
  icon?: string;
  title: string;
  titleAs?: string;
  text: string | ReactNode;
  image?: {
    src: string;
    alt?: string;
  };
  pagination?: {
    activePage: number;
    pagesNumber: number;
    onPageClick: (page: number) => void;
  };
  ctaLink?: string;
  ctaText?: string;
  ctaAction?: () => void;
}

const SliderMessage: VFC<SliderMessageProps> = ({
  icon,
  title,
  titleAs,
  text,
  image,
  pagination,
  ctaText,
  ctaLink,
  ctaAction
}) => (
  <>
    <MessageBox
      width="100%"
      className="ddb-desktop ddb-desktop-s ddb-mobile-l"
      paddingB={7}
      minHeight={52.5}
      relative
    >
      {icon && (
        <Box marginB={3} textCenter>
          <IconBadge name={icon} />
        </Box>
      )}
      <Box marginB={4} textCenter>
        <Text as={titleAs} styledAs="h1" responsive>
          {title}
        </Text>
      </Box>
      <Box>
        {!image && (
          <Box marginB={3}>
            {typeof text === "string" ? <Text formatted>{text}</Text> : text}
            {pagination && (
              <Box flex justifyCenter marginT={3}>
                <UnorderedPagination {...pagination} />
              </Box>
            )}
          </Box>
        )}
        {image && (
          <Box flex alignCenter marginT={3} marginB={3}>
            <Box flexDynamic>
              {typeof text === "string" ? <Text formatted>{text}</Text> : text}
              {pagination && (
                <Box marginT={3}>
                  <UnorderedPagination {...pagination} />
                </Box>
              )}
            </Box>
            <Box flexStatic inlineFlex width="50%" marginL={4}>
              <MessageImage src={image.src} alt={image.alt || ""} />
            </Box>
          </Box>
        )}
      </Box>
      {ctaText && (ctaLink || ctaAction) && (
        <CtaBox flex justifyCenter>
          <Button
            cta
            width={`${constants.GRID_UNIT * 40}px`}
            onClick={ctaAction}
          >
            {ctaText}
          </Button>
        </CtaBox>
      )}
    </MessageBox>

    <MessageBox width="100%" relative className="ddb-mobile">
      {icon && (
        <Box marginB={2} textCenter>
          <IconBadge name={icon} small />
        </Box>
      )}
      <Box marginB={2}>
        <Text as={titleAs} styledAs="h3">
          {title}
        </Text>
      </Box>
      {!image && (
        <Box marginB={3}>
          {typeof text === "string" ? <Text formatted>{text}</Text> : text}
        </Box>
      )}
      {image && (
        <>
          <Box marginB={2}>
            {typeof text === "string" ? <Text formatted>{text}</Text> : text}
          </Box>
          <Box marginT={3} marginB={2}>
            <MessageImage src={image.src} alt={image.alt || ""} />
          </Box>
        </>
      )}
      {ctaText && (ctaLink || ctaAction) && (
        <CtaBox flex flexWrap justifyCenter>
          {pagination && (
            <Box flex justifyCenter paddingV={3.5} paddingH={7}>
              <UnorderedPagination {...pagination} />
            </Box>
          )}
          <Button cta width="100%" onClick={ctaAction}>
            {ctaText}
          </Button>
        </CtaBox>
      )}
    </MessageBox>
  </>
);

SliderMessage.defaultProps = {
  titleAs: "h2"
};

export default SliderMessage;
