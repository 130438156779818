import React, { VFC, MouseEvent, useMemo, useCallback } from "react";
import {
  useParams,
  useSearchParams,
  useNavigate,
  useLocation
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Widget, Ad as AdProps } from "../../types";
import Transition from "../../components/particles/Transition";
import Box from "../../components/particles/Box";
import Loader from "../../components/atoms/Loader";
import Text from "../../components/atoms/Text";
import Button from "../../components/atoms/Button";
import TabLink from "../../components/molecules/TabLink";
import Subheader from "../../components/templates/Subheader";
import WidgetLicense from "../../components/templates/WidgetLicense";
import Ad from "../../components/templates/Ad";
import CreateAd from "../../components/templates/CreateAd";
import {
  WidgetPageBox,
  ActiveAreaBox,
  InactiveAreaBox,
  TabsList
} from "./styled";

interface WidgetComponentProps extends Widget {
  isCommunity: boolean;
  filteringEnabled?: boolean;
  filteringHashtag?: string;
  showLoadMoreButton: boolean;
  loadMoreAds: () => void;
  editAd: (adId: number) => void;
  playAd: (widgetId: number, adId: number) => void;
  pauseAd: (widgetId: number, adId: number) => void;
  activeAds: AdProps[];
  inactiveAds: AdProps[];
  inactiveAdsTabs: {
    id: string;
    title: string;
    onClick: (e: MouseEvent<HTMLButtonElement>) => void;
    active: boolean;
  }[];
  activeTab: "inactive" | "paused" | "expired" | "hidden";
  inactiveAdsLoaded: boolean;
}

const WidgetComponent: VFC<WidgetComponentProps> = ({
  isCommunity,
  envId: id,
  name,
  places,
  placesUsed,
  rotation,
  fetchingEnabled,
  filteringEnabled,
  filteringHashtag,
  categories,
  activeAds,
  inactiveAds,
  showLoadMoreButton,
  loadMoreAds,
  playAd,
  pauseAd,
  editAd,
  inactiveAdsTabs,
  activeTab,
  inactiveAdsLoaded
}) => {
  const { t } = useTranslation();

  const location = useLocation();
  const { state } = location;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { newspaperSlug } = useParams();

  const backUrlAction = () =>
    state?.fromDashboard
      ? navigate(-1)
      : navigate(`/${newspaperSlug}/widgets?${searchParams.toString()}`);

  const createAd = useCallback(
    () => navigate(`promotions/new?${searchParams.toString()}`),
    [location]
  );

  const sectionHeading = useMemo(() => {
    if (activeTab === "inactive")
      return isCommunity
        ? t("general.widget.inactive_ads_tab_lo_com")
        : t("general.widget.inactive_ads_tab");
    if (activeTab === "paused")
      return isCommunity
        ? t("general.widget.paused_ads_tab_lo_com")
        : t("general.widget.paused_ads_tab");
    if (activeTab === "hidden")
      return isCommunity
        ? t("general.widget.hidden_ads_tab_lo_com")
        : t("general.widget.hidden_ads_tab");
    if (activeTab === "expired")
      return isCommunity
        ? t("general.widget.expired_ads_tab_lo_com")
        : t("general.widget.expired_ads_tab");
  }, [activeTab, isCommunity]);

  const tabPanelLabelledBy = useMemo(() => {
    if (activeTab === "inactive")
      return "ddb_inactive_ads_tab";
    if (activeTab === "paused")
      return "ddb_paused_ads_tab";
    if (activeTab === "hidden")
      return "ddb_hidden_ads_tab";
    if (activeTab === "expired")
      return "ddb_expired_ads_tab";
  }, [activeTab]);

  const adProps = {
    widgetId: id,
    rotation,
    playAd,
    pauseAd,
    editAd
  };

  return (
    <Transition>
      <WidgetPageBox>
        <Subheader title={name} backUrlAction={backUrlAction} />

        <Box marginT={3} marginHAuto>
          <Box className="ddb-max-content-width-m" marginHAuto paddingH={1.5}>
            <WidgetLicense
              isCommunity={isCommunity}
              places={places}
              placesUsed={placesUsed}
              rotation={rotation}
              fetchingEnabled={fetchingEnabled}
              filteringEnabled={filteringEnabled}
              filteringHashtag={filteringHashtag}
              categories={categories}
            />
          </Box>

          <ActiveAreaBox
            as="section"
            className="ddb-max-content-width-m"
            marginT={3}
            marginHAuto
            paddingH={1.5}
          >
            <Text as="h2" styledAs="h3" marginB={1}>
              {isCommunity
                ? t("general.dashboard.active_ads_lo_com")
                : t("general.dashboard.active_ads")}
            </Text>

            {activeAds?.map((ad) => (
              <Box key={ad.id} marginB={2}>
                <Ad {...ad} {...adProps} isCommunity={isCommunity} />
              </Box>
            ))}

            <CreateAd
              action={createAd}
              extended={!activeAds?.length}
              isCommunity={isCommunity}
            />
          </ActiveAreaBox>

          <InactiveAreaBox as="section" marginT={3} paddingT={3} paddingB={6}>
            <Box className="ddb-max-content-width-m" marginHAuto paddingH={1.5}>
              <span className="ddb-visually-hidden">{sectionHeading}</span>
              <TabsList role="tablist" aria-label={t("general.widget.inactive_tabs_label")} >
                {inactiveAdsTabs?.map((tab, index) => (
                  <TabLink
                    key={index}
                    tabId={tab.id}
                    tabPanelId="ddb_tabpanel"
                    onClick={tab.onClick}
                    title={tab.title}
                    active={tab.active}
                    doScrollToActive={false}
                  />
                ))}
              </TabsList>

              <Loader active={!inactiveAdsLoaded}>
                <div role="tabpanel" id="ddb_tabpanel" aria-labelledby={tabPanelLabelledBy}>
                  {inactiveAds?.map((ad) => (
                    <Box key={ad.id} marginB={2}>
                      <Ad {...ad} {...adProps} isCommunity={isCommunity} />
                    </Box>
                  ))}

                  {showLoadMoreButton && (
                    <Button more onClick={loadMoreAds}>
                      {isCommunity
                        ? t("general.dashboard.load_more_btn_lo_com")
                        : t("general.dashboard.load_more_btn")}
                    </Button>
                  )}

                  {!inactiveAds.length && (
                    <Box textCenter marginT={6}>
                      {isCommunity
                        ? t("general.widget.no_ads_lo_com")
                        : t("general.widget.no_ads")}
                    </Box>
                  )}
                </div>
              </Loader>
            </Box>
          </InactiveAreaBox>
        </Box>
      </WidgetPageBox>
    </Transition>
  );
};

export default WidgetComponent;
