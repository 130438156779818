import React, { VFC, FormEvent, useState, useCallback, useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { pxToRem } from "../../../util/helpers";
import Box from "../../particles/Box";
import Icon from "../../atoms/Icon";
import CheckboxInput from "../CheckboxInput";
import { CheckboxTile, ToggleButton, NestedCheckboxesTile } from "./styled";

/**
 * !!!
 * For nested inputs work properly with the RHF use different names for them.
 * E.g.: "categories_1", "categories_2" instead of "categories".
 */

interface CheckboxGroupProps {
  input: {
    name: string;
    value: string | number;
    label: string;
    validate?: (value: any) => boolean | string;
    onChange?: (value: FormEvent<HTMLInputElement>) => void;
    errorId?: string;
  }
  nestedInputs?: {
    name: string;
    value: string | number;
    label: string;
    validate?: (value: any) => boolean | string;
    onChange?: (value: FormEvent<HTMLInputElement>) => void;
    errorId?: string;
  }[];
  toggleTileLabels?: { open: string; close: string };
}

const CheckboxGroupTile: VFC<CheckboxGroupProps> = ({
  input,
  nestedInputs,
  toggleTileLabels: _toggleTileLabels
}) => {
  const { t } = useTranslation();

  const { control } = useFormContext();
  const { name } = input;
  const checked = !!useWatch({ control, name });

  const [isOpen, setIsOpen] = useState(
    Boolean(checked && nestedInputs?.length)
  );

  const toggleTile = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  useEffect(() => {
    if (checked && nestedInputs?.length) setIsOpen(true);
  }, [checked]);

  const toggleTileLabel = useMemo(() => {
    const toggleTileLabels = _toggleTileLabels || {
      open: t("general.checkbox.toggle_btn_label_open"),
      close: t("general.checkbox.toggle_btn_label_close")
    };

    return isOpen ? toggleTileLabels.close : toggleTileLabels.open;
  }, [_toggleTileLabels, isOpen]);

  return (
    <>
      <CheckboxTile isOpen={isOpen}>
        <Box flex alignCenter>
          <Box flexDynamic>
            <CheckboxInput
              {...input}
              accent
            />
          </Box>
          {!!nestedInputs?.length && (
            <Box flexStatic inlineFlex>
              <ToggleButton
                onClick={toggleTile}
                clean
                isOpen={isOpen}
                label={toggleTileLabel}
              >
                <Icon name="arrow-toggle" size={pxToRem(18)} />
              </ToggleButton>
            </Box>
          )}
        </Box>
      </CheckboxTile>

      {!!nestedInputs?.length && isOpen && (
        <NestedCheckboxesTile paddingL={4} paddingR={2} paddingB={2}>
          {nestedInputs?.map((_input) => (
            <Box key={_input.value.toString()}>
              <CheckboxInput {..._input} />
            </Box>
          ))}
        </NestedCheckboxesTile>
      )}
    </>
  );
};

export default CheckboxGroupTile;
