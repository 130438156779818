import React, {
  FC,
  VFC,
  HTMLProps,
  MouseEvent,
} from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";

import constants from "../../_globalStyles/constants";
import Text, { TextProps } from "../Text";
import { pxToRem } from "../../../util/helpers";

interface SwitchProps {
  checked: boolean;
  disabled?: boolean;
  switchRef?: any;
  onClick: (e: MouseEvent<HTMLButtonElement>) => any;
  onText?: string;
  offText?: string;
  labelId?: string;
}

interface SwitchButtonProps extends HTMLProps<HTMLButtonElement> {
  checked: boolean;
}

interface SwitchLabelProps extends TextProps {
  checked: boolean;
}

const SwitchButton: FC<SwitchButtonProps> = styled.button`
  position: relative;
  margin: ${pxToRem(13)} ${pxToRem(16)};
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: 0;
  padding: 0;
  box-shadow: none;

  &:before {
    content: "";
    position: absolute;
    top: -${pxToRem(13)};
    bottom: -${pxToRem(13)};
    right: -${pxToRem(16)};
    left: -${pxToRem(16)};
    min-width: ${pxToRem(42)};
    border: 1px solid ${constants.BORDER_COLOR};
    border-radius: ${constants.BUTTON_BORDER_RADIUS};
    background: ${constants.SWITCH_OFF_BG_COLOR};
  }

  &:hover:enabled:before {
    background: ${constants.SWITCH_OFF_BG_HOVER_COLOR};
  }

  &:after {
    content: "";
    position: absolute;
    left: 0px;
    top: -${pxToRem(3)};
    width: ${pxToRem(19)};
    height: ${pxToRem(19)};
    background: ${constants.MAIN_BG_COLOR};
    box-shadow: 0 0 0 1px ${constants.SWITCH_BORDER_COLOR};
    border-radius: 50%;
    z-index: 2;
  }

  .ddb-keyboard-navigation-on &:focus {
    outline: 2px dotted ${constants.FOCUS_OUTLINE_COLOR};
    outline-offset: ${pxToRem(18)};
  }

  ${({ checked }) =>
    checked &&
    css`
      &:before {
        background: ${constants.SWITCH_ON_BG_COLOR};
      }

      &:hover:enabled:before {
        background: ${constants.SWITCH_ON_BG_HOVER_COLOR};
      }

      &:after {
        left: calc(100% - ${pxToRem(19)});
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
      }
    `};

  &:before {
    transition-property: background;
    transition-duration: 0.05s;
    transition-timing-function: ease-in;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

SwitchButton.displayName = "SwitchButton";

const SwitchLabel: FC<SwitchLabelProps> = styled(Text)`
  display: inline-flex;
  align-items: center;
  height: ${pxToRem(14)};
  min-width: ${pxToRem(42)};
  font-size: ${pxToRem(7)};
  line-height: ${pxToRem(8)};
  letter-spacing: ${pxToRem(0.5)};
  font-weight: 500;
  text-transform: uppercase;
  padding: 0 ${pxToRem(6)} 0 ${pxToRem(22)};
  border-radius: ${pxToRem(116)};
  border: 0;
  background: ${constants.SWITCH_OFF_COLOR};
  color: ${constants.SWITCH_OFF_TEXT_COLOR};
  box-shadow: 0 0 0 1px ${constants.SWITCH_BORDER_COLOR} inset;
  z-index: 2;

  ${({ checked }) =>
    checked &&
    css`
      background: ${constants.SWITCH_ON_COLOR};
      color: ${constants.SWITCH_ON_TEXT_COLOR};
      box-shadow: none;
      padding: 0 ${pxToRem(20)} 0 ${pxToRem(8)};
    `}
`;

SwitchLabel.displayName = "SwitchLabel";

const Switch: VFC<SwitchProps> = ({
  checked,
  disabled,
  onText: _onText,
  offText: _offText,
  labelId,
  onClick,
  switchRef
}) => {
  const { t } = useTranslation();

  const onText = _onText || t("general.switch.on");
  const offText = _offText || t("general.switch.off");

  return (
    <SwitchButton
      ref={switchRef || null}
      type="button"
      role="switch"
      aria-checked={checked}
      aria-labelledby={labelId || null}
      checked={checked}
      disabled={disabled}
      onClick={onClick}
    >
      {checked && (
        <SwitchLabel as="span" checked={checked} aria-hidden="true">
          {onText}
        </SwitchLabel>
      )}
      {!checked && (
        <SwitchLabel as="span" checked={checked} aria-hidden="true">
          {offText}
        </SwitchLabel>
      )}
    </SwitchButton>
  );
};

Switch.defaultProps = {
  checked: false,
  disabled: false
};

export default Switch;
