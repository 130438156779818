import { FC, HTMLProps } from "react";
import styled from "styled-components";

import constants from "../../_globalStyles/constants";
import Box, { BoxProps } from "../../particles/Box";
import Tile, { TileProps } from "../../atoms/Tile";

export const ErrorTile: FC<TileProps> = styled(Tile)`
  width: 100%;
  padding: ${constants.GRID_UNIT * 2}px;
  background: #FAE6E6;
  border: 0;
  box-shadow: 0px 4px 8px rgba(229, 37, 37, 0.08);
`;

ErrorTile.displayName = "ErrorTile";

export const ErrorIconBox: FC<BoxProps> = styled(Box)`
  @media (max-width: ${constants.BREAKPOINT_MOBILE_L}px) {
    display: none;
  }
`;

ErrorIconBox.displayName = "ErrorIconBox";

export const ErrorButtonBox: FC<HTMLProps<HTMLDivElement>> = styled.div`
  position: relative;
  z-index: 1;
  margin-top: ${constants.GRID_UNIT}px;
`;

ErrorButtonBox.displayName = "ErrorButtonBox";
