import React, {
  VFC,
  useRef,
  useMemo,
  useCallback,
  useState,
  useEffect
} from "react";
import { useTranslation } from "react-i18next";

import { pxToRem } from "../../../util/helpers";
import Text from "../../atoms/Text";
import {
  TriggerButton,
  DropdownWrap,
  DropdownUl,
  DropdownLi,
  DropdownOption,
  ResetButton,
  SelectIcon,
  LabelIcon,
  CheckIcon,
  ResetIcon,
  Popover
} from "./styled";

const Option = ({
  onOptionClick,
  item: { value, label, disabled, selected }
}) => {
  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      onOptionClick(value);
    },
    [value, onOptionClick]
  );

  return (
    <DropdownLi>
      {disabled ? (
        <DropdownOption role="button" disabled aria-disabled tabIndex={-1}>
          {label}
        </DropdownOption>
      ) : (
        <DropdownOption
          href="#"
          role="option"
          onClick={onClick}
          active={selected}
        >
          {selected && <CheckIcon name="check-wide" />}
          {label}
        </DropdownOption>
      )}
    </DropdownLi>
  );
};

const ListBox = ({ listId, listLabel, options, selection, onOptionClick }) => {
  const list = options.map((o) => ({
    ...o,
    selected: selection.indexOf(o.value) === -1 ? false : true
  }));

  return (
    <DropdownUl id={listId} role="listbox" aria-label={listLabel}>
      {list.map((item) => (
        <Option key={item.value} item={item} onOptionClick={onOptionClick} />
      ))}
    </DropdownUl>
  );
};

interface DropdownProps {
  id?: string;
  name: string;
  label: string;
  selectionLabel: string;
  icon?: string;
  options: {
    label: string;
    value: number | string;
    disabled?: boolean;
  }[];
  selectedOptions?: (string | number)[];
  onSelect?: (value: (number | string)[]) => void;
}

const MultiDropdown: VFC<DropdownProps> = (props) => {
  const { t } = useTranslation();

  const {
    id: _id,
    name,
    label,
    selectionLabel,
    icon,
    options,
    selectedOptions,
    onSelect
  } = props;

  const id = _id || name;
  const buttonId = `ddb_${id}_dropdown_trigger`;
  const listId = `ddb_${id}_dropdown_list`;

  const [isOpen, setIsOpen] = useState(false);
  const [selection, setSelection] = useState(selectedOptions);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const closeMenu = () => {
    setIsOpen(false);
    window.removeEventListener("click", closeMenuOnClickOutside);
    window.removeEventListener("keyup", closeMenuOnEsc);
  };

  const openMenu = useCallback(() => {
    setIsOpen(true);
    window.addEventListener("click", closeMenuOnClickOutside);
  }, []);

  const toggleMenu = useCallback(
    () => (isOpen ? closeMenu() : openMenu()),
    [isOpen, closeMenu, openMenu]
  );

  const closeMenuOnEsc = useCallback(
    (e) => {
      if (e.key === "Escape" || e.key === "Esc") {
        closeMenu();
      }
    },
    [closeMenu]
  );

  const closeMenuOnFocusOut = useCallback(
    (e) => {
      if (!e.currentTarget.contains(e.relatedTarget)) {
        closeMenu();
      }
    },
    [closeMenu]
  );

  const closeMenuOnClickOutside = useCallback(
    (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target))
        closeMenu();
    },
    [dropdownRef, closeMenu]
  );

  const clearSelection = useCallback(() => setSelection([]), []);

  const selectAll = useCallback(() => {
    const _selection = options.map((o) => o.value);
    setSelection(_selection);
  }, [options]);

  const updateAllItemSelection = useCallback(
    (_selection) => {
      const allI = _selection.indexOf("all");
      let allSelected = false;

      if (
        (allI === -1 && _selection.length === options.length - 1) ||
        _selection.length === options.length
      )
        allSelected = true;

      if (allI === -1 && allSelected) _selection.push("all");
      if (allI !== -1 && !allSelected) _selection.splice(allI, 1);
      return _selection;
    },
    [options]
  );

  const onOptionClick = useCallback(
    (item) => {
      const i = selection.indexOf(item);

      if (item === "all") {
        if (i === -1) selectAll();
        else clearSelection();
      } else {
        let _selection = [...selection];

        if (i === -1) _selection.push(item);
        else _selection.splice(i, 1);

        _selection = updateAllItemSelection(_selection);

        setSelection(_selection);
      }
    },
    [selection, selectAll, clearSelection, updateAllItemSelection]
  );

  const buttonLabel = useMemo(() => {
    const _selection = selection.reduce(
      (acc, curr) => (curr !== "all" ? acc.concat(curr) : acc),
      []
    );

    if (!_selection.length) return label;
    if (_selection.length === 1)
      return options.find((o) => o.value == _selection[0])?.label;
    else {
      return `${_selection.length} ${selectionLabel}`;
    }
  }, [label, selectionLabel, selection, options]);

  useEffect(() => {
    onSelect && onSelect(selection);
  }, [selection]);

  return (
    <DropdownWrap
      ref={dropdownRef}
      isOpen={isOpen}
      hasValue={selection.length > 0}
      onBlur={closeMenuOnFocusOut}
    >
      <div className="ddb-visually-hidden">{buttonLabel}</div>

      <TriggerButton
        id={buttonId}
        type="button"
        aria-label={buttonLabel}
        aria-expanded={isOpen}
        aria-controls={listId}
        big
        onClick={toggleMenu}
      >
        {icon && <LabelIcon name={icon} />}
        <Text
          as="span"
          medium
          fontSize={pxToRem(18)}
          lineHeight={pxToRem(24)}
          ellipsis
        >
          {buttonLabel}
        </Text>
        <SelectIcon name="arrow-toggle" />
      </TriggerButton>

      {selection.length > 0 && (
        <ResetButton type="button" onClick={clearSelection}>
          <ResetIcon name="close" />
          <span className="ddb-visually-hidden">
            {t("accessibility.reset")}
          </span>
        </ResetButton>
      )}

      {isOpen && (
        <Popover>
          <ListBox
            listId={listId}
            listLabel={label}
            options={options}
            selection={selection}
            onOptionClick={onOptionClick}
          />
        </Popover>
      )}
    </DropdownWrap>
  );
};

export default MultiDropdown;

MultiDropdown.defaultProps = {
  selectedOptions: []
};
