import { VFC, HTMLProps } from "react";
import styled, { css } from "styled-components";

import constants from "../_globalStyles/constants";
import { pxToRem } from "../../util/helpers";

interface TextareaBaseProps extends HTMLProps<HTMLTextAreaElement> {
  error?: boolean;
}

const TextareaBase: VFC<TextareaBaseProps> = styled.textarea`
  position: relative;
  width: 100%;
  flex: 1 1 auto;
  font-size: ${pxToRem(16)};
  line-height: 1.5;
  font-weight: 400;
  padding: 0.5em 1em;
  overflow-y: hidden;
  box-shadow: inset 0px 0px 0px 1px ${constants.INPUT_BORDER_COLOR};
  border-radius: 8px;
  -webkit-appearance: none;
  border: none;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus {
    box-shadow: inset 0px 0px 0px 2px ${constants.INPUT_FOCUS_BORDER_COLOR};
    outline: none;
  }

  &:disabled {
    box-shadow: inset 0px 0px 0px 1px ${constants.INPUT_BORDER_COLOR} !important;
    outline: none !important;
  }

  &:read-only {
    color: ${constants.INPUT_READONLY_COLOR};
  }

  &:read-only:focus {
    box-shadow: inset 0px 0px 0px 2px ${constants.INPUT_READONLY_COLOR} !important;
    outline: none;
  }

  ${({ error }) =>
    error &&
    css`
       {
        box-shadow: inset 0px 0px 0px 1px ${constants.ERROR_COLOR} !important;
        outline: none;
      }
    `}

  &:focus {
    ${({ error }) =>
    error &&
    css`
       {
        box-shadow: inset 0px 0px 0px 2px ${constants.ERROR_COLOR} !important;
      }
    `}
  }

  transition: box-shadow 0.05s ease-in;
`;

TextareaBase.displayName = "TextareaBase";

export default TextareaBase;
