import React, { VFC, ReactNode } from "react";

import { pxToRem, pxToEm } from "../../../util/helpers";
import Box from "../../particles/Box";
import Tile from "../../atoms/Tile";
import Text from "../../atoms/Text";
import Button from "../../atoms/Button";
import IconBadge from "../../molecules/IconBadge";

export interface PageMessageProps {
  title: string;
  titleAs?: string;
  text?: string;
  textElement?: ReactNode;
  icon?: string;
  ctaText?: string;
  ctaAction?: () => void;
  cancelText?: string;
  cancelAction?: () => void;
  controlElement?: ReactNode;
  width?: string | number;
  status?: "success" | "warning" | "error";
}

const PageMessage: VFC<PageMessageProps> = ({
  status,
  title,
  titleAs,
  text,
  textElement,
  icon,
  controlElement,
  ctaAction,
  ctaText,
  cancelText,
  cancelAction,
  width
}) => (
  <>
    <Tile
      paddingT={6}
      paddingB={8}
      paddingH={10}
      marginHAuto
      width={width}
      radius={4}
      className="ddb-desktop ddb-desktop-s ddb-mobile-l"
    >
      <Box textCenter>
        {icon && <IconBadge name={icon} status={status} />}
        <Box marginT={3} marginB={2}>
          <Text as={titleAs} styledAs="h2">
            {title}
          </Text>
        </Box>
        {text && (
          <Text formatted textLeft fontSize={pxToRem(18)} lineHeight={pxToEm(28, 18)}>
            {text}
          </Text>
        )}
        {textElement}
        <Box marginT={6}>
          {ctaText && ctaAction && (
            <Box marginH={1}>
              <Button cta onClick={ctaAction}>
                {ctaText}
              </Button>
            </Box>
          )}
          {cancelText && cancelAction && (
            <Box marginH={1} marginT={1}>
              <Button big onClick={cancelAction}>
                {cancelText}
              </Button>
            </Box>
          )}
          {controlElement}
        </Box>
      </Box>
    </Tile>
    <Tile paddingH={2} paddingV={2} className="ddb-mobile">
      <Box textCenter>
        {icon && <IconBadge name={icon} status={status} small />}
        <Box marginT={2} marginB={2} textLeft>
          <Text as={titleAs} styledAs="h3">
            {title}
          </Text>
        </Box>
        {text && (
          <Text formatted textLeft>
            {text}
          </Text>
        )}
        {textElement}
        <Box marginT={2}>
          {ctaText && ctaAction && (
            <Button primary onClick={ctaAction}>
              {ctaText}
            </Button>
          )}
          {cancelText && cancelAction && (
            <Box marginT={1}>
              <Button onClick={cancelAction}>{cancelText}</Button>
            </Box>
          )}
          {controlElement}
        </Box>
      </Box>
    </Tile>
  </>
);

PageMessage.defaultProps = {
  titleAs: "h2",
  width: 80, // = 640px
};
export default PageMessage;
