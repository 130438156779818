import React, { VFC, useEffect, useState, useCallback } from "react";
import { useWatch } from "react-hook-form";

import { valueExists, pxToRem } from "../../../util/helpers";
import constants from "../../../constants";
import Box from "../../particles/Box";
import Button from "../../atoms/Button";
import Section from "../../molecules/Section";
import TextInput from "../../organisms/TextInput";
import { t } from "i18next";

interface UrlSectionProps {
  title: string;
  titleAs?: string;
  inputName: string;
  inputLabel: string;
  submitText: string;
  onSubmit: (value: string) => Awaited<Promise<any>>;
  getFieldState: (name: string) => any;
  control: any;
}

const UrlSection: VFC<UrlSectionProps> = ({
  title,
  titleAs,
  inputName,
  inputLabel,
  submitText,
  onSubmit: _onSubmit,
  getFieldState,
  control
}) => {
  const value = useWatch({ control, name: inputName });
  const inputState = getFieldState(inputName);
  const isOpen = valueExists(value);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitAllowed, setIsSubmitAllowed] = useState(
    value && !inputState.invalid
  );

  const onSubmit = useCallback(() => {
    setIsSubmitting(true);
    _onSubmit(value).then(() => setIsSubmitting(false));
  }, [value]);

  useEffect(() => {
    setIsSubmitAllowed(value && !inputState.invalid);
  }, [value, inputState]);

  return (
    <Section title={title} titleAs={titleAs} expandable isOpen={isOpen}>
      <Box marginB={0.5} marginT={pxToRem(8)}>
        <TextInput
          name={inputName}
          label={inputLabel}
          type="url"
          pattern={{
            value: constants.URL_REG,
            message: t("errors.invalid_parse_url")
          }}
        />
      </Box>
      {isSubmitAllowed && (
        <Box marginT={2}>
          <Button onClick={onSubmit} disabled={isSubmitting}>
            {submitText}
          </Button>
        </Box>
      )}
    </Section>
  );
};

UrlSection.defaultProps = {
  titleAs: "h2"
};

export default UrlSection;
