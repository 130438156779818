/**
 * Use this component to provide discernible text in links with no text.
 */
import React, { FC, HTMLProps } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import constants from "../_globalStyles/constants";
import Box, { BoxProps } from "../particles/Box";

export interface LinkBaseProps extends HTMLProps<HTMLAnchorElement & BoxProps> {
  to?: string | (() => string);
  href?: string;
  target?: string;
  label?: string;
  styledAs?: "a";
}

const StyledLink = styled(Link)`
  &,
  &:link,
  &:visited {
    color: ${constants.LINK_COLOR};
    text-decoration: none;
  }

  &:hover {
    color: ${constants.LINK_HOVER_COLOR};
  }

  &:active,
  &:focus {
    color: ${constants.LINK_ACTIVE_COLOR};
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
`;

StyledLink.displayName = "StyledLink";

const StyledAnchor: FC<LinkBaseProps> = styled(Box).attrs({ as: "a" })`
  ${(props) =>
    props.styledAs === "a" &&
    css`
      &,
      &:link,
      &:visited {
        color: ${constants.LINK_COLOR};
        text-decoration: none;
      }

      &:hover {
        color: ${constants.LINK_HOVER_COLOR};
      }

      &:active,
      &:focus {
        color: ${constants.LINK_ACTIVE_COLOR};
      }

      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    `}
`;

StyledAnchor.displayName = "StyledAnchor";

const LinkBase: FC<LinkBaseProps> = ({
  to,
  href,
  target,
  label,
  children,
  styledAs,
  ...props
}) => {
  if (to) {
    return (
      <StyledLink to={to} state={{ fromDashboard: true }}>
        {children}
        {label && <span className="ddb-visually-hidden">{label}</span>}
      </StyledLink>
    );
  }

  const rel = target === "_blank" ? "noopener noreferrer" : "";

  return (
    <StyledAnchor href={href} target={target} rel={rel} {...props}>
      {children}
      {label && <span className="ddb-visually-hidden">{label}</span>}
    </StyledAnchor>
  );
};

LinkBase.defaultProps = {
  target: "_self"
};

export default LinkBase;
